import React, { useState } from "react";
import arrowDown from "../../assets/img/popup/arrow-down.svg";
export const NetworkDropdown = ({
  subheading,
  heading,
  list,
  stake = null,
  classStyle,
  placeholder = "Select",
  onChange = () => {},
}) => {
  const [active, setActive] = useState(false);
  return (
    <div className="dropdown relative ">
      <div
        className={`dropdown-head bg-[#2E2E2E] cursor-pointer rounded flex px-3 py-2 items-center ${classStyle} `}
        onClick={(e) => {
          setActive(!active);
        }}
      >
        <p className="flex-1 text-[18px] sm:text-[14px] text-white font-arabicreg ">
          {heading ?? placeholder}
        </p>
        {/* <span className="flex-1 ml-3 sm:text-[14px] text-[#AEB2B1] text-[18px] font-arabicreg">
          BNB Smart Chain (BEP20)
        </span> */}
        <img src={arrowDown} alt="" className="ml-3 " />
      </div>
      {active && (
        <ul className="paydropdown w-full absolute z-10 bg-[#2E2E2E]">
          {list.map((el) => (
            <li
              className="h-10 flex items-center pl-3 cursor-pointer"
              onClick={(e) => {
                setActive(false);
              }}
            >
              <p className="text-[18px] text-white font-arabicreg ">
                {el}
              </p>
              {/* <span className="flex-1 ml-3 text-[#AEB2B1] text-[18px] font-arabicreg">
                {EachList.subheading}
              </span> */}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
