import React, { useState } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import ButtonLoader from "../../components/ButtonLoader";
import { useTradingData } from '../../queries/useTradingData';
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";
export const RightAreaTradingPerformance = ({ setsigninState, setSignUpState, login }) => {
  const { totalTrades, totalProfitPercent, winRate, recentTrades, isLoading, isError, error } = useTradingData();
  const [typeShowAside, settypeShowAside] = useState(-1);

  if (isLoading) return <div className="flex justify-center items-center h-screen w-full">
    <div className='text-white leading-10'>
    <ButtonLoader /> 
    Loading Trading Performance
    </div>
  </div>;
  if (isError) return <div className="flex justify-center items-center h-screen text-red-500">An error occurred: {error.message}</div>;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };


  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(price);
  };

  return (
    <div className="flex-1 bg-gray-900 text-white h-screen flex flex-col">
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex-grow overflow-hidden p-6">
        <div className="bg-gray-900 rounded-lg shadow-lg h-full flex flex-col">
          <div className="flex justify-between items-center px-6 py-4 bg-gray-700">
            <h1 className="text-2xl font-bold">Trading Performance</h1>
            <div className="stats flex space-x-4 text-sm">
              <div className="stat-item">
                <span className="text-gray-400">Total Trades:</span>
                <span className="ml-2 font-semibold">{totalTrades}</span>
              </div>
              <div className="stat-item">
                <span className="text-gray-400">Win Rate:</span>
                <span className={`ml-2 font-semibold ${winRate >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                  {winRate?.toFixed(2)}%
                </span>
              </div>
            </div>
          </div>
          <div className="flex-grow ">
            <table className="w-full">
              <thead className="bg-gray-900 sticky top-0">
                <tr className="text-left text-sm">
                  <th className="px-4 py-2 w-1/7">Timestamp</th>
                  <th className="px-4 py-2 w-1/7">Pair</th>
                  <th className="px-4 py-2 w-1/7">Type</th>
                  <th className="px-4 py-2 w-1/7">Entry Price</th>
                  <th className="px-4 py-2 w-1/7">Exit Price</th>
                  <th className="px-4 py-2 w-1/7">Exchange</th>
                  <th className="px-4 py-2 w-1/7">Profit/Loss</th>
                </tr>
              </thead>
              <tbody >
                {recentTrades.map(trade => {
                  const profitLossPercent = trade.direction === 'long'
                    ? ((trade.closePrice - trade.openPrice) / trade.openPrice * 100).toFixed(2)
                    : ((trade.openPrice - trade.closePrice) / trade.openPrice * 100).toFixed(2);
                  const profitLossColor = profitLossPercent >= 0 ? 'text-green-500' : 'text-red-500';
                  return (
                    <tr key={trade._id} className="border-b border-gray-700 hover:bg-gray-750 text-sm">
                      <td className="px-4 py-2 w-1/7">{formatDate(trade.closeTimestamp)}</td>
                      <td className="px-4 py-2 w-1/7">{trade.pair}</td>
                      <td className="px-4 py-2 w-1/7 capitalize">{trade.direction}</td>
                      <td className="px-4 py-2 w-1/7">{formatPrice(trade.openPrice)}</td>
                      <td className="px-4 py-2 w-1/7">{formatPrice(trade.closePrice)}</td>
                      <td className="px-4 py-2 w-1/7">{trade.exchange}</td>
                      <td className={`px-4 py-2 w-1/7 ${profitLossColor} flex items-center`}>
                        {profitLossPercent >= 0 ? <FaArrowUp className="mr-1" /> : <FaArrowDown className="mr-1" />}
                        {Math.abs(profitLossPercent)}%
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <SidebarArea typeShowAside={typeShowAside} />
    </div>
  );
};