//gaming.js
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

// export const usePlayGameMutation = (gameType) => {
//   const current_language = gt_get_cookie('googtrans')?.split('/').pop();
//   //console.log("cur", current_language);
//   return useMutation(async (gameId) => {
//     try {
//       let response;
//       // console.log("LANGUAGE: ", gameType, gameId, current_language)
//       if (gameType === "original") {
//         response = await privateRequest.post("imoon-game/play-imoon", { gameId, current_language });
//       } else if (gameType === "sports") {
//         response = await privateRequest.post(`bc-game/sports-game?gameId=${gameId}`);
//       } else {
//         response = await privateRequest.post("bc-game/play-game", { gameId });
//       }
//       //console.log("URL RESPONSE", response.data);
//       return response.data ?? {};
//     } catch (error) {
//       console.error(error);
//       throw new Error(error?.response?.data?.error ?? "Error playing game");
//     }
//   });
// };
export const usePlayGameMutation = (gameType) => {
  const current_language = gt_get_cookie('googtrans')?.split('/').pop();

  return useMutation(async ({ gameId, freePlay=false }) => {
    try {
      let response;
      const payload = {
        gameId,
        freePlay,
        current_language
      };

      if (gameType === "original") {
        response = await privateRequest.post("imoon-game/play-imoon", payload);
      } else if (gameType === "sports") {
        response = await privateRequest.post(`bc-game/sports-game?gameId=${gameId}`, payload);
      } else {
        response = await privateRequest.post("bc-game/play-game", payload);
      }

      return response.data ?? {};
    } catch (error) {
      console.error(error);
      throw new Error(error?.response?.data?.error ?? "Error playing game");
    }
  });
};


export const fetchSports = async () => {
  try {
    const res = await privateRequest.post("bc-game/sports-game");
    const embed_code = res.data.data.embed_code;
    return embed_code;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports games"
    );
  }
};

export const fetchSportsBetGroups = async (tab) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-groups", { tab });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports bet groups"
    );
  }
};
export const fetchFavouriteSportList = async (sportName) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-favouritelist", { sportName })
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching favourite sports bet groups"
    );
  }
};
export const postFavouriteSport = async (sportName, status) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-favourites", { sportName, status });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error posting favourite sports bet groups"
    );
  }
};
export const postSportsBet = async (betData, tab) => {
  try {
    const response = await privateRequest.post("bc-game/post-sportsbet", { betData, tab });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error saving sports P2P bet"
    );
  }
};
export const refundSportsBet = async (transaction_id, tab) => {
  try {
    const response = await privateRequest.post("bc-game/refund-sportsbet", { transaction_id, tab });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error refunding sports P2P bet"
    );
  }
};
export const fetchSportsBet = async (tab) => {
  try {
    const response = await privateRequest.post("bc-game/get-sportsbet", { tab });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching P2P sports bet"
    );
  }
};
export const fetchMySportsBet = async (type) => {
  try {
    const response = await privateRequest.post("bc-game/get-mybets", { type });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching My P2P sports bet"
    );
  }
};
export const fetchAllSportsBet = async (type) => {
  try {
    const response = await privateRequest.post("bc-game/get-allsportsbet", { type });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching All P2P sports bet"
    );
  }
};
export const fetchSportsBetHighRollers = async (type) => {
  try {
    const response = await privateRequest.post("bc-game/get-sportsbet-highrollers", { type });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching P2P Sports Bet High Rollers"
    );
  }
};
export const fetchSportsBetLeaderboards = async (type) => {
  try {
    const response = await privateRequest.post("bc-game/get-sportsbet-leaderboards", { type });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching P2P Sports Bet Leaderboards"
    );
  }
};
export const fetchSportsBetCategory = async (sportName) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-category", { sportName });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports bet category"
    );
  }
};
export const fetchSportsEvents = async (keys, tab) => {
  try {
    const response = await privateRequest.post("bc-game/sport-events", { keys, tab });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports events"
    );
  }
};


export const fetchProviders = async ({ category, subcategory }) => {
  try {

    const queryParams = [
      category ? `category=${encodeURIComponent(category)}` : "",
      subcategory ? `subcategory=${encodeURIComponent(subcategory)}` : "",
    ].filter(Boolean).join("&");
    const res = await privateRequest.get(
      `bc-game/get-providers?${queryParams}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching providers list"
    );
  }
};
export const fetchAllSubcategory = async ({ category }) => {
  try {
    const res = await privateRequest.get(
      `bc-game/get-subcategories?category=${encodeURIComponent(category)}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching subcategory details"
    );
  }
};
export const useFetchGamesPerCategory = ({
  selectedProvider,
  category,
  subcategory,
  mobile,
  searchGame
}) => {
  return useInfiniteQuery(
    ["games", selectedProvider, category, subcategory, mobile, searchGame],
    async ({ pageParam = 1 }) => {
      try {
        const condition = category !== "gaminghub" && category !== "hot-games" && category !== "Sports";
        if (condition) {
          const queryParams = [
            `page=${pageParam}`,
            mobile ? `mobile=${mobile}` : "",
            selectedProvider ? `provider=${selectedProvider}` : "",
            category ? `category=${encodeURIComponent(category)}` : "",
            subcategory ? `subcategory=${subcategory}` : "",
            searchGame ? `searchGame=${searchGame}` : "",
          ]
            .filter(Boolean)
            .join("&");

          const url = `bc-game/categories?${queryParams}`;
          console.log("url games = " + url);

          const res = await privateRequest.get(url);
          console.log(res.data)
          return res.data;
        } else {
          return {};
        }

      } catch (error) {
        console.error(error);
        throw new Error(error?.response?.data?.error ?? "Error fetching games");
      }
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length;
        const maxPage = Math.ceil(lastPage.total / 60);
        return currentPage < maxPage ? currentPage + 1 : undefined;
      },
    }
  );
};

export const useSubcategoryQuery = (game_subcategory) => {
  return useQuery(["subcategory", game_subcategory], async () => {
    const data = await fetchAllSubcategory(game_subcategory);
    return data;
  });
};
export const useCategories = ({ type }) => {
  return useQuery("categories", async () => {
    const queryParams = [type ? `${type}` : ""];
    const res = await privateRequest.get(
      `bc-game/get-categories?${queryParams}`
    );
    return res.data.categories;
  });
};
export const useInfiniteGames = ({
  filterCategory,
  type,
  types,
  mobile,
} = {}) => {
  return useInfiniteQuery(
    ["games", filterCategory, type, types, mobile],
    async ({ pageParam = 1 }) => {
      const queryParams = [
        `page=${pageParam}`,
        type ? `type=${type}` : "",
        types ? `types=${types}` : "",
        mobile ? `mobile=${mobile}` : "",
      ]
        .filter(Boolean)
        .join("&");

      const url = `bc-game/get-games?${queryParams}`;

      const res = await privateRequest.get(url);
      return res.data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length;
        const maxPage = Math.ceil(lastPage.total / 30);
        return currentPage < maxPage ? currentPage + 1 : undefined;
      },
    }
  );
};

export const useHotGames = ({ filterCategory, type, types, mobile, limit = 30, searchGame } = {}) => {
  return useInfiniteQuery(
    ["games", filterCategory, type, types, mobile, limit, searchGame],
    async ({ pageParam = 1 }) => {
      const queryParams = [
        `page=${pageParam}`,
        filterCategory ? `category=${filterCategory}` : "",
        type ? `type=${type}` : "",
        types ? `types=${types}` : "",
        mobile ? `mobile=${mobile}` : "",
        limit ? `limit=${limit}` : "",
        searchGame ? `searchGame=${searchGame}` : "",
      ]
        .filter(Boolean)
        .join("&");
      const url = `bc-game/hot-games?${queryParams}`;
      const res = await privateRequest.get(url);
      return res.data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length;
        const maxPage = Math.ceil(lastPage.total / 60);
        return currentPage < maxPage ? currentPage + 1 : undefined;
      },
    }
  );
};

export const useGameDetailsQuery = (gameId, gameType) => {
  return useQuery(
    ["gameDetails", gameId],
    async () => {
      // console.log("GAME TYPE RECEIVED: ", gameType);
      let response;
      if (gameType === "original") {
        response = await privateRequest.get(`imoon-game/game-iMoondetails/${gameId}`);
      } else {
        response = await privateRequest.get(`bc-game/game-details/${gameId}`);
      }
      return response.data;
    },
    // {
    //   enabled: !!gameId, // Only run query if gameId is provided
    // }
  );
};

export const GetImoonGames = () => {
  return useQuery("imoongames", async () => {
    const res = await privateRequest.get(`imoon-game/getImoon-games`);
    return res.data;
  })
};
function gt_get_cookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie?.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

