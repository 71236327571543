import React, { useState } from "react";
import master from "../../assets/img/wallet/master.svg";
import greenArrow from "../../assets/img/wallet/green-arrow.svg";
import step2 from "../../assets/img/wallet/step2.svg";

export const DepositeFiatTwo = ({ setStep }) => {
  const [PaymentState, setPayment] = useState(1);

  return (
    <div>
      <h1 className="text-[24px] text-white font-arabicmed">Deposit Fiat</h1>

      <div className="flex items-start mt-[51px] gap-12 ">
        <img src={step2} alt="" className="" />
        <div className="flex-1">
          <h1 className="text-[22px] text-white font-arabicmed mb-[52px]">
            Enter deposit amount and place order
          </h1>

          <div className="">
            <div className="flex items-center justify-between">
              <label
                htmlFor="#"
                className="text-[16px] font-arabicreg text-[#AEB2B1]"
              >
                Amount
              </label>

              <p className="text-[16px] font-arabicreg text-[#AEB2B1]">
                Available limit:{" "}
                <span className="text-white font-arabicmed">43,170 EUR</span>
              </p>
            </div>
            <div className="relative">
              <input
                type="text"
                placeholder="300"
                className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white placeholder:text-white text-[16px] font-arabicmed px-6"
              />
              <p className="text-[18px] text-white absolute right-5 top-6">
                EUR
              </p>
            </div>
          </div>

          <ul className="my-[39px]">
            <li className="flex items-center justify-between">
              <h1 className="text-[#AEB2B1] text-[16px] font-arabicreg">
                You Receive
              </h1>
              <p className="text-[#fff] text-[24px] font-arabicmed">
                294.60 EUR
              </p>
            </li>
            <li className="flex items-center justify-between mt-6">
              <h1 className="text-[#AEB2B1] text-[16px] font-arabicreg">
                Payment Method:
              </h1>
              <div className="flex items-center gap-1">
                <img src={master} alt="" />
                <p className="text-[#fff] text-[16px] font-arabicmed">
                  ****7014
                </p>
                <a
                  href="#"
                  className="flex items-center text-[#5DF9C2] text-[16px] font-arabicmed"
                >
                  Change Card <img src={greenArrow} alt="" className="ml-1" />
                </a>
              </div>
            </li>
            <li className="flex items-center justify-between mt-6">
              <h1 className="text-[#AEB2B1] text-[16px] font-arabicreg">
                Transaction Fee:
              </h1>
              <p className="text-[#fff] text-[16px] font-arabicmed">5.40 EUR</p>
            </li>
          </ul>

          <div className="grid grid-cols-2 gap-6">
            <button
              className="text-[#fff] text-[16px] font-arabicbold flex items-center gap-1 w-full h-[50px] rounded-lg bg-[#252525] justify-center 
            border-[1px] border-[#3C403F]"
              onClick={(e) => {
                setStep(1);
              }}
            >
              Previous
            </button>

            <button
              className="text-[#111010] text-[16px] font-arabicbold flex items-center gap-1 w-full h-[50px] rounded-lg bg-gradient-to-br from-[#9BE402] to-[#1FA83C] justify-center"
              onClick={(e) => {
                setStep(3);
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
