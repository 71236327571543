import React, { useState } from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Header } from "../../components/admin/Header";
import { ActivityBox } from "../../components/admin/ActivityBox";
import { Footer } from "../../components/admin/Footer";
import ReactApexChart from "react-apexcharts";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import search from "../../assets/img/admin/svg/search.svg";
import arrowdown from "../../assets/img/admin/svg/arrow-down.svg";
import per_country from "../../assets/img/admin/svg/per_country.svg";

// utils
import { state, state2 } from "../../utils/ChartFeatured";
export const Dashboard = () => {
  const [activeTab, setactiveTab] = useState(null);
  return (
    <div>
      <div className="flex items-start home admin-dashboard">
        {/* left area */}
        <Aside active={1} />

        <div className="flex-1">
          <Header heading="Home" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Dashboard</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                Home
              </button>
            </div>

            <div className="agent-wrapper agent-wrapper-dashboard mt-8 p-6 rounded-xl">
              <div className="top-agent flex items-center justify-between">
                <h1>Agent</h1>
                <p>2023-09-14 / 23:54:12</p>
              </div>
              <div className="bottom-agent mt-3  gap-2 flex items-center">
                <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                  <img src={search} alt="" />

                  <input
                    type="text"
                    placeholder="Search Agent"
                    className="flex-1 h-full bg-transparent border-0 outline-none ml-2"
                  />
                  <img src={arrowdown} alt="" />
                </div>

                <button
                  className={`rounded-lg px-5 ${activeTab == 1 && "active"}`}
                  onClick={(e) => {
                    setactiveTab(1);
                  }}
                >
                  Daily
                </button>
                <button
                  className={`rounded-lg px-5 ${activeTab == 2 && "active"}`}
                  onClick={(e) => {
                    setactiveTab(2);
                  }}
                >
                  Weekly
                </button>
                <button
                  className={`rounded-lg px-5 ${activeTab == 3 && "active"}`}
                  onClick={(e) => {
                    setactiveTab(3);
                  }}
                >
                  Monthly
                </button>
                <button
                  className={`rounded-lg px-5 ${activeTab == 4 && "active"}`}
                  onClick={(e) => {
                    setactiveTab(4);
                  }}
                >
                  Custom
                </button>
                <button
                  className="rounded px-5"
                  onClick={(e) => {
                    setactiveTab(null);
                  }}
                >
                  Reset Filter
                </button>
              </div>
            </div>

            <div className="charts-wrapper grid grid-cols-3 gap-4 mt-8">
              <div className="chart py-4 rounded-xl">
                <div className="chart-top px-4 pb-2 ">
                  <h1>Total Deposits</h1>
                </div>
                <div>
                  <ReactApexChart
                    options={state.options}
                    series={state.series}
                    type="area"
                    height={180}
                  />
                </div>
                <div className="chart-bottom px-4 flex items-center justify-between">
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>Crypto</p>
                  </div>
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>Fiat</p>
                  </div>
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>Usdt</p>
                  </div>
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>USD</p>
                  </div>
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>BTC</p>
                  </div>
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>PHP</p>
                  </div>
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>ETH</p>
                  </div>
                </div>
              </div>
              <div className="chart  py-4 rounded-xl">
                <div className="chart-top px-4 pb-2">
                  <h1>Total Active Players</h1>
                </div>
                <div>
                  <ReactApexChart
                    options={state2.options}
                    series={state2.series}
                    type="area"
                    height={180}
                  />
                </div>
                <div className="chart-bottom px-4  flex items-center justify-center">
                  <p>All Players</p>
                </div>
              </div>
              <div className="chart chartbgchange relative  p-4 rounded-xl">
                <div className="chart-top pb-2">
                  <h1>Total Players per Country</h1>
                </div>
                <img
                  src={per_country}
                  className="percountry-img mx-auto mt-6"
                  alt=""
                />
                <div className="chart-bottom mt-4 flex items-center justify-center">
                  <p>Philippine 59 players</p>
                </div>
              </div>
            </div>

            <div className="activity-row p-4 rounded-xl mt-8">
              <div className="top-area">
                <h1>Financial</h1>
              </div>

              <div className="grid grid-cols-3 mt-5 gap-3">
                <ActivityBox />
                <ActivityBox />
                <ActivityBox />
              </div>
            </div>
            <div className="activity-row p-4 rounded-xl mt-8">
              <div className="top-area">
                <h1>Gaming Activity</h1>
              </div>

              <div className="grid grid-cols-3 mt-5 gap-3">
                <ActivityBox />
                <ActivityBox />
                <ActivityBox />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
