import React from "react";

import { dateFormatter } from "../../utils";
import ButtonLoader from "../ButtonLoader";
export const TransactionTable = ({ transactionDataList, loading }) => {
  const pageSize = 10; // Number of transactions per page
  const totalPages = transactionDataList?.data?.totalPages;
  // console.log("TABLE LIST COUNT: ", totalPages);


  return (
    <>
      {loading ? <>
        <ButtonLoader />
      </> : <>
        {transactionDataList?.length === 0 ? (
          <div>
            <h1 className="no-data-available text-center text-white">Oops! There is no data yet!</h1>
          </div>
        ) : <>
          <table className="my-8 w-full lgmed:min-w-[800px]">
            <thead>
              <tr className="h-[56px] bg-[#252525] border-[1px] border-[#3C403F]">
                <th className="pl-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                  TxID
                </th>
                <th className="pl-4 text-left px-2 font-arabicreg text-[18px] text-[#AEB2B1]">
                  Timestamp
                </th>
                <th className="text-left px-2 font-arabicreg text-[18px] text-[#AEB2B1]">
                  Currency
                </th>
                <th className="text-left px-2 font-arabicreg text-[18px] text-[#AEB2B1]">
                  Amount
                </th>
                <th className="pr-4 text-left px-2 font-arabicreg text-[18px] text-[#AEB2B1]">
                  Type
                </th>
                <th className="pr-4 text-left px-2 font-arabicreg text-[18px] text-[#AEB2B1]">
                  Note
                </th>
                <th className="pr-4 text-left px-2 font-arabicreg text-[18px] text-[#AEB2B1]">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {transactionDataList
                //.filter(row => row.flag !== "game" && row.flag !== "rollback")
                ?.map((row, key) => (
                  <tr
                    key={key}
                    className="h-[56px] bg-[#1A1A1B]
            border-b-[1px] border-b-[#3C403F]
            border-l-[1px] border-l-[#3C403F]
            border-r-[1px] border-r-[#3C403F]
           "
                  >
                    <td className={`pl-4 font-arabicreg text-[18px] text-[#fff]`}>
                      {row?._id}
                    </td>
                    <td className={`pl-4 font-arabicreg text-[18px] text-[#fff]`}>
                      {dateFormatter(row.createdAt)}
                    </td>
                    <td className={`font-arabicreg text-[18px] text-[#fff] uppercase px-3`}>
                      {row.currency}
                    </td>
                    <td
                      className={`font-arabicreg text-[18px] text-[#fff] ${row.transactionType === "debit"
                        ? "text-red-500"
                        : "text-green-500"
                        }`}
                    >
                      {row.transactionType === "debit" ? "-" : "+"}{(parseFloat(row.amount ?? "0")).toFixed(6)}
                    </td>
                    <td className={`font-arabicreg capitalize text-[18px] text-[#fff] px-5`}>
                      {row?.flag}
                    </td>
                    <td className={`font-arabicreg text-[18px] text-[#fff]`}>
                      {row.note}
                    </td>
                    <td className={`font-arabicreg capitalize text-[18px] text-[#fff] px-2`}>
                      {row.status}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>}
      </>}

    </>

  );
};
