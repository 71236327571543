import bch from "../../assets/img/crypto/btc-cash.png";
import btc from "../../assets/img/crypto/btc.png";
import eth from "../../assets/img/crypto/eth.png";
import ltc from "../../assets/img/crypto/lte.png";
import matic from "../../assets/img/crypto/matic.png";
import usdc from "../../assets/img/crypto/usd-coin.png";
import PWETH from "../../assets/img/crypto/wrapped-eth.png";
// import cop from "../../assets/img/currency/cop.png";

export const CryptoCoins = [
    { img: btc, heading: "BITCOIN", currency: "Bitcoin" },
    { img: eth, heading: "ETHEREUM", currency: "Ethereum" },
    { img: usdc, heading: "PUSDC", currency: "USD Coin" },
    { img: ltc, heading: "LITECOIN", currency: "Litecoin" },
    { img: bch, heading: "BITCOINCASH", currency: "Bitcoin Cash" },
    { img: matic, heading: "POLYGAN", currency: "Matic" },
    { img: PWETH, heading: "PWETH", currency: "Wrapped Ether" },

];
