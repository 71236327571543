import React from "react";

export const ActivityBox = () => {
  return (
    <div className="box-activity rounded-xl">
      <div className="top-box px-6 py-5 pb-3">
        <h1>Total Deposits</h1>
      </div>
      <div className="content-area px-4 mb-3 mt-2">
        <div className="activity-box-content-row px-3 flex items-center">
          <p>Fiat</p>
          <p className="flex-1 text-center">0</p>
          <p>USD 0</p>
        </div>
        <div className="activity-box-content-row px-3 flex items-center">
          <p>Fiat</p>
          <p className="flex-1 text-center">0</p>
          <p>USD 0</p>
        </div>
        <div className="activity-box-content-row px-3 flex items-center">
          <p>Fiat</p>
          <p className="flex-1 text-center">0</p>
          <p>USD 0</p>
        </div>
        <div className="activity-box-content-row px-3 flex items-center">
          <p>Fiat</p>
          <p className="flex-1 text-center">0</p>
          <p>USD 0</p>
        </div>
      </div>
      <div className="bottom-box rounded-bl-xl rounded-br-xl h-11 px-4 flex items-center justify-between">
        <h1>Total</h1>
        <p>0.00 USD</p>
      </div>
    </div>
  );
};
