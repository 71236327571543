import React, { useState } from "react";
import { DepositeFiatOne } from "./DepositeFiatOne";
import { DepositeFiatTwo } from "./DepositeFiatTwo";
import { DepositeFiatThree } from "./DepositeFiatThree";
export const DepositeFiat = () => {
  const [step, setStep] = useState(1);
  return (
    <div>
      {step == 1 && <DepositeFiatOne setStep={setStep} />}
      {step == 2 && <DepositeFiatTwo setStep={setStep} />}
      {step == 3 && <DepositeFiatThree setStep={setStep} />}
    </div>
  );
};
