import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import profile from "../../assets/img/p2p/profile.svg";
import verified from "../../assets/img/p2p/verified.svg";
import { privateRequest } from "../../config/axios.config";
import {
  UseBLockUser,
  UseBlockList,
  UseGetTraderRatings,
  UseUnblockUser
} from "../../queries/p2p";
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";
import { AdvertiserBuyTable } from "./AdvertiserBuyTable";
import { AdvertiserSellTable } from "./AdvertiserSellTable";
import { FeedbackTable } from "./FeedbackTable";


export const RightAreaP2PProfile = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const navigate = useNavigate();
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [active, setActive] = useState(true);
  const [activeOption, setActiveOption] = useState("OnlineAds");
  const [reportOpen, setReportOpen] = useState(false);
  const [reportReason, setReportReason] = useState();
  const [reportDescription, setReportDescription] = useState();
  //get id from params
  const { id } = useParams();


  const { data: blockedData } = useQuery("get-blocklist", UseBlockList);
  const blockedUsers = blockedData?.data;

  const { data, isLoading } = useQuery("advertiserProfile", async () => {
    try {
      const res = await privateRequest.get(
        `user/p2p/advertisement/profile/${id}`
      );
      return res.data.profile;
    } catch (error) {
      console.log(error);
    }
  });
  const handleBlock = async () => {
    try {
      const res = await UseBLockUser(id);

      toast.success(res?.message)
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUnblock = async () => {
    try {
      const res = await UseUnblockUser(id);

      toast.success(res?.message)
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleReport = async () => {
    try {
      const res = await privateRequest.post(`user/p2p/report/submitReport`, {
        reportedUserId: id,
        reason: reportReason,
        description: reportDescription
      });
      toast.success("Report Submitted!");
    } catch (error) {
      console.log("Error : ", error);
      if (error.response.status === 400 && error.response.data.status === 'fail') {
        toast.error(`Error: ${error.response.data.error}`);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  useEffect(() => {
  }, [blockedData]);

  const reasonOptions = [
    { value: 'reason1', label: 'Reason 1' },
    { value: 'reason2', label: 'Reason 2' },
    { value: 'reason3', label: 'Reason 3' },
    // Add more reasons as needed
  ];

  const handleReasonChange = (selectedOption) => {
    setReportReason(selectedOption);
  };

  const handleDescriptionChange = (e) => {
    setReportDescription(e.target.value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#1A1A1B",
      borderColor: "#3c403f",
      color: "white",
      paddingTop: "10px",
      paddingBottom: "10px",
      borderRadius: "8px",
      width: "130px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#111010",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#343536" : "#111010",
      color: state.isSelected ? "white" : "white",
      ":hover": {
        ...provided[":hover"],
        backgroundColor: "#2c2c2c",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
  };


  const userRatings = UseGetTraderRatings(id);


  return (
    <>
      <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />
      <div className="flex-1">

        <HeaderStaker
          headerType="Staker"
          login={login}
          settypeShowAside={settypeShowAside}
          setsigninState={setsigninState}
          setSignUpState={setSignUpState}
        />
        <div className="body-area flex items-start px-6 pt-6">
          <div className="body-left-area w-full">
            <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
              <div className="flex justify-between items-center p-6 border-[#3C403F] border-b-[1px]">
                <h1 className="text-white text-[26px] text-center"> P2P </h1>
                <Link
                  to='/p2p/terms-and-conditions'
                  className="text-[20px] font-arabicmed text-[#6FCF17] hover:text-[#a6e66b]"
                >
                  Terms and Conditions
                </Link>
              </div>

              <div className="p-6">
                <div className="flex justify-between mb-4 sm:flex-col">
                  <div className="flex gap-2 sm:mb-4">
                    <img
                      src={data?.user?.avatar ? data?.user?.avatar : profile}
                      className="w-[80px] h-[80px] sm:w-[50px] sm:h-[50px]"
                    />
                    <div>
                      <div className="flex justify-between items-start gap-4 sm:grid sm:grid-cols-2">
                        <h1 className="text-white text-[20px] font-arabicbold flex gap-2 sm:text-[18px]">
                          {data?.user?.name
                            ? data?.user?.name
                            : data?.user?.email}
                          <img src={verified} />
                        </h1>
                        {/* <h2 className="text-white bg-[#3C502E] border border-[#6FCF17] rounded-lg px-3 text-center">
                          + Follow
                        </h2> */}
                        {/* <p className="text-white">
                          {blockedUsers?.includes(id) ? (
                            <button onClick={handleUnblock}>
                              Unblock
                            </button>
                          ) : (
                            <button onClick={handleBlock}>
                              Block
                            </button>
                          )}
                          &nbsp; | &nbsp;
                          <button
                            onClick={() => setReportOpen(true)}
                          >Report</button>
                        </p> */}
                      </div>
                      <p className="text-[#AEB2B1] text-[14px] mt-4">
                        Online | Joined on {data?.user?.createdAt}
                      </p>
                    </div>
                  </div>
                  <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-4 ">
                    <div className="flex justify-between gap-8 sm:gap-2">
                      <div>
                        <p className="text-[#AEB2B1] text-[14px] mb-3 mt-2">
                          Positive Feedback
                        </p>
                        <h1 className="text-white text-[20px] font-arabicbold">
                          0% (0)
                        </h1>
                      </div>
                      <div>
                        <p className="text-[#AEB2B1] text-[16px] flex gap-6 items-center justify-end mb-2">
                          Positive
                          <span className="text-center text-white bg-[#3C502E] border border-[#6FCF17] py-1 w-[72px] rounded-lg">
                            {data?.user?.like}
                          </span>
                        </p>
                        <p className="text-[#AEB2B1] text-[16px] flex gap-4 items-center justify-end">
                          Negative
                          <span className="text-center text-white bg-[#ff707e3a] border border-[#FF707E] py-1 w-[72px] rounded-lg">
                            {data?.user?.dislike}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between mb-4 gap-4 sm:grid sm:grid-cols-2">
                  <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-4 text-center w-full">
                    <p className="text-[#AEB2B1] text-[14px] mb-3">All Trades</p>
                    <h1 className="text-white text-[20px] font-arabicbold mb-1">
                      {data?.totalTrades} Time(s)
                    </h1>
                    <p className="text-[#AEB2B1] text-[14px]">
                      <span className="text-[#6FCF17]">
                        Buy {data?.totalBuyAds}
                      </span>
                      &nbsp; / &nbsp;{" "}
                      <span className="text-[#FF707E]">
                        Sell {data?.totalSellAds}
                      </span>
                    </p>
                  </div>
                  <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-4 text-center w-full">
                    <p className="text-[#AEB2B1] text-[14px] mb-3">30d Trades</p>
                    <h1 className="text-white text-[20px] font-arabicbold">
                      {data?.last30DaysTrades} Time(s)
                    </h1>
                  </div>
                  <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-4 text-center w-full">
                    <p className="text-[#AEB2B1] text-[14px] mb-3">
                      30d Completion Rate
                    </p>
                    <h1 className="text-white text-[20px] font-arabicbold">
                      {data?.last30DayCompletionRate} %
                    </h1>
                  </div>
                  <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-4 text-center w-full">
                    <p className="text-[#AEB2B1] text-[14px] mb-3">
                      Avg. Release Time
                    </p>
                    <h1 className="text-white text-[20px] font-arabicbold">
                      {data?.avgReleaseTime} Minute(s)
                    </h1>
                  </div>
                  <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-4 text-center w-full">
                    <p className="text-[#AEB2B1] text-[14px] mb-3">
                      Avg. Pay Time
                    </p>
                    <h1 className="text-white text-[20px] font-arabicbold">
                      {data?.avgPayTime} Minute(s)
                    </h1>
                  </div>
                </div>
                <div className="flex justify-between rounded-md w-full max-w-[220px] sm:max-w-full sm:mb-4 border border-[#3C403F] bg-[#252525]">
                  <div
                    className={`text-white text-center font-arabicmed rounded-md text-[14px] py-2 w-full  cursor-pointer ${activeOption === "OnlineAds"
                      ? "bg-[#6FCF17]"
                      : "bg-[#252525]"
                      }`}
                    onClick={() => setActiveOption("OnlineAds")}
                  >
                    Online Ads
                  </div>
                  <div
                    className={`text-white text-center font-arabicmed rounded-md text-[14px] py-2 w-full whitespace-nowrap px-2  cursor-pointer ${activeOption === "Feedback"
                      ? "bg-[#6FCF17]"
                      : "bg-[#252525]"
                      }`}
                    onClick={() => setActiveOption("Feedback")}
                  >
                    Feedback ({userRatings?.data?.length})
                  </div>
                </div>``

                {activeOption === "OnlineAds" && (
                  <>
                    <h1 className="text-white text-[20px] font-arabicbold mt-4">
                      Online Buy Ads
                    </h1>
                    <AdvertiserBuyTable p2pData={data?.totalSellAdsData?.docs} />
                    <h1 className="text-white text-[20px] font-arabicbold mt-4">
                      Online Sell Ads
                    </h1>
                    <AdvertiserSellTable p2pData={data?.totalBuyAdsData?.docs} />
                  </>
                )}

                {reportOpen && (
                  <div className="fixed inset-0 flex items-center justify-center bg-[#111010] bg-opacity-70 z-999">
                    <div className="bg-[#252525] border border-[#3C403F] p-8 rounded-lg w-[520px]">
                      <h1 className="text-white text-[20px] font-arabicbold text-center mb-6">
                        Cancellation
                      </h1>
                      <div className="max-h-[520px] overflow-y-auto px-2">
                        <div className="mb-4">
                          <Select
                            value={reportReason}
                            onChange={handleReasonChange}
                            options={reasonOptions}
                            placeholder="Select a reason"
                            styles={customStyles}
                            className="text-black"
                          />
                        </div>
                        <div className="mb-4">
                          <textarea
                            value={reportDescription}
                            onChange={handleDescriptionChange}
                            placeholder="Enter description"
                            className="w-full h-32 p-2 border border-[#3C403F] rounded-lg bg-[#252525] text-white"
                          />
                        </div>
                      </div>
                      <div className="flex justify-between gap-4 my-4">
                        <button
                          className="text-white text-[14px] bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-3 w-full"
                          onClick={() => setReportOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="text-white text-[14px] bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-lg py-3 w-full"
                          onClick={() => {
                            setReportOpen(false);
                          }}
                        >
                          Confirm Cancellation
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {activeOption === "Feedback" && <FeedbackTable ratings={userRatings?.data} />}
              </div>
            </div>
          </div>
          <SidebarArea typeShowAside={typeShowAside} />
        </div>
      </div>
    </>
  );
};
