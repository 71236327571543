import React from "react";
import crypto from "../../assets/img/p2p/crypto.svg";
import loan from "../../assets/img/p2p/loan.svg";
import order from "../../assets/img/p2p/order.svg";


export default function P2PBottom() {
    return (
        <>
            <div className="flex justify-between gap-4 my-4 sm:flex-col">
                <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-10 text-center w-1/3 sm:w-full">
                    <img src={order} className="mx-auto my-4" />
                    <h1 className="text-white font-arabicbold text-[20px] mb-4">
                        Place an Order
                    </h1>
                    <p className="text-[#AEB2B1] font-arabicreg text-[14px]">
                        Once you place a P2P order the crypto asset will be escrowed
                        by Binance P2P.
                    </p>
                </div>
                <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-10 text-center w-1/3 sm:w-full">
                    <img src={loan} className="mx-auto my-2" />
                    <h1 className="text-white font-arabicbold text-[20px] mb-4">
                        Pay the Seller
                    </h1>
                    <p className="text-[#AEB2B1] font-arabicreg text-[14px]">
                        Send money to the seller via the suggested payment method.
                        Complete the fiat transaction and click Transferred notify
                        seller on Binance P2P.
                    </p>
                </div>
                <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-10 text-center w-1/3 sm:w-full">
                    <img src={crypto} className="mx-auto my-4" />
                    <h1 className="text-white font-arabicbold text-[20px] mb-4">
                        Get your Crypto
                    </h1>
                    <p className="text-[#AEB2B1] font-arabicreg text-[14px]">
                        Once the confirms receipt of money the escrowed crypto will
                        be released to you.
                    </p>
                </div>
            </div>
        </>
    )
}
