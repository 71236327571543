import React, { useState } from "react";
import { BetsTable } from "./BetsTable";
import search from "../../assets/img/search-header.svg";
import { AllBetsTable } from "./AllBetsTable";
import { HighRollersTable } from "./HighRollersTable";
import { LeaderboardTable } from "./LeaderboardTable";

const data = [
  {
    category: "All Bets",
    active: true,
  },
  {
    category: "High Rollers",
    active: false,
  },
  {
    category: "Leaderboard",
    active: false,
  },
];

export const MyBets = () => {
  const [active, setActive] = useState(true);
  const handleClick = () => {
    setActive((prevActive) => !prevActive);
  };
  const [filters, setFilters] = useState(data);

  const toggleFilter = (index) => {
    const updatedFilters = filters.map((filter, i) => ({
      ...filter,
      active: i === index,
    }));
    setFilters(updatedFilters);
  };
  const activeFilter = filters.find((filter) => filter.active);


  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchRollers = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  return (
    <>
      <div className="font-arabicmed w-full">
      <div className="py-6">
        <div className="flex justify-between gap-1 bg-[#252525] p-3 rounded-full w-1/4">
          <button
            className={`text-white font-arabicmed text-[12px] py-3 px-5 rounded-3xl ${
              active
                ? "bg-[#3A3A3A] w-full"
                : "bg-transparent w-1/2"
            }`}
            onClick={handleClick}
          >
            Market-based
          </button>
          <button
            className={`text-white font-arabicmed text-[12px] py-3 px-5 rounded-3xl ${
              active
                ? "bg-transparent w-1/2"
                : "bg-[#3A3A3A] w-1/2"
            }`}
            onClick={handleClick}
          >
            P2P
          </button>
        </div>
      </div>
        <BetsTable type={active} />
        <div className="flex justify-between items-center whitespace-nowrap mt-4">
          <div className="flex my-4">
            {filters.map((bet, index) => (
              <button
                key={index}
                className="flex items-center mr-4"
                onClick={() => toggleFilter(index)}
              >
                <p className={bet.active ? "text-[#6FCF17]" : "text-[#68727B]"}>
                  {bet.category}
                </p>
              </button>
            ))}
          </div>
          <div className="flex w-fit items-center my-2 md:w-full">
            <p className="text-[#fff] mr-2 md:hidden">Search Event</p>
            <div className="search-box-container w-[201px] h-8 px-4 bg-[#2E2E2E] border-[1px] border-[#3C403F] rounded-lg  flex items-center gap-1 md:w-full">
              <img src={search} alt="" />
              <input
                type="text"
                placeholder="Search here"
                className="w-full bg-transparent border-0 outline-none text-[14px] text-[#AEB2B1]"
                value={searchTerm}
                onChange={handleSearchRollers}
              />
            </div>
          </div>
        </div>
        {activeFilter && activeFilter.category === "All Bets" && <AllBetsTable searchTerm={searchTerm} type={active} />}
        {activeFilter && activeFilter.category === "High Rollers" && <HighRollersTable searchTerm={searchTerm} type={active} />}
        {activeFilter && activeFilter.category === "Leaderboard" && <LeaderboardTable searchTerm={searchTerm} type={active} />}
      </div>
    </>
  );
};