import React, { useState } from "react";
import emoji from "../../assets/img/emoji.svg";
import bankwhite from "../../assets/img/p2p/bankwhite.svg";
import confetti from "../../assets/img/p2p/confetti.svg";
import notice from "../../assets/img/p2p/notice.svg";
import copy from "../../assets/img/p2p/copy.svg";
import profile from "../../assets/img/p2p/profile.svg";
import remove from "../../assets/img/p2p/remove.svg";
import send from "../../assets/img/send.svg";
import addImage from "../../assets/img/p2p/addImage.svg";
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";
import { Dropdown } from "../transaction/Dropdown";
import { Input } from "../transaction/Input";
import { Link } from "react-router-dom";
import backImg from "../../assets/img/transaction/back.svg";
import Stars from "./../popups/Stars";

export const RightAreaP2PReleaseOrder = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const defaultRating = localStorage.getItem("starRating"); //handle this in database
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [stepActive, setStepActive] = useState(1);
  const [appealName, setAppealName] = useState("");
  const [appealNumer, setAppealNumber] = useState("");
  const [status, setStatus] = useState("");
  const [previewImages, setPreviewImages] = useState([]);
  const [showRateModal, setShowRateModal] = useState(false);

  const handleConfirmClick = () => {
    setShowModal(true);
  };

  const handlePaymentReceived = () => {
    setShowRateModal(true);
  };

  const handleAppeal = () => {
    setStepActive(2);
    setShowModal(false);
  };

  const handleGoBack = () => {
    setStepActive(1);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const previewImagesArray = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        previewImagesArray.push(reader.result);
        setPreviewImages([...previewImages, reader.result]);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleRemoveImage = (index) => {
    const newPreviewImages = [...previewImages];
    newPreviewImages.splice(index, 1);
    setPreviewImages(newPreviewImages);
  };

  const dropdownOptions = [
    { data: "Please Select", value: "" },
    { data: "Order Execution Errors", value: "appeal1" },
    { data: "Order Rejection", value: "appeal-2" },
    { data: "Market Manipulation", value: "appeal-3" },
    { data: "Technical Glitches", value: "appeal-4" },
    { data: "Fraudulent Activity", value: "appeal-5" },
    { data: "Dispute with Counterparty", value: "appeal-6" },
    { data: "Funds Settlement Issues", value: "appeal-7" },
    { data: "Margin Trading Issues", value: "appeal-8" },
    { data: "Customer Support Response", value: "appeal-9" },
  ];

  const [appealReason, setAppealReason] = useState("");

  const handleTextareaChange = (event) => {
    const inputValue = event.target.value;
    // Update the state with the new value
    setAppealReason(inputValue);
  };

  return (
    <>
      <div className="flex-1">
        <HeaderStaker
          headerType="Staker"
          login={login}
          settypeShowAside={settypeShowAside}
          setsigninState={setsigninState}
          setSignUpState={setSignUpState}
        />
        <div className="body-area flex items-start px-6 pt-6">
          <div className="body-left-area w-full">
            <div className="flex justify-between gap-4 sm:flex-col">
              {stepActive === 1 && (
                <>
                  {/*RELEASE DIV */}
                  <div className="bg-[#1A1A1B] border border-[#3C403F] rounded-lg p-4 w-full">
                    <div className="flex items-center">
                      <Link to="/p2p/order-history">
                        <img src={backImg} alt="" />
                      </Link>
                      <h1 className="text-white text-[20px] font-arabicbold ml-4">
                        Releasing
                      </h1>
                    </div>

                    <div className="flex justify-between pb-6 border-b border-[#3C403F]  sm:flex-col">
                      <p className="text-[#AEB2B1] text-[16px] font-arabicreg">
                        Created time:{" "}
                        <span className="text-white">2023-11-04, 13:15:18</span>
                      </p>
                      <p className="text-[#AEB2B1] text-[16px] font-arabicreg flex gap-2">
                        Order Number:{" "}
                        <span className="text-white">2545482875963</span>
                        <img src={copy} />
                      </p>
                    </div>
                    <div className="flex justify-between py-6 border-b border-[#3C403F]">
                      <div>
                        <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                          Amount
                        </p>
                        <h1 className="text-white text-[16px] font-arabicbold">
                          $ 100.00
                        </h1>
                      </div>
                      <div>
                        <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                          Price
                        </p>
                        <h1 className="text-white text-[16px] font-arabicbold">
                          75.30 EUR
                        </h1>
                      </div>
                      <div>
                        <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                          Quantity
                        </p>
                        <h1 className="text-white text-[16px] font-arabicbold">
                          1.31 USDT
                        </h1>
                      </div>
                    </div>
                    <div className="flex justify-between items-start py-6 border-b border-[#3C403F] sm:flex-col">
                      <div className="flex items-center gap-2 w-1/3 sm:mb-4">
                        <img src={bankwhite} />
                        <h1 className="text-white text-[16px] font-arabicbold">
                          Bank
                        </h1>
                      </div>
                      <div className="w-full">
                        <div className="flex justify-between items-center mb-2">
                          <p className="text-[#AEB2B1] text-[16px] font-arabicbold">
                            Recipient:
                          </p>
                          <h1 className="text-white text-[16px] font-arabicbold flex items-center">
                            2545482875963
                            <img src={copy} className="ml-2" />
                          </h1>
                        </div>
                        <div className="flex justify-between items-center mb-2">
                          <p className="text-[#AEB2B1] text-[16px] font-arabicbold">
                            Contact:
                          </p>
                          <h1 className="text-white text-[16px] font-arabicbold flex items-center">
                            52148
                            <img src={copy} className="ml-2" />
                          </h1>
                        </div>
                        <div className="flex justify-between items-center mb-2">
                          <p className="text-[#AEB2B1] text-[16px] font-arabicbold">
                            Bank Information:
                          </p>
                          <h1 className="text-white text-[16px] font-arabicbold flex items-center">
                            National Bank, USA
                          </h1>
                        </div>
                        <div className="flex justify-between items-center mb-2">
                          <p className="text-[#AEB2B1] text-[16px] font-arabicbold">
                            Card Number:
                          </p>
                          <h1 className="text-white text-[16px] font-arabicbold flex items-center">
                            8475963
                            <img src={copy} className="ml-2" />
                          </h1>
                        </div>
                        <div className="flex justify-between items-center mb-2">
                          <p className="text-[#AEB2B1] text-[16px] font-arabicbold">
                            Card Number:
                          </p>
                          <h1 className="text-white text-[16px] font-arabicbold flex items-center">
                            8475963
                            <img src={copy} className="ml-2" />
                          </h1>
                        </div>
                        <div className="flex gap-4 items-start mt-8">
                          <p className="text-[#6FCF17] text-[14px] font-arabicbold">
                            Note:
                          </p>
                          <p className="text-white text-[14px] font-arabicmed text-justify">
                            Lorem ipsum dolor sit amet consectetur. Rhoncus
                            senectus diam sapien nisi hendrerit nam pretium
                            consequat. Consectetur eget enim dignissim at
                            adipiscing ultrices.
                          </p>
                        </div>
                      </div>
                    </div>
                    <h1 className="text-white text-[20px] font-arabicbold pt-4">
                      Payment to be made{" "}
                      <span className="text-[#6FCF17]">00:58:06</span>
                    </h1>
                    <p className="text-[#AEB2B1] text-[14px]">
                      Please make a payment within 60:30 minutes, otherwise the
                      order will be cancelled.
                    </p>
                    <div className="flex justify-between gap-4 my-4">
                      <button
                        className="text-white bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-2 w-full"
                        onClick={handleConfirmClick}
                      >
                        Appeal
                      </button>
                      <button
                        className="text-white bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-lg py-2 w-full"
                        onClick={handlePaymentReceived}
                      >
                        Payment Received
                      </button>
                    </div>
                  </div>
                </>
              )}

              {stepActive === 2 && (
                <>
                  {/*APPEAL DIV*/}
                  <div className="bg-[#1A1A1B] border border-[#3C403F] rounded-lg p-4 w-full">
                    <h1 className="text-white text-[20px] font-arabicbold">
                      Appeal
                    </h1>
                    {/* <div className="flex justify-between pb-6 border-b bg-[#2E2E2E] border-[#3C403F]  sm:flex-col">
                      <p className="text-[#AEB2B1] text-[16px] font-arabicreg">
                        Created time:{" "}
                        <span className="text-white">2023-11-04, 13:15:18</span>
                      </p>
                      <p className="text-[#AEB2B1] text-[16px] font-arabicreg flex gap-2">
                        Order Number:{" "}
                        <span className="text-white">2545482875963</span>
                        <img src={copy} />
                      </p>
                    </div> */}
                    <div className="py-4">
                      <div className="flex flex-col gap-2 py-4  bg-[#2E2E2E] rounded-xl">
                        <div className="flex gap-4 ml-4">
                          <div>
                            <h1 className="flex justify-center items-center text-black bg-[#6FCF17] rounded-2xl text-[16px] font-arabicbold min-w-[30px] min-h-[30px]">
                              1
                            </h1>
                          </div>
                          <p className="text-[#AEB2B1] text-[16px] font-arabicreg mb-2">
                            Reason for appeal and proofs are visible to both
                            parties and CS. Please abode including any private
                            or sensitive info.
                          </p>
                        </div>
                        <div className="flex gap-4 ml-4">
                          <div>
                            <h1 className="flex justify-center items-center text-black bg-[#6FCF17] rounded-2xl text-[16px] font-arabicbold min-w-[30px] min-h-[30px]">
                              2
                            </h1>
                          </div>

                          <p className="text-[#AEB2B1] text-[16px] font-arabicreg mb-2">
                            Baseless appeal request can result in banning of the
                            account
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="grid grid-cols-1 gap-4">
                        <div className="flex flex-col">
                          <h1 className="text-white text-[18px] font-arabicbold pt-4">
                            Reason for Appeal{" "}
                            <span className="text-[#6FCF17]">(Mandatory)</span>
                          </h1>
                          <Dropdown
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            placeholder="Please Select"
                            list={dropdownOptions}
                            className="w-full"
                          />
                        </div>

                        <div className="flex flex-col">
                          <h1 className="text-white text-[18px] font-arabicbold pt-4">
                            Details
                          </h1>
                          <div className="grid grid-cols-2 gap-6 ">
                            <Input
                              value={appealName}
                              onChange={(e) => setAppealName(e.target.value)}
                              placeholder="Name"
                            />
                            <Input
                              value={appealNumer}
                              onChange={(e) => setAppealNumber(e.target.value)}
                              placeholder="Phone number"
                            />
                          </div>
                        </div>

                        <div className="flex flex-col">
                          <h1 className="text-white text-[18px] font-arabicbold pt-4">
                            Description
                          </h1>
                          <textarea
                            className="mt-4 p-2 border text-white font-arabicbold bg-[#111010] border-gray-300 rounded-md focus:outline-none focus:ring focus:white"
                            rows="5"
                            maxLength="500"
                            placeholder="Please provide as much details as possible"
                            value={appealReason}
                            onChange={handleTextareaChange}
                          ></textarea>
                          <p className="text-right text-[#6FCF17] text-[14px] py-4 font-arabicbold">
                            {appealReason.length}{" "}
                            <span className="text-white font-arabicbold text-[14px]">
                              /500
                            </span>
                          </p>
                        </div>

                        <div className="flex flex-col">
                          <h1 className="text-white text-[18px] font-arabicbold pt-4">
                            Upload proof{" "}
                            <span className="text-[#6FCF17]">(Mandatory)</span>
                          </h1>
                          <p className="text-[#AEB2B1] text-[14px] py-2">
                            Screenshot, recording, documents of payment and
                            communication log Size less than 5MB
                          </p>
                          <div className="my-4">
                            <div className="relative">
                              <input
                                type="file"
                                id="fileUpload"
                                className="hidden"
                                onChange={handleFileChange}
                                multiple // Allow multiple file selection
                              />

                              <label
                                htmlFor="fileUpload"
                                className="bg-[#2E2E2E]  text-gray-700 py-2 px-4 rounded-md cursor-pointer flex flex-wrap items-center justify-start"
                              >
                                {previewImages.map((image, index) => (
                                  <div key={index} className="relative m-2">
                                    <img
                                      src={image}
                                      alt={`Preview ${index}`}
                                      className="h-24 w-auto rounded-md"
                                    />
                                    <button
                                      onClick={() => handleRemoveImage(index)}
                                      className="absolute top-[-4px] right-[-4px] bg-white rounded-full p-1 shadow cursor-pointer"
                                    >
                                      {/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 text-red-600"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M10 1a1 1 0 0 1 1 1v1h4.293a1 1 0 1 1-.707 1.707L11 4.707V10h1a1 1 0 0 1 0 2H9v1.293a1 1 0 1 1-1.707-.707L7.293 12H2V5a1 1 0 0 1 1-1h1V2a1 1 0 0 1 1-1h4zM5 14a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1z"
                                          clipRule="evenodd"
                                        />
                                      </svg> */}
                                      <img src={remove} alt="remove" />
                                    </button>
                                  </div>
                                ))}
                                <div className="border-2 border-dashed border-[#6FCF17]">
                                  <img
                                    src={addImage}
                                    alt="Upload File"
                                    className="h-10 w-10 m-[28px]  " // You can adjust the styles if needed
                                  />
                                </div>

                                {/* Upload File */}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between gap-4 mt-6 ">
                      <button
                        className="text-white bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-2 w-full"
                        onClick={handleGoBack}
                      >
                        Cancel
                      </button>
                      <button
                        className="text-white bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-lg py-2 w-full"
                        onClick={handleConfirmClick}
                      >
                        Appeal
                      </button>
                    </div>
                  </div>
                </>
              )}

              {/* CHAT BOX */}
              <div className="bg-[#1A1A1B] border border-[#3C403F] rounded-lg p-4 w-full relative">
                <div className="flex items-start gap-2 pb-6 border-b border-[#3C403F]">
                  <img src={profile} />
                  <div>
                    <h1 className="text-white text-[18px] font-arabicbold">
                      John Doe
                    </h1>
                    <p className="text-[#AEB2B1] text-[16px] font-arabicreg">
                      ID- 54876215985
                    </p>
                  </div>
                </div>

                <div className="my-4 overflow-y-auto h-[100vh]">
                  <p className="text-[#AEB2B1] text-[14px] font-arabicreg text-center">
                    2023-05-03, 13:15
                  </p>
                  <p className="text-[#AEB2B1] text-[14px] font-arabicreg text-center">
                    Negotiate with the counterparty
                  </p>
                  <div className="p-4 bg-[#252525] rounded-lg my-4">
                    <p className="text-[#AEB2B1] text-[14px] font-arabicreg">
                      If there is an issue with the transaction, the most
                      effective solution is to contact the counterparty
                      directly. You can uplaod the payment receipt and account
                      information in the chat window for both parties to verify
                      and negociate.
                    </p>
                  </div>
                </div>

                <div className="message-area flex items-center px-4 h-14 rounded-lg border border-[#3C403F]">
                  <input
                    type="text"
                    placeholder="Send a message..."
                    className="flex-1 w-full"
                  />
                  <div className="flex">
                    <img src={emoji} alt="emoji" className="mr-3" />
                    <img src={send} alt="send" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SidebarArea typeShowAside={typeShowAside} />
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#111010] bg-opacity-70 z-999">
          <div className="bg-[#252525] border border-[#3C403F] p-8 rounded-lg text-center">
            <img src={notice} className="mx-auto my-4 w-50 h-50" />
            <h1 className="text-white text-[20px] font-arabicbold">
              Are you sure you want to Appeal?
            </h1>
            <p className="text-[#AEB2B1] text-[16px] font-arabicreg mb-10">
              Baseless appeal request can result in banning of the account.{" "}
            </p>
            <div className="flex justify-between gap-4 my-4">
              <button
                className="text-white text-[14px] bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-3 w-full"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="text-white text-[14px] bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-lg py-3 w-full"
                // onClick={handleConfirmClick}
                onClick={handleAppeal}
              >
                Appeal
              </button>
            </div>
          </div>
        </div>
      )}

      {showRateModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#111010] bg-opacity-70 z-999 ">
          <div className="bg-[#252525] border border-[#3C403F] p-8 rounded-lg text-center w-[520px]">
            {/* <img src={confetti} className="mx-auto my-4 w-50 h-50" /> */}
            <h1 className="text-white text-[20px] font-arabicbold">
              Rate your experience
            </h1>
            <p className="text-[#AEB2B1] text-[16px] font-arabicreg mb-4">
              We highly value your feedback! Kindly take a moment to rate your
              experience and provide us with your valuable feedback.{" "}
            </p>

            <Stars iconSize={74} defaultRating={defaultRating} />
            <div className="flex flex-col">
              <textarea
                className="mt-4 p-2 border text-white font-arabicbold bg-[#2E2E2E] border-gray-300 rounded-md focus:outline-none focus:ring focus:white"
                rows="5"
                maxLength="500"
                placeholder="Tell us about your experience!"
                value={appealReason}
                onChange={handleTextareaChange}
              ></textarea>
              <p className="text-right text-[#6FCF17] text-[14px] py-4 font-arabicbold">
                {appealReason.length}{" "}
                <span className="text-white font-arabicbold text-[14px]">
                  /500
                </span>
              </p>
            </div>
            <div className="flex justify-between gap-4 my-4">
              <button
                className="text-white text-[14px] bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-3 w-full"
                onClick={() => setShowRateModal(false)}
              >
                No, Thanks
              </button>
              <button
                className="text-white text-[14px] bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-lg py-3 w-full"
                onClick={() => setShowRateModal(false)}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
