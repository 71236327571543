import React from "react";
import { FaXmark } from "react-icons/fa6";

export const P2PModal = ({
    modalType,
    title,
    text,
    onClose,
    method,
}) => {
  return (
    <>
      <div className="fixed inset-0  backdrop-blur-md flex items-center justify-center z-40">
        <div className="bg-[#23262E] p-8 rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl text-white font-bold">
              {title}
            </h2>
            <button
              className="text-white hover:text-gray-700"
              onClick={onClose()}
            >
              <FaXmark />
            </button>
          </div>
          <p className="text-lg py-4 text-white">
            {text}
          </p>
          <div className="flex justify-center mt-5">
            <button
                className="py-3 mr-5 px-8 bg-gradient-to-b from-[#EA3B1C] to-[#7D0102] rounded-lg flex items-center justify-start text-white text-[14px] font-medium"
                onClick={() => method(true)}
            >
                YES
            </button>
            <button
                className="py-3 px-5 bg-gradient-to-b from-[#BEBDBD] to-[#4F4E4E] rounded-lg flex items-center justify-start text-white text-[14px] font-medium"
                onClick={() => method(false)}
            >
                NO
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
