import BPI from "../../assets/img/a-bank/BPI.png";
import gcashlogo from "../../assets/img/a-bank/Gcash.png";
import paymaya from "../../assets/img/a-bank/Maya.png";
import METROBANK from "../../assets/img/a-bank/METROBANK.png";
import MYBANK from "../../assets/img/a-bank/MYBANK.png";
import RCBC from "../../assets/img/a-bank/RCBC.png";
import UNIONBANK from "../../assets/img/a-bank/UB.png";


// import PlaceholderImage from "../../assets/img/placeholder.png";
export const Accept = [
  { img: gcashlogo, heading: "GCash Bills Pay" },
  { img: paymaya, heading: "Maya Bills Pay" },
  { img: BPI, heading: "BPI Online" },
  { img: MYBANK, heading: "Maybank Online Banking" },
  { img: METROBANK, heading: "Metrobank Online Banking" },
  { img: RCBC, heading: "RCBC Online Banking" },
 
  { img: UNIONBANK, heading: "Unionbank Online Banking" }


];
