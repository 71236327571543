import { File } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { PhotoProvider, PhotoView } from "react-photo-view";
import 'react-photo-view/dist/react-photo-view.css';
import { ToastContainer, toast } from "react-toastify";
import { io } from "socket.io-client";
import confetti from "../../assets/img/p2p/confetti.svg";
import copy from "../../assets/img/p2p/copy.svg";
import noticeRed from "../../assets/img/p2p/noticeRed.svg";
import profile from "../../assets/img/p2p/profile.svg";
import send from "../../assets/img/send.svg";
import notificationSound from '../../assets/sound/message_sound.mp3';
import { privateRequest } from "../../config/axios.config";
import { useAuth } from "../../contexts/AuthContext";
import { useOrderDetails } from "../../queries/p2p";
import { dateFormatter } from "../../utils";
import ButtonLoader from '../ButtonLoader';
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";
import Stars from "./../popups/Stars";
import { P2PNav } from "./P2PNav";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL || "http://localhost:3000";
const SOCKET_PATH = "/user/socket/chat";

export const RightAreaP2PSellDetails = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const { userInfo } = useAuth();
  const audio = new Audio(notificationSound);
  const defaultRating = localStorage.getItem("starRating"); //handle this in database
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [showRateModal, setShowRateModal] = useState(false);
  const [appealReason, setAppealReason] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const details = urlParams.get("details");
  const result = useOrderDetails(details);
  const orderDetails = result?.data?.data;
  const [orderStatus, setOrderStatus] = useState(""); // State to track order status
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [openNotice, setOpenNotice] = useState(false);
  const [diputeOrderModal, setDisputeOrderModal] = useState(false);
  const currentUserId = userInfo?.user?._id;
  const chatContainerRef = useRef(null);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachment, setAttachment] = useState("");
  const attachmentInput = useRef("attachmentInput");
  const [attachmentPreview, setAttachmentPreview] = useState("");
  // get chat conversation messages
  const getMessages = async () => {
    try {
      const res = await privateRequest.get(
        `user/chat/getMessages/${details}`
      );
      setMessages(res?.data?.data?.docs);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("Error : ", error);
    }
  };

  const [disputeForm, setDisputeForm] = useState({
    reason: '',
    details: '',
    proof: '',
  });

  const handleConfirmClick = () => {
    setShowModal(true);
  };

  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      toast.success("Text copied");
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  const handleReleaseOrder = async () => {
    try {
      const orderId = orderDetails?._id;
      const res = await privateRequest.patch(
        `user/p2p/order/releaseOrder/${orderId}`
      );
      toast.success("Order released successfully!");
      setShowRateModal(true);
      result.refetch();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("Error : ", error);
    }
    setShowModal(false);
  };

  //handle disputeOrder
  const handleDisputeOrder = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('reason', disputeForm.reason);
    formData.append('details', disputeForm.details);
    formData.append('myRole', orderDetails?.myRole);
    if (disputeForm.proof) {
      formData.append('proof', disputeForm.proof);
    }

    try {
      const orderId = orderDetails?._id;
      const res = await privateRequest.patch(
        `user/p2p/order/disputeOrder/${orderId}`, formData
      );
      toast.success("Order disputed successfully!");
      setOrderStatus('disputed');
      setDisputeForm({
        reason: '',
        details: '',
        proof: '',
      });

    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("Error : ", error);
    }
    setDisputeOrderModal(false);
  };


  // chat
  // send message

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };


  const sendMessage = async () => {
    if (!message) return; // Prevent sending empty messages

    try {
      const sentMessage = {
        senderType: "sender",
        sender: {
          _id: currentUserId,
        },
        attachment: "",
        content: message,
        conversation: details,
        createdAt: new Date().toISOString(),
      };

      const form = new FormData();
      form.append('refId', details);
      form.append('message', message);

      await privateRequest.post('user/chat/sendMessage', form);

      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      setMessage("");
      setAttachment(""); // Clear the attachment after sending the message
    } catch (error) {
      console.log(error);
    }
  };

  const sendAttachment = async () => {
    try {
      let attachmentUrl = null;
      if (attachment) {
        attachmentUrl = URL.createObjectURL(attachment);
      }

      const sentMessage = {
        senderType: "sender",
        sender: {
          _id: currentUserId,
        },
        content: message,
        attachment: attachmentUrl,
        conversation: details,
        createdAt: new Date().toISOString(),
      };

      const form = new FormData();
      if (attachment) {
        form.append('attachment', attachment);
      }
      form.append('refId', details);

      const res = await privateRequest.post('user/chat/sendMessage', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      setMessage("");
      setAttachment(""); // Clear the attachment after sending the message
      setAttachmentPreview(""); // Clear the preview after sending the message

      console.log("send message ", sentMessage);
    } catch (error) {
      console.log("Error sending message:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  const uploadAttachment = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setAttachment(file);
      await sendAttachment();
    }

  };

  const triggerFileInput = () => {
    if (attachmentInput.current) {
      attachmentInput.current.click();
    }
  };



  const handleTextareaChange = (event) => {
    const inputValue = event.target.value;
    // Update the state with the new value
    setAppealReason(inputValue);
  };

  // handle ratings
  const handleRatings = async () => {
    setLoading(true);
    setDisable(true);
    try {
      const res = await privateRequest.post(`user/p2p/rating/submitRating`, {
        orderId: details,
        point: defaultRating,
        remarks: appealReason,
        myRole: orderDetails?.myRole
      });
      toast.success("Ratings given successfully!");
      setLoading(false);
      setDisable(false);
      result.refetch();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("Error : ", error);
    }
    setShowRateModal(false);
  };

  const handleFileChange = (e) => {
    setDisputeForm({
      ...disputeForm,
      proof: e.target.files[0],
    });
  };


  useEffect(() => {
    getMessages();
    const newSocket = io(SOCKET_SERVER_URL, { path: SOCKET_PATH });

    newSocket.on("connect", () => {
      console.log("Socket connected, joining room:", details);
      newSocket.emit("joinRoom", { chatroomId: details });
    });

    newSocket.on("newMessage", (message) => {
      if (message.content.sender._id !== currentUserId) {
        setMessages((prevMessages) => [...prevMessages, message.content]);
        audio.play().catch(error => console.error('Error playing the sound:', error));
        result.refetch();
      }
    });

    newSocket.on("orderMessage", (message) => {
      if (message?.messages?.type === 'markAsPaid') {
        toast.success(message?.messages?.paidMessage);
      }
      result.refetch();
    });

    newSocket.on("connect_error", (err) => {
      console.error("Connection error:", err);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [details]);


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);


  return (
    <>
      <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />
      {orderDetails && (
        <div className="flex-1">
          <HeaderStaker
            headerType="Staker"
            login={login}
            settypeShowAside={settypeShowAside}
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
          />
          <div>
            <P2PNav />
          </div>
          <div className="body-area flex items-start px-6 pt-6">
            <div className="body-left-area w-full">
              <div className="flex justify-between gap-4 sm:flex-col">
                <div className="bg-[#1A1A1B] border border-[#3C403F] rounded-lg p-4 w-full">
                  <h1 className="text-white flex justify-between text-[20px] font-arabicbold mb-4">
                    Sell {orderDetails?.advertisement.cryptoCurrency}
                  </h1>
                  <div className="flex justify-between pb-6 border-b border-[#3C403F]  sm:flex-col">
                    <p className="text-[#AEB2B1] text-[16px] font-arabicreg flex gap-2">
                      Order Number:{" "}
                      <span className="text-white">{orderDetails?._id}</span>
                      <img
                        src={copy}
                        className="cursor-pointer"
                        onClick={() => handleCopyClick(orderDetails?._id)}
                      />
                    </p>
                  </div>
                  <div className="flex justify-between py-6 border-b border-[#3C403F]">
                    <div>
                      <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                        Amount
                      </p>
                      <h1 className="text-white text-[16px] font-arabicbold">
                        {orderDetails?.fiatAmount}{" "}
                        {orderDetails?.advertisement?.fiatCurrency}
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                        Price per {orderDetails?.advertisement?.cryptoCurrency}
                      </p>
                      <h1 className="text-white text-[16px] font-arabicbold">
                        {orderDetails?.exchangeRate}{" "}
                        {orderDetails?.advertisement?.fiatCurrency}
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                        Quantity
                      </p>
                      <h1 className="text-white text-[16px] font-arabicbold">
                        {orderDetails?.amount}{" "}
                        {orderDetails?.advertisement?.cryptoCurrency}
                      </h1>
                    </div>
                  </div>
                  <div className="flex justify-between items-start py-6 border-b border-[#3C403F] sm:flex-col">
                    <div className="w-full">
                      {orderDetails?.isFiatPaid === false ? <>
                        <h1 className="text-white text-[20px] font-arabicbold mb-4">
                          Buyer will transfer funds to this account:
                        </h1>
                      </> : <>
                        <h1 className="text-white text-[20px] font-arabicbold mb-4">
                          Buyer has Transfered the payment
                        </h1>
                      </>}
                      {orderDetails?.paymentMethod?.map((detail, index) => (
                        <div key={index}>
                          <div className="text-center">
                            <h1 className="text-white text-[18px] font-arabicbold mx-auto">
                              {detail?.name}
                            </h1>
                          </div>
                          {detail?.data?.data ? <>
                            {Object?.entries(detail?.data?.data)?.map(
                              ([key, value]) => (
                                <div
                                  key={key}
                                  className="flex justify-between items-center mb-2"
                                >
                                  <p className="text-[#AEB2B1] text-[16px] font-arabicbold">
                                    {key}
                                  </p>
                                  <h1 className="text-white text-[16px] font-arabicbold flex items-center">
                                    {value}
                                    <img
                                      src={copy}
                                      className="ml-2 cursor-pointer"
                                      alt="copy icon"
                                      onClick={() => handleCopyClick(value)}
                                    />
                                  </h1>
                                </div>
                              )
                            )}
                          </> : <>
                            {orderDetails?.paymentDetails?.map((detail, index) => (
                              <div key={index}>
                                {Object?.entries(detail)?.map(([key, value]) => (
                                  <div
                                    key={key}
                                    className="flex justify-between items-center mb-2"
                                  >
                                    <p className="text-[#AEB2B1] text-[16px] font-arabicbold">
                                      {key}
                                    </p>
                                    <h1 className="text-white text-[16px] font-arabicbold flex items-center">
                                      {value}
                                      <img
                                        src={copy}
                                        className="ml-2 cursor-pointer"
                                        alt="copy icon"
                                        onClick={() => handleCopyClick(value)}
                                      />
                                    </h1>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </>}

                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mt-5">
                    {orderDetails?.status === 'released' ? <>
                      <h1 className="text-[#6FCF17] text-[18px] font-arabicbold pt-4 w-full text-center mb-3">
                        Order released, trade has completed
                      </h1>
                      {orderDetails?.senderReviewed === false && <>
                        <button
                          className="text-[#282828] bg-[#6FCF17] rounded-lg py-2 w-full font-arabicbold"
                          onClick={() => {
                            setShowRateModal(!showRateModal);
                          }}
                        >
                          Give ratings
                        </button>
                      </>}
                    </> : <>
                      {orderDetails?.isFiatPaid === true ? <>
                        <p className="text-white mb-5">
                          The buyer has transferred the funds. Please confirm the transaction and click on 'Release Order' to complete the process
                        </p>
                        <div className="flex justify-between items-center gap-3">
                          <button
                            onClick={() => setOpenNotice(true)}
                            className="text-white bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-2 w-full"
                          >
                            Dispute
                          </button>
                          <button
                            className="text-[#2E2E2E] bg-[#6FCF17] rounded-lg py-2 w-full font-arabicbold"
                            onClick={handleConfirmClick}
                          >
                            Release Order
                          </button>
                        </div>
                      </> : <>
                        <h1 className="text-white text-[20px] font-arabicbold mb-4 text-center">
                          Wait for the buyer to paid
                        </h1>
                      </>}
                    </>}
                  </div>

                </div>

                {/* chat boxes */}
                <div className="bg-[#1A1A1B] border border-[#3C403F] rounded-lg p-4 w-full relative">
                  <div className="flex items-start gap-2 pb-6 border-b border-[#3C403F]">
                    <img src={profile} />
                    <div>
                      <h1 className="text-white text-[18px] font-arabicbold">
                        {orderDetails?.receiver?.email}
                      </h1>
                      <p className="text-[#AEB2B1] text-[16px] font-arabicreg">
                        ID - {orderDetails?.receiver?._id}
                      </p>
                    </div>
                  </div>
                  <div
                    ref={chatContainerRef}
                    className="flex flex-col gap-2 pb-6 mt-3 h-[340px] overflow-y-scroll pr-2 mb-10">
                    {messages?.map((msg, index) => (
                      <div
                        key={index}
                        className={`flex ${msg?.sender?._id === currentUserId
                          ? "justify-end"
                          : "justify-start"
                          } w-full`}
                      >
                        <div className="flex flex-col w-2/3">
                          <div className="flex items-center">
                            {msg?.sender?._id === currentUserId ? (

                              <>
                                <span class="text-xs text-gray-700">
                                  You
                                </span>
                              </>
                            ) : <>
                              <img src={profile} className="w-12 h-12 pr-4" />
                            </>}
                            {msg?.sender?._id !== currentUserId && <>
                              {msg?.senderType !== 'admin' ? <>
                                <h4 className="text-sm text-white">
                                  {msg?.sender?.email}
                                </h4>
                              </> : <>
                                <h4 className="text-sm text-white">
                                  Betwin Admin
                                </h4>
                              </>}
                            </>}

                          </div>
                          <div className="attachment">
                            {msg?.attachment[0] && <>
                              {isImage(msg?.attachment[0]) ? <>
                                {msg?.attachment[0] && <>
                                  <PhotoProvider>
                                    <PhotoView src={msg?.attachment[0]}>
                                      <img src={msg?.attachment[0]} className="w-full h-40 cursor-pointer object-cover rounded-md" />
                                    </PhotoView>
                                  </PhotoProvider>
                                </>}
                              </> : <>
                                <a
                                  href={msg?.attachment[0]}
                                  download
                                  className="w-full cursor-pointer object-cover rounded-md bg-[#49db69] py-3 flex items-center justify-center text-center"
                                >
                                  <span className="flex items-center gap-2"><File className="text-white" /> <span className="text-white">file{getFileExtension(msg?.attachment[0])}</span></span>
                                </a>
                              </>}
                            </>}

                          </div>
                          {msg?.content && <>
                            <div className="p-4 mt-1 bg-[#252525] rounded-t-lg rounded-br-xl">
                              <p className="text-[#AEB2B1] text-[14px] font-arabicreg">
                                {msg?.content}
                              </p>
                            </div>
                          </>}
                          {msg?.sender?._id !== currentUserId && (
                            <>
                              <span class="text-xs text-gray-700">
                                {dateFormatter(msg?.createdAt)}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="message-area flex items-center px-4 h-14 rounded-lg border border-[#3C403F]">
                    <input
                      type="text"
                      placeholder="Send a message..."
                      className="flex-1 w-full"
                      value={message}
                      onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                    <div className="flex items-center justify-between gap-2">
                      <input
                        type="file"
                        onChange={uploadAttachment}
                        className="hidden" // Hide the input
                        ref={attachmentInput}

                      />
                      <GrAttachment className="text-white cursor-pointer"
                        onClick={triggerFileInput} />

                      <img
                        src={send}
                        alt="send"
                        className="cursor-pointer"
                        onClick={sendMessage}
                      />
                    </div>
                  </div>
                </div>
                {/* chat box end */}
              </div>
            </div>
            <SidebarArea typeShowAside={typeShowAside} />
          </div>
        </div>
      )}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#111010] bg-opacity-70 z-999">
          <div className="bg-[#252525] border border-[#3C403F] p-8 rounded-lg text-center">
            <img src={confetti} className="mx-auto my-4 w-50 h-50" />
            <h1 className="text-white text-[20px] font-arabicbold">
              Release Order
            </h1>
            <p className="text-[#AEB2B1] text-[16px] font-arabicreg mb-10">
              Please confirm theat payment has been made to the seller. <br />
              Malicious clicks will lead to account frozen.
            </p>
            <div className="flex justify-between gap-4 my-4">
              <button
                className="text-white text-[14px] bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-3 w-full"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="text-[#2E2E2E] text-[14px] bg-[#F6465D] rounded-lg py-3 w-full font-arabicbold"
                onClick={handleReleaseOrder}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showRateModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#111010] bg-opacity-70 z-999 ">
          <div className="bg-[#252525] border border-[#3C403F] p-8 rounded-lg text-center w-[520px]">
            {/* <img src={confetti} className="mx-auto my-4 w-50 h-50" /> */}
            <h1 className="text-white text-[20px] font-arabicbold">
              Rate your experience
            </h1>
            <p className="text-[#AEB2B1] text-[16px] font-arabicreg mb-4">
              We highly value your feedback! Kindly take a moment to rate your
              experience and provide us with your valuable feedback.{" "}
            </p>

            <Stars iconSize={74} defaultRating={defaultRating} />
            <div className="flex flex-col">
              <textarea
                className="mt-4 p-2 border text-white font-arabicbold bg-[#2E2E2E] border-gray-300 rounded-md focus:outline-none focus:ring focus:white"
                rows="5"
                maxLength="500"
                placeholder="Tell us about your experience!"
                value={appealReason}
                onChange={handleTextareaChange}
              ></textarea>
              <p className="text-right text-[#6FCF17] text-[14px] py-4 font-arabicbold">
                {appealReason.length}{" "}
                <span className="text-white font-arabicbold text-[14px]">
                  /500
                </span>
              </p>
            </div>
            <div className="flex justify-between gap-4 my-4">
              <button
                className="text-white text-[14px] bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-3 w-full"
                onClick={() => setShowRateModal(false)}
              >
                No, Thanks
              </button>
              <button
                disabled={disable}
                className="text-white text-[14px] bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-lg py-3 w-full"
                // onClick={handleConfirmClick}
                onClick={handleRatings}
              >
                {loading ? <>
                  <ButtonLoader />
                </> : <>
                  Send
                </>}
              </button>
            </div>
          </div>
        </div>
      )}
      {diputeOrderModal && (
        <div className="modal w-full">
          <div className="modal-content font-arabicmed rounded-lg overflow-hidden w-full">
            <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6">
              <div className="w-full flex flex-col justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                <p className="text-white text-[24px] sm:text-[18px] font-arabicbold">
                  Dispute Order
                </p>
                <div className="dipute_form">
                  <form>
                    <div className="form-group">
                      <p className="text-[#AEB2B1] py-2 font-arabicbold">
                        Reason for dispute
                      </p>
                      <input type="text" value={disputeForm.reason} onChange={(e) => {
                        setDisputeForm({
                          ...disputeForm,
                          reason: e.target.value
                        })
                      }} className="w-full px-4 bg-[#161616] text-white h-12 rounded-lg border border-[#3C403F]" />
                    </div>
                    <div className="form-group">
                      <p className="text-[#AEB2B1] py-2 font-arabicbold">
                        Details
                      </p>
                      <textarea onChange={(e) => {
                        setDisputeForm({
                          ...disputeForm,
                          details: e.target.value
                        })
                      }} className="w-full px-4 bg-[#161616] text-white h-20 rounded-lg border border-[#3C403F]">{disputeForm.details}</textarea>
                    </div>
                    <div className="form-group">
                      <p className="text-[#AEB2B1] py-2 font-arabicbold">
                        Proof Attatchment *
                      </p>
                      <input type="file" onChange={handleFileChange} className="w-full px-4 bg-[#161616] py-4 text-white rounded-lg border border-[#3C403F]" />
                    </div>
                  </form>
                </div>
              </div>

              <div className="w-full flex sm:flex-col items-center py-4 pt-4 my-2 gap-4">
                <button
                  className="bg-[#252525] w-full sm:order-2 border-2 border-[#3C403F] rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#363636]"
                  // onClick={closeModal}
                  onClick={() => setDisputeOrderModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#F6465D] w-full sm:order-1 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#ad4343]"
                  // onClick={handleSellClick}
                  onClick={handleDisputeOrder}
                >
                  Dispute
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {openNotice && (
        <div className="modal w-full">
          <div className="modal-content font-arabicmed rounded-lg overflow-hidden w-full">
            <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6">
              <div className="w-full flex flex-col items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                <img
                  src={noticeRed}
                  className="py-4"
                  style={{ color: "#F6465D" }}
                />
                <p className="text-white text-[24px] text-center sm:text-[18px] font-arabicbold">
                  Dispute Order
                </p>
                <p className="text-[#AEB2B1] text-center py-2 font-arabicbold">
                  Are you sure you want to dispute order?
                </p>
              </div>

              <div className="w-full flex sm:flex-col items-center py-4 pt-4 my-2 gap-4">
                <button
                  className="bg-[#252525] w-full sm:order-2 border-2 border-[#3C403F] rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#363636]"
                  // onClick={closeModal}
                  onClick={() => setOpenNotice(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#F6465D] w-full sm:order-1 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#ad4343]"
                  // onClick={handleSellClick}
                  onClick={() => {
                    setDisputeOrderModal(true);
                    setOpenNotice(false);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const isImage = (fileUrl) => {
  const imageExtensions = [".png", ".jpeg", ".jpg", ".gif", ".bmp", ".webp"];
  const fileExtension = fileUrl?.slice(fileUrl?.lastIndexOf(".")).toLowerCase();
  return imageExtensions?.includes(fileExtension);
};

const getFileExtension = (fileUrl) => {
  const fileExtension = fileUrl?.slice(fileUrl?.lastIndexOf(".")).toLowerCase();
  return fileExtension;
}