import brl from "../../assets/img/currency/brl.png";
import cad from "../../assets/img/currency/cad.png";
// import cop from "../../assets/img/currency/cop.png";
import aed from "../../assets/img/currency/aed.png";
import eur from "../../assets/img/currency/eur.png";
import gbp from "../../assets/img/currency/gbp.png";
import hkd from "../../assets/img/currency/hkd.png";
import usd from "../../assets/img/currency/usd.png";

export const withdrawCurrencies = [
  { img: usd, heading: "USD", currency: "US Dollar" },
  { img: brl, heading: "BRL", currency: "Brazilian Real" },
  { img: cad, heading: "CAD", currency: "Canadian Dollar" },
  { img: eur, heading: "EUR", currency: "Euro" },
  { img: gbp, heading: "GBP", currency: "Pound Sterling" },
  { img: hkd, heading: "HKD", currency: "Hong Kong Dollar" },
  { img: aed, heading: "AED", currency: "UAE Dirham" },
 
];
