import React from "react";

export const Footer = () => {
  return (
    <footer className="pt-6 pb-6 mt-16">
      <div className="footer-width content-footer-area">
        <div className="copyright-area flex items-center justify-between">
          <p>© 2024 BetWin. All rights reserved</p>
          <p>
            Privacy Policy
            <span></span>
            Terms Of Service
          </p>
        </div>
      </div>
    </footer>
  );
};
