import React from "react";
import { toast } from "react-toastify";
import step2 from "../../assets/img/wallet/step2.svg";
import { Coin } from "../../utils/staking/Coin";
import { Network } from "../../utils/wallet/Network";
import { NetworkDropdown } from "../popups/NetworkDropdown";
import { PayDropdown } from "../popups/PayDropdown";
export const WithDrawCrypto = () => {
  const notify = () => toast("Deposit Address Coppied");
  return (
    <div>
      <h1 className="text-[24px] text-white font-arabicmed">Withdraw Crypto</h1>

      <div className="grid grid-cols-2 mt-[51px] gap-8">
        <div className="bg-[#252525] h-[682px] p-6 rounded-lg">
          <div className="flex items-center gap-4 mb-8">
            <img src={step2} alt="" className="" />
            <h1 className="text-[22px] text-white font-arabicreg">
              Select coin and payment method
            </h1>
          </div>
          <div>
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
            >
              Select Coin
            </label>
            <PayDropdown
              stake
              classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
              dollar={Coin[0].img}
              heading={Coin[0].heading}
              list={Coin}
            />
          </div>
          <div className="mb-3 mt-[36px] flex items-center justify-between">
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
            >
              Withdraw to
            </label>
            <div className="flex items-center gap-3">
              <button className="text-[14px] font-arabicmed text-white bg-[#2E2E2E] w-[150px] h-[43px] rounded-lg border-[1px] border-[#6FCF17]">
                New Address
              </button>
              <button className="text-[14px] font-arabicmed text-white bg-[#252525] w-[150px] h-[43px] rounded-lg  border-[1px] border-[transparent]">
                Address Book
              </button>
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
            >
              Address
            </label>
            <input
              type="text"
              value="0xcd73f4e8f50c48267e26348df60e6d27c5dbf168"
              placeholder="0xcd73f4e8f50c48267e26348df60e6d27c5dbf168"
              className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6"
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
            >
              Select Network
            </label>
            <NetworkDropdown
              stake
              classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
              subheading={Network[0].subheading}
              heading={Network[0].heading}
              list={Network}
            />
          </div>
          <p className="text-[#6FCF17] text-[16px] font-arabicreg">
            Wallet address automatically matched to corresponding network.
          </p>
        </div>

        <div className="bg-[#252525] h-[682px] p-6 rounded-lg">
          <div className="flex items-center gap-4 mb-8">
            <img src={step2} alt="" className="" />
            <h1 className="text-[22px] text-white font-arabicreg">
              Withdrawal Information
            </h1>
          </div>
          <div>
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
            >
              Select Coin
            </label>
            <PayDropdown
              stake
              classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
              dollar={Coin[0].img}
              heading={Coin[0].heading}
              list={Coin}
            />
          </div>
          <div className="mb-3 mt-[36px] flex items-center justify-between">
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
            >
              Withdraw to
            </label>
            <div className="flex items-center gap-3">
              <button className="text-[14px] font-arabicmed text-white bg-[#2E2E2E] w-[150px] h-[43px] rounded-lg border-[1px] border-[#6FCF17]">
                New Address
              </button>
              <button className="text-[14px] font-arabicmed text-white bg-[#252525] w-[150px] h-[43px] rounded-lg  border-[1px] border-[transparent]">
                Address Book
              </button>
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
            >
              Address
            </label>
            <input
              type="text"
              value="0xcd73f4e8f50c48267e26348df60e6d27c5dbf168"
              placeholder="0xcd73f4e8f50c48267e26348df60e6d27c5dbf168"
              className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6"
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
            >
              Select Network
            </label>
            <NetworkDropdown
              stake
              classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
              subheading={Network[0].subheading}
              heading={Network[0].heading}
              list={Network}
            />
          </div>
          <p className="text-[#6FCF17] text-[16px] font-arabicreg">
            Wallet address automatically matched to corresponding network.
          </p>
        </div>
      </div>
    </div>
  );
};
