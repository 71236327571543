import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

const AuthContext = createContext(null);

export const useAuth = () => {
  return useContext(AuthContext);
};
const fetchUserInfo = async (token) => {
  const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";
  const response = await axios.get(`${API_URL}/user/me`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data.data;
};
export const AuthProvider = ({ children }) => {
  // const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem("token") ? true : false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);


  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      setIsAuthenticated(true);
      // Optionally, you can also fetch user info here if required
    }
  }, []);

  const token = queryParams.get('token') ?? localStorage.getItem("token");
  const { data: userInfo, isError, isLoading, refetch } = useQuery(
    'userInfos',
    () => fetchUserInfo(token),
    {
      enabled: !!token,
      retry: false,
    }
  );

  useEffect(() => {
    if (userInfo) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [userInfo]);

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    userInfo,
    isError,
    isLoading,
    refetch
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
