import React, { useState } from "react";
import { Link } from "react-router-dom";
import pdf from "../../assets/img/p2p/pdf.svg";
import backImg from "../../assets/img/transaction/back.svg";
import { AffiliateHeader } from "../AffiliateHeader";
import { TraderOrderAllOrders } from "./TraderOrderAllOrders";
import { TraderOrderProcessing } from "./TraderOrderProcessing";
import { P2PNav } from "./P2PNav";


export const RightAreaP2PTraderOrder = ({
  setsigninState,
  setSignUpState,
  login,
}) => {


  const [activeButton, setActiveButton] = useState(1);
  const handleButtonClick = (active) => {
    setActiveButton(active);
  };

  return (
    <div className="flex-1">
      <AffiliateHeader
        headerType="Affiliate"
        login={login}
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />

      <P2PNav />

      <div className="body-area flex-1  px-6 pt-6">
        <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
          <div className="flex items-center  px-6 h-[91px] border-[#3C403F] border-b-[1px]">
            <Link to="/p2p/buy">
              <img src={backImg} alt="" />
            </Link>
            <h1 className="text-white flex-1 text-[24px]  sm:text-[18px] ml-4">
              Trader Order List
            </h1>
            {/* <Link
              to="#"
              className="text-[14px] font-arabicmed text-white w-[236px] h-[43px]
               bg-[#2E2E2E] border-[1px] border-[#6FCF17] flex items-center justify-center rounded-lg sm:text-[12px] sm:w-[140px] gap-2 hover:bg-[#3C403F]"
            >
              <img src={exportImg} alt="" />
              Export Transaction
            </Link> */}
          </div>

          <div className="px-6 pb-2 pt-8">
            <div className="border border-[#3C403F] rounded-lg p-4 mb-4">
              <div className="flex justify-between mb-4">
                <h1 className="text-[20px] text-white">P2P</h1>
                <h1 className="text-[20px] text-white flex gap-2 items-center">
                  <img src={pdf} className="w-[20px] h-[20px]" />
                  User Guide
                </h1>
              </div>
              <div className="flex justify-between sm:flex-col">
                <div className="flex justify-between gap-4 sm:overflow-x-auto">
                  <button
                    className={`border ${
                      activeButton === 1
                        ? "border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]"
                        : "border-[#3C403F] bg-[##111010] text-[#AEB2B1]"
                    } p-2 rounded-md w-32`}
                    onClick={() => {
                      handleButtonClick(1);
                    }}
                  >
                    All Orders
                  </button>
                  <button
                    className={`border ${
                      activeButton === 2
                        ? "border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]"
                        : "border-[#3C403F] bg-[##111010] text-[#AEB2B1]"
                    } p-2 rounded-md w-32`}
                    onClick={() => {
                      handleButtonClick(2);
                    }}
                  >
                    Processing
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="px-6 pb-8 pt-2 ">
            {activeButton === 1 && <TraderOrderProcessing />}
            {activeButton === 2 && <TraderOrderAllOrders />}
          </div>
        </div>
      </div>
    </div>
  );
};
