import React, {useEffect} from "react";
import "../assets/css/settings/index.css";

// images
import close from "../assets/img/settings/svg/close.svg";

import dark from "../assets/img/dark.svg";
import light from "../assets/img/light.svg";
import dollar from "../assets/img/popup/dollar.svg";
// components
import { SettingsOptions } from "../components/common/SettingsOptions";
import { Switch } from "./settings/Switch";
import { DropdownSettings } from "./settings/DropdownSettings";
import { Tabs, TabsHeader, Tab } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { Currency } from "../utils/staking/Currency";
import { CurrencyCoins } from "../utils/staking/CurrencyCoins";

export const GeneralSettingsLeft = () => {
  const navigate = useNavigate();
  const data = [
    {
      label: "Dark",
      value: "Dark",
      Icon: dark,
    },
    {
      label: "Light",
      value: "Light",
      Icon: light,
    },
  ];
  useEffect(() => {
    window.gtranslateSettings = {
      default_language: "en",
      native_language_names: true,
      languages: [
        "en",
        "es",
        "ur",
        "zh-CN",
        "pt",
        "ja",
        "th",
        "vi",
        "hi",
        "ru",
        "de",
        "fr",
      ],
      wrapper_selector: ".gtranslate_wrapper",
    };
    const script = document.createElement("script");
    script.src = "https://cdn.gtranslate.net/widgets/latest/dwf.js";
    script.async = true;
    script.defer = true;
    document.querySelector(".body-left-area").appendChild(script);
  }, []);



  return (
      <div className="body-left-area w-full">
        <div className="settings-area  rounded-lg">
          <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
            <h1>Global Settings</h1>
            <img
              src={close}
              className="cursor-pointer"
              alt=""
              onClick={(e) => {
                navigate("/");
              }}
            />
          </div>

          <div className="settings-content-area flex items-start relative">
            <div className="settings-options-area flex flex-col p-3">
              <SettingsOptions active={1} />
            </div>

            <div className="settings-content-body-area py-6 px-6 flex-1">
              <div className="switch-row flex pb-4 items-center justify-between">
                <h1>Show full name of currency in Crypto list</h1>
                <Switch id="curr_name" />
              </div>
              <div className="switch-row flex py-4 items-center justify-between">
                <h1>Receive Marketing Promotions</h1>
                <Switch id="curr_marketing" />
              </div>
              <div className="switch-row flex py-4 items-center justify-between">
                <div className="dropdown-area">
                  <h1 className="mb-2">Currency Setting</h1>
                  <DropdownSettings
                    img={dollar}
                    heading="USD"
                    listdata={CurrencyCoins}
                  />
                </div>
                <Switch id="curr_settings" />
              </div>
              <div className="switch-row flex py-4 items-center justify-between">
                <div className="dropdown-area">
                  <h1 className="mb-2">Display mode</h1>
                  <Tabs value="Dark" className="tabs-area">
                    <TabsHeader>
                      {data.map(({ Icon, value }) => (
                        <Tab
                          key={value}
                          value={value}
                          onClick={(e) => {
                            let ActiveLi = document.querySelector(
                              ".tabs-area ul li.active"
                            );
                            if (ActiveLi) {
                              ActiveLi.classList.remove("active");
                            }

                            e.target.classList.add("active");
                          }}
                        >
                          <div className="flex items-center">
                            {Icon && (
                              <img src={Icon} alt="" className="w-5 h-5" />
                            )}
                          </div>
                        </Tab>
                      ))}
                    </TabsHeader>
                  </Tabs>
                </div>
              </div>
              <div className="switch-row flex py-4 items-center justify-between"  style={{ borderBottom: '0' }}>
                <div className="dropdown-area">
                  <h1 className="mb-2">Language</h1>
                  <div className="gtranslate_wrapper"></div>

                  {/* <DropdownSettings
                    heading="English"
                    listdata={[
                      {
                        heading: "English",
                      },
                      {
                        heading: "Spanish",
                      },
                      {
                        heading: "Urdu",
                      },
                      {
                        heading: "Chinese",
                      },
                      {
                        heading: "Portuguese",
                      },
                      {
                        heading: "Japanese",
                      },
                      {
                        heading: "Thai",
                      },
                      {
                        heading: "Vietnamese",
                      },
                      {
                        heading: "Hindi",
                      },
                      {
                        heading: "Russian",
                      },
                      {
                        heading: "German",
                      },
                      {
                        heading: "French",
                      },
                    ]}
                  /> */}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </section>
  );
};