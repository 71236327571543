import React, { useEffect, useRef } from "react";
import "../assets/css/settings/index.css";

// images
import close from "../assets/img/settings/svg/close.svg";

// components
import { SettingsOptions } from "./common/SettingsOptions";
import { useNavigate } from "react-router-dom";

export const GeneralActiveSessionLeft = () => {
  // const ref = useRef(null);
  const navigate = useNavigate();
  // const ResizeWork = (e) => {
  //   let Element = window.innerWidth;
  //   ref.current.style.width = `${Element - 600}px`;
  // };
  // useEffect(() => {
  //   ResizeWork();
  //   window.addEventListener("resize", ResizeWork);
  // }, []);

  return (
    // <div className="body-left-area  mr-6" ref={ref}>
    <div className="body-left-area w-full">
      <div className="settings-area  rounded-lg">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Global Settings</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>

        <div className="settings-content-area flex items-start relative">
          <div className="settings-options-area flex flex-col p-3">
            <SettingsOptions active={5} />
          </div>

          <div className="settings-content-body-area gap-6 py-6 px-6 flex-1">
            <h1 className="heading">Sessions Used Past</h1>

            <table className="w-full mt-6">
              <thead>
                <th>Browser</th>
                <th>Location</th>
                <th>IP Address</th>
                <th>Last Used</th>
                <th>Action</th>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
