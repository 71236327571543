import React from "react";

export const RadioPayment = ({ id, onchange }) => {
  return (
    <div className="mt-2 RadioPayment">
      <input type="radio" name="payment" id={id} className="hidden" />
      <label
        onClick={onchange}
        htmlFor={id}
        className="bg-[#2E2E2E] flex w-[24px] h-[24px] rounded-full border-[2px] border-[#6FCF17] cursor-pointer"
      ></label>
    </div>
  );
};
