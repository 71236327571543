import React, { useState } from "react";
import "../assets/css/sign-in.css";
import signInMainImg from "../assets/img/sign-in-girl.png";

import button from "../assets/img/Button.svg";
import button1 from "../assets/img/Button-1.svg";
import button2 from "../assets/img/Button-2.svg";
import button3 from "../assets/img/Button-3.svg";
import button4 from "../assets/img/Button-4.svg";
import button5 from "../assets/img/Button-5.svg";
import eye from "../assets/img/eye.svg";

import { CheckboxStyleSignUp } from "./CheckboxStyleSignUp";
import { FlagSelect } from "./FlagSelect";
export const SignUpPhone = ({
  setSignUpPhoneState,
  setSignUpState,
  setloginType,
}) => {
  const [type, setType] = useState(true);
  return (
    <div className="SignIn LayerUp">
      <div className="inner-work-sign-in grid grid-cols-2">
        <div className="left-side flex items-center justify-center flex-col">
          <img src={signInMainImg} alt="sign in main presentation" />
          <div className="presentation">
            <h1>Welcome to BetWin</h1>
            <p className="mt-4">Start your game journey now!</p>
          </div>
        </div>
        <div className="right-side  px-8 py-10">
          <form action="#" className="flex flex-col h-full">
            <h1 className="mb-3">Sign Up</h1>
            <div className="grid grid-cols-2 tabs-wrapper gap-3 mb-5">
              <button
                className="rounded-lg "
                onClick={(e) => {
                  setSignUpPhoneState(false);
                  setSignUpState(true);
                }}
              >
                Email
              </button>
              <button
                className="rounded-lg active"
                onClick={(e) => {
                  setSignUpPhoneState(true);
                  setSignUpState(false);
                }}
              >
                Phone Number
              </button>
            </div>
            <div className="input-wrapper">
              <label htmlFor="phone">Phone Number</label>
              <div className="flex  mt-3 items-center">
                <FlagSelect />
                <input
                  type="tel"
                  placeholder="Phone Number"
                  id="phone"
                  className="rounded-lg px-6 flex-1"
                />
              </div>
            </div>
            <div className="input-wrapper mt-4 mb-5">
              <label htmlFor="password">Login Password</label>
              <input
                type={type ? "password" : type}
                placeholder="Enter your passwoard"
                id="password"
                className="rounded-lg px-6 mt-3"
              />
              <img
                src={eye}
                className="eye-icon"
                onClick={(e) => {
                  setType(!type);
                }}
                alt="eye icon"
              />
            </div>

            <div className="agreement flex items-center">
              <h1 className="mr-2">Enter Referral / Promo Code</h1>
              <svg
                width="22"
                height="17"
                viewBox="0 0 22 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.51825 4.59054L11.0911 9.64645L15.664 4.59054C15.7731 4.4699 15.9027 4.37421 16.0453 4.30892C16.1878 4.24363 16.3406 4.21002 16.4949 4.21002C16.6492 4.21002 16.802 4.24363 16.9446 4.30892C17.0872 4.37421 17.2167 4.4699 17.3258 4.59054C17.4349 4.71119 17.5215 4.85441 17.5806 5.01203C17.6396 5.16965 17.67 5.3386 17.67 5.50921C17.67 5.67982 17.6396 5.84876 17.5806 6.00638C17.5215 6.16401 17.4349 6.30723 17.3258 6.42787L11.9161 12.4089C11.8071 12.5297 11.6776 12.6256 11.535 12.691C11.3924 12.7564 11.2396 12.79 11.0852 12.79C10.9309 12.79 10.7781 12.7564 10.6355 12.691C10.4929 12.6256 10.3634 12.5297 10.2544 12.4089L4.84467 6.42787C4.73541 6.30732 4.64873 6.16413 4.58959 6.00649C4.53044 5.84885 4.5 5.67987 4.5 5.50921C4.5 5.33855 4.53044 5.16956 4.58959 5.01192C4.64873 4.85429 4.73541 4.7111 4.84467 4.59054C5.30431 4.09538 6.05861 4.08235 6.51825 4.59054Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="agreement-list mt-4">
              <div className="list-inner flex items-start">
                <CheckboxStyleSignUp type="checkbox" id="user-agree" />
                <p className="ml-3">
                  I agree to the User <a href="/">Agreement & Confirm</a> i am
                  at I east 18 years old
                </p>
              </div>

              <div className="list-inner mt-2 flex items-center">
                <CheckboxStyleSignUp type="checkbox" id="marketing" />
                <p className="ml-3" style={{ fontSize: 12 }}>
                  I agree to receive marketing promotions from BetWin
                </p>
              </div>
            </div>

            <button
              className="w-full rounded-lg mt-4 mb-4"
              onClick={(e) => {
                e.preventDefault();
                setloginType(true);
                setSignUpPhoneState(false);
              }}
            >
              Sign Up
            </button>
            <p className="dont-have-p">
              Already have an account! <a href="/">Sign In </a>
            </p>

            <div className="ending-point  flex-1 flex flex-col  justify-end">
              <div className="line-breaker flex items-center mt-3">
                <span className="flex-1 mr-2"></span>
                <p>Or Login with</p>
                <span className="flex-1 ml-2"></span>
              </div>

              <div className="mt-3 social-icons flex items-center justify-between">
                <a href="/">
                  <img src={button} alt="social button" />
                </a>
                <a href="/">
                  <img src={button1} alt="social button" />
                </a>
                <a href="/">
                  <img src={button2} alt="social button" />
                </a>
                <a href="/">
                  <img src={button3} alt="social button" />
                </a>
                <a href="/">
                  <img src={button4} alt="social button" />
                </a>
                <a href="/">
                  <img src={button5} alt="social button" />
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
