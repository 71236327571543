import React from "react";
import { AffiliateHeader } from "../AffiliateHeader";
import { P2PNav } from "./P2PNav";

export const TermsAndConditonsPage = ({
    setsigninState,
    setSignUpState,
    login,
}) => {

    return (
        <div className="flex-1">
            <AffiliateHeader
                headerType="Affiliate"
                login={login}
                settypeShowAside={1}
                setsigninState={setsigninState}
                setSignUpState={setSignUpState}
            />

            <P2PNav />

            <div className="body-area flex items-start px-6 pt-6">
                <h2 className="text-white text-lg">Terms & Conditions</h2>
            </div>

        </div>
    );
};
