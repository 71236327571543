import React, { useState } from "react";
import { Link } from "react-router-dom";
import backImg from "../assets/img/transaction/back.svg";
import { useGetTransactions } from "../queries/transactions";
import { AffiliateHeader } from "./AffiliateHeader";
import { Dropdown } from "./transaction/Dropdown";
import { Input } from "./transaction/Input";
import { TransactionTable } from "./transaction/TransactionTable";
export const TransactionHistoryRightArea = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const [txid, setTxid] = useState("");
  const [status, setStatus] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const { data: transactionData, isLoading, refetch } = useGetTransactions({
    page: currentPage,
    interval: '',
    date: '',
    flag: '',
    filteredFlag: ['game', 'rollback'].join(','),
  });


  const transactionDataList = transactionData?.data?.transactions ?? [];
  const totalPages = transactionDataList?.data?.totalPages;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //console.log("TABLE LIST COUNT: ", transactionDataList);
  return (
    <div className="flex-1">
      <AffiliateHeader
        headerType="Affiliate"
        login={login}
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex-1  px-6 pt-6">
        <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
          <div className="flex items-center  px-6 h-[91px] border-[#3C403F] border-b-[1px]">
            <Link to="/">
              <img src={backImg} alt="" />
            </Link>
            <h1 className="text-white flex-1 text-[24px]  sm:text-[18px] ml-4">
              Transaction History
            </h1>
          </div>

          <div className="px-6 py-8 ">
            

            <div className="grid sm:grid-cols-2 grid-cols-4 gap-6  mb-10">
             
              <Dropdown
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                head="Status"
                list={[
                  {
                    data: "All",
                    value: "",
                  },
                  {
                    data: "Active",
                    value: "active",
                  },
                  {
                    data: "Processing",
                    value: "processing",
                  },
                  {
                    data: "Confirmed",
                    value: "confirmed",
                  },
                  {
                    data: "Completed",
                    value: "completed",
                  },
                  {
                    data: "Pending",
                    value: "pending",
                  },
                  {
                    data: "Approved",
                    value: "approved",
                  },
                  {
                    data: "Declined",
                    value: "declined",
                  },
                  {
                    data: "Failed",
                    value: "failed",
                  },
                ]}

              />
              <Input
                value={txid}
                onChange={(e) => setTxid(e.target.value)}
                head="TxID"
                placeholder="Enter TxID"
              />
            </div>

            <div className="sm:overflow-x-scroll">
              <TransactionTable
                loading={isLoading}
                transactionDataList={transactionDataList
                  ?.filter((el) => el._id?.includes(txid))
                  ?.filter((el) => el.status.includes(status))}
              />
            </div>
            <div className="flex pagination-table-buttons items-center justify-center mt-8">
              <button className="flex items-center mr-2" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">

                </svg>
                <p>Previous</p>
              </button>
              {Array.from({ length: totalPages }).map((_, index) => (
                <button key={index} className={`rounded count ${currentPage === index + 1 ? "active" : ""}`} onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              ))}
              <button
                className={`flex active items-center ml-2 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <p>Next</p>
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">

                </svg>
              </button>
            </div>
            {/* {Transactions.length > 0 && (
              <div className="flex items-center justify-center">
                <button
                  className="flex items-center text-[12px] font-arabicmed 
                  text-[#AEB2B1] gap-1"
                >
                  <img src={previous} alt="" />
                  Previous
                </button>

                <div className="flex items-center mx-2 gap-1">
                  <button className="px-2 py-1 text-[#FFF] text-[10px] font-arabicmed bg-[#2E2E2E] rounded">
                    1
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    2
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    3
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    ...
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    15
                  </button>
                </div>

                <button
                  className="flex items-center text-[12px] font-arabicmed 
                     text-[#fff] gap-1"
                >
                  Next
                  <img src={forward} alt="" />
                </button>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};
