import React, { useState } from "react";
import { Link } from "react-router-dom";
import backImg from "../../assets/img/transaction/back.svg";
// import exportImg from "../../assets/img/transaction/export.svg";
import { useMyOrderList } from "../../queries/p2p";
import { AffiliateHeader } from "../AffiliateHeader";
import { Dropdown } from "../transaction/Dropdown";
import { Input } from "../transaction/Input";
// import { OrderTransactionTable } from "./OrderTransactionTable";
import { BlockListTable } from "./BlockListTable";
import search from "../../assets/img/p2p/search.svg";
import arrowdown from "../../assets/img/p2p/arrow-down.svg";
import { P2PNav } from "./P2PNav";

export const RightAreaP2PBlockList = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [idx, setIdx] = useState("");
  const [status, setStatus] = useState("");
  const orderData = useMyOrderList();
  const orderList = orderData.data?.data?.order ?? [];
  const [selectedRank, setSelectedRank] = useState("");

  const handleSearchFetch = (e) => {
    setSearchInput(e.target.value);
    //   refetch();
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSearchInput("");
    setSelectedRank("");
    // refetch();
  };

  return (
    <div className="flex-1">
      <AffiliateHeader
        headerType="Affiliate"
        login={login}
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />

      <P2PNav />

      <div className="body-area flex-1  px-6 pt-6">
        <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
          <div className="flex items-center  px-6 h-[91px] border-[#3C403F] border-b-[1px]">
            <Link to="/p2p/buy">
              <img src={backImg} alt="" />
            </Link>
            <h1 className="text-white flex-1 text-[24px]  sm:text-[18px] ml-4">
              Block List
            </h1>
            {/* <Link
              to="#"
              className="text-[14px] font-arabicmed text-white w-[236px] h-[43px]
               bg-[#2E2E2E] border-[1px] border-[#6FCF17] flex items-center justify-center rounded-lg sm:text-[12px] sm:w-[140px] gap-2 hover:bg-[#3C403F]"
            >
              <img src={exportImg} alt="" />
              Export Transaction
            </Link> */}
          </div>

          <div className="px-6 py-8 ">
            <div className="bottom-agent bottom-agent-page pt-6  pb-4  gap-2 flex items-end">
              <div
                className="grid grid-cols-5 sm:grid-cols-1 gap-6 mb-4"
                style={{ width: "100%" }}
              >
                <div className="col-span-3">
                  <Input
                    value={idx}
                    onChange={(e) => setIdx(e.target.value)}
                    head="Search"
                    placeholder="Search email, agent name, first name, last name"
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
                <div className="col-span-1">
                  <Dropdown
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    head="Size"
                    list={[
                      {
                        data: "10",
                        value: "10",
                      },
                      {
                        data: "20",
                        value: "20",
                      },
                      {
                        data: "30",
                        value: "30",
                      },
                    ]}
                  />
                </div>

                <div className="flex flex-col justify-end items">
                  <button
                    onClick={resetFilter}
                    className="rounded-lg px-4  py-[13px] bg-[#2E2E2E] text-white "
                  >
                    Reset Filter
                  </button>
                </div>
              </div>
            </div>

            <div className="lgmed:overflow-auto">
              <BlockListTable
              // orderList={orderList
              //   ?.filter((el) => el._id?.includes(txid))
              //   ?.filter((el) => el.status.includes(status))}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
