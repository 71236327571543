import React from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Header } from "../../components/admin/Header";
import { Footer } from "../../components/admin/Footer";

// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
// utils
import { CheckboxCustom } from "../../components/admin/CheckboxCustom";
import { useNavigate } from "react-router-dom";

export const SiteMantainence = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-start home admin-dashboard">
        {/* left area */}
        <Aside active={9} />

        <div className="flex-1">
          <Header heading="Site Maintenance" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Site Maintenance</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/admin-dashboard");
                  }}
                >
                  Home
                </span>{" "}
                / Site Maintenance
              </button>
            </div>

            <div className="agent-wrapper create-agents mt-8  rounded-xl">
              <div className="top-agent  rounded-tl-xl  rounded-tr-xl py-7 px-6 top-agent-page flex items-center justify-between">
                <h1>Site Maintenance</h1>
              </div>
              <div className="forms-wrapper px-6 py-6">
                <div className="p-3 form-wrapper-outline-up">
                  <h1>Maintenance Mode</h1>
                </div>
                <div className="p-3 form-wrapper-outline-down">
                  <form action="#" className="grid grid-cols-2 gap-x-3 gap-y-6">
                    <div className="input-wrapper">
                      <label htmlFor="">
                        Title <span>*</span>
                      </label>
                      <div className="input-container  mt-3">
                        <input type="text" placeholder="Enter your user name" />
                      </div>
                    </div>

                    <div className="input-wrapper">
                      <label htmlFor="">
                        Announcement Title <span>*</span>
                      </label>
                      <div className="input-container mt-3">
                        <input type="text" placeholder="Enter First name" />
                      </div>
                    </div>

                    <div className="input-wrapper  flex items-center justify-between col-span-2">
                      <div className="flex items-center">
                        <div className="mr-6">
                          <h1 className="title-checkbox">Add description</h1>
                          <div className="flex mr-9 mt-3 items-center">
                            <CheckboxCustom
                              type="checkbox"
                              id="whitelist-agree"
                            />
                            <p className="ml-2 checkbox-para">Yes</p>
                          </div>
                        </div>
                        <div>
                          <h1 className="title-checkbox">
                            When you activate it, the site will go on
                            maintenance mode until you deactivate it.
                          </h1>
                          <div className="flex mr-9 mt-3 items-center">
                            <CheckboxCustom type="checkbox" id="active-agree" />
                            <p className="ml-2 checkbox-para">Site Down</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex buttons-wrapper items-center">
                        <button
                          className="h-11 px-4 rounded mr-3"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/admin-dashboard");
                          }}
                        >
                          Submit
                        </button>
                        <button className="h-11 px-4 rounded outline-button">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
