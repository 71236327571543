import React, { useState } from "react";

const PaymentMethodRenderer = ({ rowMethodNames, onOptionClick }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChangeInternal = (option) => {
    onOptionClick(option);
    setSelectedOption(option);
  };

  return (
    <>
      <h1 class="text-[#AEB2B1] font-arabicmed text-[16px] mt-3 mb-3">
        Select Payment Method:
      </h1>
      <div className="flex flex-wrap">
        {rowMethodNames.map((detail, index) => (
          <div
            key={index}
            className={`bg-[#2A2A2A] rounded-lg p-4 w-3/4 sm:w-full mb-4 ${
              selectedOption === detail
                ? "border-green-500 border-2"
                : "border-[#3C403F]"
            }`}
            onClick={() => handleOptionChangeInternal(detail)}
          >
            <div className="flex gap-2 items-center mb-2">
              <h6 className="text-[#FFFFFF] font-arabicreg">
                {detail.paymentMethod?.name}
              </h6>
            </div>
            <div className="bg-[#111010] rounded-lg border border-[#3C403F]">
              <div className="p-2">
                {Object.entries(detail.data).map(([key, value]) => (
                  <div
                    key={key}
                    className="flex justify-between items-center mb-2"
                  >
                    <p className="text-[#AEB2B1] font-arabicreg">{key}</p>
                    <p className="text-[#AEB2B1] font-arabicreg">{value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PaymentMethodRenderer;
