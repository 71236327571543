import React from "react";
import { Link } from "react-router-dom";
import { useGetCommunityLeaders } from "../../queries/community";

// import JuniorAssociate from "../../assets/img/Rank/JuniorAssociate.png";
// import ManagingDirector from "../../assets/img/Rank/ManagingDirector.png";
// import NoRank from "../../assets/img/Rank/NoRank.png";
// import SeniorPartner from "../../assets/img/Rank/SeniorPartner.png";
import Pro from "../../assets/img/affiliate/rank/Pro.svg";
import Rookie from "../../assets/img/affiliate/rank/Rookie.svg";
import Star from "../../assets/img/affiliate/rank/Star.svg";
import SuperStar from "../../assets/img/affiliate/rank/SuperStar.svg";

export const TableCommunity = () => {
  const { data } = useGetCommunityLeaders();
  return (
    <table className="w-full mt-3">
      <thead>
        <tr className="border-b-[1px] border-b-[#3C403F]">
          <th className="text-left text-[14px] pl-3 text-[#AEB2B1] font-arabicmed pb-2">
            Symbol
          </th>
          <th className="text-left text-[14px] text-[#AEB2B1] font-arabicmed pb-2">
            Rank
          </th>
          <th className="text-left text-[14px] text-[#AEB2B1] font-arabicmed pb-2">
            Total
          </th>
          <th className="text-left text-[14px] pr-3 text-[#AEB2B1] font-arabicmed pb-2"></th>
        </tr>
      </thead>
      <tbody>
        <tr className="border-b-[1px] h-[66px] border-b-[#3C403F]">
          <td className=" pl-3">
            <img src={Rookie} alt="" className="w-[30px]" />
          </td>
          <td>
            <p className="text-white text-[16px]  font-arabicmed ">
              {/* Player */}
              Rookie
            </p>
          </td>
          <td>
            <p className="text-white text-[16px] font-arabicmed ">{data?.rankSummary?.Rookie}</p>
          </td>
          <td className="text-right pr-3">
            <Link
              to="/community-team"
              className="text-[14px] font-arabicmed text-[#6FCF17] hover:text-[#a6e66b]"
            >
              View more
            </Link>
          </td>
        </tr>
        <tr className="border-b-[1px] h-[66px] border-b-[#3C403F]">
          <td className="pl-3">
            <img src={Pro} alt="" className="w-[30px]" />
          </td>
          <td>
            <p className="text-white text-[16px] font-arabicmed ">
              {/* Junior Associate */}
              Pro
            </p>
          </td>
          <td>
          <p className="text-white text-[16px] font-arabicmed ">{data?.rankSummary?.Pro}</p>
          </td>
          <td className="text-right pr-3">
            <Link
              to="/community-team"
              className="text-[14px] font-arabicmed text-[#6FCF17] hover:text-[#a6e66b]"
            >
              View more
            </Link>
          </td>
        </tr>
        <tr className="border-b-[1px] h-[66px] border-b-[#3C403F]">
          <td className=" pl-3">
            <img src={Star} alt="" className="w-[30px]" />
          </td>
          <td>
            <p className="text-white text-[16px] font-arabicmed ">
              {/* Senior Partner */}
              Star
            </p>
          </td>
          <td>
          <p className="text-white text-[16px] font-arabicmed ">{data?.rankSummary?.Star}</p>
          </td>
          <td className="text-right pr-3">
            <Link
              to="/community-team"
              className="text-[14px] font-arabicmed text-[#6FCF17] hover:text-[#a6e66b]"
            >
              View more
            </Link>
          </td>
        </tr>
        <tr className="h-[66px]">
          <td className=" pl-3">
            <img src={SuperStar} alt="" className="w-[30px]" />
          </td>
          <td>
            <p className="text-white text-[16px] font-arabicmed ">
              {/* Managing Director */}
              Super Star
            </p>
          </td>
          <td>
          <p className="text-white text-[16px] font-arabicmed ">{data?.rankSummary?.SuperStar}</p>
          </td>
          <td className="text-right pr-3">
            <Link
              to="/community-team"
              className="text-[14px] font-arabicmed text-[#6FCF17] hover:text-[#a6e66b]"
            >
              View more
            </Link>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
