import { useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

export const useGetTransactions = (
  page,
) =>
  useQuery(["transactionData", page], () =>
    privateRequest.get(
      `user/balance/get-transactions?page=${page?.page}&interval=${page?.interval}&date=${page?.date}&flag=${page?.flag}&filteredFlag=${page?.filteredFlag}`
    )
  );
export const useGetGCTransactions = (
  page,
) =>
  useQuery(["gctransactionData", page], () =>
    privateRequest.get(
      `user/balance/get-gc-transactions?page=${page?.page}&interval=${page?.interval}&date=${page?.date}&flag=${page?.flag}&filteredFlag=${page?.filteredFlag}`
    )
  );
