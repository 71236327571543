import React from "react";

export const Dropdown = ({ head, list, value, onChange }) => {
  return (
    <div>
      <label htmlFor="" className="text-[18px] font-arabicreg text-[#AEB2B1]">
        {head}
      </label>
      <div className="block h-[50px] w-full px-4 bg-[#111010] rounded-lg mt-3">
        <select
          name=""
          id=""
          value={value}
          onChange={onChange}
          className="h-full w-full border-none outline-none bg-[transparent] text-[18px] font-arabicreg text-[#fff]"
        >
          {list.map((EachList) => (
            <option
              className="text-[18px] font-arabicreg text-[#fff] bg-black"
              value={EachList.value}
            >
              {EachList.data}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
