import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import notFound from "../../assets/img/bonus-dashboard/not-found.png";
import { refundSportsBet } from "../../queries/gaming";
import { P2PModal } from "./P2PModal";

export const SportsTable = ({ sportsBet, fetchData, tab }) => {
  const [showModal, setShowModal] = useState(false);
  const [current, setCurrent] = useState([]);
  let isPostingBetCancel = false;

  const handleCancel = async (isCancel) => {
    if (isCancel) {
      if (isPostingBetCancel) {
        toast.error("Your request is being processed. Please wait a moment before trying again.");
        return;
      }

      isPostingBetCancel = true;

      try {
        const { message } = await refundSportsBet(current._id, tab);
        toast.success(message);
        fetchData();
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to cancel bet.");
      } finally {
        isPostingBetCancel = false;
      }
    }
    handleModalClose();
  }

  const getStatusElement = (row, commenceTime) => {
    const currentTime = new Date();
    const commence_time = new Date(commenceTime);
    const tenMinutesBeforeCommence = new Date(commence_time.getTime() - 10 * 60 * 1000);

    if ((row.status === "Matched" || row.status === "Waiting") && currentTime < tenMinutesBeforeCommence) {
      return (
        <button className="text-red-500"
          onClick={() => {
            setShowModal(true);
            setCurrent(row);
          }}>
          Cancel
        </button>
      );
    } else if (row.status === "Matched" && currentTime >= tenMinutesBeforeCommence) {
      return (
        <span className="text-green-500">
          {row.status}
        </span>
      );
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setCurrent([]);
  };
  return (
    <>
      {showModal && (
        <>
          <P2PModal
            title={`Cancel Bet`}
            text={`Are you sure you want to cancel your bet?`}
            onClose={() => handleModalClose}
            method={handleCancel}
          />
        </>
      )}
      <ToastContainer theme="dark" autoClose={3000} />
      <div className="bg-[#1A1A1B] p-6 mt-4 rounded-lg w-full">
        <h1 className="text-white font-arabicbold">Betting Book</h1>
        <div className="flex md:flex-col">
          {sportsBet && sportsBet.length === 0 ? (
            <div className="w-full mt-3">
              <img
                src={notFound}
                alt="Not Found"
                className="mx-auto my-0"
              />
              <h1 className="border-t py-3 no-data-available text-center text-white">
                Oops! There is no data yet!
              </h1>
            </div>
          ) : (
            <>
              <div className="w-full overflow-x-auto">
                <table className="my-4 w-full whitespace-nowrap">
                  <thead>
                    <tr className="h-[56px] border-b border-[#3A3A3A]">
                      <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                        Event
                      </th>
                      <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                        Amount
                      </th>
                      <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                        Odds
                      </th>
                      <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                        {tab === "P2P" ? 'User' : 'Bet Team'}
                      </th>
                      <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sportsBet?.map((row, key) => (
                      <tr key={key} className="h-[56px]">
                        <td
                          className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                        >
                          {row.bet_team}
                          {tab !== "P2P" && (
                            <>
                              {" "} vs {row.opponent_team}
                            </>
                          )}
                        </td>
                        <td
                          className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                        >
                          {row.bet_amount}
                        </td>
                        <td
                          className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                        >
                          {row.bet_odds}
                        </td>
                        <td
                          className={`text-left px-2 font-arabicreg text-[14px] text-yellow-500`}
                        >
                          {tab === "P2P" ? row.user_gamingId : row.bet_team}
                        </td>
                        <td
                          className={`text-left px-2 font-arabicreg text-[14px]`}
                        >
                          {getStatusElement(row, row.commence_time)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {tab === "P2P" && (
                <>
                  <div className="border-r border-white m-4"></div>
                  <div className="w-full overflow-x-auto">
                    <table className="my-4 w-full whitespace-nowrap">
                      <thead>
                        <tr className="h-[56px] border-b border-[#3A3A3A]">
                          <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                            Event
                          </th>
                          <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                            Amount
                          </th>
                          <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                            Odds
                          </th>
                          <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                            User
                          </th>
                          <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sportsBet?.map((row, key) => (
                          <tr key={key} className="h-[56px]">
                            <td
                              className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                            >
                              {row.opponent_team}
                            </td>
                            <td
                              className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                            >
                              {/* {row.opponent_amount ? row.opponent_amount : "-"} */}
                              {row.bet_amount}
                            </td>
                            <td
                              className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                            >
                              {row.opponent_odds || row.opponent_odds === 0 ? row.opponent_odds : "-"}
                            </td>
                            <td
                              className={`text-left px-2 font-arabicreg text-[14px] text-red-500`}
                            >
                              {row.opponent_gamingId ? row.opponent_gamingId : "-"}
                            </td>
                            <td
                              className={`text-left px-2 font-arabicreg text-[14px] ${row.status === "Cancel"
                                  ? "text-red-500"
                                  : row.status === "Waiting"
                                    ? "text-yellow-500"
                                    : "text-green-500"
                                }`}
                            >
                              {row.status}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

            </>
          )}
        </div>
      </div>
    </>
  );
};