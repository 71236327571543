export const RejectedUtil = [
  {
    id: 112,
    username: "rashika",
    bankname: "SBI",
    accountname: "Rahul Sadhu",
    accountnumber: "7896541230",
    ifscnumber: "SBIN000000",
    amount: "500 PHP",
    remark: "Rejected",
    withdrawalType: "N/A",
    requestedAt: "07-07-2023 10:39 AM",
    actions: "Approve",
  },
  {
    id: 112,
    username: "rashika",
    bankname: "SBI",
    accountname: "Rahul Sadhu",
    accountnumber: "7896541230",
    ifscnumber: "SBIN000000",
    amount: "500 PHP",
    remark: "Rejected",
    withdrawalType: "N/A",
    requestedAt: "07-07-2023 10:39 AM",
    actions: "Approve",
  },
  {
    id: 112,
    username: "rashika",
    bankname: "SBI",
    accountname: "Rahul Sadhu",
    accountnumber: "7896541230",
    ifscnumber: "SBIN000000",
    amount: "500 PHP",
    remark: "Rejected",
    withdrawalType: "N/A",
    requestedAt: "07-07-2023 10:39 AM",
    actions: "Approve",
  },
  {
    id: 112,
    username: "rashika",
    bankname: "SBI",
    accountname: "Rahul Sadhu",
    accountnumber: "7896541230",
    ifscnumber: "SBIN000000",
    amount: "500 PHP",
    remark: "Rejected",
    withdrawalType: "N/A",
    requestedAt: "07-07-2023 10:39 AM",
    actions: "Approve",
  },
  {
    id: 112,
    username: "rashika",
    bankname: "SBI",
    accountname: "Rahul Sadhu",
    accountnumber: "7896541230",
    ifscnumber: "SBIN000000",
    amount: "500 PHP",
    remark: "Rejected",
    withdrawalType: "N/A",
    requestedAt: "07-07-2023 10:39 AM",
    actions: "Approve",
  },
];
