import React from "react";
import flag from "../assets/img/flag.svg";
import user from "../assets/img/users.svg";
import metal from "../assets/img/metal1.svg";
import metal2 from "../assets/img/metal2.svg";
import metal3 from "../assets/img/metal3.svg";

import "../assets/css/user.css";
export const MasterMedal = ({ setusername, setmastermedalState }) => {
  return (
    <div className="LayerUp">
      <div className="user-profile rounded-xl">
        <div className="head-edit flex items-center px-6 h-16">
          <svg
            onClick={(e) => {
              setusername(true);
              setmastermedalState(false);
            }}
            className="cursor-pointer"
            width="21"
            height="26"
            viewBox="0 0 21 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.1307 18.3225L9.23806 12.9928L15.1307 7.66311C15.2713 7.53594 15.3829 7.38496 15.459 7.2188C15.5351 7.05264 15.5742 6.87455 15.5742 6.6947C15.5742 6.51485 15.5351 6.33676 15.459 6.1706C15.3829 6.00444 15.2713 5.85346 15.1307 5.72628C14.9901 5.59911 14.8232 5.49823 14.6395 5.42941C14.4558 5.36058 14.2589 5.32516 14.06 5.32516C13.8612 5.32516 13.6643 5.36058 13.4806 5.42941C13.2968 5.49823 13.1299 5.59911 12.9893 5.72628L6.01836 12.0313C5.87757 12.1584 5.76587 12.3093 5.68966 12.4755C5.61345 12.6416 5.57422 12.8198 5.57422 12.9997C5.57422 13.1796 5.61345 13.3577 5.68966 13.5239C5.76587 13.6901 5.87757 13.841 6.01836 13.9681L12.9893 20.2731C13.1298 20.4004 13.2967 20.5015 13.4804 20.5704C13.6642 20.6393 13.8611 20.6748 14.06 20.6748C14.2589 20.6748 14.4559 20.6393 14.6396 20.5704C14.8233 20.5015 14.9902 20.4004 15.1307 20.2731C15.7078 19.7374 15.723 18.8582 15.1307 18.3225Z"
              fill="#959699"
            />
          </svg>

          <h1 className="flex-1 ml-2">Master Medals</h1>
          <div className="user-tag flex items-center">
            <svg
              onClick={(e) => {
                setmastermedalState(false);
              }}
              className="cursor-pointer"
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1.02539"
                y="0.507324"
                width="31"
                height="31"
                rx="7.5"
                fill="#2E2E2E"
              />
              <rect
                x="1.02539"
                y="0.507324"
                width="31"
                height="31"
                rx="7.5"
                stroke="#3C403F"
              />
              <path
                d="M12.3587 11.8406L20.6921 20.1739M12.3587 20.1739L20.6921 11.8406"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="p-6 pt-0">
          <div className="grid box-wrapper-master-medal grid-cols-5 gap-2 mt-8 mb-4">
            <div className="box flex flex-col items-center justify-center">
              <img src={flag} alt="" />
              <h1 className="mt-2">0</h1>
            </div>
            <div className="box flex flex-col items-center justify-center">
              <img src={flag} alt="" />
              <h1 className="mt-2">5</h1>
            </div>
            <div className="box flex flex-col items-center justify-center">
              <img src={flag} alt="" />
              <h1 className="mt-2">10</h1>
            </div>
            <div className="box flex flex-col items-center justify-center">
              <img src={flag} alt="" />
              <h1 className="mt-2">15</h1>
            </div>
            <div className="box flex flex-col items-center justify-center">
              <img src={flag} alt="" />
              <h1 className="mt-2">Max</h1>
            </div>
          </div>

          <div className="percentage-master-medal mt-4 flex items-center px-3 h-6">
            <div>
              <span style={{ width: "30%" }}></span>
            </div>
          </div>

          <ul className="mt-8 mb-8 master-medal-list">
            <li className="mb-2">
              Achieve <span>5</span> medals: Get 113808 PHP
            </li>
            <li className="mb-2">
              Achieve <span>10</span> medals: Get 113808 PHP
            </li>
            <li className="mb-2">
              Achieve <span>15</span> medals: Get 113808 PHP
            </li>
            <li>
              Achieve <span>20</span> medals: Get 113808 PHP
            </li>
          </ul>

          <h1 className="heading-medal border-custom pb-6 mb-8">Unlocked</h1>
          <h1 className="heading-medal mb-4">Awaiting unlocking</h1>
          <div className="grid heading-medal-grid-mbl heading-medal-grid grid-cols-3 gap-3">
            <div className="box-coin flex flex-col items-center justify-center">
              <img src={metal} alt="" />
              <h1 className="my-3">Talkative</h1>
              <div className="badge flex items-center justify-center">
                <img src={user} alt="" />
                <p className="ml-1">7712</p>
              </div>
            </div>
            <div className="box-coin flex flex-col items-center justify-center">
              <img src={metal2} alt="" />
              <h1 className="my-3">Fearless One</h1>
              <div className="badge flex items-center justify-center">
                <img src={user} alt="" />
                <p className="ml-1">6</p>
              </div>
            </div>
            <div className="box-coin flex flex-col items-center justify-center">
              <img src={metal3} alt="" />
              <h1 className="my-3">The Loaded King</h1>
              <div className="badge flex items-center justify-center">
                <img src={user} alt="" />
                <p className="ml-1">1</p>
              </div>
            </div>

            <div className="box-coin flex flex-col items-center justify-center">
              <img src={metal} alt="" />
              <h1 className="my-3">Talkative</h1>
              <div className="badge flex items-center justify-center">
                <img src={user} alt="" />
                <p className="ml-1">7712</p>
              </div>
            </div>
            <div className="box-coin flex flex-col items-center justify-center">
              <img src={metal2} alt="" />
              <h1 className="my-3">Fearless One</h1>
              <div className="badge flex items-center justify-center">
                <img src={user} alt="" />
                <p className="ml-1">6</p>
              </div>
            </div>
            <div className="box-coin flex flex-col items-center justify-center">
              <img src={metal3} alt="" />
              <h1 className="my-3">The Loaded King</h1>
              <div className="badge flex items-center justify-center">
                <img src={user} alt="" />
                <p className="ml-1">1</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
