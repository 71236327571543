import React from "react";
import { FaXmark } from "react-icons/fa6";

const AmountHistoryModal = ({
  amount,
  title,
  isOpen,
  onClose,
  claimCashback,
}) => {
  return (
    <>
      <div className="fixed inset-0  backdrop-blur-md flex items-center justify-center z-40">
        <div className="bg-[#23262E] w-1/2 p-8 rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl text-white font-bold">
              {title === "monthly_cashback" && "Monthly Cashback"}
              {title === "sports_weekly_cashback" && "Sports Weekly Cashback"}
              {title === "weekly_cashback" && "Weekly Cashback"}
              {title === "vip_level_reward" && "VIP Level Reward"}
            </h2>
            <button
              className="text-white hover:text-gray-700"
              onClick={onClose}
            >
              <FaXmark />
            </button>
          </div>
          <p className="text-lg mb-4 text-white">
            Total Cashback Amount : {amount}
          </p>
          <button
            className="py-1 px-5 bg-gradient-to-b from-[#F8D634] to-[#D08A1A] rounded-lg flex items-center justify-start text-white text-[14px] font-medium"
            onClick={claimCashback}
          >
            Claim Cashback
          </button>
        </div>
      </div>
    </>
  );
};

export default AmountHistoryModal;
