/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import favourite_plus from "../../assets/img/sports/favourite-plus.svg";
import { fetchSportsBetGroups } from "../../queries/gaming";
import LobbySports from "./LobbySports";

const sportImages = [
  "aussie",
  "basketball",
  "soccer",
  "football",
  "volleyball",
  "baseball",
  "golf",
  "cricket",
  "hockey",
  "boxing",
  "mma",
  "rugby",
  "formula",
  "horse",
  "virtual",
  "lacrosse"
];

const getImageForSport = (sport) => {
  try {
    const imageName = sport.toLowerCase();
    const matchingSport = sportImages.find((sport) =>
      imageName.includes(sport)
    );
    if (matchingSport) {
      return require(`../../assets/img/sports/${matchingSport}.svg`);
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};

export const Lobby = ({ tab, searchTerm }) => {
  const [activeSport, setActiveSport] = useState(1);
  const handleClick = (index) => {
    setActiveSport(index);
  };
  const [group, setGroup] = useState([]);
  const fetchGroups = async () => {
    try {
      const { data } = await fetchSportsBetGroups(tab);
      if (data) {
        setGroup(data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <>
      <div className="font-arabicmed w-full">
        <div className="w-full overflow-x-auto">
          <div className="flex gap-2 my-2 whitespace-nowrap">
            {/* <button
              className={`py-3 px-4 bg-[#252525] hover:bg-gradient-to-br hover:from-[#9BE402] hover:to-[#1FA83C] border border-[#3C403F] rounded-lg ${
                activeSport === 0
                  ? "bg-gradient-to-br from-[#9BE402] to-[#1FA83C]"
                  : ""
              }`}
              onClick={() => handleClick(0)}
            >
              <img src={home} className="mx-auto w-6 h-6" alt="Home" />
              <p className="text-[#fff] text-[14px] pt-1">Home</p>
            </button> */}
            {group.map(
              (sport, index) =>
                sport !== "Politics" && (
                  <button
                    key={index}
                    className={`py-3 px-4 bg-[#252525] hover:bg-gradient-to-br hover:from-[#9BE402] hover:to-[#1FA83C] border border-[#3C403F] rounded-lg ${
                      activeSport === index + 1
                        ? "bg-gradient-to-br from-[#9BE402] to-[#1FA83C]"
                        : ""
                    }`}
                    onClick={() => handleClick(index + 1)}
                  >
                    <img
                      src={getImageForSport(
                        sport === "Mixed Martial Arts" ? "MMA" : sport
                      )}
                      className="mx-auto w-6 h-6"
                      alt={sport === "Mixed Martial Arts" ? "MMA" : sport}
                    />
                    <p className="text-[#fff] text-[14px] pt-1">
                      {sport === "Mixed Martial Arts" ? "MMA" : sport}
                    </p>
                  </button>
                )
            )}

            {group.length === 0 && tab === "Favourites" ? (
              <div className="text-center text-white border border-white p-5 rounded mt-5 w-full">
                <div className="w-20 mx-auto mb-3">
                  <img src={favourite_plus} alt="" className="w-full" />
                </div>
                Uh-oh! It looks like you haven't added any favorites yet. Would
                you like to start adding some?
              </div>
            ) : (
              group.length === 0 && (
                <div className="text-center text-white border border-white p-5 rounded mt-5 w-full">
                  <div className="w-20 mx-auto mb-3">
                    <img src={favourite_plus} alt="" className="w-full" />
                  </div>
                  {tab === "Live Events" ? (
                    <>Uh-oh! There are no live events at the moment.</>
                  ) : tab === "Starting Soon" ? (
                    <>Uh-oh! There are no starting soon events at the moment.</>
                  ) : null}
                </div>
              )
            )}
          </div>
        </div>

        {activeSport && (
          <LobbySports
            sportName={group[activeSport - 1]}
            getImageForSport={getImageForSport}
            tab={tab}
            searchTerm={searchTerm}
          />
        )}
      </div>
    </>
  );
};
