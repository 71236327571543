import React from "react";

// Base URL for the payment method images
const baseURL = "https://www.dcodes.net/2/payment_icons/";

// List of payment method names. These names should match the filenames in the CDN
const paymentMethods = [
  "visa_bevel",
  "mastercard_bevel",
  "americanexpress_bevel",
  "dinersclub_bevel",
  "discover_bevel",
  "maestro_bevel",
];

function AcceptedCard() {
  return (
    <>
      <div className="bg-[#252525] p-6 mb-3 rounded-lg">
        <h4 className="text-xl mb-5 text-white">We accept</h4>

        <div className="flex sm:flex-col flex-row items-center">
          <ul className="flex list-none p-0">
            {paymentMethods.map((method) => (
              <li key={method} className="mr-4">
                <img
                  src={`${baseURL}${method}.png`}
                  alt={`${method} logo`}
                  className="h-18" // Adjust height as needed
                />
              </li>
            ))}
          </ul>
          <h4 className="text-white font-bold ml-3">and many more....</h4>
        </div>
      </div>
    </>
  );
}

export default AcceptedCard;
