import React from "react";

import Countdown from 'react-countdown';

export const Timer = () => {


  const timerRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return (
        <div className="timer-popup pl-6 important mb-10 top-10 right-10 lgmed:!top-10 lgmed:!right-0  lgmed:!pl-0 lgmed:w-full">
          <div className="timer-inner flex flex-col justify-center items-center">
            <h1 className="!text-[15px] !font-arabicbold !text-[white] mb-2">
              {/* Timer added */}
              <span className="text-lg text-red-400">Gaming Hub</span> will be Live
              on 01. 25 . 24

            </h1>

            <div className="timer-area grid grid-cols-4 gap-4">
              <div className="timer-box flex flex-col items-center">
                <div className="timer w-[80px] h-[80px] flex items-center justify-center">
                  <h1 className="!text-[70px] sm:!text[40px] !font-ds_digital !text-white !leading-3">
                    {days}
                  </h1>
                </div>

                <h1 className="!text-[12px] !font-poppins !text-[#fff] !mt-[6px]">
                  DAYS
                </h1>
              </div>
              <div className="timer-box flex flex-col items-center">
                <div className="timer w-[80px] h-[80px] flex items-center justify-center">
                  <h1 className="!text-[70px] !font-ds_digital !text-white !leading-3">
                    {hours}
                  </h1>
                </div>
                <h1 className="!text-[12px] !font-poppins !text-[#fff] !mt-[6px]">
                  HOURS
                </h1>
              </div>
              <div className="timer-box flex flex-col items-center">
                <div className="timer w-[80px] h-[80px] flex items-center justify-center">
                  <h1 className="!text-[70px] !font-ds_digital !text-white !leading-3">
                    {minutes}
                  </h1>
                </div>
                <h1 className="!text-[12px] !font-poppins !text-[#fff] !mt-[6px]">
                  MINUTES
                </h1>
              </div>
              <div className="timer-box flex flex-col items-center">
                <div className="timer w-[80px] h-[80px] flex items-center justify-center">
                  <h1 className="!text-[70px] !font-ds_digital !text-white !leading-3">
                    {seconds}
                  </h1>
                </div>
                <h1 className="!text-[12px] !font-poppins !text-[#fff] !mt-[6px]">
                  SECONDS
                </h1>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };


  return <Countdown date="2024-01-27" renderer={timerRenderer} />;
};
