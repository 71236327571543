import { Maximize, Minimize } from "lucide-react";
import { useEffect, useState } from "react";
import { MdFavorite } from "react-icons/md";
import playIcon from "../../assets/img/casino/playIcon2.png";
import rightArrow from "../../assets/img/casino/right-arrow.png";
import { useGameDetailsQuery, usePlayGameMutation } from "../../queries/gaming";
import { Header } from "../Header";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../components/ButtonLoader";

const GameModal = ({ gameId, login, setsigninState, setSignUpState }) => {
  const [openGame, setOpenGame] = useState(false);

  const [isPopupFullScreen, setIsPopupFullScreen] = useState(false);
  const [gameDetails, setGameDetails] = useState(null);

  const [playGameError, setPlayGameError] = useState(null);

  const {
    data: gameDetail,
    isLoading: isGameLoading,
    isError: isGameError,
  } = useGameDetailsQuery(gameId);
  console.log("gameId", gameId);
  useEffect(() => {
    if (gameDetail) {
      console.log("gameDetail", gameDetail);
      setGameDetails(gameDetail);
    }
  }, [gameDetail]);
  console.log(gameDetails);

  const {
    mutate: playGame,
    data: gameData,
    isLoading: loadingGame,
  } = usePlayGameMutation();
  console.log("loadingGame", loadingGame);

 const handlePlayGameClick = () => {
   setOpenGame(true);
   playGame(gameDetail.id, {
     onSuccess: () => {
       setPlayGameError(null);
       document.body.style.overflow = "hidden";
     },
     onError: (error) => {
       console.error("Error playing game:", error.message);
       setPlayGameError(error.message || "An unexpected error occurred");
     },
   });
 };


  // Close modal function
  const handleCloseModal = () => {
    setOpenGame(false);
    setIsPopupFullScreen(false);
    document.body.style.overflow = "unset"; // Re-enable scrolling
  };

  //Back Button
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This will navigate to the previous page
  };

  // Full Screen Toggle
  const toggleFullScreen = () => {
    setIsPopupFullScreen(!isPopupFullScreen);
  };

  console.log(loadingGame);

  return (
    <div className="flex-1 overflow-hidden">
      {/* <Header
        login={login}
        headerType="player"
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      /> */}
      <div className="relative w-full flex flex-wrap p-6 z-50 overflow-x-auto no-scrollbar">
        <div className="flex w-full justify-between gap-3 mb-8 sm:flex-col">
          <div className="inline-flex bg-[#252525] rounded-sm text-white py-2 px-3 items-center">
            <span className="text-[#AEB2B1] capitalize">
              {gameDetails?.category}
            </span>
            &nbsp;
            <img src={rightArrow} alt="" />
            &nbsp;
            <span className="capitalize">
              {gameDetails?.name || "Game name"}
            </span>
          </div>
          <div className="flex justify-end">
            <div className="inline-flex text-white py-2 px-3">
              <button
                onClick={handleGoBack}
                className="py-3 rounded-xl my-2 border-[#403c3c] bg-[#252525] text-white px-4 !text-lg hover:bg-gradient-to-br from-[#9BE402] to-[#1FA83C] hover:text-white hover:scale-105 transition duration-200 ease-in-out "
              >
                Games
              </button>
            </div>
          </div>
        </div>

        {/*======== GAME PREVIEW ========*/}
        <div className="flex w-full flex-col sm:mx-auto mx-40 relative border-[#403c3c]">
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          ></div>
          <img
            src={gameDetails?.image_preview}
            alt=""
            className="rounded-tl-lg rounded-tr-lg"
          />

          {/*======== PLAY BUTTON ========*/}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-28">
            <button
              className="flex items-center gap-3 justify-center py-4 px-10 bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-lg text-white transform transition-transform hover:scale-105"
              style={{ transition: "box-shadow 0.05s, transform 0.05s" }}
              onMouseEnter={(e) => {
                e.target.style.boxShadow = "0 0 16px rgba(155, 228, 2, 0.8)";
                e.target.style.transform = "scale(1.05)";
              }}
              onMouseLeave={(e) => {
                e.target.style.boxShadow = "none";
                e.target.style.transform = "scale(1)";
              }}
              onClick={(e) => {
                handlePlayGameClick(e);
                // Reset styles after clicking
                e.target.style.boxShadow = "none";
                e.target.style.transform = "scale(1)";
              }}
            >
              <img src={playIcon} alt="" className="text-white" />
              <span className="text-white">Play Now</span>
            </button>
          </div>
          {/*======== END PLAY BUTTON ========*/}

          <div className="flex flex-col py-6 px-4 bg-[#1a1a1b] rounded-bl-lg rounded-br-lg">
            <div className="flex">
              <h3 className="text-white text-[24px] font-bold inline-flex relative items-center">
                {gameDetails?.name}
                <div className="buttons py-5 ml-3 cursor-pointer">
                  <MdFavorite />
                </div>
              </h3>
            </div>

            {/*======== GAME MODAL ========*/}
            {openGame && (
              <div className="fixed overflow-auto inset-0 z-50 justify-center items-center flex">
                <div
                  className={`flex flex-col border border-[#3C403F] rounded-xl ${
                    isPopupFullScreen ? "w-full h-full" : "w-[70%] h-[90%]"
                  }`}
                  style={{
                    backgroundImage: `url(${gameDetails?.image_background})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
                  }}
                >
                  <div className="flex flex-col flex-grow">
                    <div className="flex items-center justify-between p-4 border-b border-[#3C403F]">
                      <h4 className="text-white text-lg font-bold">
                        {gameDetails?.name || "Game name"}
                      </h4>
                      <div className="flex items-center">
                        <button
                          onClick={handleCloseModal}
                          className="border text-white border-[#3C403F] rounded-lg w-10 h-10 inline-flex items-center justify-center"
                        >
                          X
                        </button>
                      </div>
                    </div>
                    {loadingGame ? (
                      <div className="flex-grow flex justify-center items-center">
                        <ButtonLoader />
                      </div>
                    ) : playGameError ? (
                      <div className="flex-grow bg-white flex justify-center items-center">
                        <p>Error playing the game: </p>
                        <p> {playGameError}</p>
                      </div>
                    ) : (
                      <div className="flex-grow">
                        <iframe
                          src={gameData.data.response}
                          title="Game"
                          className="w-full h-full"
                        ></iframe>
                      </div>
                    )}

                    {/*======== GAME MODAL FOOTER ========*/}
                    <div className="flex justify-between items-center p-4 border-t border-[#3C403F]">
                      <div>
                        {/* Display GC Balance - replace 'gcBalance' with actual variable */}
                        {/* <span className="text-white">
                          GC Balance: {gcBalance}
                        </span> */}
                      </div>
                      <div className="flex items-center gap-3">
                        <button
                          onClick={toggleFullScreen}
                          className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2"
                        >
                          {isPopupFullScreen ? (
                            <>
                              <Minimize size={18} />{" "}
                              {/* Adjust the size as needed */}
                              <span>Exit Fullscreen</span>
                            </>
                          ) : (
                            <>
                              <Maximize size={18} />{" "}
                              {/* Adjust the size as needed */}
                              <span>Go Fullscreen</span>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameModal;
