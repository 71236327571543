export const Terms = [
  {
    heading: "1. General",
    list: [
      "1.1 BetWin Service Platform: BetWin is a service platform dedicated to offering gaming and exchange solutions. Please be aware that BetWin is not an investment platform.",
      "1.2 Risk Warning: Participating in gaming, cryptocurrency transactions, and financial activities carries inherent risks. BetWin strongly advises users to conduct their own research and consider these risks carefully before engaging in any activities on our platform.",
    ],
  },

  {
    heading: "2. Eligibility",
    list: [
      "2.1 <span class='bold'>Age:</span> You must be of legal age in your jurisdiction to use our services. Users under the legal age are prohibited from accessing our platform.",
      "2.2 Jurisdictional Restrictions: Users are responsible for complying with the laws and regulations of their jurisdiction. BetWin does not offer services to individuals or entities where prohibited by law.",
    ],
  },

  {
    heading: "3. User Accounts",
    list: [
      "3.1 <span class='bold'>Registration:</span> Users must provide accurate and complete information during the registration process. You are responsible for maintaining the confidentiality of your account credentials.",
      "3.2 <span class='bold'>Security:</span> You are responsible for the security of your account and any activity occurring under your account. Notify BetWin immediately if you suspect unauthorized access.",
    ],
  },
  {
    heading: "4. Services",
    list: [
      "4.1 <span class='bold'>Use of Services:</span> Users agree to use BetWin services in compliance with these Terms and all applicable laws and regulations.",
      "4.2 <span class='bold'>Availability:</span> BetWin reserves the right to modify, suspend, or terminate services at any time without notice.",
    ],
  },

  {
    heading: "5. Privacy Policy",
    list: [
      "5.1 BetWin's Privacy Policy governs the collection, use, and storage of user data. By using our services, you consent to the terms of our Privacy Policy.",
    ],
  },

  {
    heading: "6. Limitation of Liability",
    list: [
      "6.1 BetWin is not liable for any losses, damages, or consequences resulting from your use of our services, including any third-party services linked from our platform.",
    ],
  },

  {
    heading: "7. Termination",
    list: [
      "7.1 BetWin reserves the right to terminate or suspend user accounts and access to our services at our discretion, including violations of these Terms.",
    ],
  },

  {
    heading: "8. Governing Law",
    list: [
      "8.1 These Terms are governed by and construed in accordance with the laws of Curacao, without regard to its conflict of law principles.",
    ],
  },

  {
    heading: "9. Contact Information",
    list: [
      "9.1 For questions, support, or inquiries regarding these Terms, please contact us at <a href='support@betwin789.net'>support@betwin789.net</a>",
    ],
  },
];
