import React, { useState } from "react";
import "../assets/css/affiliate/index.css";
import { AffiliateRight } from "../components/AffiliateRight";
import { Aside } from "../components/Aside";
import { Footer } from "../components/Footer";
import { RepeatPassword } from "../components/RepeatPassword";
import { SignIn } from "../components/SignIn";
import { SignUp } from "../components/SignUp";
import { SignUpPhone } from "../components/SignUpPhone";

export const Affiliate = () => {
  const [loginType, setloginType] = useState(true);
  const [signInState, setsigninState] = useState(false);
  const [RepeatPasswordState, setRepeatPasswordState] = useState(false);
  const [SignUpState, setSignUpState] = useState(false);
  const [SignUpPhoneState, setSignUpPhoneState] = useState(false);

  return (
    <div>
      <div className="flex items-start home">
        {signInState && (
          <SignIn
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setRepeatPasswordState={setRepeatPasswordState}
            setloginType={setloginType}
          />
        )}
        {RepeatPasswordState && (
          <RepeatPassword
            setsigninState={setsigninState}
            setRepeatPasswordState={setRepeatPasswordState}
          />
        )}

        {SignUpState && (
          <SignUp
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setSignUpPhoneState={setSignUpPhoneState}
            setloginType={setloginType}
          />
        )}
        {SignUpPhoneState && (
          <SignUpPhone
            setsigninState={setsigninState}
            setSignUpPhoneState={setSignUpPhoneState}
            setloginType={setloginType}
          />
        )}

        {/* left area */}
        <Aside activeSwitch="affiliate-switch" />
        {/* right area */}
        <AffiliateRight
          login={loginType}
          setsigninState={setsigninState}
          setRepeatPasswordState={setRepeatPasswordState}
          setSignUpState={setSignUpState}
          setSignUpPhoneState={setSignUpPhoneState}
        />
      </div>
      <Footer />
    </div>
  );
};
