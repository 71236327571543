export const state = {
  series: [
    {
      name: "Total Deposits",
      data: ["12", "11", "8", "9", "10", "9", "9", "7"],
    },
  ],
  options: {
    chart: {
      type: "area",

      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    colors: ["#3EFFBB"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    labels: [
      "12:00",
      "12:30",
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
    ],
    yaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      borderColor: "#3C403F",
    },
    legend: {
      horizontalAlign: "left",
    },
  },
};

export const state2 = {
  series: [
    {
      name: "Total Active Players",
      data: ["9", "28", "59", "70", "75", "95", "105", "111", "122"],
    },
  ],
  options: {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    colors: ["#007BFF", "#007BFF"],
    dataLabels: {
      enabled: false,
    },

    stroke: {
      curve: "smooth",
    },
    labels: [
      "12:00",
      "12:30",
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
    ],
    yaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      borderColor: "#3C403F",
    },
    legend: {
      horizontalAlign: "left",
    },
  },
};


export const state3 = {
  series: [
    {
      name: "Total GRR",
      data: ["5", "20", "40", "55", "60", "55", "50", "55", "75", "90", "100", "95", "90", "100", "120", "140", "160"],
    },
  ],
  options: {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    colors: ["#55AC38"],
    dataLabels: {
      enabled: false,
    },

    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.5,
        opacityFrom: 1,
        opacityTo: 0.9,
        stops: [0, 100],
        colorStops: [
          {
            offset: 60,
            color: "#55AC38",
            opacity: 1
          },
          {
            offset: 100,
            color: "#3EFFBB",
            opacity: 0.1
          }
        ]
      }
    },
    labels: [
      "0",
      "1",
      "2",
      "4",
      "6",
      "8",
      "10",
      "12",
      "14",
      "16",
      "18",
      "20",
      "22",
      "24",
      "26",
      "28",
      "30",
    ],
    yaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      borderColor: "#3C403F",
    },
    legend: {
      horizontalAlign: "left",
    },
  },
};

export const state4 = {
  series: [
    {
      name: "Total GGR",
      data: ["5", "20", "40", "55", "60", "55", "50", "55", "75", "90", "100", "95", "90", "100", "120", "140", "160"],
    },
  ],
  options: {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    colors: ["#7D1A3F"],
    dataLabels: {
      enabled: false,
    },

    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.5,
        opacityFrom: 1,
        opacityTo: 0.9,
        stops: [0, 100],
        colorStops: [
          {
            offset: 60,
            color: "#7D1A3F",
            opacity: 1
          },
          {
            offset: 100,
            color: "#CB386D",
            opacity: 1
          }
        ]
      }
    },
    labels: [
      "0",
      "1",
      "2",
      "4",
      "6",
      "8",
      "10",
      "12",
      "14",
      "16",
      "18",
      "20",
      "22",
      "24",
      "26",
      "28",
      "30",
    ],
    yaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      borderColor: "#3C403F",
    },
    legend: {
      horizontalAlign: "left",
    },
  },
};


export const state5 = {
  series: [
    {
      name: "Total NGR",
      data: ["5", "20", "40", "55", "60", "55", "50", "55", "75", "90", "100", "95", "90", "100", "120", "140", "160"],
    },
  ],
  options: {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    colors: ['#3A2ADB'],
    dataLabels: {
      enabled: false,
    },

    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.5,
        opacityFrom: 1,
        opacityTo: 0.9,
        stops: [0, 100],
        colorStops: [
          {
            offset: 60,
            color: "#3A2ADB",
            opacity: 1
          },
          {
            offset: 100,
            color: "#8675E4",
            opacity: 1
          }
        ]
      }
    },
    labels: [
      "0",
      "1",
      "2",
      "4",
      "6",
      "8",
      "10",
      "12",
      "14",
      "16",
      "18",
      "20",
      "22",
      "24",
      "26",
      "28",
      "30",
    ],
    yaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      borderColor: "#3C403F",
    },
    legend: {
      horizontalAlign: "left",
    },
  },
};