import React, { useEffect } from "react";
import successFeature from "../assets/img/stake/successfull.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { HeaderStaker } from "./HeaderStaker";
import { convertToCurrency, dateFormatter } from "../utils";
export const RightAreaStakeDone = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); 
  const provider = searchParams.get("provider"); 

  const { state } = useLocation();
  const subscription = state?.data;
  const isBalanceMethod = subscription?.paymentMethod === "Balance";
  const isCryptoMethod = subscription?.paymentMethod === "Crypto";
  const isEplaymentProvider = provider === "eplayment";
  const isMoneyCollectProvider = provider === "moneycollect";


  const renderPaymentStatusMessage = () => {
    if (isEplaymentProvider) {
      return "Your payment is being processed and waiting for deposit. Please complete payment as per instruction given. Once payment is confirmed, your subscription will be activated.";
    } else if (isMoneyCollectProvider) {
      return "Your payment is being processed.Your subscription will be activated within 24 hours.";
    } else if (isCryptoMethod) {
      return `Your crypto transaction is currently underway. We're awaiting network confirmation to finalize your staking subscription...`;
    } else if (isBalanceMethod) {
      return "Your subscription is activated! Payment through your Cash Wallet is processed.";
    }
    return "Staking Payment Successful";
  };

  useEffect(() => {
    if (!subscription && !isEplaymentProvider && !isMoneyCollectProvider ) navigate("/stake/dashboard");
  }, [subscription, navigate]);

  return (
    <div className="flex-1">
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex-1 px-6 pt-6">
        <div className="bg-[#1A1A1B] px-10 rounded-xl border-[1px] border-[#3C403F] pt-[137px] pb-[200px]">
          <img src={successFeature} alt="" className="mx-auto" />
          <h1 className="text-white text-[24px] text-center mt-12 sm:text-[20px]">
          {isEplaymentProvider || isMoneyCollectProvider || isCryptoMethod
              ? "Your payment is being processed"
              : "Staking Payment Successful"}
          </h1>
          <p className="text-[#AEB2B1] w-[720px] mx-auto text-[18px] text-center mt-3 lgmed:w-full sm:text-[14px]">
            {renderPaymentStatusMessage()}
           </p>
        {(!isEplaymentProvider && !isMoneyCollectProvider&& !isCryptoMethod)
              && (
          <ul className="my-12">
            <li className="flex items-center justify-between mb-6">
              <p className="text-[21px] text-[#AEB2B1] font-arabicreg sm:text-[14px]">
                Total amount Paid
              </p>
              <p className="text-[21px] text-[white] font-arabicmed sm:text-[14px]">
                ${convertToCurrency(subscription?.subscriptionAmount ?? "0", 2)}
              </p>
            </li>
            <li className="flex items-center justify-between mb-6">
              <p className="text-[21px] text-[#AEB2B1] font-arabicreg sm:text-[14px]">
                Paid By:
              </p>
              <p className="text-[21px] text-[white] font-arabicmed sm:text-[14px]">
                {subscription?.paymentMethod}
              </p>
            </li>
            <li className="flex items-center justify-between ">
              <p className="text-[21px] text-[#AEB2B1] font-arabicreg sm:text-[14px]">
                Staking Date
              </p>
              <p className="text-[21px] text-[white] font-arabicmed sm:text-[14px]">
                {dateFormatter(subscription?.startDate)}
              </p>
            </li>
          </ul>
              )}
          <button
            className="text-[#111010] text-[18px] font-arabicbold bg-[#58F2BB] h-[50px] w-full rounded-lg mt-12 sm:text-[14px]"
            onClick={(e) => {
              navigate("/stake/dashboard");
            }}
          >
            Go to Staking Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};
