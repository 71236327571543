import { useMutation, useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

export const useBonusBalance = () => {
  return useQuery("bonuBalances", async () => {
    const res = await privateRequest.get("user/bonus/get-balance");
    return res.data.balance[0];
  });
};

export const useBonsTransactions = () => {
  return useQuery("bonusTransactions", async () => {
    const res = await privateRequest.get("user/bonus/bonus-transactions");
    return res.data;
  });
};

export const useGameStatistics = () => {
  return useQuery("gameStatistics", async () => {
    const res = await privateRequest.get("user/bonus/get-statistics");
    return res.data;
  });
};

export const useBonusStats = () => {
  return useQuery("gameStats", async () => {
    const res = await privateRequest.get("user/bonus/get-stats");
    return res.data;
  });
};

export const useClaimBonus = () => {
  return useMutation(async () => {
    try {
      const res = await privateRequest.post("user/bonus/claimBonus");
      return res.data ?? {};
    } catch (error) {
      console.error(error);
      throw new Error(error?.response?.data?.error ?? "Error Claiming Bonus");
    }
  });
};

export const useStats = () => {
  return useQuery("bonusStats", async () => {
    const res = await privateRequest.get("user/bonus/get-stats");
    return res.data;
  });
};

export const useCashBackStatistics = () => {
  return useQuery("cashBackStatistics", async () => {
    const res = await privateRequest.get("user/bonus/cashback-statistics");
    return res.data;
  });
};

export const useVipLevelBonus = () => {
  return useQuery("vipBonus", async () => {
    const res = await privateRequest.get("user/bonus/vip-bonus-level");
    return res.data;
  });
};

export const useUnClaimedBonuses = (type) => {
  return useQuery(["unClaimedBonus",type], async () => {
    const res = await privateRequest.get(
      `user/bonus/unclaimed-cashback-stats?type=${type}`
    );
    
    return res?.data?.data;
  });
};
export const useClaimCashback = () => {
  return useMutation(async (type) => {
    try {
      const payload = {
        type,
      };

      const res = await privateRequest.post(
        "user/bonus/claim-cashback-bonus",
        payload
      );
      return res.data ?? {};
    } catch (error) {
      console.error(error);
      throw new Error(error?.response?.data?.error ?? "Error Claiming Bonus");
    }
  });
};
