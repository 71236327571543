import React, { useState } from "react";
import uae from "../assets/img/uae.svg";
import pakistan from "../assets/img/pakistan.png";
import india from "../assets/img/india.png";
import australia from "../assets/img/australia.png";
import america from "../assets/img/united-states.png";
import russia from "../assets/img/russia.png";
import selectDropdown from "../assets/img/select-dropdown.svg";
export const FlagSelect = () => {
  const flags = [uae, pakistan, india, australia, america, russia];
  const [selected, setSelected] = useState(false);
  const [flagImg, setflagImg] = useState(uae);
  return (
    <div className="FlagSelect relative mr-2">
      <div
        className="head flex rounded-lg cursor-pointer items-center justify-center"
        onClick={(e) => {
          setSelected(!selected);
        }}
      >
        <img src={flagImg} alt="flag" className="mr-2 w-6" />
        <img src={selectDropdown} alt="selectDropdown" />
      </div>
      {selected && (
        <ul className="list-flag-select z-10 rounded-lg absolute w-full">
          {flags.map((EachFlag) => (
            <li
              className="flex justify-center py-2"
              onClick={(e) => {
                setflagImg(EachFlag);
                setSelected(!selected);
              }}
            >
              <img src={EachFlag} className="w-6" alt="flag" />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
