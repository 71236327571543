import React, { useEffect, useState } from "react";
import ButtonLoader from "../ButtonLoader";

export const GA = ({ onOtpsChange, isLoading, confirm, setgasetter }) => {
  const [otps, setOtps] = useState(["", "", "", "", "", ""]);

  const focusOnNextInput = (index) => {
    const nextInput = document.getElementById(`text-${index + 1}`);
    if (nextInput) {
      nextInput.focus();
    }
  };
  // const otpHandler = (e, index) => {
  //   let value = e.target.value;
  //   let newOtps = [...otps];
  //   newOtps[index] = value;
  //   setOtps(newOtps);
  //   onOtpsChange(newOtps);
  // };

  const otpHandler = (e, index) => {
    const value = e.target.value;
    const newOtps = [...otps];
    if (index < otps.length - 1 && e.target.value !== "") {
      focusOnNextInput(index);
    }
    newOtps[index] = value;
    setOtps(newOtps);
    onOtpsChange(newOtps);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && e.target.value === "") {
      const previousInput = document.getElementById(`text-${index - 1}`);
      if (previousInput) {
        e.preventDefault(); // Prevent the browser from navigating back
        previousInput.focus();
      }
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").trim();
    const newOtps = [...otps];

    for (let i = 0; i < pastedData.length && index + i < newOtps.length; i++) {
      newOtps[index + i] = pastedData[i];
    }

    setOtps(newOtps);
    onOtpsChange(newOtps);

    // If there are more characters in the pasted data, focus on the next input
    if (index + pastedData.length < otps.length) {
      focusOnNextInput(index + pastedData.length - 1);
    }
  };

  useEffect(() => {
    const firstInput = document.getElementById(`text-0`);
    if (firstInput) {
      firstInput.focus();
    }
  }, []); // Focus on the first input when the component mounts

  return (
    <div className="welcome-ref-popup h-[100vh] w-full fixed top-0 left-0 flex items-center justify-center z-[99999] ">
      <span
        className="h-[100vh] w-full fixed top-0 left-0 -z-10"
        onClick={(e) => {
          setgasetter(false);
        }}
      ></span>
      <div className="welcome-ref-inner  w-[600px] h-[500px] border-[1px] border-[#3C403F] rounded-xl flex flex-col justify-center items-center text-center lg:w-full lg:h-full relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#fff"
          className="w-10 absolute top-10 right-8 hidden sm:block"
        >
          <path
            fillRule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
            clipRule="evenodd"
          />
        </svg>

        <h1 className="text-[25px] sm:text-[25px] font-arabicbold text-white mb-2">
          Enter Your One-Time Password (OTP)
        </h1>

        <h2 className="text-[16px] sm:text-[14px] font-arabicreg text-[#fff]">
          Please type the 6-digit OTP from the <br /> Google Authenticator app
          to proceed.
        </h2>

        <div className="grid grid-cols-6 gap-2 sm:px-2 my-8">
          <input
            onKeyDown={(e) => handleKeyDown(e, 0)}
            type="text"
            onPaste={(e) => handlePaste(e, 0)}
            id={`text-0`}
            maxLength={1}
            value={otps[0]}
            onChange={(e) => otpHandler(e, 0)}
            className="outline-none rounded-lg bg-[#252525] text-white font-arabicreg text-[30px] text-center sm:w-[60px] sm:h-[60px] h-[70px] w-[70px] sm:min-h-[50px] flex-1 border-[1px] border-[#3C403F]"
          />
          <input
            onKeyDown={(e) => handleKeyDown(e, 1)}
            type="text"
            onPaste={(e) => handlePaste(e, 1)}
            id={`text-1`}
            maxLength={1}
            value={otps[1]}
            onChange={(e) => otpHandler(e, 1)}
            className="outline-none rounded-lg bg-[#252525] text-white font-arabicreg text-[30px] text-center sm:w-[60px] sm:h-[60px] h-[70px] w-[70px] sm:min-h-[50px] flex-1 border-[1px] border-[#3C403F]"
          />
          <input
            onKeyDown={(e) => handleKeyDown(e, 2)}
            type="text"
            onPaste={(e) => handlePaste(e, 2)}
            id={`text-2`}
            maxLength={1}
            value={otps[2]}
            onChange={(e) => otpHandler(e, 2)}
            className="outline-none rounded-lg bg-[#252525] text-white font-arabicreg text-[30px] text-center sm:w-[60px] sm:h-[60px] h-[70px] w-[70px] sm:min-h-[50px] flex-1 border-[1px] border-[#3C403F]"
          />
          <input
            onKeyDown={(e) => handleKeyDown(e, 3)}
            type="text"
            onPaste={(e) => handlePaste(e, 3)}
            id={`text-3`}
            maxLength={1}
            value={otps[3]}
            onChange={(e) => otpHandler(e, 3)}
            className="outline-none rounded-lg bg-[#252525] text-white font-arabicreg text-[30px] text-center sm:w-[60px] sm:h-[60px] h-[70px] w-[70px] sm:min-h-[50px] flex-1 border-[1px] border-[#3C403F]"
          />
          <input
            onKeyDown={(e) => handleKeyDown(e, 4)}
            type="text"
            onPaste={(e) => handlePaste(e, 4)}
            id={`text-4`}
            maxLength={1}
            value={otps[4]}
            onChange={(e) => otpHandler(e, 4)}
            className="outline-none rounded-lg bg-[#252525] text-white font-arabicreg text-[30px] text-center sm:w-[60px] sm:h-[60px] h-[70px] w-[70px] sm:min-h-[50px] flex-1 border-[1px] border-[#3C403F]"
          />
          <input
            onKeyDown={(e) => handleKeyDown(e, 5)}
            type="text"
            onPaste={(e) => handlePaste(e, 5)}
            id={`text-5`}
            maxLength={1}
            value={otps[5]}
            onChange={(e) => otpHandler(e, 5)}
            className="outline-none rounded-lg bg-[#252525] text-white font-arabicreg text-[30px] text-center sm:w-[60px] sm:h-[60px] h-[70px] w-[70px] sm:min-h-[50px] flex-1 border-[1px] border-[#3C403F]"
          />
        </div>
        <button
          disabled={isLoading}
          onClick={(e) => confirm(true)}
          className="h-[50px] rounded-lg w-[200px] copy-btn flex items-center justify-center sm:w-full font-arabicbold"
        >
          {isLoading ? <ButtonLoader /> : "Verify"}
        </button>
      </div>
    </div>
  );
};
