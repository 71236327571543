import React, { useState, useEffect } from "react";
import notFound from "../../assets/img/bonus-dashboard/not-found.png"
import {
  fetchSportsBetLeaderboards
} from "../../queries/gaming";

export const LeaderboardTable= ({ searchTerm, type }) => {
  const currency = "GC";
  const [leaderboards, setLeaderboards] = useState([]);
  const fetchData = async () => {
    try {
      const { data } = await fetchSportsBetLeaderboards(type);
      if (data) {
        setLeaderboards(data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [type])

  const [filteredBets, setFilteredBets] = useState([]);
  
  useEffect(() => {
    const filtered = leaderboards.filter((bet) =>
      bet.user_id.toString().toLowerCase().includes(searchTerm) ||
      (`${bet.totalBetAmount} ${currency}}`).toLowerCase().includes(searchTerm) ||
      (`${bet.totalPayout} ${currency}}`).toLowerCase().includes(searchTerm)
    );
    setFilteredBets(filtered);
  }, [leaderboards, searchTerm]);

  function getRankText(rank) {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder10 = rank % 10;
    const remainder100 = rank % 100;
    
    let suffix = suffixes[0];
    if (remainder10 === 1 && remainder100 !== 11) {
        suffix = suffixes[1];
    } else if (remainder10 === 2 && remainder100 !== 12) {
        suffix = suffixes[2];
    } else if (remainder10 === 3 && remainder100 !== 13) {
        suffix = suffixes[3];
    }
    
    return rank + suffix;
  }

  function formatNumberWithCommas(number) {
    if (typeof number !== 'number' || isNaN(number)) {
        return 'Invalid number';
    }
    
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  function formatDateString(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    return formattedDate;
  }

  return (
    <>
    <div className="bg-[#1A1A1B] px-6 py-4 mt-4 rounded-lg w-full">
        {filteredBets && filteredBets.length === 0 ? (
          <div>
            <img
                src={notFound}
                alt="Not Found"
                className="mx-auto my-0"
              />
            <h1 className="no-data-available text-center text-white">Oops! There is no data yet!</h1>
          </div>
        ) : (
          <table className="w-full lgmed:min-w-[800px]">
            <thead>
              <tr className="h-[56px] border-b border-[#3A3A3A]">
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Rank
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  User
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Wagered
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredBets?.map((row, key) => (
                <tr key={key} className="h-[56px]">
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {getRankText(key + 1)}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.user_id}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {formatNumberWithCommas(row.totalBetAmount)} {currency}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {formatNumberWithCommas(row.totalPayout)} {currency}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
    </>
  );
};