// SubcategoryComponent.js
import React from "react";
import { useInfiniteQuery } from "react-query";
import { fetchAllSubcategory } from "../queries/gaming";

const SubcategoryComponent = ({ game_subcategory }) => {
    const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery(
        ["subcategories", game_subcategory],
        ({ pageParam = 1 }) => fetchAllSubcategory(game_subcategory, pageParam),
        {
            getNextPageParam: (lastPage, allPages) => {
                const currentPage = allPages.length;
                const maxPage = Math.ceil(lastPage.total / 30);
                return currentPage < maxPage ? currentPage + 1 : undefined;
            },
        }
    );

    return (
        <div>
            {data && data.pages && data.pages.map((page, pageIndex) => (
                <div key={pageIndex}>
                    {Array.isArray(page) && page.map((item) => (
                        <div key={item.id}>
                            <h1>FETCH</h1>
                        </div>
                    ))}
                </div>
            ))}

            {/* Load More button */}
            {hasNextPage && (
                <button onClick={() => fetchNextPage()} disabled={isFetching}>
                    {isFetching ? 'Loading...' : 'Load More'}
                </button>
            )}
        </div>
    );
};

export default SubcategoryComponent;
