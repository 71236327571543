import React, { useState } from "react";
import "../assets/css/settings/index.css";

// images
import avatar from "../assets/img/settings/svg/avatar.svg";
import card from "../assets/img/settings/svg/card.svg";
import close from "../assets/img/settings/svg/close.svg";
import cloud from "../assets/img/settings/svg/cloud.svg";

// components
import { useNavigate } from "react-router-dom";
import { countries } from "../utils/Countries";
import { SettingsOptions } from "./common/SettingsOptions";
import { DropdownSettings } from "./settings/DropdownSettings";

export const GeneralVerifyLeft = () => {
  const [active, setActive] = useState(1);
  // const ref = useRef(null);
  const navigate = useNavigate();
  // const ResizeWork = (e) => {
  //   let Element = window.innerWidth;
  //   ref.current.style.width = `${Element - 600}px`;
  // };
  // useEffect(() => {
  //   ResizeWork();
  //   window.addEventListener("resize", ResizeWork);
  // }, []);

  return (
    // <div className="body-left-area  mr-6" ref={ref}>
    <div className="body-left-area w-full">
      <div className="settings-area rounded-lg ">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Global Settings</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>

        <div className="settings-content-area flex items-start relative">
          <div className="settings-options-area flex flex-col p-3">
            <SettingsOptions active={6} />
          </div>

          <div className="settings-content-body-area settings-content-body-area-grid-settings  verify-settings-wrapper grid grid-cols-2 gap-6 py-6 px-6 flex-1">
            <div className="verify-settings">
              <h1 className="heading mb-6">Verify Setup</h1>
              <p>
                To serve you better we ask that you provide original identifying
                documents. This will secure your account in cases of account
                recovery. It also helps to ensure that the gifts or actual
                rewards you receive are sent to the correct location.
              </p>
              <p className="mt-4">
                An acceptable proof of identification includes a photo of your
                government issued ID card, driver's license and passport. We
                will reach out to you via email once this process has been
                completed.
              </p>
            </div>

            <div className="form-card-mail relative">
              {active !== 4 && (
                <div className="grid buttons-wrapper-step grid-cols-3 gap-4 mb-6">
                  <button
                    className={`button-step ${active === 1 && "active"}`}
                    onClick={(e) => {
                      setActive(1);
                    }}
                  >
                    Step 01
                  </button>
                  <button
                    className={`button-step ${active === 2 && "active"}`}
                    onClick={(e) => {
                      setActive(2);
                    }}
                  >
                    Step 02
                  </button>
                  <button
                    className={`button-step ${active === 3 && "active"}`}
                    onClick={(e) => {
                      setActive(3);
                    }}
                  >
                    Step 03
                  </button>
                </div>
              )}

              {active === 1 && (
                <>
                  <p className="mt-6">
                    For a more considerate service, please fill in the following
                    information to let us know about you. Thank you!
                  </p>
                  <div className="grid grid-cols-2 mt-6 gap-4">
                    <div className="input-wrapper">
                      <label htmlFor="op">First Name</label>
                      <div className="input-container flex items-center pr-4 rounded-lg  mt-2">
                        <input
                          id="op"
                          type="text"
                          className="w-full px-6 bg-transparent h-full "
                          placeholder="John"
                        />
                      </div>
                    </div>

                    <div className="input-wrapper">
                      <label htmlFor="np">Last Name</label>
                      <div className="input-container pr-4 flex items-center rounded-lg  mt-2">
                        <input
                          id="np"
                          type="text"
                          className="w-full px-6 bg-transparent h-full "
                          placeholder="Smith"
                        />
                      </div>
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="np">Date of Birth</label>
                      <div className="input-container pr-4 flex items-center rounded-lg  mt-2">
                        <input
                          id="np"
                          type="date"
                          className="w-full pl-6 bg-transparent h-full"
                          placeholder="Smith"
                        />
                      </div>
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="cnp">Gender</label>
                      <div className="mt-2">
                        <DropdownSettings
                          heading="Male"
                          listdata={[
                            {
                              heading: "Male",
                            },
                            {
                              heading: "Female",
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="input-wrapper col-span-2">
                      <label htmlFor="cnp">Country</label>
                      <div className="mt-2">
                        <DropdownSettings
                          heading="Bangladesh"
                          listdata={countries}
                        />
                      </div>
                    </div>
                  </div>
                  <button className="mt-12 w-full rounded-lg">Next</button>
                </>
              )}

              {active === 2 && (
                <div className="active-2">
                  <h2 className="mb-3">Choose either one</h2>
                  <div className="grid buttons-wrapper mb-6 grid-cols-2 gap-3">
                    <button className="h-8 rounded-lg active">ID Card</button>
                    <button className="h-8 rounded-lg">Pasport</button>
                  </div>
                  <p className="mb-6">
                    Please upload a valid government-issued ID document or
                    driving license. It must contain your full name, date of
                    birth, your photo and the document’s expiration date.
                  </p>

                  <div className="grid grid-cols-2 gap-3">
                    <div className="card-wrapper">
                      <h1 className="mb-2 pl-2">Front Side</h1>
                      <img src={card} alt="" />
                      <p className="mt-2 text-center">
                        Front ( jpg, png ) File size limit 1mb
                      </p>

                      <label
                        htmlFor="face-front"
                        className="h-10 flex items-center justify-center mt-4"
                      >
                        <input type="file" id="face-front" className="hidden" />
                        Choose File
                      </label>
                    </div>
                    <div className="card-wrapper">
                      <h1 className="mb-2 pl-2">Back Side</h1>
                      <img src={card} alt="" />
                      <p className="mt-2 text-center">
                        Back ( jpg, png ) File size limit 1mb
                      </p>

                      <label
                        htmlFor="back-front"
                        className="h-10 flex items-center justify-center mt-4"
                      >
                        <input type="file" id="back-front" className="hidden" />
                        Choose File
                      </label>
                    </div>
                  </div>

                  <button className="mt-12 w-full rounded-lg">Next</button>
                </div>
              )}

              {active === 3 && (
                <div className="active-2">
                  <p className="mb-10">
                    Please upload a photo of yourself. In the photo you need to
                    hold your passport or ID, and a piece of paper with
                    ‘’BetWin’’ your username and the date on it. Please make
                    sure your face and the information you hold are clear.
                  </p>

                  <div className="profile-wrapper flex flex-col items-center justify-center">
                    <h1>Your Photo</h1>
                    <img src={avatar} alt="" className="mt-2" />
                    <p className="mt-2">
                      Front ( jpg, png ) File size limit 1mb
                    </p>
                    <label
                      htmlFor="avatar"
                      className="h-10 flex items-center justify-center mt-6"
                    >
                      <input type="file" id="avatar" className="hidden" />
                      Choose File
                    </label>
                  </div>

                  <button
                    className="mt-10 w-full rounded-lg"
                    onClick={(e) => {
                      setActive(4);
                    }}
                  >
                    Next
                  </button>
                </div>
              )}

              {active === 4 && (
                <div className="active-2 active-4 flex flex-col items-center justify-center">
                  <img src={cloud} alt="" className="mt-2" />
                  <h1 className="mt-8 text-center">
                    ALL INFORMATION ARE SUBMITTED
                  </h1>
                  <p className="mt-4 text-center">
                    Thank you for your cooperation. We will inform you as the
                    review finished.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
