import React, { useState } from "react";
import arrowDown from "../../assets/img/popup/arrow-down.svg";
export const PayDropdownFiat = ({
  heading,
  list,
  stake = null,
  classStyle,
  setcurrencyCode,
}) => {
  const [active, setActive] = useState(false);
  const [data, setData] = useState({
    heading: heading,
  });
  return (
    <div className="dropdown relative">
      <div
        className={`dropdown-head bg-[#2E2E2E] cursor-pointer rounded flex px-3 py-2 items-center ${classStyle}`}
        onClick={(e) => {
          setActive(!active);
        }}
      >
        <p className="text-[18px] text-white x font-arabicreg flex-1 sm:text-[14px]">
          {data.heading}
        </p>
        <img src={arrowDown} alt="" className="ml-3" />
      </div>
      {active && (
        <ul className="paydropdown w-full h-[280px] overflow-auto scrollbar-hide absolute z-10 bg-[#2E2E2E]">
          {list.map((EachList) => (
            <li
              className="h-10 flex items-center justify-between px-3 cursor-pointer"
              onClick={(e) => {
                setActive(false);
                setData({
                  heading: EachList.currency,
                });
                setcurrencyCode(EachList.code);
              }}
            >
              <p className="text-[18px] text-white font-arabicreg sm:text-[14px]">
                {EachList.currency}
              </p>
              <p className="text-[18px] text-white font-arabicreg sm:text-[14px]">
                {EachList.code}
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
