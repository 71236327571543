import React, { useState } from "react";
import "../assets/css/sign-in.css";
import signInMainImg from "../assets/img/sign-in-girl.png";
import eye from "../assets/img/eye.svg";
export const CreateNewPassword = ({
  setcreatePassword,
  setpasswordChangeState,
}) => {
  const [type, setType] = useState(true);
  const [type2, setType2] = useState(true);
  return (
    <div className="SignIn LayerUp">
      <div className="inner-work-sign-in grid grid-cols-2">
        <div className="left-side px-[26px] flex items-center justify-center flex-col">
          <img src={signInMainImg} alt="sign in main presentation" />
          <div className="presentation">
            <h1>Welcome to BetWin</h1>
            <p className="mt-4">
              Welcome to BetWin, where you've just made the perfect choice by
              signing up! We're thrilled to have you on board, and we're here to
              set you up for success.
            </p>
          </div>
        </div>
        <div className="right-side  px-8 py-10">
          <form action="#" className="flex flex-col h-full">
            <h1 className="mb-12">Create new password </h1>
            <div className="input-wrapper mb-8">
              <label htmlFor="password">New Password</label>
              <input
                type={type ? "password" : type}
                placeholder="Enter your password"
                id="password"
                className="rounded-lg px-6 mt-3"
              />
              <img
                src={eye}
                className="eye-icon"
                onClick={(e) => {
                  setType(!type);
                }}
                alt="eye icon"
              />
            </div>

            <div className="input-wrapper mb-8">
              <label htmlFor="password">Confirm Password</label>
              <input
                type={type2 ? "password" : type2}
                placeholder="Enter your password"
                id="password"
                className="rounded-lg px-6 mt-3"
              />
              <img
                src={eye}
                className="eye-icon"
                onClick={(e) => {
                  setType2(!type2);
                }}
                alt="eye icon"
              />
            </div>

            <button
              className="w-full rounded-lg"
              onClick={(e) => {
                e.preventDefault();
                setcreatePassword(false);
                setpasswordChangeState(true);
              }}
            >
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
