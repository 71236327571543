import React, { useEffect } from "react";
import { Summary } from "./staker/Summary";
import close from "../assets/img/stake/close-details.svg";
import { StakeDuration } from "./staker/StakeDuration";
import { AutoSubscribe } from "./staker/AutoSubscribe";
import { HeaderStaker } from "./HeaderStaker";
import { useLocation, useNavigate, Link } from "react-router-dom";
export const RightAreaStakeDetails = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { data } = state;

  useEffect(() => {
    if (!data?._id) {
      navigate("/stake");
    } else {
      window.sessionStorage.setItem("stake-package-id", data._id);
    }
  }, [data, navigate]);

  return (
    <div className="flex-1">
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex-1 px-6 pt-6">
        <div className="px-10 py-6 bg-[#1A1A1B] border-[1px] rounded-xl border-[#3C403F] lg:px-6 sm:!p-4">
          {/* <div className="flex items-center justify-between mb-8">
            <h1 className="text-white text-[22px] font-arabicmed sm:text-[18px]">
              Staking Details
            </h1>
            <img src={close} alt="" />
          </div> */}
          <Link to="/stake">
            <div className="flex items-center justify-between mb-8">
              <h1 className="text-white text-[22px] font-arabicmed sm:text-[18px]">
                Staking Details
              </h1>
              <img src={close} alt="" />
            </div>
          </Link>
          <div className="grid grid-cols-2 gap-6 lg:grid-cols-1">
            <div>
              <StakeDuration data={data} />
              <AutoSubscribe data={data} />
            </div>
            <Summary />
          </div>
        </div>
      </div>
    </div>
  );
};
