export const Currency = [
  {
    code: "AUD",
    currency: "Australian Dollar",
  },
  {
    code: "BGN",
    currency: "Bulgarian Lev",
  },
  {
    code: "PHP",
    currency: "Philippine Peso",
  },
  {
    code: "BRL",
    currency: "Brazilian Real",
  },
  {
    code: "CAD",
    currency: "Canadian Dollar",
  },
  {
    code: "CHF",
    currency: "Swiss Franc",
  },
  {
    code: "COP",
    currency: "Colombia Peso",
  },
  {
    code: "CZK",
    currency: "Czech Koruna",
  },
  {
    code: "DKK",
    currency: "Danish Krone",
  },
  {
    code: "DOP",
    currency: "Dominican Peso",
  },
  {
    code: "EGP",
    currency: "Egyptian Pound",
  },
  {
    code: "EUR",
    currency: "Euro",
  },
  {
    code: "GBP",
    currency: "Pound Sterling",
  },
  {
    code: "HKD",
    currency: "Hong Kong Dollar",
  },
  {
    code: "IDR",
    currency: "Indonesian Rupiah",
  },
  {
    code: "ILS",
    currency: "Israeli New Shekel",
  },
  {
    code: "JOD",
    currency: "Jordanian Dollar",
  },
  {
    code: "KES",
    currency: "Kenyan Shilling",
  },
  {
    code: "KWD",
    currency: "Kuwaiti Dinar",
  },
  {
    code: "LKR",
    currency: "Sri Lankan Rupee",
  },
  {
    code: "MXN",
    currency: "Mexican Peso",
  },

  {
    code: "NGN",
    currency: "Nigerian Naira",
  },
  {
    code: "NOK",
    currency: "Norwegian Krone",
  },
  {
    code: "NZD",
    currency: "New Zealand Dollar",
  },
  {
    code: "OMR",
    currency: "Omani Rial",
  },

  // ========

  {
    code: "PEN",
    currency: "Peruvian Sol",
  },
  {
    code: "PLN",
    currency: "Polish Złoty",
  },
  {
    code: "RON",
    currency: "Romanian Leu",
  },
  {
    code: "SEK",
    currency: "Swedish Krona",
  },
  {
    code: "THB",
    currency: "Thai Baht",
  },
  {
    code: "TRY",
    currency: "Turkish Lira",
  },
  {
    code: "TWD",
    currency: "Taiwan Dollar",
  },

  {
    code: "USD",
    currency: "US Dollar",
  },
  {
    code: "VND",
    currency: "Vietnamese Dong",
  },
  {
    code: "ZAR",
    currency: "South African Rand",
  },
];
