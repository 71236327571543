import React from "react";
import card from "../../assets/img/stake/card.svg";
export const CreditCard = () => {
  return (
    <div className="grid grid-cols-3 mt-6 gap-6">
      <div className="input-wrapper relative col-span-3">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          Card Number
        </label>
        <input
          type="text"
          placeholder="000 000 0000 0000"
          className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white text-[16px] font-arabicmed px-6"
        />
        <img src={card} alt="" className="absolute right-4 top-[50px]" />
      </div>
      <div className="input-wrapper col-span-2">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          Expriration Date
        </label>
        <input
          type="text"
          placeholder="MM / YY"
          className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white text-[16px] font-arabicmed px-6"
        />
      </div>
      <div className="input-wrapper ">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          Security code
        </label>
        <input
          type="text"
          placeholder="CVV"
          className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white text-[16px] font-arabicmed px-6"
        />
      </div>
      <div className="input-wrapper col-span-3">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          Card Holder Name
        </label>
        <input
          type="text"
          placeholder="Card Holder Name"
          className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white text-[16px] font-arabicmed px-6"
        />
      </div>
    </div>
  );
};
