import React, { useState } from "react";
import { useGetSubscription } from "../../queries/subscription";
import { dateFormatter } from "../../utils";
import ButtonLoader from "../ButtonLoader";

export const OverviewTable = ({
  setTerminate,
  setTerminateId,
  setCancel,
  setCancelId,
}) => {
  const [status, setStatus] = useState("Active");
  const { data, isLoading } = useGetSubscription(status);
  const activeClass = "bg-gradient-to-br from-[#9BE402] to-[#1FA83C]";

  const onStatusHandler = (value) => {
    setStatus(value);
  };

  return (
    <>
      {/* filters part */}
      <div className="w-full mt-5">
        <div className="flex items-center gap-4">
          <button
            className={`px-4 py-3 rounded-sm text-white ${
              status === "Active" ? activeClass : "bg-gray-700"
            }`}
            onClick={(e) => {
              onStatusHandler("Active");
            }}
          >
            Active
          </button>
          <button
            className={`px-4 py-3 rounded-sm text-white bg-gray-700 ${
              status === "Pending" ? activeClass : "bg-gray-700"
            }`}
            onClick={(e) => {
              onStatusHandler("Pending");
            }}
          >
            Pending
          </button>
          <button
            className={`px-4 py-3 rounded-sm text-white bg-gray-700 ${
              status === "Terminated" ? activeClass : "bg-gray-700"
            }`}
            onClick={(e) => {
              onStatusHandler("Terminated");
            }}
          >
            Terminated
          </button>
        </div>
      </div>
      {isLoading ? (
        <>
          <ButtonLoader />
        </>
      ) : (
        <>
          {data?.length > 0 ? (
            <>
              <div className="grid grid-cols-2 lg:grid-cols-1 gap-6">
                {data?.map((row, key) => (
                  <div
                    key={key}
                    className={`border-[1px] rounded-xl lgmed:flex-col p-6 mt-6 ${
                      row?.status?.toLowerCase() !== "terminated"
                        ? "border-[#3C403F]"
                        : "border-red-900"
                    }`}
                  >
                    <ul className="mt-10 flex flex-col gap-6 sm:mt-3 sm:gap-3">
                      {[
                        { name: "Payment Method", value: row.paymentMethod },
                        {
                          name: "Amount",
                          value: `$${row.subscriptionAmount?.toFixed(2)}`,
                        },
                        ...(row?.status?.toLowerCase() !== "pending"
                          ? [
                              {
                                name: "Start Date",
                                value: dateFormatter(row.startDate),
                              },
                              {
                                name: "Expiry Date",
                                value: dateFormatter(row.expiryDate),
                              },
                            ]
                          : []),
                        {
                          name: "Locked Months",
                          value: `${row.lockedMonths} ${
                            row.lockedMonths > 1 ? "months" : "month"
                          }`,
                        },
                        { name: "Status", value: row.status },
                      ].map((item, index) => (
                        <li
                          key={index}
                          className="flex justify-between items-center gap-3 font-arabicmed text-[18px] text-white sm:text-[14px] "
                        >
                          {item.name}
                          <span className="capitalize">{item.value}</span>
                        </li>
                      ))}
                    </ul>
                    {row?.status?.toLowerCase() !== "terminated" &&
                      row?.status?.toLowerCase() !== "pending" &&
                      row?.status?.toLowerCase() !== "canceled" && (
                        <button
                          className="mt-10 w-full text-[#FFF] text-[18px] font-arabicmed bg-gradient-to-br from-[#9BE402] to-[#1FA83C] h-[50px]  rounded-lg  md:text-[14px] md:w-[120px] md:h-[40px] hover:from-[#82e402] hover:to-[#146626]"
                          onClick={(e) => {
                            setTerminate(true);
                            setTerminateId(row._id);
                          }}
                        >
                          Terminate
                        </button>
                      )}
                    {row?.status?.toLowerCase() === "pending" && (
                      <button
                        className="mt-10 w-full text-[#111010] text-[18px] font-arabicbold bg-gradient-to-br from-[#9BE402] to-[#1FA83C] h-[50px]  rounded-lg  md:text-[14px] md:w-[120px] md:h-[40px]"
                        onClick={(e) => {
                          setCancel(true);
                          setCancelId(row._id);
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="w-full text-center py-7">
                <p className="text-center w-full text-white">No data found</p>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
