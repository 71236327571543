import React from "react";
import glowdot from "../assets/img/glow-dot.svg";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import gameg1 from "../assets/img/gameg1.png";
import gameg2 from "../assets/img/gameg2.png";
import gameg3 from "../assets/img/gameg3.png";
import gameg4 from "../assets/img/gameg4.png";
import gameg5 from "../assets/img/gameg5.png";
import gameg6 from "../assets/img/gameg6.png";
export const RecentWin = () => {
  const ImagesArray = [gameg1, gameg2, gameg3, gameg4, gameg5, gameg6];
  return (
    <div className="RecentWin">
      <div className="top flex items-center justify-between mb-4">
        <h1 className="flex items-center">
          <img src={glowdot} alt="glowdot" className="mr-2" />
          Recent Big Wins
        </h1>
        <a href="/">See all</a>
      </div>

      <div className="slider-wrapper-recent">
        <Splide
          className="mt-8 mb-16 SliderAreaFirst"
          options={{
            gap: 10,
            arrows: false,
            pagination: false,
            perPage: 6,
            breakpoints: {
              900: {
                perPage: 4,
              },
              600: {
                perPage: 3,
              },
              500: {
                perPage: 2,
              },
            },
          }}
        >
          {ImagesArray.map((EachImage, key) => (
            <SplideSlide>
              <div className="card ">
                <img
                  src={EachImage}
                  alt={`slider ${key + 1}`}
                  className="rounded-tr-lg rounded-tl-lg"
                />
                <div className="presentation p-3 rounded-bl-lg rounded-br-lg">
                  <h1 className="mb-2">Heyltsjdd9</h1>
                  <button>$ 3,494.88</button>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};
