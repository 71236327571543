import React, { useEffect, useRef } from "react";
import { RecentWin } from "./RecentWin";
import { TopRatesGame } from "./TopRatesGame";
import { RecommendedGames } from "./RecommendedGames";
import { RegisterBanner } from "./RegisterBanner";
import { LatestBets } from "./LatestBets";
import rewardBanner from "../assets/img/reward-banner.png";
import { SliderAreaFirst } from "./SliderAreaFirst";
import { CardCustom } from "./Card";
import card from "../assets/img/card-white.svg";
import sports from "../assets/img/sports-white.svg";
import game1 from "../assets/img/game1.png";
import game2 from "../assets/img/game2.png";
export const HomeLeft = () => {
  const ref = useRef(null);

  const ResizeWork = (e) => {
    let Element = window.innerWidth;
    ref.current.style.width = `${Element - 600}px`;
  };
  useEffect(() => {
    ResizeWork();
    window.addEventListener("resize", ResizeWork);
  }, []);

  return (
    <div className="body-left-area  mr-6" ref={ref}>
      <div className="welcome-area p-6 rounded-xl relative">
        <h2 className="mb-5">Hi, Imran Hossen ! Welcome aboard</h2>
        <h1 className="mb-4">FIRST DEPSIT BONUS</h1>
        <p>+180% REWARDS</p>
        <div className="buttons-wrapper mt-6">
          <button className="w-36 h-11 mr-3">Deposit & Play</button>
          <button className="w-28 h-11">Free Play</button>
        </div>

        <img src={rewardBanner} className="rewardBanner" alt="" />
      </div>

      {/* slider area */}
      <SliderAreaFirst />

      <div className="grid card-custom-wrapper grid-cols-2 gap-4 mb-10">
        <CardCustom
          img={game1}
          icon={card}
          heading="Casino"
          para="Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu"
          link="/"
          linkpresentation="Go to Casino"
        />
        <CardCustom
          img={game2}
          icon={sports}
          heading="Sports"
          para="Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu"
          link="/"
          linkpresentation="Go to Sports"
        />
      </div>

      {/* RecentWin*/}
      <RecentWin />

      {/* Top Rates Game */}
      <TopRatesGame />

      {/* LatestBets */}
      <LatestBets />

      {/* Recommended Game */}
      <RecommendedGames />

      {/* RegisterBanner */}
      <RegisterBanner />
    </div>
  );
};
