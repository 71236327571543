/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import heart from "../../assets/img/p2p/heart.svg";
import close from "../../assets/img/sports/close.svg";
import collapse from "../../assets/img/sports/collapse.svg";
import expand from "../../assets/img/sports/expand.svg";
import live from "../../assets/img/sports/live.svg";
import place from "../../assets/img/sports/place.svg";
import { useGetAllBalance } from "../../queries/balance";
import {
  fetchFavouriteSportList,
  fetchSportsBet,
  fetchSportsBetCategory,
  fetchSportsEvents,
  postFavouriteSport,
  postSportsBet,
} from "../../queries/gaming";
import ButtonLoader from "../ButtonLoader";
import { SportsTable } from "./SportsTable";

export const LobbySports = ({
  sportName,
  getImageForSport,
  tab,
  searchTerm,
}) => {
  const [currency] = useState('GC');
  const [sportsCategoryData, setSportsCategoryData] = useState(null);
  const [events, setEvents] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);
  const [sportsBet, setSportsBet] = useState([]);
  const { data: balance } = useGetAllBalance();
  const winningBalance = parseFloat(balance?.balances?.winningBalance) ?? 0.0;
  const gbcBalance = parseFloat(balance?.balances?.gbcBalance) ?? 0.0;
  const inPlayBalance = winningBalance + gbcBalance;
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      const { data } = await fetchSportsBet(tab);

      if (data) {
        setSportsBet(data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let canceled = false;
    setLoading(true);

    const fetchData = async () => {
      try {
        const { data: sportsData } = await fetchSportsBetCategory(sportName);
        if (!canceled) {
          setSportsCategoryData(sportsData);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false); // Set loading state to false after data fetching, whether it's successful or not
      }
    };

    if (sportName) {
      fetchData();
    }

    return () => {
      canceled = true;
    };
  }, [sportName]);


  useEffect(() => {
    setLoading(true);
    
    if (sportsCategoryData) {
      const keys = sportsCategoryData.map((obj) => obj.key);
      const fetchData = async () => {
        try {
          const { data } = await fetchSportsEvents(keys, tab);
          setEvents(data);
          setExpandedItems(new Array(sportsCategoryData.length).fill(false));
        } catch (error) {
          console.error(error.message);
        } finally {
          setLoading(false); // Set loading state to false after data fetching, whether it's successful or not
        }
      };
  
      fetchData();
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportsCategoryData, tab]);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const options = {
      weekday: "short",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = date.toLocaleDateString("en-US", options);

    return `${formattedDate}`;
  }

  const toggleCollapse = (index) => {
    const newExpandedItems = [...expandedItems];
    newExpandedItems[index] = !newExpandedItems[index];
    setExpandedItems(newExpandedItems);
  };

  const [active, setActive] = useState(true);
  const handleClick = () => {
    setActive((prevActive) => !prevActive);
  };

  const [showBetSlip, setShowBetSlip] = useState(false);
  const [multiBetInput, setMultiBetInput] = useState(0);
  const [totalOdds, setTotalOdds] = useState(0);
  const [totalInput, setTotalInput] = useState(0);
  const [totalPayout, setTotalPayout] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [bets, setBets] = useState([]);
  const [multiBets, setMultiBets] = useState([]);

  const updateTotals = (newBets) => {
    const inputOdds = newBets.reduce(
      (total, bet) => total + (bet.bet_odds || 0),
      0
    );
    const inputTotal = newBets.reduce(
      (total, bet) => total + (bet.bet_amount || 0),
      0
    );
    const payoutTotal = newBets.reduce(
      (total, bet) => total + (bet.payout || 0),
      0
    );
    const commissionTotal = newBets.reduce(
      (total, bet) => total + (bet.platform_fees || 0),
      0
    );

    setTotalOdds(inputOdds);
    setTotalInput(inputTotal);
    setTotalPayout(payoutTotal);
    setTotalCommission(commissionTotal);
  };

  let isPostingBet = false;

  const handlePlaceBet = async () => {
    if (isPostingBet) {
      toast.error("A bet is already being processed. Please wait.");
      return;
    }

    let betsToCheck = active ? bets : multiBets;
    let message = "";

    if (queue.length !== betsToCheck.length) {
      toast.error("Please ensure all bet amounts are greater than 0.");
      return;
    }

    const isValid = betsToCheck.length + sportsBet.length <= 5 ? true : false;

    if (isValid) {
      const invalidBets = betsToCheck.filter(bet => bet.bet_amount === 0);
      const invalidBetExist = invalidBets.map(bet => bet.index + 1);

      if (invalidBetExist.length > 0) {
        toast.error(`Bet${invalidBetExist.length > 1 ? 's' : ''} ${invalidBetExist} can't have a value of 0. Please adjust your bets.`);
        return;
      }

      const totalBetAmount = betsToCheck.reduce((total, bet) => total + bet.bet_amount, 0);
      if (totalBetAmount > inPlayBalance) {
        const exceedsAmount = totalBetAmount - inPlayBalance;
        toast.error(`Your total bet amount exceeds the available balance by ${exceedsAmount.toFixed(2)}. Please adjust your bets.`);
        return;
      }

      isPostingBet = true;

      try {
        message = (await postSportsBet(betsToCheck, tab)).message;

        if (!active) {
          setMultiBetInput(0);
        }
        handleClearAll();
        fetchData();
        toast.success(message);
        setShowBetSlip(false);
        const index = expandedItems.findIndex((item) => item === true);
        if (index !== -1) {
          toggleCollapse(index);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to place bet.");
      } finally {
        isPostingBet = false;
      }
    } else {
      toast.error(
        "Attention: You have reached the maximum limit of 5 bets per player."
      );
    }
  };

  const [activeButtons, setActiveButtons] = useState({});
  const [queue, setQueue] = useState([]);

  const handleClickButton = (id) => {
    setActiveButtons((prevActiveButtons) => {
      const updatedButtons = {
        ...prevActiveButtons,
        [id]: !prevActiveButtons[id],
      };

      if (!prevActiveButtons[id]) {
        setShowBetSlip(true);
      }

      const [, homeOrAway, index, gameIndex] = id.split("-");
      const selectedTeam = {
        id: events[index][gameIndex].id,
        gameId: id,
        sport_key: events[index][gameIndex].sport_key,
        home_team: events[index][gameIndex].home_team,
        away_team: events[index][gameIndex].away_team,
        winner_team:
          homeOrAway === "home"
            ? events[index][gameIndex].home_team
            : events[index][gameIndex].away_team,
        opponent_team:
          homeOrAway === "home"
            ? events[index][gameIndex].away_team
            : events[index][gameIndex].home_team,
        odds: (tab === "P2P") ? 0 :
          events[index][gameIndex].bookmakers[0]?.markets[0]?.outcomes.find(
            (outcome) =>
              outcome.name ===
              (homeOrAway === "home"
                ? events[index][gameIndex].home_team
                : events[index][gameIndex].away_team)
          )?.price,
      };

      setQueue((prevQueue) => {
        const existingItemIndex = prevQueue.findIndex(
          (item) => item.gameId === id
        );
        if (existingItemIndex !== -1) {
          const updatedQueue = [...prevQueue];
          updatedQueue.splice(existingItemIndex, 1, selectedTeam);
          return updatedQueue;
        }

        const updatedQueue = [...prevQueue, selectedTeam];
        if (updatedQueue.length >= 2) {
          const lastItem = updatedQueue[updatedQueue.length - 1];
          const secondToLastItem = updatedQueue[updatedQueue.length - 2];
          if (
            lastItem.gameId === secondToLastItem.gameId &&
            lastItem.home_team === secondToLastItem.home_team &&
            lastItem.away_team === secondToLastItem.away_team
          ) {
            updatedQueue.pop();
          }
        }
        return updatedQueue;
      });

      if (prevActiveButtons[id]) {
        setQueue((prevQueue) =>
          prevQueue.filter((item, index) => {
            if (item.gameId === id) {
              setBets((prevBets) => {
                const newBets = prevBets.filter((_, i) => i !== index);
                return newBets;
              });
              setMultiBets((prevBets) => {
                const newBets = prevBets.filter((_, i) => i !== index);
                return newBets;
              });
            }
            return item.gameId !== id;
          })
        );
      }

      return updatedButtons;
    });
  };

  const memoizedQueue = useMemo(() => queue, [queue]);

  const handleChange = (event, index, bet) => {
    let inputValue = event.target.value.trim();
    if (inputValue.includes('0.')) {
      event.target.value = inputValue;
    } else if (inputValue[0] === '0') {
      event.target.value = inputValue.substring(1);
    }

    if (!isNaN(inputValue)) {
      const newInput = Math.max(inputValue, 0);
      if (newInput > inPlayBalance) {
        toast.error(`The bet exceeds the available balance. Please adjust your bets.`);
        return;
      }
      if (active) {
        setBets((prevBets) => updateBets(prevBets, index, bet, newInput, true, inPlayBalance));
      } else {
        setMultiBetInput(newInput);
      }
    }
  };

  const handleOddChange = (event, index, bet) => {
    let inputOdd = event.target.value.trim();
    if (inputOdd.includes('0.')) {
      event.target.value = inputOdd;
    } else if (inputOdd[0] === '0') {
      event.target.value = inputOdd.substring(1);
    }
    if (!isNaN(inputOdd)) {
      const newInput = Math.max(inputOdd, 0);
      if (newInput > inPlayBalance) {
        toast.error(`The bet exceeds the available balance. Please adjust your bets.`);
        return;
      }
      setBets((prevBets) => updateBets(prevBets, index, bet, newInput, false, inPlayBalance));
      setMultiBets((prevBets) =>
        updateBets(prevBets, index, bet, newInput, false, inPlayBalance)
      );
    }
  };

  const updateBets = (prevBets, index, bet, newValue, isAmount) => {
    const commissionRate = 0.05;
    const updatedBets = [...prevBets];
    if (updatedBets[index]) {
      updatedBets[index] = {
        ...updatedBets[index],
        [isAmount ? "bet_amount" : "bet_odds"]: newValue,
        player_type: !isAmount ? ((newValue === 0) ? "B" : "A") : "B",
        payout: isAmount ? (newValue * updatedBets[index].bet_odds) * (1 - commissionRate) : (updatedBets[index].bet_amount * newValue) * (1 - commissionRate),
        platform_fees: isAmount ? (newValue * updatedBets[index].bet_odds) * commissionRate : (updatedBets[index].bet_amount * newValue) * commissionRate,
      };
    } else {
      updatedBets.push({
        index,
        game_id: bet.id,
        category: sportName,
        sport_key: bet.sport_key,
        user_id: null,
        opponent_id: null,
        bet_team: bet.winner_team,
        opponent_team: bet.opponent_team,
        bet_amount: isAmount ? newValue : 0,
        bet_odds: isAmount ? bet.odds : newValue,
        payout: newValue * bet.odds,
        player_type: !isAmount ? ((newValue === 0) ? "B" : "A") : "B",
        platform_fees: isAmount ? (newValue * bet.odds) * commissionRate : 0,
        status: "",
      });
    }
    return updatedBets;
  };

  useEffect(() => {
    setMultiBets((prevBets) => {
      let updatedBets = [...prevBets];
      queue.forEach((bet, i) => {
        if (multiBetInput > inPlayBalance) {
          setMultiBetInput(0);
          toast.error(`The bet exceeds the available balance. Please adjust your bets.`);
          return;
        }
        updatedBets = updateBets(updatedBets, i, bet, multiBetInput, true, inPlayBalance);
      });

      return updatedBets;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue, multiBetInput]);

  useEffect(() => {
    if (active) {
      updateTotals(bets);
    } else {
      updateTotals(multiBets);
    }
  }, [active, bets, multiBets]);

  const formatNumber = (number) => {
    const formattedNumber = number.toFixed(2);
    const parts = formattedNumber?.split(".");
    const paddedNumber = parts[0].padStart(7, "0") + "." + parts[1];
    return paddedNumber;
  };

  const truncateString = (str) => {
    if (str.length <= 15) {
      return str;
    } else {
      return str.slice(0, 15) + "...";
    }
  };

  const handleClearAll = () => {
    setActiveButtons({});
    setQueue([]);
    setBets([]);
    setMultiBets([]);
  };

  const handleCancelBet = (id, index) => {
    setActiveButtons((prevActiveButtons) => {
      const updatedButtons = {
        ...prevActiveButtons,
        [id]: false,
      };
      return updatedButtons;
    });
    setBets((prevBets) => {
      const newBets = prevBets.filter((_, i) => i !== index);
      return newBets;
    });
    setMultiBets((prevBets) => {
      const newBets = prevBets.filter((_, i) => i !== index);
      return newBets;
    });
    setQueue((prevQueue) => prevQueue.filter((item) => item.gameId !== id));
  };

  const [status, setStatus] = useState(false);

  const fetchFavourites = async () => {
    const { data } = await fetchFavouriteSportList(sportName);
    setStatus(data);
  };

  useEffect(() => {
    fetchFavourites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportName]);

  const handleAddToFavourites = async () => {
    const { message } = await postFavouriteSport(sportName, !status);
    fetchFavourites();
    toast.success(message);
  };

  useEffect(() => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.map((item) => (searchTerm ? true : false))
    );
  }, [searchTerm]);

  const highlightText = (text, search, index) => {
    return text.toLowerCase().includes(search.toLowerCase());
  };

  return (
    <>
      <ToastContainer theme="dark" autoClose={3000} />
      {tab !== "Favourites" && (
        <div className="flex justify-end mt-5">
          <button
            className={`text-white border ${status ? "border-white" : "border-white"
              } rounded p-3 flex justfy-between items-center`}
            onClick={handleAddToFavourites}
          >
            <img src={heart} alt="Favourites" className="mr-3" />
            {status ? "Remove From Favourites" : "Add To Favourites"}
          </button>
        </div>
      )}
      <div className="flex justify-between gap-2">
        <div className="flex flex-col w-full">
          {events && Object.keys(events).length < 1 && (
            <div className="text-center text-white py-3">
              <img
                src={getImageForSport(
                  sportName === "Mixed Martial Arts" ? "MMA" : sportName
                )}
                alt="Not Found"
                className="mx-auto my-3 w-[70px]"
              />
              <p className="text-sm font-normal text-[#AEB2B1]">
                There are no events available at the moment.
              </p>
            </div>
          )}
          {loading ? <>
            <ButtonLoader />
          </> : <>
            {sportsCategoryData &&
              sportsCategoryData.map((sport, index) => (
                <div className="bg-[#1A1A1B] p-6 rounded-lg mt-4" key={index}>
                  <div className="flex justify-between">
                    <h1 className="text-[14px] text-[#fff] font-arabicbold">
                      {sport.title}
                    </h1>
                    <button onClick={() => toggleCollapse(index)}>
                      {expandedItems[index] ? (
                        <img src={collapse} alt="collapse" />
                      ) : (
                        <img src={expand} alt="expand" />
                      )}
                    </button>
                  </div>
                  {expandedItems[index] && (
                    <>
                      {events &&
                        events[index] &&
                        events[index].map((game, gameIndex) => {
                          if (
                            game.bookmakers.length > 0 &&
                            game.home_team &&
                            game.away_team
                          ) {
                            const homeId = `${sportName}-home-${index}-${gameIndex}`;
                            const awayId = `${sportName}-away-${index}-${gameIndex}`;
                            if (searchTerm) {
                              if (
                                highlightText(game.home_team, searchTerm) ||
                                highlightText(game.away_team, searchTerm)
                              ) {
                                return (
                                  <div
                                    className="py-6 border-t border-[#2E2E2E] mt-6 text-[12px]"
                                    key={gameIndex}
                                  >
                                    <div className="flex md:flex-col justify-between items-start">
                                      <div className="w-3/4 md:w-full md:mb-10">
                                        <p className="text-[#fff] font-arabicreg mb-6">
                                          {formatDate(game.commence_time)}
                                        </p>
                                        <div className="flex justify-between mb-1">
                                          <h1 className="w-full text-[#fff] font-arabicbold">
                                            {/* {highlightText(game.home_team, searchTerm, index)} */}
                                            {game.home_team}
                                          </h1>
                                          {/* <p className="w-full text-center text-[#6FCF17] font-arabicbold">
                                          0
                                        </p> */}
                                        </div>
                                        <div className="flex justify-between">
                                          <h1 className="w-full text-[#fff] font-arabicbold">
                                            {/* {highlightText(game.away_team, searchTerm, index)} */}
                                            {game.away_team}
                                          </h1>
                                          {/* <p className="w-full text-center text-[#6FCF17] font-arabicbold">
                                          2
                                        </p> */}
                                        </div>
                                      </div>
                                      <div className="flex w-full md:w-full justify-between items-center">
                                        <div className="w-full text-center">
                                          <p className="text-[#fff] font-arabicreg mb-4">
                                            Winner
                                            {/* (Incl. Overtime) */}
                                          </p>
                                          <div className="flex sm:flex-col justify-between gap-4">
                                            <button
                                              id={homeId}
                                              key={homeId}
                                              className={`w-full py-4 px-6 rounded-lg text-start text-[${tab !== "P2P" ? '12px' : '15px'}] ${activeButtons[homeId]
                                                ? "bg-[#111010]"
                                                : "bg-[#2E2E2E]"
                                                }`}
                                              onClick={() =>
                                                handleClickButton(homeId)
                                              }
                                            >
                                              <h1 className="text-[#fff] font-arabicbold">
                                                {game.home_team}
                                              </h1>
                                              {tab !== "P2P" && (
                                                <h1 className="text-[#fff] font-arabicbold">
                                                  {
                                                    game.bookmakers[0]?.markets[0]?.outcomes.find(
                                                      (outcome) =>
                                                        outcome.name ===
                                                        game.home_team
                                                    )?.price
                                                  }
                                                </h1>
                                              )}
                                            </button>
                                            <button
                                              id={awayId}
                                              key={awayId}
                                              className={`w-full py-4 px-6 rounded-lg text-start text-[${tab !== "P2P" ? '12px' : '15px'}] ${activeButtons[awayId]
                                                ? "bg-[#111010]"
                                                : "bg-[#2E2E2E]"
                                                }`}
                                              onClick={() =>
                                                handleClickButton(awayId)
                                              }
                                            >
                                              <h1 className="text-[#fff] font-arabicbold">
                                                {game.away_team}
                                              </h1>
                                              {tab !== "P2P" && (
                                                <h1 className="text-[#fff] font-arabicbold">
                                                  {
                                                    game.bookmakers[0]?.markets[0]?.outcomes.find(
                                                      (outcome) =>
                                                        outcome.name ===
                                                        game.away_team
                                                    )?.price
                                                  }
                                                </h1>
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            } else {
                              return (
                                <div
                                  className="py-6 border-t border-[#2E2E2E] mt-6 text-[12px]"
                                  key={gameIndex}
                                >
                                  <div className="flex md:flex-col justify-between items-start">
                                    <div className="w-3/4 md:w-full md:mb-10">
                                      <p className="text-[#fff] font-arabicreg mb-6">
                                        {formatDate(game.commence_time)}
                                      </p>
                                      <div className="flex justify-between mb-1">
                                        <h1 className="w-full text-[#fff] font-arabicbold">
                                          {/* {highlightText(game.home_team, searchTerm, index)} */}
                                          {game.home_team}
                                        </h1>
                                        {/* <p className="w-full text-center text-[#6FCF17] font-arabicbold">
                                        0
                                      </p> */}
                                      </div>
                                      <div className="flex justify-between">
                                        <h1 className="w-full text-[#fff] font-arabicbold">
                                          {/* {highlightText(game.away_team, searchTerm, index)} */}
                                          {game.away_team}
                                        </h1>
                                        {/* <p className="w-full text-center text-[#6FCF17] font-arabicbold">
                                        2
                                      </p> */}
                                      </div>
                                    </div>
                                    <div className="flex w-full md:w-full justify-between items-center">
                                      <div className="w-full text-center">
                                        <p className="text-[#fff] font-arabicreg mb-4">
                                          Winner
                                          {/* (Incl. Overtime) */}
                                        </p>
                                        <div className="flex sm:flex-col justify-between gap-4">
                                          <button
                                            id={homeId}
                                            key={homeId}
                                            className={`w-full py-4 px-6 rounded-lg text-start text-[${tab !== "P2P" ? '12px' : '15px'}] ${activeButtons[homeId]
                                              ? "bg-[#111010]"
                                              : "bg-[#2E2E2E]"
                                              }`}
                                            onClick={() =>
                                              handleClickButton(homeId)
                                            }
                                          >
                                            <h1 className="text-[#fff] font-arabicbold">
                                              {game.home_team}
                                            </h1>
                                            {tab !== "P2P" && (
                                              <h1 className="text-[#fff] font-arabicbold">
                                                {
                                                  game.bookmakers[0]?.markets[0]?.outcomes.find(
                                                    (outcome) =>
                                                      outcome.name ===
                                                      game.home_team
                                                  )?.price
                                                }
                                              </h1>
                                            )}
                                          </button>
                                          <button
                                            id={awayId}
                                            key={awayId}
                                            className={`w-full py-4 px-6 rounded-lg text-start text-[${tab !== "P2P" ? '12px' : '15px'}] ${activeButtons[awayId]
                                              ? "bg-[#111010]"
                                              : "bg-[#2E2E2E]"
                                              }`}
                                            onClick={() =>
                                              handleClickButton(awayId)
                                            }
                                          >
                                            <h1 className="text-[#fff] font-arabicbold">
                                              {game.away_team}
                                            </h1>
                                            {tab !== "P2P" && (
                                              <h1 className="text-[#fff] font-arabicbold">
                                                {
                                                  game.bookmakers[0]?.markets[0]?.outcomes.find(
                                                    (outcome) =>
                                                      outcome.name ===
                                                      game.away_team
                                                  )?.price
                                                }
                                              </h1>
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          }
                          return null;
                        })}
                      {Object.keys(events).length < 1 ||
                        (!events[index][0]?.home_team &&
                          !events[index][0]?.away_team && (
                            <div
                              className="py-6 border-t border-[#2E2E2E] mt-6 text-[12px] text-white"
                              key={index}
                            >
                              No games Available
                            </div>
                          ))}
                    </>
                  )}
                </div>
              ))}
          </>}
        </div>

        {showBetSlip && (
          <div className="w-1/2 lg:inset-0 lg:w-full lg:absolute lg:items-center lg:justify-center lg:bg-gray-800 lg:bg-opacity-80">
            <div className="flex flex-col smd:w-3/4 mmd:w-full lg:w-full lg:fixed smd:inset-x-20 lg:inset-x-0 smd:inset-y-20 lg:inset-y-0 lg:justify-center lg:items-center">
              <div className="flex flex-col justify-between bg-[#1A1A1B] p-4 rounded-lg my-4 mr-4 lg:mr-4 lg:ml-4 w-full">
                <div>
                  <div className="flex justify-between border-b pt-2 pb-5 border-white">
                    <div className="flex justify-between gap-2 items-center">
                      <img
                        src={getImageForSport(
                          sportName === "Mixed Martial Arts" ? "MMA" : sportName
                        )}
                        alt={
                          sportName === "Mixed Martial Arts" ? "MMA" : sportName
                        }
                        className="mx-auto w-6 h-6"
                      />
                      <h1 className="text-[14px] text-[#fff] font-arabicbold w-full">
                        Bet Slip{" "}
                      </h1>
                    </div>
                    <button onClick={() => setShowBetSlip(false)}>
                      {" "}
                      <img src={close} alt="close" />{" "}
                    </button>
                  </div>
                  <div className="py-6">
                    <div className="flex justify-between gap-1 bg-[#252525] p-3 rounded-full w-full max-w-[200px]">
                      <button
                        className={`text-white font-arabicmed text-[12px] py-3 px-5 rounded-3xl ${active
                          ? "bg-[#3A3A3A] w-full"
                          : "bg-transparent w-1/2"
                          }`}
                        onClick={handleClick}
                      >
                        Single
                      </button>
                      <button
                        className={`text-white font-arabicmed text-[12px] py-3 px-5 rounded-3xl ${active
                          ? "bg-transparent w-1/2"
                          : "bg-[#3A3A3A] w-full"
                          }`}
                        onClick={handleClick}
                      >
                        Multiple
                      </button>
                    </div>
                  </div>
                  {queue.length > 0 && (
                    <h1 className="font-arabicbold text-white flex justify-end p-2 text-[14px]">
                      <button onClick={handleClearAll}>Clear All</button>
                    </h1>
                  )}
                  {memoizedQueue &&
                    memoizedQueue.map((bet, index) => (
                      <div
                        className="bg-[#2E2E2E] rounded-md p-3 mb-4"
                        key={index}
                      >
                        <div className="flex justify-between items-center">
                          <img src={live} alt="" />
                          <h1 className="text-white font-arabicbold text-[14px]">
                            {truncateString(bet.home_team)}
                          </h1>
                          <h1 className="text-white font-arabicbold text-[14px]">
                            {" "}
                            &nbsp;-&nbsp;{" "}
                          </h1>
                          <h1 className="text-white font-arabicbold text-[14px]">
                            {truncateString(bet.away_team)}
                          </h1>
                          <button
                            className="text-white font-arabicbold text-[14px]"
                            onClick={() => handleCancelBet(bet.gameId, index)}
                          >
                            {" "}
                            x{" "}
                          </button>
                        </div>

                        <div className="flex justify-between mb-4">
                          <div className="">
                            <h1 className="text-white font-arabicreg text-[14px] mt-4">
                              {" "}
                              Winner
                              {/* (Incl. Overtime) */}{" "}
                            </h1>
                            <h1 className="text-white font-arabicbold text-[14px]">
                              {bet.winner_team}
                            </h1>
                          </div>
                          <div className={`${tab !== "P2P" ? 'flex items-end' : ''}`}>
                            {tab === "P2P" ? (
                              <>
                                <h1 className="text-white font-arabicreg text-[13px] mt-4 mb-1 text-end">
                                  Odds
                                </h1>
                                <div className="bg-[#171717] py-1 px-2 rounded-lg w-1/2 ml-auto">
                                  <input
                                    type="number"
                                    className="text-[13px] font-arabicreg text-[#AEB2B1] bg-transparent outline-none w-full"
                                    value={bets[index]?.bet_odds || bet.odds}
                                    onChange={(e) => handleOddChange(e, index, bet)}
                                  />
                                </div>
                              </>
                            ) : (
                              <h1 className="text-white font-arabicbold text-[14px]">
                                {bet.odds}
                              </h1>
                            )}
                          </div>
                        </div>
                        {active && (
                          <div className="flex justify-between">
                            <div className="">
                              <h1 className="text-white font-arabicreg text-[13px]">
                                Bet Amount
                              </h1>
                              <div className="flex flex-row items-center bg-[#171717] pt-2 pb-1 mt-1 rounded-lg">
                                <input
                                  type="number"
                                  className="text-center font-arabicreg text-white text-[14px] bg-[#171717] outline-none border-none w-full"
                                  value={
                                    bets[index]?.bet_amount || 0
                                  }
                                  onChange={(e) => handleChange(e, index, bet)}
                                />
                              </div>
                            </div>
                            <div className="">
                              <div className="flex items-center space-x-2 justify-end mt-4">
                                <div>
                                  <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                                    {" "}
                                    Est. Payout{" "}
                                  </h1>
                                  <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                                    {" "}
                                    {formatNumber(
                                      bets[index]?.payout || 0
                                    )} {currency}{" "}
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>

                <div className="items-end">
                  {memoizedQueue.length > 0 ? (
                    <>
                      <div>
                        {!active && (
                          <div className="flex justify-between">
                            <h1 className=" font-arabicbold text-white text-[14px] flex justify-end">
                              Bet Amount
                            </h1>
                            <div className="flex flex-row items-center bg-[#171717] w-1/6 rounded-lg">
                              <input
                                type="number"
                                className="font-arabicreg text-white text-[14px] bg-[#171717] outline-none border-none w-full"
                                value={multiBetInput || 0}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                        )}
                        <div className="flex justify-between">
                          <h1 className="text-center font-arabicbold text-white text-[14px] flex justify-end">
                            Odds
                          </h1>
                          <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                            {totalOdds || 0}
                          </h1>
                        </div>
                        <div className="flex justify-between">
                          <h1 className="text-center font-arabicbold text-white text-[14px] flex justify-end">
                            Total Stake
                          </h1>
                          <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                            {/* {formatNumber(totalInput)} {currency} */}
                            {totalInput.toFixed(2)} {currency}
                          </h1>
                        </div>
                        <div className="flex justify-between">
                          <h1 className="text-center font-arabicbold text-white text-[14px] flex justify-end">
                            Est. Payout
                          </h1>
                          <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                            {/* {formatNumber(totalPayout)} {currency} */}
                            {totalPayout.toFixed(2)} {currency}
                          </h1>
                        </div>
                        <div className=" text-white text-[12px] mt-4">
                          <span className="">Note: </span>5% commission will be deducted from your bet amount.
                        </div>
                        <div className=" text-white text-[12px]">
                          <span className="">Total Commissioned: </span>{totalCommission.toFixed(2)} {currency}
                        </div>
                      </div>
                      <button
                        className="w-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C] flex items-center justify-center gap-2 py-3 my-4 rounded-lg text-white text-[16px]"
                        onClick={handlePlaceBet}
                      >
                        <img src={place} alt="" />
                        Place Bet
                      </button>
                    </>
                  ) : (
                    <div className="text-white text-center border border-white rounded p-3">
                      You haven't selected any bets to place.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex">
        <SportsTable sportsBet={sportsBet} fetchData={fetchData} tab={tab} />
      </div>
    </>
  );
};

export default LobbySports;
