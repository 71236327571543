import React, { useState } from "react";
import summaryStep from "../../assets/img/stake/summary-step.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CheckboxStyleSignUp } from "../CheckboxStyleSignUp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";
export const Summary = () => {
  const navigate = useNavigate();
  const [TermsState, setTerms] = useState(false);
  const notify = () => toast("Please Accept Terms & Conditions");

  const { state } = useLocation();
  const { data } = state;

  return (
    <div className="bg-[#252525] mb-8 p-6 border-[1px] border-[#3C403F] rounded-xl">
      <ToastContainer theme="dark" autoClose={3000} />
      <h1 className="text-[22px]  font-arabicmed text-white sm:text-[18px]">
        Summary
      </h1>

      <ul className="py-6 relative border-b-[1px] pl-5 border-b-[#3C403F]">
        <img src={summaryStep} className="absolute left-0 top-[30px]" alt="" />
        <li className="flex items-center justify-between">
          <p className="text-[18px] font-arabicreg text-[#AEB2B1] sm:text-[16px]">
            Stake date
          </p>
          <p className="text-[18px] font-arabicreg text-[#6d9c41] sm:text-[16px]">
            {moment.utc(new Date()).format("YYYY-MM-DD")}
          </p>
        </li>
        <li className="flex items-center justify-between mt-6">
          <p className="text-[18px] font-arabicreg text-[#AEB2B1] sm:text-[16px]">
            Reward date start
          </p>
          <p className="text-[18px] font-arabicreg text-[#AEB2B1] sm:text-[16px]">
            {moment.utc(new Date()).add(1, "day").format("YYYY-MM-DD")}
          </p>
        </li>
        <li className="flex items-center justify-between mt-6">
          <p className="text-[18px] font-arabicreg text-[#AEB2B1] sm:text-[16px]">
            Expiration date
          </p>
          <p className="text-[18px] font-arabicreg text-[#AEB2B1] sm:text-[16px]">
            {moment
              .utc(new Date())
              .add(data?.lockedMonths ?? 1, "months")
              .format("YYYY-MM-DD")}
          </p>
        </li>
      </ul>

      <p className="text-[14px] mt-6 font-arabicreg text-[#AEB2B1] px-4 py-3 bg-[#2E2E2E] rounded-xl sm:text-[12px]">
        The Annual Gaming Reward revenue (AGRR) is subject to change on a
        monthly basis. If you wish to redeem you staking early or terminate fees
        may apply <br />
        Upon expiry, your locked digital asset will redeemed to the same asset.
        If you choose to redeem, your digital asset will be returned to you
        wallet
      </p>

      <div className="accept-wrapper mt-4 mb-3 flex items-center">
        <CheckboxStyleSignUp
          onchange={(e) => {
            setTerms(e.target.checked);
          }}
          type="checkbox"
          id="accept-stake"
        />
        <label htmlFor="accept-stake" className="cursor-pointer">
          <p className="text-[18px] font-arabicreg ml-2 text-white sm:text-[14px] xs:!text-[10px]">
            I accepted the{" "}
            <Link className="text-[#6FCF17]" to="/stake/terms">
              Terms and Condition
            </Link>{" "}
            of BetWin.
          </p>
        </label>
      </div>

      <button
        className="text-[#FFF] text-[18px] font-arabicmed bg-gradient-to-br from-[#9BE402] to-[#1FA83C] h-[50px] w-full rounded-lg sm:text-[16px] hover:from-[#82e402] hover:to-[#146626]"
        onClick={(e) => {
          const amount = window.sessionStorage.getItem("stake-amount");
          if (
            data?.minAmount &&
            data?.maxAmount &&
            (Number(amount) < data?.minAmount ||
              Number(amount) > data?.maxAmount)
          ) {
            toast.error(
              `Please enter amount between ${data?.minAmount} and ${data?.maxAmount}`
            );
            return;
          } else if (data?.minAmount && Number(amount) < data?.minAmount) {
            toast.error(`Please enter amount minimum ${data?.minAmount}`);
            return;
          }
          TermsState ? navigate("/stake/payment/method/cash-wallet") : notify();
        }}
      >
        Confirm
      </button>
    </div>
  );
};
