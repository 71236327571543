import React, { useState } from "react";
import "../../assets/css/home.css";
import { Aside } from "../Aside";
import { Footer } from "../Footer";
import { RepeatPassword } from "../RepeatPassword";
import { SignIn } from "../SignIn";
import { SignUp } from "../SignUp";
import { SignUpPhone } from "../SignUpPhone";
import { RightAreaP2PMyAds } from "./RightAreaP2PMyAds";
export const P2PMyAds = () => {
  const [signInState, setsigninState] = useState(false);
  const [RepeatPasswordState, setRepeatPasswordState] = useState(false);
  const [SignUpState, setSignUpState] = useState(false);
  const [SignUpPhoneState, setSignUpPhoneState] = useState(false);
  return (
    <div>
      <div className="flex items-start home">
        {signInState && (
          <SignIn
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setRepeatPasswordState={setRepeatPasswordState}
          />
        )}
        {RepeatPasswordState && (
          <RepeatPassword
            setsigninState={setsigninState}
            setRepeatPasswordState={setRepeatPasswordState}
          />
        )}

        {SignUpState && (
          <SignUp
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setSignUpPhoneState={setSignUpPhoneState}
          />
        )}
        {SignUpPhoneState && (
          <SignUpPhone
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setSignUpPhoneState={setSignUpPhoneState}
          />
        )}

        {/* left area */}
        <Aside activeSwitch="staker-switch" />
        {/* right area */}
        <RightAreaP2PMyAds
          login={true}
          setsigninState={setsigninState}
          setRepeatPasswordState={setRepeatPasswordState}
          setSignUpState={setSignUpState}
          setSignUpPhoneState={setSignUpPhoneState}
        />
      </div>

      <Footer />
    </div>
  );
};