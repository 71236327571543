import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import block from "../../assets/img/p2p/block.svg";
import {
  UseBlockList,
  UseUnblockUser
} from "../../queries/p2p";
import { useGetTransactions } from "../../queries/transactions";
export const BlockListTable = ({ orderList }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of transactions per page
  const { data: transactionData } = useGetTransactions({
    page: currentPage,
    interval: "",
    date: "",
    flag: "",
    filteredFlag: ["game", "rollback"].join(","), // Convert array to string
  });
  const { data: blockedData, isLoading } = useQuery("get-blocklist", UseBlockList);
  const blockedUsers = blockedData?.data;
  const transactions = transactionData?.data?.transactions ?? [];
  const totalPages = transactionData?.data?.totalPages ?? 1; // Set default to 1 if totalPages is undefined
  // console.log("TABLE LIST COUNT: ", totalPages);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatText = (text) => {
    return text
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      ?.join(" ");
  };

  const [showEllipsis, setShowEllipsis] = useState(false);
  const renderPaginationButtons = () => {
    const buttons = [];
    const startPage = Math.max(1, currentPage - 4);
    const endPage = Math.min(totalPages, currentPage + 5);

    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={i}
            className={`rounded count ${currentPage === i ? "active" : ""}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      for (let i = startPage; i <= endPage; i++) {
        buttons.push(
          <button
            key={i}
            className={`rounded count ${currentPage === i ? "active" : ""}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
      if (currentPage > 5) {
        buttons.unshift(
          <button
            key="ellipsis-start"
            className="rounded count ellipsis"
            onClick={() => handlePageChange(currentPage - 5)}
          >
            ...
          </button>
        );
      }
      if (currentPage < totalPages - 4) {
        buttons.push(
          <button
            key="ellipsis-end"
            className="rounded count ellipsis"
            onClick={() => handlePageChange(currentPage + 5)}
          >
            ...
          </button>
        );
      }
    }

    return buttons;
  };

  const handleUnblock = async (unblockId) => {
    try {
      const res = await UseUnblockUser(unblockId);

      toast.success(res?.message)
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    console.log(blockedUsers)
  }, [blockedData]);

  return (
    <div >
      <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />
      {blockedUsers?.length === 0 ? (
        <>
          <table className=" my-4 w-full lgmed:min-w-[950px]">
            <thead>
              <tr className="h-[56px] bg-[#252525] border-[1px] border-[#3C403F]">
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  ID
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Name
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Username
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Date joined
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Email
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Telephone number
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Country
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Aciton
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={6} className="text-center text-white">
                  No data found
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <>
          <table className="my-4 w-full lgmed:min-w-[950px]">
            <thead>
              <tr className="h-[56px] bg-[#252525] border-[1px] border-[#3C403F]">
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  ID
                </th>
                {/* <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Name
                </th> */}
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Username
                </th>
                {/* <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Date joined
                </th> */}
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Email
                </th>
                {/* <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Telephone number
                </th> */}
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Country
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Action
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]"></th>
              </tr>
            </thead>
            <tbody>
              {blockedUsers?.map((user, key) => (
                <tr
                  key={key}
                  className="h-[56px] bg-[#1A1A1B] border-b-[1px] border-b-[#3C403F] border-l-[1px] border-l-[#3C403F] border-r-[1px] border-r-[#3C403F] "
                >
                  <td
                    className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                  >
                    {user?.blockedUsers?._id}
                  </td>
                  <td
                    className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                  >
                    {user?.blockedUsers?.name}
                  </td>
                  <td
                    className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                  >
                    {user?.blockedUsers?.email}
                  </td>
                  <td
                    className={`pl-4 font-arabicreg capitalize text-[16px] text-[#fff] flex items-center gap-1 mt-4`}
                  >
                    {user?.blockedUsers?.country}
                  </td>
                  <td
                    className={`pl-4 font-arabicreg capitalize text-[16px] text-[#fff] mt-8`}
                  >
                    <button
                      className="flex items-center justify-center gap-1 bg-[#8A3FFC]
                     font-arabicreg capitalize text-[16px] h-[26px] px-[10px] rounded-[2px]"
                      onClick={() => {
                        handleUnblock(user?.blockedUsers?._id);
                      }}
                    >
                      <img
                        src={block}
                        alt=""
                        style={{
                          width: "20px",
                          height: "auto",
                          margin: "2px",
                        }}

                      />
                      Unblock
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex pagination-table-buttons items-center justify-center mt-8">
            <button
              className="flex items-center mr-2"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              ></svg>
              <p>Previous</p>
            </button>
            {renderPaginationButtons()}
            <button
              className={`flex active items-center ml-2 ${currentPage === totalPages
                ? "opacity-50 cursor-not-allowed"
                : ""
                }`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <p>Next</p>
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              ></svg>
            </button>
          </div>
        </>
      )}
    </div>
  );
};
