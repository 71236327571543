import React, { useState } from "react";

import arrow from "../../assets/img/popup/arrow-down.svg";
export const CurrencyDropdown = ({
  dollar,
  heading,
  list,
  onCurrencyChange,
}) => {
  const [active, setActive] = useState(false);
  const phpItem = list.find((item) => item.heading === "PHP") || list[0];

  const [data, setData] = useState({
    img: phpItem.img, // Set to PHP image
    heading: phpItem.heading, // Set to "PHP"
    heading2: phpItem.heading2,
  });
  return (
    <div className="dropdown relative">
      <div
        className="input-notify-wrapper cursor-pointer rounded-lg mt-0 flex text-white bg-[#2E2E2E] h-12 px-3 py-2 items-center"
        onClick={(e) => {
          setActive(!active);
        }}
      >
        <img
          src={data.img}
          alt=""
          className={`${data.img && "mr-2"} ${data.img && "w-5 h-5"}`}
        />
        <p className="flex-1">{data.heading}</p>
        <h1>{data.heading2}</h1>
        <img src={arrow} alt="" className="ml-3" />
      </div>
      {active && (
        <ul className="CurrencyDropdown w-full absolute z-10 max-h-[300px] overflow-auto scrollbar-hide bg-[#2E2E2E] text-white">
          {list.map((EachList) => (
            <li
              className="h-10 my-4 flex items-center px-4 pb-4 cursor-pointer"
              onClick={(e) => {
                setActive(false);
                setData({
                  img: EachList.img,
                  heading: EachList.heading,
                  heading2: EachList.heading2 ? EachList.heading2 : null,
                });
                if (onCurrencyChange) {
                  onCurrencyChange(EachList.heading); // Call the callback function with the selected currency
                }
              }}
            >
              <img src={EachList.img} alt="" className=" h-6 w-6 mr-1" />
              <p className="ml-1 flex-1">{EachList.heading}</p>
              {EachList.heading2 && (
                <h1 className="mr-2">{EachList.heading2}</h1>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
