import { useRouteError } from "react-router-dom";

const ErrorBoundary = () => {
    const error = useRouteError();

    return (
        <section className="bg-white h-screen p-5 border-t-2 border-red-500">
            <h1 className="text-red-700 font-bold text-2xl">Errors</h1>
            <code>
                <small>{error?.message}</small>
            </code>
            <br />
            <button onClick={() => window.location.reload()} className="bg-red-400 px-5 py-1 mt-5 text-white rounded-sm">Try again</button>

        </section>
    );
};

export default ErrorBoundary;