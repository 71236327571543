import React, { useState, useEffect } from 'react';

function LinkCarousel({ banners = [] }) {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBannerIndex((prevIndex) =>
        prevIndex === banners.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000); // Change banner every 4 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [banners.length]);

  return (
    <div className="carousel">
      {banners.length > 0 && (
        <a href={banners[currentBannerIndex]?.route}>
          <img
            src={banners[currentBannerIndex]?.imageUrl}
            className="w-full rounded-lg cursor-pointer"
            alt=""
          />
        </a>
      )}
    </div>
  );
}

export default LinkCarousel;
