import React from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Header } from "../../components/admin/Header";
import { Footer } from "../../components/admin/Footer";
// images
import search from "../../assets/img/admin/svg/search.svg";
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import csvblack from "../../assets/img/admin/svg/csv-black.svg";
import arrowdown from "../../assets/img/admin/svg/arrow-down.svg";

// utils
import { TransactionTable } from "./TransactionTable";
import { TransactionsUtil } from "../../utils/TransactionsUtil";
import { useNavigate } from "react-router-dom";

export const TransactionReport = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-start home admin-dashboard admin-dashboard-agent">
        {/* left area */}
        <Aside active="11/3" />

        <div className="flex-1">
          <Header heading="Report" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Transaction Report</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/admin-dashboard");
                  }}
                >
                  Home
                </span>{" "}
                / Transaction Report
              </button>
            </div>

            <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
              <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                <h1>Transaction Report</h1>
                <div className="input-container input-container-top">
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <select name="" id="">
                      <option value="Select your currency">
                        (GMT+ 00:00) UTC
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="bottom-agent bottom-agent-page  pt-6  pb-4  gap-2 flex items-end">
                <div className="input-container flex-1">
                  <label htmlFor="#" className="mb-3 block">
                    Search
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <img src={search} alt="" />
                    <input
                      type="text"
                      placeholder="Search ID, Round ID"
                      className="flex-1 h-full bg-transparent border-0 outline-none ml-2"
                    />
                    <img src={arrowdown} alt="" />
                  </div>
                </div>

                <div className="input-container flex-1">
                  <label htmlFor="#" className="mb-3 block">
                    Currency
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <select name="" id="">
                      <option value="Select your currency">
                        Select currency
                      </option>
                    </select>
                  </div>
                </div>
                <div className="input-container flex-1">
                  <label htmlFor="#" className="mb-3 block">
                    Action Type
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <select name="" id="">
                      <option value="Select your currency">
                        Select Action
                      </option>
                    </select>
                  </div>
                </div>
                <div className="input-container flex-1">
                  <label htmlFor="#" className="mb-3 block">
                    Time Period
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <input
                      type="text"
                      placeholder="2023/09/19 to 2024/12/01"
                      className="flex-1 h-full bg-transparent border-0 outline-none "
                    />
                  </div>
                </div>

                <div className="input-container w-[122px]">
                  <label htmlFor="#" className="mb-3 block">
                    Size
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <select name="" id="">
                      <option value="Select your currency">10</option>
                    </select>
                  </div>
                </div>

                <button className="rounded-lg px-5 filter">Reset Filter</button>
              </div>
              <div className="table-wrapper">
                <TransactionTable data={TransactionsUtil} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
