import React from "react";
// import JuniorAssociate from "../assets/img/affiliate/rank/JuniorAssociate.png";
// import ManagingDirector from "../assets/img/affiliate/rank/ManagingDirector.png";
// import SeniorPartner from "../assets/img/affiliate/rank/SeniorPartner.png";
// import Player from "../assets/img/affiliate/rank/NoRank.png";

import Pro from "../assets/img/affiliate/rank/Pro.svg";
import Rookie from "../assets/img/affiliate/rank/Rookie.svg";
import Star from "../assets/img/affiliate/rank/Star.svg";
import SuperStar from "../assets/img/affiliate/rank/SuperStar.svg";

import close from "../assets/img/close.svg";
import editUser from "../assets/img/edit-user.svg";
import like from "../assets/img/like-yellow.svg";
import metal from "../assets/img/small-metal.svg";
import arrow from "../assets/img/yellow-arrow.svg";

import "../assets/css/user.css";
import no_data from "../assets/img/no_data.png";
import state from "../assets/img/state.svg";
import { useAuth } from "../contexts/AuthContext";
const rankImages = {
  // JuniorAssociate: JuniorAssociate,
  // ManagingDirector: ManagingDirector,
  // SeniorPartner: SeniorPartner,
  Rookie: Rookie,
  Pro: Pro,
  Star: Star,
  SuperStar: SuperStar,
};
export const UserInformation = ({
  setusername,
  setprofileState,
  setmedalState,
  setmastermedalState,
}) => {
  const { userInfo } = useAuth();

  return (
    <div className="LayerUp">
      <div className="user-profile rounded-xl p-6">
        <div className="top flex items-center justify-between">
          <h1>User Information</h1>
          <div className="user-tag flex items-center">
            {userInfo?.user?.rank !== "NoRank" && (
              <img
                src={rankImages[userInfo?.user?.rank]}
                alt=""
                className="mx-2 w-7"
              />
            )}
            <img
              src={close}
              alt=""
              onClick={(e) => {
                setusername(false);
              }}
              className="cursor-pointer"
            />
          </div>
        </div>

        <div className="profile-pure-area mt-9 flex items-start justify-between">
          {/* LIKE IMAGE */}
          <div className="like-wrapper flex items-center p-2 rounded-lg cursor-pointer">
            <img src={like} alt="" />
            <p className="ml-1.5">0</p>
          </div>

          {/* RANK IMAGE */}
          <div className="main-profile flex flex-col items-center">
            {userInfo?.user?.rank !== "NoRank" && (
              <img
                src={rankImages[userInfo?.user?.rank]}
                alt={userInfo?.user?.rank}
                className="h-40"
              />
            )}

            <h1 className="mt-4">{userInfo?.email}</h1>
            {/* <p className="mt-2 ">User ID: {userInfo.userCode}</p> */}
          </div>

          {/* EDIT BUTTON */}
          <div
            className="like-wrapper flex cursor-pointer items-center p-2 rounded-lg"
            onClick={(e) => {
              setusername(false);
              setprofileState(true);
            }}
          >
            <img src={editUser} alt="" />
          </div>
        </div>

        {/* <div className="metal-box rounded-lg p-3 mt-8">
          <div className="top flex items-center justify-between">
            <div className="left-top flex items-center">
              <img src={metal} alt="" />
              <h1 className="mx-2 mr-3">Ranks</h1>
              <p>0</p>
            </div>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setusername(false);
                setmastermedalState(true);
              }}
              className="right-top flex items-center"
            >
              Details
              <img src={arrow} alt="arrow" className="ml-3" />
            </a>
          </div>

          <div className="medal-wrapper flex items-center mt-3 justify-between">
            {/* <img src={metal1} alt="" />
            <img src={metal2} alt="" />
            <img src={metal3} alt="" />
            <img src={metal4} alt="" />
            <img src={metal5} alt="" />
            <img src={metal6} alt="" />
            <img src={metal7} alt="" /> 
          </div>
        </div> */}

        {/* <div className="metal-box rounded-lg p-3 mt-5">
          <div className="top flex items-center justify-between">
            <div className="left-top flex items-center">
              <img src={state} alt="" />
              <h1 className="mx-2 mr-3">Statistics</h1>
            </div>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setusername(false);
                setmedalState(true);
              }}
              className="right-top flex items-center"
            >
              Details
              <img src={arrow} alt="arrow" className="ml-3" />
            </a>
          </div>
          <div className="boxes-wrapper mt-4 grid grid-cols-3 gap-5">
            {/* <div className="box flex flex-col items-center justify-center rounded-lg">
              <h1 className="flex items-center">
                <img src={cup} alt="cup" className="mr-1" />
                Total Wins
              </h1>
              <p className="mt-2">0</p>
            </div>
            <div className="box  flex flex-col items-center justify-center rounded-lg">
              <h1 className="flex items-center">
                <img src={bets} alt="cup" className="mr-1" />
                Total Bets
              </h1>
              <p className="mt-2">0</p>
            </div>
            <div className="box  flex flex-col items-center justify-center rounded-lg">
              <h1 className="flex items-center">
                <img src={coin} alt="cup" className="mr-1" />
                Total Wagered
              </h1>
              <p className="mt-2">0</p>
            </div> 
          </div>
        </div> */}

        <div className="metal-box rounded-lg p-3 mt-5">
          <div className="top flex items-center justify-between">
            <div className="left-top flex items-center">
              <h1 className="mx-2 mr-3">Top 3 Favorite Games</h1>
            </div>
          </div>
          <div className="games-body py-3 flex flex-col items-center justify-center">
            <img src={no_data} alt="" />
            <h1>Oops! There is no data yet!</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
