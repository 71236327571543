import React, { useEffect, useRef } from "react";
import "../assets/css/settings/index.css";

// images
import close from "../assets/img/settings/svg/close.svg";

// components
import { SettingsOptions } from "../components/common/SettingsOptions";
import { Switch } from "./settings/Switch";
import { useNavigate } from "react-router-dom";

export const GeneralPrivacyLeft = () => {
  // const ref = useRef(null);
  const navigate = useNavigate();
  // const ResizeWork = (e) => {
  //   let Element = window.innerWidth;
  //   ref.current.style.width = `${Element - 600}px`;
  // };
  // useEffect(() => {
  //   ResizeWork();
  //   window.addEventListener("resize", ResizeWork);
  // }, []);

  return (
    // <div className="body-left-area mr-6" ref={ref}>
    <div className="body-left-area w-full">
      <div className="settings-area  rounded-lg">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Global Settings</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>

        <div className="settings-content-area flex items-start relative">
          <div className="settings-options-area flex flex-col p-3">
            <SettingsOptions active={2} />
          </div>

          <div className="settings-content-body-area py-6 px-6 flex-1">
            <div className="switch-row flex pb-4 items-center justify-between">
              <div className="privacy-content">
                <h1>Hide my gaming data on profile</h1>
                <p className="mt-2">
                  Even if hidden, your avatar and username are always visible in
                  chatrooms.
                </p>
              </div>
              <Switch id="curr_name" />
            </div>
            <div className="switch-row flex py-4 items-center justify-between">
              <div className="privacy-content">
                <h1>Hide my username from public lists</h1>
                <p className="mt-2">
                  If hidden, no one can view your profile by clicking on your
                  avatar or username on public rankings or bets list.
                </p>
              </div>
              <Switch id="curr_marketing" />
            </div>
            <div className="switch-row flex py-4 items-center justify-between">
              <div className="dropdown-area">
                <div className="privacy-content">
                  <h1>Refuse tip from strangers</h1>
                </div>
              </div>
              <Switch id="curr_settings" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
