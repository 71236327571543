import React from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Header } from "../../components/admin/Header";
import { Footer } from "../../components/admin/Footer";

// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
// utils
import { CheckboxCustom } from "../../components/admin/CheckboxCustom";
import { useNavigate } from "react-router-dom";

export const EditPlayer = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-start home admin-dashboard">
        {/* left area */}
        <Aside active={5} />

        <div className="flex-1">
          <Header heading="Player" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Players</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/admin-dashboard");
                  }}
                >
                  Home
                </span>{" "}
                /
                <span
                  className="mr-1"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/admin/players");
                  }}
                >
                  Players
                </span>{" "}
                / Edit
              </button>
            </div>

            <div className="agent-wrapper create-agents mt-8  rounded-xl">
              <div className="top-agent  rounded-tl-xl  rounded-tr-xl py-7 px-6 top-agent-page flex items-center justify-between">
                <h1>Edit Players</h1>
              </div>
              <div className="forms-wrapper px-6 py-6">
                <form action="#" className="grid grid-cols-2 gap-x-3 gap-y-6">
                  <div className="input-wrapper">
                    <label htmlFor="">
                      User Name <span>*</span>
                    </label>
                    <div className="input-container  mt-3">
                      <input type="text" placeholder="Enter your user name" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Nick Name</label>
                    <div className="input-container mt-3">
                      <input type="text" placeholder="Enter your nick name" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      First Name <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="text" placeholder="Enter First name" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Last Name <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="text" placeholder="Enter Last name" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Email <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="email" placeholder="Enter your email" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Password <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="text" placeholder="Enter your password" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Phone Number <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="text" placeholder="Enter your phone" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Date of birth <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input
                        type="text"
                        placeholder="Enter your date of birth"
                      />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Country Code <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <select name="" id="">
                        <option value="Select your currency">
                          Select a country
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">Currencies (PHP)</label>
                    <div className="input-container mt-3">
                      <select name="" id="">
                        <option value="Select your currency">
                          Select your currency
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-2 grid grid-cols-3 gap-3">
                    <div className="input-wrapper">
                      <label htmlFor="">
                        Vip Level <span>*</span>
                      </label>
                      <div className="input-container mt-3">
                        <select name="" id="">
                          <option value="Select your currency">0</option>
                        </select>
                      </div>
                    </div>

                    <div className="input-wrapper">
                      <label htmlFor="">
                        City <span>*</span>
                      </label>
                      <div className="input-container mt-3">
                        <input type="text" placeholder="Enter your city" />
                      </div>
                    </div>

                    <div className="input-wrapper">
                      <label htmlFor="">
                        Zip Code <span>*</span>
                      </label>
                      <div className="input-container mt-3">
                        <input type="text" placeholder="Enter your zip code" />
                      </div>
                    </div>
                  </div>

                  <div className="input-wrapper  flex items-center justify-between col-span-2">
                    <div className="flex items-center">
                      <div className="flex mr-9 items-center">
                        <CheckboxCustom type="checkbox" id="whitelist-agree" />
                        <p className="ml-2 checkbox-para">Email Verified</p>
                      </div>
                      <div className="flex mr-9 items-center">
                        <CheckboxCustom type="checkbox" id="active-agree" />
                        <p className="ml-2 checkbox-para">Active</p>
                      </div>
                    </div>
                    <div className="flex buttons-wrapper items-center">
                      <button
                        className="h-11 px-4 rounded mr-3"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/admin/players");
                        }}
                      >
                        Submit
                      </button>
                      <button className="h-11 px-4 rounded outline-button">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
