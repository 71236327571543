import React from "react";
import done from "../../assets/img/wallet/done.svg";

export const DepositeFiatThree = ({ setStep }) => {
  return (
    <div className="w-[629px] mt-10 mx-auto flex-1 flex flex-col items-center justify-center">
      <img src={done} alt="" />
      <h1 className="text-[24px] mt-10 text-white font-arabicmed">
        Deposit Successful
      </h1>
      <p className="text-[#AEB2B1] my-10 font-arabicmed text-center">
        Your request has been confirmed. You can track its progress on the
        Transaction History Page.
      </p>
      <div className="grid grid-cols-2 gap-6 w-full">
        <button
          className="text-[#fff] text-[16px] font-arabicbold flex items-center gap-1 w-full h-[50px] rounded-lg bg-[#252525] justify-center 
            border-[1px] border-[#3C403F]"
          onClick={(e) => {
            setStep(1);
          }}
        >
          Buy Crypto
        </button>

        <button
          className="text-[#111010] text-[16px] font-arabicbold flex items-center gap-1 w-full h-[50px] rounded-lg bg-gradient-to-br from-[#9BE402] to-[#1FA83C] justify-center"
          onClick={(e) => {
            setStep(3);
          }}
        >
          View History
        </button>
      </div>
    </div>
  );
};
