import React, { useState } from "react";

export const PaymentFormDetails = ({ details }) => {
  const feilds = details?.customFeild;
  const [inputs, setInputs] = useState({});

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [id]: value,
    }));
  };
  return (
    <>
      <div className="rounded-xl border-[1px] border-[#3C403F] p-5  mt-3 gap-4">
        <h1 className="text-white font-arabicbold text-[20px] mb-4">
          {details.name}
        </h1>
        {feilds?.map((item, index) => (
          <div key={index} className="w-full mt-3">
            <h1 className="text-white font-arabicmed mb-3">{item}</h1>
            <input
              type="text"
              className="h-[48px] px-4 w-full rounded-lg bg-[#111010] border border-[#3C403F] text-[14px] text-white font-arabicmed"
              // placeholder={item}
              id={item.replace(/\s/g, "")} // Remove all whitespace
              onChange={handleInputChange}
              methodId={details._id}
            />
          </div>
        ))}
      </div>
    </>
  );
};
