import ButtonLoader from "./components/ButtonLoader";
import { useAuth } from "./contexts/AuthContext";

const PrivateRoute = ({ children }) => {
  const { userInfo, isLoading } = useAuth();

  if (isLoading) {
    return <div className="fixed left-0 top-0 flex items-center justify-center h-screen w-full bg-black"><ButtonLoader /></div>;
  }

  // console.log(userInfo, 'isAuthenticated', isLoading)

  if (!userInfo) {
    window.location.href = `${process.env.REACT_APP_WEB_URL}`;
    return null;
  }

  return children;
};

export default PrivateRoute;
