import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tooltip from "../components/common/Tooltip";
import { useGetAllBalance, useGetStakingStatistics } from "../queries/balance";
import { AffiliateHeader } from "./AffiliateHeader";
import { SidebarArea } from "./SidebarArea";
import { CancelPopup } from "./staker/CancelPopup";
import { StakePopup } from "./staker/StakePopup";
import { TerminatePopup } from "./staker/TerminatePopup";

import banner3 from "../assets/img/banner/banner3.png";
import banner5 from "../assets/img/banner/banner5.png";
import banner14 from "../assets/img/banner/banner14.png";
import info from "../assets/img/bonus-dashboard/info.png";
import Carousel from "./affiliate/Carousel";
import { TablePlayer } from "./affiliate/TablePlayer";

export const RightAreaPlayerDashboard = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(1);
  const [StakePopupState, setStakePopupState] = useState(false);
  const [terminate, setTerminate] = useState(false);
  const [terminateId, setTerminateId] = useState();
  const [cancel, setCancel] = useState(false);
  const [cancelId, setCancelId] = useState();
  const { data: statistics } = useGetStakingStatistics();
  const [payCheck, setPaycheck] = useState(false);
  const { data: balance } = useGetAllBalance();
  const [typeShowAside, settypeShowAside] = useState(-1);

  const inplay =
    parseFloat(
      balance?.balances?.gbcBalance + balance?.balances?.winningBalance
    ) ?? 0.0;
  const availableBalance = parseFloat(balance?.balances?.winningBalance) ?? 0.0;

  const imageUrls = [banner3, banner5, banner14];

  return (
    <div className="flex-1">
      {StakePopupState && (
        <StakePopup setStakePopupState={setStakePopupState} />
      )}
      {terminate && (
        <TerminatePopup
          closePopup={() => {
            setTerminate(false);
            setTerminateId(null);
          }}
          terminateId={terminateId}
        />
      )}

      {cancel && (
        <CancelPopup
          closePopup={() => {
            setCancel(false);
            setCancelId(null);
          }}
          cancelId={cancelId}
        />
      )}

      <AffiliateHeader
        headerType="Player"
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />

      <div className="body-area flex gap-2 staker-dashboard items-start px-6 pt-6">
        <div className="body-left-area w-full">
          {/* <Link to="/all-games">
        <img
          src={banner3}
          className="w-full rounded-lg mb-6 cursor-pointer"
          alt=""
        />
      </Link> */}

          <Link to="/all-games">
            <Carousel imageUrls={imageUrls} />
          </Link>

          <div class="flex justify-start gap-6  my-6 lg:flex-col">
            <div className="bg-[#1A1A1B] rounded-lg py-6 px-12 text-center">
              <div className="flex flex-wrap gap-x-2 items-center justify-center mb-2">
                <p className="text-white text-[18px] mr-2">In-play Balance</p>
                <div className="inline-flex relative group">
                  <img src={info} alt="Info" />
                  <Tooltip
                    message="In-Play Balance is a real-time gauge of funds available for gaming."
                    classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                  />
                </div>
              </div>
              <div className="text-[#6FCF17] font-bold text-[24px]">
                {inplay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </div>

            <div className="bg-[#1A1A1B] rounded-lg py-6 px-12 text-center">
              <div className="flex flex-wrap gap-x-2 items-center justify-center mb-2">
                <p className="text-white text-[18px] mr-2">
                  Available GC Balance
                </p>
                <div className="inline-flex relative group">
                  <img src={info} alt="Info" />
                  <Tooltip
                    message="This holds only the winnings from games and can be transferred to your cash wallet."
                    classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                  />
                </div>
              </div>
              <div className="text-[#6FCF17] font-bold text-[24px]">
                {availableBalance
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <h1 className="text-[24px] sm:text-[20px]  font-arabicmed text-white">
              Transaction History
            </h1>
          </div>

          <div className="flex mt-10 lg:flex-col">
            <div className="transaction-box bg-[#252525] rounded-xl p-8 flex-1 sm:p-5">
              <div className="lg:overflow-auto">
                <TablePlayer />
              </div>
            </div>
          </div>
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};
