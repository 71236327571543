import React from "react";
import { Link } from "react-router-dom";

export const SettingsOptions = ({ active }) => {
  return (
    <>
      {/* <Link
        to="/home/global-settings"
        className={`${active === 1 && "active"}  flex items-center rounded-lg`}
      >
        General
      </Link> */}
      {/* <Link
        to="/home/global-privacy"
        className={`${active === 2 && "active"}  flex items-center rounded-lg`}
      >
        Privacy
      </Link> */}
      {/* <Link
        to="/home/global-mail-phone"
        className={`${active === 3 && "active"}  flex items-center rounded-lg`}
      >
        Mail/Phone Number
      </Link> */}
      <Link
        to="/home/global-security"
        className={`${active === 4 && "active"}  flex items-center rounded-lg`}
      >
        Security
      </Link>
      {/* <Link
        to="/home/global-active-session"
        className={`${active === 5 && "active"}  flex items-center rounded-lg`}
      >
        Active Sessions
      </Link> */}
      {/* <Link
        to="/home/global-verify"
        className={`${active === 6 && "active"}  flex items-center rounded-lg`}
      >
        Verify
      </Link> */}
    </>
  );
};
