import { useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

export const useGetCommunityTeamList = () =>
  useQuery("communityTeamList", async () => {
    try {
      const res = await privateRequest.get("user/community/list");
      return res.data ?? [];
    } catch (error) {
      console.log(error);
      throw new Error(
        error?.response?.data?.message ?? "Error getting community team list"
      );
    }
  });

export const useGetCommunityStatistics = () =>
  useQuery("communityStatistics", async () => {
    try {
      const res = await privateRequest.get("user/community/getStatistics");
      return res.data ?? {};
    } catch (error) {
      console.log(error);
      throw new Error(
        error?.response?.data?.message ?? "Error getting community statistics"
      );
    }
  });

export const useGetCommunityLeaders = () =>
  useQuery("getCommunityLeader", async () => {
    try {
      const res = await privateRequest.get("user/community/getCommunityLeader");
      return res.data ?? [];
    } catch (error) {
      console.log(error);
      throw new Error(
        error?.response?.data?.message ?? "Error getting community leader"
      );
    }
  });
