export class CopyCutter {
  copyText(reference, notify) {
    // Get the text field
    var copyText = reference.current;

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    notify();
  }
}
const copyCutter = new CopyCutter();

export { copyCutter };
