import React, { useRef, useState } from "react";
import "../../assets/css/deposite.css";
import usdt from "../../assets/img/popup/usdt.svg";
import btb from "../../assets/img/popup/btb.svg";
import bnb from "../../assets/img/popup/bnb.svg";
import pron from "../../assets/img/popup/pron.svg";
import qr from "../../assets/img/popup/qr.png";
import dollar from "../../assets/img/popup/dollar.svg";
import tusdt from "../../assets/img/popup/t-usdt.svg";
import moonPay from "../../assets/img/popup/moon-pay.svg";
import gcash from "../../assets/img/mastercard.svg";
import { PayDropdown } from "./PayDropdown";
import { DepositeCurrencyDropdown } from "./DepositeCurrencyDropdown";
import { DepositeChooseNetwork } from "./DepositeChooseNetwork";
import { CopyCutter } from "../../utils/modules/CopyCustter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DepositeYourMailDropdown } from "./DepositeYourMailDropdown";

export const Deposite = ({ setnotificationPopup }) => {
  const [optionDeposite, setoptionDeposite] = useState(1);
  const [optionMail, setoptionMail] = useState(1);
  const refText = useRef(null);
  const copyCutter = new CopyCutter();
  const notify = () => toast("Deposit Address Coppied");
  return (
    <div className="popupWrapper ">
      <div
        className="popup-wrapper-bg"
        onClick={(e) => {
          setnotificationPopup(false);
        }}
      ></div>
      <ToastContainer theme="dark" autoClose={3000} />
      <div className="popup DepositePopUp px-10 py-6 rounded-xl">
        <div className="buttons-wrapper pb-6  mb-6 grid grid-cols-3 gap-3">
          <button
            className={`${optionDeposite === 1 && "active"}`}
            onClick={(e) => {
              setoptionDeposite(1);
            }}
          >
            Crypto
          </button>
          <button
            className={`${optionDeposite === 2 && "active"}`}
            onClick={(e) => {
              setoptionDeposite(2);
            }}
          >
            Flat
          </button>
          <button
            className={`${optionDeposite === 3 && "active"}`}
            onClick={(e) => {
              setoptionDeposite(3);
            }}
          >
            Buy Crypto
          </button>
        </div>
        {optionDeposite === 1 && (
          <div className="option-one">
            <div className="your-mall">
              <h1>Your Mail</h1>
              <div className="flex items-center mt-3 mb-3">
                <button
                  className={`flex items-center justify-center ${
                    optionMail === 1 && "active"
                  }`}
                  onClick={(e) => {
                    setoptionMail(1);
                  }}
                >
                  <img src={usdt} alt="" className="mr-2" />
                  USDT
                </button>
                <button
                  className={`flex items-center justify-center ${
                    optionMail === 2 && "active"
                  }`}
                  onClick={(e) => {
                    setoptionMail(2);
                  }}
                >
                  <img src={btb} alt="" className="mr-2" />
                  BTC
                </button>
                <button
                  className={`flex items-center justify-center ${
                    optionMail === 3 && "active"
                  }`}
                  onClick={(e) => {
                    setoptionMail(3);
                  }}
                >
                  <img src={bnb} alt="" className="mr-2" />
                  BNB
                </button>
                <button
                  className={`flex items-center justify-center ${
                    optionMail === 4 && "active"
                  }`}
                  onClick={(e) => {
                    setoptionMail(4);
                  }}
                >
                  <img src={pron} alt="" className="mr-2" />
                  TRON
                </button>
              </div>
            </div>

            <DepositeYourMailDropdown
              heading="USDT"
              dollar={usdt}
              list={[
                {
                  img: usdt,
                  heading: "USDT",
                },
                {
                  img: usdt,
                  heading: "USDT Two",
                },
              ]}
            />

            <div className="input-container mt-6">
              <label htmlFor="">Choose Network</label>

              <DepositeChooseNetwork
                heading="ERC20"
                list={["ERC20", "ERC21", "ERC22"]}
              />
            </div>

            <p className="content my-6">
              Get extra <span>180%</span> bonus on minimum of{" "}
              <span>10.00 BCD</span> deposit
            </p>
            <div className="qr-container p-5 flex items-center">
              <div className="qr-wrapper mr-6">
                <img src={qr} alt="" />
              </div>
              <div className="presentation flex-1">
                <h1>Deposit Address</h1>
                <div className="copy-area w-full mt-6 px-4 flex items-center">
                  <input
                    ref={refText}
                    type="text"
                    className="w-full flex-1 bg-transparent border-0 outline-none"
                    value="0X221654378765654328726GHFGDHJJDGS"
                  />

                  <button
                    onClick={(e) => {
                      copyCutter.copyText(refText, notify);
                    }}
                  >
                    Copy Address
                  </button>
                </div>
              </div>
            </div>

            <div className="warning mt-6 flex items-center px-6 rounded-xl">
              <p>
                <span>Notice</span> : Do not deposit any currency other than BCD
              </p>
            </div>
          </div>
        )}

        {optionDeposite === 2 && (
          <div className="option-two">
            <div className="input-container mt-4">
              <label htmlFor="">Deposit Currency</label>
              <DepositeCurrencyDropdown
                dollar={gcash}
                heading="USD"
                list={[
                  {
                    heading: "USD",
                    img: gcash,
                  },
                ]}
              />
            </div>

            <div className="input-container mt-4">
              <label htmlFor="">Choose a Payment Method</label>
              <div className="details-wrapper mt-3 px-3 py-3 rounded-lg">
                <div className="input-container">
                  <label htmlFor="">Recommended</label>

                  <DepositeCurrencyDropdown
                    dollar={gcash}
                    heading="USD"
                    list={[
                      {
                        heading: "USD",
                        heading2: "0 USD",
                        img: gcash,
                      },
                    ]}
                  />
                </div>

                <div className="input-container mt-4">
                  <label htmlFor="">E-wallet</label>
                  <DepositeCurrencyDropdown
                    dollar={gcash}
                    heading="USD"
                    list={[
                      {
                        heading: "USD",
                        heading2: "0 USD",
                        img: gcash,
                      },
                    ]}
                  />
                  <DepositeCurrencyDropdown
                    dollar={gcash}
                    heading="USD"
                    list={[
                      {
                        heading: "USD",
                        heading2: "0 USD",
                        img: gcash,
                      },
                    ]}
                  />
                  <DepositeCurrencyDropdown
                    dollar={gcash}
                    heading="USD"
                    list={[
                      {
                        heading: "USD",
                        heading2: "0 USD",
                        img: gcash,
                      },
                    ]}
                  />
                </div>

                <button className="flex moon-pay-btn items-center justify-center mt-3 w-full ">
                  Show More
                </button>

                <div className="input-container mt-4">
                  <label htmlFor="">Bank transfer</label>
                  <DepositeCurrencyDropdown
                    dollar={gcash}
                    heading="USD"
                    list={[
                      {
                        heading: "USD",
                        heading2: "0 USD",
                        img: gcash,
                      },
                    ]}
                  />
                  <DepositeCurrencyDropdown
                    dollar={gcash}
                    heading="USD"
                    list={[
                      {
                        heading: "USD",
                        heading2: "0 USD",
                        img: gcash,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {optionDeposite === 3 && (
          <div className="option-three">
            <div className="input-container mt-4">
              <label htmlFor="">You pay with</label>
              <div className="input-notify-wrapper rounded-lg mt-3 flex items-center px-4">
                <p className="flex-1">30</p>
                <PayDropdown
                  dollar={dollar}
                  heading="USD"
                  list={[
                    {
                      heading: "USD",
                      img: dollar,
                    },
                    {
                      heading: "USDT",
                      img: tusdt,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="input-container mt-4">
              <label htmlFor="">You get</label>
              <div className="input-notify-wrapper rounded-lg mt-3 flex items-center px-4">
                <p className="flex-1">29.55</p>

                <div className="dropdown">
                  <PayDropdown
                    dollar={tusdt}
                    heading="USDT"
                    list={[
                      {
                        heading: "USD",
                        img: dollar,
                      },
                      {
                        heading: "USDT",
                        img: tusdt,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>

            <div className="input-container mt-4">
              <label htmlFor="">Payment Details</label>
              <div className="details-wrapper mt-3 px-3 py-6 rounded-lg">
                <div className="detail-row flex pb-6 items-center justify-between">
                  <h1>Provider</h1>
                  <p>MoonPay</p>
                </div>
                <div className="detail-row flex pb-6 items-center justify-between">
                  <h1>Method</h1>
                  <p>Credit Card</p>
                </div>
                <div className="detail-row flex items-center justify-between">
                  <h1>Deposit to account</h1>
                  <p>Kiyskvotnyb</p>
                </div>
              </div>
              <div className="details-wrapper mt-3 px-3 py-6 rounded-lg">
                <div className="detail-row flex pb-6 items-center justify-between">
                  <h1>Total (including fee)</h1>
                  <p>30 USD</p>
                </div>
                <div className="detail-row flex  items-center justify-between">
                  <h1>You will get</h1>
                  <p className="yellow">29.55 USDT</p>
                </div>
              </div>
            </div>

            <div className="warning mt-5 flex items-center px-6 rounded-xl">
              <p className="text-[#AEB2B1]">
                <span>Notice</span> : Depending on the blockchain, the deposit
                may take a <br /> few minutes to 1 hour to arrive.
              </p>
            </div>

            <div className="agreement flex items-center my-5">
              <input type="checkbox" id="agreement-deposit" />
              <label
                htmlFor="agreement-deposit"
                className="w-3 h-3 rounded flex items-center justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#fff"
                  class="w-6 h-6"
                >
                  <path
                    fill-rule="evenodd"
                    d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                    clip-rule="evenodd"
                  />
                </svg>
              </label>
              <p className="ml-3">I have read and agree to the disclaimer.</p>
            </div>

            <button
              className="flex moon-pay-btn items-center justify-center w-full "
              onClick={(e) => {
                e.preventDefault();
                setnotificationPopup(false);
              }}
            >
              <img src={moonPay} alt="" className="mr-2" />
              Buy Via MoonPay
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
