import React, { useEffect, useRef, useState } from "react";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { useQuery, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../assets/css/p2p-post-ad.css";
import "../../assets/css/settings/index.css";
import Equal from "../../assets/img/equals.svg";
import add from "../../assets/img/p2p/add.svg";
import confetti from "../../assets/img/p2p/confetti.svg";
import remove from "../../assets/img/p2p/delete.svg";
import notice from "../../assets/img/p2p/notice.svg";
import swap from "../../assets/img/swap/swap.svg";
import swapTCx from "../../assets/img/swap/swap_TC.pdf";
import backImg from "../../assets/img/transaction/back.svg";
import { useGetAvailableCryptos } from "../../queries/crypto";
import {
  UseFetchMarketPrice,
  UseP2PSaveAds,
  UseP2PSavePaymentMethod,
  UseRegionsList,
  UseSaveUserPaymentMethodDetails,
  fetchCryptoExchangeRate,
  usePaymentMethods,
  useUserPaymentMethods,
} from "../../queries/p2p";
import { Coin } from "../../utils/staking/Coin";
import { CurrencyCoins } from "../../utils/staking/CurrencyCoins";
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";
import { DepositeCurrencyDropdown } from "../popups/DepositeCurrencyDropdown";
import { PayDropdown } from "../popups/PayDropdown";
import { P2PNav } from "./P2PNav";
import { PaymentFormDetails } from "./PaymentFormDetails";

export const RightAreaP2PPostAd = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [active, setActive] = useState(true);
  const [coin, setCoin] = useState("USDT");
  const [selectedCurrency, setSelectedCurrency] = useState("PHP");
  const [exchangeRateType, setExchangeRateType] = useState("fixed");
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [stepActive, setStepActive] = useState(1);
  const [stepPayment, setStepPayment] = useState(1);
  const [adsType, setadsType] = useState("to_buy");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const { data: regions, isLoading } = useQuery("get-regions", UseRegionsList);
  let filters = {
    cryptoCurrency: coin,
    fiatCurrency: selectedCurrency,
    adsType: adsType,
  }
  const [market, setMarket] = useState();
  const { data: marketPrice, error: marketError, isLoading: isMarketLoading, refetch: marketRefetch } = UseFetchMarketPrice(filters);

  useEffect(() => {
    marketRefetch();

    if (marketPrice) {
      console.log("Market price fetched:", marketPrice);
      setMarket(marketPrice);
    }
    if (marketError) {
      console.error('Failed to fetch market price:', marketError);
    }
  }, [marketPrice, marketError]);

  useEffect(() => {
    setMarket(marketPrice);
  }, [coin, selectedCurrency, adsType]);

  useEffect(() => {
  }, [market]);

  // PAYMENT METHOD FORM
  const [formInputs, setFormInputs] = useState([{ id: 1, title: "" }]);
  const [formErrors, setFormErrors] = useState([]);
  const [paymentMethodName, setPaymentMethodName] = useState("");

  const handleFormInputChange = (index, event) => {
    const { name, value } = event.target;
    const newformInputs = [...formInputs];
    newformInputs[index][name] = value;
    setFormInputs(newformInputs);
    // Reset formErrors when input changes
    setFormErrors([]);
  };

  const handleAddFormInput = () => {
    setFormInputs([...formInputs, { id: formInputs.length + 1, title: "" }]);
  };

  const handleRemoveInput = (index) => {
    const newformInputs = [...formInputs];
    newformInputs.splice(index, 1);
    setFormInputs(newformInputs);
    // Reset formErrors when input is removed
    setFormErrors([]);
  };

  const handleFormSubmit = () => {
    // Basic validation - Check if any required fields are empty
    const newformErrors = formInputs.map((input) => {
      if (!input.title.trim()) {
        return "Field is required.";
      }
      return "";
    });

    setFormErrors(newformErrors);

    // If there are no formErrors, submit the form
    if (!newformErrors.some((error) => error !== "")) {
      // Implement your submit logic here
      console.log("Form submitted:", formInputs);
      setOpenNotice(true);
    }
  };

  const closeNotice = () => {
    setOpenNotice(false);
  };

  const [inputs, setInputs] = useState({});

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [id]: value,
    }));
  };

  // END PAYMENT METHOD FORM

  const handleClick = () => {
    setActive(!active);
  };

  const handlePriceTypeChange = (event) => {
    console.log(event.target.value);
    setExchangeRateType(event.target.value);
  };


  // const { heading, img } = Coin[0];
  // const [coin, setCoin] = useState(Coin.find((item) => item.heading === "BTC").img);


  const [paycheck, setPaycheck] = useState(true);

  const {
    data: cryptoData,
    isLoading: loadingCryptos,
    error: cryptoError,
  } = useGetAvailableCryptos();

  const [flag, setFlag] = useState("");

  const handleProceed1 = () => {
    setStepActive(2);
  };

  const handleProceed2 = () => {
    setStepActive(3);
  };

  const handlePrevious = () => {
    setStepActive(stepActive - 1);
  };

  const handlePayment1 = () => {
    if (!advertiserTerms.trim()) {
      toast.error("Advertiser terms are required.");
    } else if (region === "") {
      toast.error("Region is required.");
    } else {
      setStepPayment(2);
      setStepActive(2);
    }
  };

  const [limit, setLimit] = useState(15);

  const handleChange = (event) => {
    setLimit(event.target.value);
  };

  //INCREMENT, DECREMENT FUNCTION
  const [usdtValue, setUsdtValue] = useState(0.0);
  const [marginValue, setMarginValue] = useState(100.0);
  const [fiatWithMarginValue, setFiatWithMarginValue] = useState(0.0);

  const [totalAmount, setTotalAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [openNotice, setOpenNotice] = useState("");
  const [totalInputError, setTotalInputError] = useState("");
  const [maxAmountError, setMaxAmountError] = useState("");
  const [minAmountError, setMinAmountError] = useState("");

  const [renderedComponents, setRenderedComponents] = useState([]);
  const [userSelectedPayments, setUserSelectedPayments] = useState([]);
  const [advertiserTerms, setadvertiserTerms] = useState("");
  const [region, setRegion] = useState("");
  // Modal state
  const [isOpen, setIsOpen] = useState(false);
  const [isPayMethod, setIsPayMethod] = useState(false);

  const handleTotalInputBlur = () => {
    if (parseFloat(minAmount) >= parseFloat(totalAmount)) {
      setTotalInputError(`The minimum should be lower`);
    } else {
      setTotalInputError("");
    }
  };

  const handleMaxInputBlur = () => {
    if (parseFloat(minAmount) >= parseFloat(totalAmount)) {
      setMaxAmountError(`The maximum input should be lower`);
    } else if (parseFloat(maxAmount) < parseFloat(minAmount)) {
      setMaxAmountError(`The minimum input is ${minAmount}`);
    } else if (parseFloat(maxAmount) > parseFloat(totalAmount)) {
      setMaxAmountError(`The maximum input is ${totalAmount}`);
    } else {
      setMaxAmountError("");
    }
  };

  const handleMinInputBlur = () => {
    if (parseFloat(minAmount) >= parseFloat(totalAmount)) {
      setMinAmountError(`The minimum should be lower`);
    } else {
      setMinAmountError("");
    }
  };

  const handleSelect = (selectedItem) => {
    setCoin(selectedItem?.heading);
  };

  const postPaymentMethod = async () => {
    try {
      const fieldsValue = Array.from(
        document.querySelectorAll("#paymentFields input")
      ).map((input) => input.value);
      const name_ = document.querySelector("input#Title").value;
      const objectValue = {
        name: name_,
        customFeild: fieldsValue,
      };

      const data = await UseP2PSavePaymentMethod({
        paymentMethods: objectValue,
      });
      // console.log(data);
    } catch (error) {
      console.error(error.message);
    }
    setStepPayment(6);
    setOpenNotice(false);
  };

  // const handleSubmit = () => {
  //   if (
  //     !maxAmountError &&
  //     !minAmountError &&
  //     !totalInputError &&
  //     maxAmount &&
  //     minAmount &&
  //     totalAmount
  //   ) {
  //     setIsOpen(true);
  //   }
  // };
  const handleSubmit = () => {
    // Check if all required fields are filled and valid
    if (
      !maxAmountError &&
      !minAmountError &&
      !totalInputError &&
      maxAmount &&
      minAmount &&
      totalAmount
    ) {
      setIsOpen(true);
    } else {
      // Handling errors and showing appropriate toast messages
      if (maxAmountError) {
        toast.error(maxAmountError); // Assuming maxAmountError contains the error message
      }
      if (minAmountError) {
        toast.error(minAmountError); // Assuming minAmountError contains the error message
      }
      if (totalInputError) {
        toast.error(totalInputError); // Assuming totalInputError contains the error message
      }
      if (!maxAmount) {
        toast.error("Maximum amount is required.");
      }
      if (!minAmount) {
        toast.error("Minimum amount is required.");
      }
      if (!totalAmount) {
        toast.error("Total amount is required.");
      }
    }
  };
  const handlePayment2 = () => {
    if (selectedPaymentMethod.method === "E-Wallet") {
      setStepPayment(3);
    } else if (selectedPaymentMethod.method === "Bank Transfer") {
      setStepPayment(4);
    } else if (selectedPaymentMethod.method === "Card") {
      setStepPayment(5);
    }
  };

  const handlePayment = (method) => {
    setSelectedPaymentMethod(method);
    setStepPayment(2);
  };

  //PAYMENT METHOD MODAL
  const paymentMethodModal = () => {
    setIsPayMethod(true);
    setStepPayment(2);
  };

  // Function to handle opening the modal
  // const openModal = () => {
  //   setIsOpen(true);
  // };

  // Function to handle closing the modal
  const closeModal = async () => {
    setIsOpen(false);
    setIsPayMethod(false);
    await queryClient.refetchQueries("paymentMethodList");
  };

  const handleGoBack = async () => {
    if (stepPayment === 3) {
      setStepPayment(2);
    } else if (stepPayment === 4) {
      setStepPayment(2);
    } else if (stepPayment === 2) {
      setSelectedPaymentMethod(null);
    } else if (stepPayment === 5) {
      setStepPayment(2);
    } else if (stepPayment === 6) {
      setStepPayment(2);
    }
    await queryClient.refetchQueries("paymentMethodList");

    // setSelectedPaymentMethod(null);
  };

  const increment = () => {
    setUsdtValue((prevValue) => prevValue + 1);
  };

  const decrement = () => {
    if (usdtValue > 1) {
      // To avoid negative values
      setUsdtValue((prevValue) => prevValue - 1);
    }
  };

  const incrementMargin = () => {
    if (marginValue + 1 <= 120) {
      setMarginValue((prevValue) => {
        const updatedMargin = prevValue + 1;
        const adjustedRate = exchangeRate * (updatedMargin / 100);
        setFiatWithMarginValue(adjustedRate);
        return updatedMargin;
      });
    }
  };

  const decrementMargin = () => {
    if (marginValue - 1 >= 80) {
      setMarginValue((prevValue) => {
        const updatedMargin = prevValue - 1;
        const adjustedRate = exchangeRate * (updatedMargin / 100);
        setFiatWithMarginValue(adjustedRate);
        return updatedMargin;
      });
    }
  };

  //for ads type
  useEffect(() => {
    const adsType = active ? "to_buy" : "for_sell";
    setadsType(adsType);
  }, [active]);

  useEffect(() => {
    const fetchExchange = async () => {
      const { exchangeRate: cryptoExchangeRate } =
        await fetchCryptoExchangeRate({
          selectedCurrency: selectedCurrency,
          selectedCrypto: coin,
        });
      setExchangeRate(cryptoExchangeRate);
      setUsdtValue(cryptoExchangeRate);
      const initialRate = cryptoExchangeRate * (marginValue / 100);
      setFiatWithMarginValue(initialRate);
    };
    fetchExchange();
  }, [coin, selectedCurrency]);

  useEffect(() => {
    setUsdtValue(exchangeRate);
  }, []);

  const [value, setValue] = useState([]);
  const payments = usePaymentMethods();
  const userPaymentMethods = useUserPaymentMethods();
  const formsDivRef = useRef(null);

  const handleSelectForm = (val) => {
    const trimmedItem = val.target.value.trim();
    const paymentMatch = payments.data.data.find(
      (payment) => payment.name === val.target.value
    );
    const arrayValue = [
      {
        _id: paymentMatch ? paymentMatch._id : null,
        name: trimmedItem,
        customFeild: paymentMatch ? paymentMatch.customFeild : null,
      },
    ];
    setValue(arrayValue);
  };

  const handleSubmitPaymentDetails = async () => {
    const inputs = {}; // Object to store input values
    // Find all input elements inside the formsDivRef
    const inputElements = formsDivRef.current.querySelectorAll("input");
    var methodId = "";
    // Iterate over inputElements
    inputElements.forEach((input) => {
      const id = input.id;
      const value = input.value;
      methodId = input.getAttribute("methodid"); // Get the value of the custom attribute methodid
      inputs[id] = value; // Update inputs object
    });
    setInputs((prevInputs) => ({
      ...prevInputs,
      ...inputs, // Update state with collected inputs
    }));
    console.log(inputs);

    try {
      const data = await UseSaveUserPaymentMethodDetails({
        paymentMethodId: methodId,
        data: inputs,
      });
      toast.success(data?.message);
    } catch (error) {
      console.error("Posting payment details error", error.message);
      toast.error(error.message);
    }
  };

  const handleOnchange = (val) => {
    const arrayValue = val?.split(",").map((item) => {
      const trimmedItem = item.trim();

      if (adsType == "for_sell") {
        const paymentMatch = userSelectedPayments.find(
          (payment) => payment.name === trimmedItem
        );

        return {
          _id: paymentMatch ? paymentMatch._id : null,
          name: trimmedItem,
          data: paymentMatch,
        };
      }
      const paymentMatch = payments?.data?.data.find(
        (payment) => payment.name === trimmedItem
      );

      return {
        _id: paymentMatch ? paymentMatch._id : null,
        name: trimmedItem,
        customFeild: paymentMatch ? paymentMatch.customFeild : null,
      };
    });

    setValue(arrayValue);
  };

  const [newOptions, setNewOptions] = useState([]);
  const [newDetailsOptions, setNewDetailsOptions] = useState([]);

  useEffect(() => {
    if (adsType == "for_sell") {
      if (payments?.data?.data && userPaymentMethods) {
        const userPayments = [];
        (userPaymentMethods?.data?.data || []).forEach((item) => {
          const userPayment = payments.data.data.find(
            (payment) => payment._id === item.paymentMethod._id
          );
          if (userPayment) {
            userPayment.data = item.data;
            userPayments.push(userPayment);
          }
        });
        setUserSelectedPayments(userPayments);
        setNewOptions(() => {
          return userPayments.map((payment) => ({
            label: payment.name,
            value: payment.name,
          }));
        });

        setNewDetailsOptions(() => {
          return payments?.data?.data.map((payment) => ({
            label: payment.name,
            value: payment.name,
          }));
        });
      }
    } else {
      if (payments?.data?.data) {
        setNewOptions(() => {
          return payments.data.data.map((payment) => ({
            label: payment.name,
            value: payment.name,
          }));
        });
      }
    }
  }, [payments?.data?.data, adsType]);

  useEffect(() => {
    if (adsType === "for_sell") {
      value.forEach((item) => {
        const paymentMatch = payments.data.data.find(
          (payment) => payment._id === item._id
        );
        const components = <PaymentFormDetails details={paymentMatch} />;
        setRenderedComponents(components);
      });
    }
  }, [value]);

  //posting advertisement
  const postAdvertisement = async () => {
    try {
      const data = await UseP2PSaveAds({
        adsType: adsType,
        exchangeRateType: exchangeRateType,
        cryptoCurrency: coin,
        fiatCurrency: selectedCurrency,
        min: minAmount,
        max: maxAmount,
        totalSupply: totalAmount,
        paymentMethods: value,
        exchangeRate: usdtValue,
        priceMarkupPercentage: marginValue,
        timeWindowInMinute: limit,
        advertiserTerms: advertiserTerms,
        region: region,
      });
      closeModal();
      toast.success(data?.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />
      <div className="flex-1">
        <HeaderStaker
          headerType="Staker"
          login={login}
          settypeShowAside={settypeShowAside}
          setsigninState={setsigninState}
          setSignUpState={setSignUpState}
        />
        <P2PNav />

        <div className="body-area flex items-start px-6 pt-6">
          <div className="body-left-area w-full">
            <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
              <div className="flex items-center px-6 py-4 h-[91px] border-[#3C403F] border-b-[1px]">
                <div className="flex items-center">
                  <Link to="/p2p/buy" className="px-2 flex">
                    <img src={backImg} alt="" />
                    <h1 className="ml-2 text-white text-[24px] text-center sm:text-[18px]">
                      Post Your Ad
                    </h1>
                  </Link>
                </div>

                <span className="flex-1"></span>

                <Link
                  target="_blank"
                  to={swapTCx}
                  className="text-[18px] font-arabicmed text-[#6FCF17] sm:text-[12px] hover:text-[#a6e66b] "
                >
                  Terms and Conditions
                </Link>
              </div>

              <div className="p-6">
                <>
                  {/*****  2 STEPS  *******/}
                  <div className="grid grid-cols-2 mb-4 ">
                    <h1 className="text-[18px]  text-[#AEB2B1] sm:text-[12px] font-arabicmed place-self-start md:place-self-start">
                      Set Type Price
                    </h1>
                    <h1 className="text-[18px] text-[#AEB2B1] sm:text-[12px]  font-arabicmed place-self-end">
                      Set Total Amount Payment Method
                    </h1>
                  </div>

                  {stepActive !== 4 && (
                    <div className="flex justify-around buttons-wrapper-step gap-4 mb-6">
                      <div className="w-auto">
                        <button
                          className={`button-step  bg-[#252525] text-white ${stepActive === 1 ||
                            stepActive === 2 ||
                            stepActive === 3
                            ? "bg-[#6FCF17]"
                            : "" && "active"
                            } rounded-full px-5 h-12`}
                          onClick={(e) => {
                            setStepActive(1);
                          }}
                        >
                          1
                        </button>
                      </div>

                      <hr
                        style={{ borderWidth: "1.5px" }}
                        className={`border-[#4b4b4b] w-full my-auto ${stepActive === 2 || stepActive === 3
                          ? "border-[#6cb339]"
                          : ""
                          }`}
                      />

                      <div className="w-auto">
                        <button
                          className={`button-step bg-[#252525] text-white ${stepActive === 2 || stepActive === 3
                            ? "bg-[#6FCF17]"
                            : "" && "active"
                            } rounded-full px-5 h-12`}
                          onClick={(e) => {
                            setStepActive(2);
                          }}
                        >
                          2
                        </button>
                      </div>
                    </div>
                  )}

                  {stepActive === 1 && (
                    <div className="">
                      <div className="flex w-full justify-between mb-4 rounded-md border-[1px] border-blue-gray-800 bg-[#252525]">
                        <button
                          className={`text-white font-arabicmed rounded-md text-[14px] py-3 w-full ${active ? "bg-[#6FCF17]" : "bg-transparent"
                            }`}
                          onClick={handleClick}
                        >
                          I want to buy
                        </button>
                        <button
                          className={`text-white font-arabicmed rounded-md text-[14px] py-3 w-full ${active ? "bg-transparent" : "bg-[#6FCF17]"
                            }`}
                          onClick={handleClick}
                        >
                          I want to sell
                        </button>
                      </div>

                      <div className="flex md:flex-col md:justify-center justify-between items-end  gap-8 md:gap-1 mb-6">
                        <div className="w-full h-auto py-4">
                          <h1 className="text-[18px] text-[#AEB2B1] font-arabicmed mb-2">
                            Asset
                          </h1>
                          <PayDropdown
                            classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
                            stakeFix
                            dollar={Coin[0].img}
                            heading={Coin[0].heading}
                            list={Coin}
                            callback={handleSelect}
                          />
                        </div>
                        <div className="w-28 h-auto md:py-1 py-4 flex justify-center items-center md:w-full">
                          <img
                            src={swap}
                            alt=""
                            // onClick={(e) => {
                            //   setActive(!active);
                            // }}
                            className="cursor-pointer md:ml-4 md:self-center"
                            style={{ transform: "rotate(90deg)" }}
                          />
                        </div>
                        <div className="w-full h-auto py-4">
                          <h1 className="text-[18px] text-[#AEB2B1] font-arabicmed mb-2">
                            With Fiat
                          </h1>
                          <div className="w-full h-auto py-[11px] bg-[#111010] rounded-lg border-[1px] border-[#3C403F]">
                            <DepositeCurrencyDropdown
                              onCurrencyChange={(currency) => {
                                setSelectedCurrency(currency);
                                setPaycheck(currency === "PHP");
                              }}
                              list={CurrencyCoins}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex justify-between gap-6 mt-6 md:flex-col">
                        <div className="w-full h-[150px] mt-2 flex flex-col justify-center rounded-lg border border-[#4b4b4b] px-6">
                          <div className="flex items-center justify-between">
                            <p className="text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                              Price Type
                            </p>
                          </div>
                          <div className="flex gap-2 mt-4">
                            <div className="w-full flex items-center gap-4">
                              <input
                                type="radio"
                                id="option1"
                                name="exchangeRateType"
                                value="fixed"
                                style={{ transform: "scale(1.5)" }}
                                onChange={handlePriceTypeChange}
                                checked={exchangeRateType === "fixed"}
                              />
                              <label
                                htmlFor="option1"
                                className="text-[18px] text-[#ACADB1] font-arabicmed"
                              >
                                <span className="text-white">Fixed</span>
                              </label>
                            </div>

                            <div className="w-full flex items-center gap-2">
                              <input
                                type="radio"
                                id="option2"
                                name="exchangeRateType"
                                value="float"
                                style={{ transform: "scale(1.5)" }}
                                onChange={handlePriceTypeChange}
                                checked={exchangeRateType === "float"}
                              />
                              <label
                                htmlFor="option2"
                                className="text-[18px] text-[#ACADB1] font-arabicmed"
                              >
                                <span className="text-white">Float</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        {exchangeRateType === "fixed" ? (
                          <div className="w-full md:h-full md:py-2 h-[150px] mt-2 flex flex-col justify-center rounded-lg border border-[#525252] px-6 ">
                            <div className="flex items-center justify-between">
                              <p className="text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                                Fixed
                              </p>
                            </div>
                            <div className="flex items-center justify-between mt-3 p-[6px] border-gray-700 border-[1px] rounded-md">
                              <button
                                onClick={decrement}
                                className="flex justify-center items-center text-center w-10 h-10 bg-gray-800 rounded-md text-white border-gray-700 text-[36px]"
                              >
                                <p className="mb-1">-</p>
                              </button>
                              <input
                                type="text"
                                value={
                                  usdtValue === 0
                                    ? exchangeRate.toString()
                                    : usdtValue.toString()
                                }
                                onChange={(e) => {
                                  const newValue = e.target.value.trim();
                                  if (newValue === "" || newValue === "-") {
                                    setUsdtValue(0);
                                  } else {
                                    const parsedValue = parseFloat(newValue);
                                    if (!isNaN(parsedValue)) {
                                      setUsdtValue(parsedValue);
                                    }
                                  }
                                }}
                                onBlur={(e) => {
                                  if (e.target.value === "") {
                                    e.target.setSelectionRange(0, 0);
                                  }
                                }}
                                className="text-white text-[18px] w-full h-10 bg-transparent rounded-md text-center focus:outline-none"
                              />

                              <button
                                onClick={increment}
                                className="flex justify-center items-center text-center w-10 h-10 bg-gray-800 rounded-md text-white border-gray-700 text-[26px] font-normal"
                              >
                                +
                              </button>
                            </div>

                            <div className="flex items-center justify-between">
                              <p className="text-center text-[15px] text-[#ACADB1] m-2 flex-1 font-arabicmed ">
                                The fixed price should be between{" "}
                                {selectedCurrency} 3119485.73 -{" "}
                                {selectedCurrency} 4679228.60
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="w-full h-[150px] mt-2 flex flex-col justify-center rounded-lg border border-[#525252] px-6 ">
                            <div className="flex items-center justify-between">
                              <p className="text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                                Floating Price Margin
                              </p>
                            </div>
                            <div className="flex items-center justify-between mt-3 p-[6px] border-gray-700 border-[1px] rounded-md">
                              <button
                                onClick={decrementMargin}
                                className="flex justify-center items-center text-center w-10 h-10 bg-gray-800 rounded-md text-white border-gray-700 text-[36px]"
                              >
                                <p className="mb-1">-</p>
                              </button>
                              <span className="text-white text-[18px]">
                                {marginValue.toFixed(2)} %
                              </span>
                              <button
                                onClick={incrementMargin}
                                className="flex justify-center items-center text-center w-10 h-10 bg-gray-800 rounded-md text-white border-gray-700 text-[26px] font-normal"
                              >
                                +
                              </button>
                            </div>
                            <div className="flex items-center justify-between">
                              <p className="text-center text-[15px] text-[#ACADB1] m-2 flex-1 font-arabicmed ">
                                Pricing formula {exchangeRate.toFixed(2)} *{" "}
                                {marginValue.toFixed(2)} % ≈{" "}
                                {fiatWithMarginValue.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="w-full flex justify-between gap-6 mt-10 md:flex-col">
                        <div className="w-full h-[120px] mt-2 flex flex-col justify-center rounded-lg border border-[#4b4b4b] px-6">
                          <div className="flex items-center justify-between">
                            <p className="text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                              Your Price
                            </p>
                          </div>
                          <div className="flex items-center justify-between mt-6">
                            {exchangeRateType === "fixed" ? (
                              <p className="text-[18px] text-[#ACADB1] font-arabicmed ">
                                {selectedCurrency}{" "}
                                <span className="text-white">
                                  {usdtValue.toFixed(3)}
                                </span>
                              </p>
                            ) : (
                              <p className="text-[18px] text-[#ACADB1] font-arabicmed ">
                                {" "}
                                <span className="text-white">
                                  {fiatWithMarginValue.toFixed(3)}
                                </span>
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="w-full h-[120px] mt-2 flex flex-col justify-center rounded-lg border border-[#4b4b4b] px-6">
                          <div className="flex items-center justify-between">
                            {adsType == "to_buy" ? (
                              <p className="text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                                Highest Order Price
                              </p>
                            ) : (
                              <p className="text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                                Lowest Order Price
                              </p>
                            )}
                          </div>
                          <div className="flex items-center justify-between mt-6">
                            {exchangeRateType === "fixed" ? (
                              <p className="text-[18px] text-[#ACADB1] font-arabicmed ">
                                {selectedCurrency}{" "}
                                <span className="text-white">
                                  {parseFloat(market?.data?.highest_price).toFixed(2)}
                                </span>
                              </p>
                            ) : adsType === "to_buy" ? (
                              <p className="text-[18px] text-[#ACADB1] font-arabicmed ">
                                {selectedCurrency}{" "}
                                <span className="text-white">
                                  {exchangeRate.toFixed(3)}{" "}
                                  {parseFloat(market?.data?.highest_price).toFixed(2)}
                                </span>
                              </p>
                            ) : (
                              <p className="text-[18px] text-[#ACADB1] font-arabicmed ">
                                {selectedCurrency}{" "}
                                <span className="text-white">
                                  {exchangeRate.toFixed(3)}
                                  {parseFloat(market?.data?.lowest_price).toFixed(2)}
                                </span>
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-full mt-2 flex justify-between flex-wrap rounded-lg border border-[#4b4b4b] px-6 py-5 gap-2">
                        <div className="md:w-full w-[49%]">
                          <div className="flex items-center justify-between">
                            <p className="text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                              Regions
                            </p>
                          </div>
                          <div className="mt-6">
                            <select
                              value={region}
                              onChange={(e) => setRegion(e.target.value)}
                              className="w-full h-auto py-[11px] bg-[#111010] rounded-lg border-[1px] text-white border-[#3C403F] px-3"
                            >
                              <option value="">Select Region</option>
                              {regions?.data?.map((region, index) => (
                                <>
                                  <option value={region?._id}>
                                    {region?.country}
                                  </option>
                                </>
                              ))}
                            </select>
                          </div>
                          <div></div>
                        </div>
                        <div className="md:w-full w-[49%]">
                          <div className="flex items-center justify-between">
                            <p className="text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                              Advertisment Terms
                            </p>
                          </div>
                          <div className="flex items-center justify-between mt-6">
                            <textarea
                              value={advertiserTerms}
                              onChange={(e) =>
                                setadvertiserTerms(e.target.value)
                              }
                              className="text-white font-arabicmed w-full h-full bg-transparent text-start focus:outline-none rounded-lg border border-[#4b4b4b] p-3"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="w-full flex  gap-4 mt-10 ">
                        <button
                          className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:from-[#82e402] hover:to-[#146626]"
                          onClick={handlePayment1}
                        >
                          Next page
                        </button>
                      </div>
                    </div>
                  )}

                  {stepActive === 2 && (
                    <div>
                      <div className="pt-4">
                        <div className="w-full flex justify-between text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                          <p>Total Amount</p>
                          {/* <p>= 0 USD</p> */}
                          <p>= 0 {coin}</p>
                        </div>
                        <div className="w-full h-[60px] my-2 flex justify-between rounded-lg border border-[#4b4b4b] px-4">
                          <div className="flex w-full justify-between items-center">
                            {/***** TOTAL AMOUNT INPUT *****/}

                            <input
                              type="text"
                              placeholder="0"
                              className="text-white font-arabicmed w-full h-full bg-transparent text-start focus:outline-none"
                              value={totalAmount}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*\.?\d*$/.test(value)) {
                                  setTotalAmount(value);
                                }
                              }}
                              onBlur={handleTotalInputBlur}
                            />
                            <p className="text-white font-arabicmed">{coin}</p>
                          </div>
                        </div>
                        {totalInputError && (
                          <p style={{ color: "#db0d29" }}>{totalInputError}</p>
                        )}
                      </div>

                      <div className="pt-4">
                        <div className="w-full flex justify-between text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                          <p>Order Limit</p>
                        </div>
                        <div className="flex gap-6">
                          <div className="flex flex-col w-full">
                            <div className="w-full h-[60px] my-4 flex justify-between rounded-lg border border-[#4b4b4b] px-4">
                              <div className="flex w-full justify-between items-center">
                                <div className="flex w-full justify-between items-center">
                                  <p className="text-[#ACADB1] font-arabicmed mr-2">
                                    Min
                                  </p>
                                  {/***** MINIMUM AMOUNT INPUT *****/}

                                  <input
                                    type="text"
                                    placeholder="0"
                                    className="text-white font-arabicmed w-full h-full bg-transparent text-start focus:outline-none"
                                    value={minAmount}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (/^\d*\.?\d*$/.test(value)) {
                                        setMinAmount(value);
                                      }
                                    }}
                                    onBlur={handleMinInputBlur}
                                  />
                                  <p className="text-white font-arabicmed">
                                    {coin}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {minAmountError && (
                              <p style={{ color: "#db0d29" }}>
                                {minAmountError}
                              </p>
                            )}
                          </div>

                          <div className="flex justify-center items-center">
                            <img src={Equal} alt="equal" />
                          </div>

                          <div className="flex flex-col w-full">
                            <div className="w-full h-[60px] my-4 flex justify-between rounded-lg border border-[#4b4b4b] px-4">
                              <div className="flex w-full justify-between items-center">
                                <div className="flex w-full justify-between items-center">
                                  <p className="text-[#ACADB1] font-arabicmed mr-2">
                                    Max
                                  </p>
                                  {/***** MAXIMUM AMOUNT INPUT *****/}

                                  <input
                                    type="text"
                                    placeholder="0"
                                    className="text-white font-arabicmed w-full h-full bg-transparent text-start focus:outline-none"
                                    value={maxAmount}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (/^\d*\.?\d*$/.test(value)) {
                                        setMaxAmount(value);
                                      }
                                    }}
                                    onBlur={handleMaxInputBlur}
                                  />
                                  <p className="text-white font-arabicmed">
                                    {coin}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {maxAmountError && (
                              <p style={{ color: "#db0d29" }}>
                                {maxAmountError}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-16 sm:flex-col sm:gap-4">
                        <div className="pt-4 w-full">
                          <div className="w-full flex justify-between text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                            <p>Payment Time Limit</p>
                          </div>
                          <div className="bg-[#1A1A1B] px-4 my-4 sm:px-1 mb-2 rounded-lg flex items-center h-[60px] sm:h-[40px] lg:min-h-[60px] border border-[#3C403F]">
                            <select
                              id="time"
                              value={limit}
                              onChange={handleChange}
                              className="bg-[transparent] text-[18px] sm:text-[14px] text-[#AEB2B1] font-arabicmed w-full outline-none"
                            >
                              <option value={15}>15 minutes</option>
                              <option value={30}>30 minutes</option>
                              <option value={60}>1 hour</option>
                            </select>
                          </div>
                        </div>

                        <div className="pt-4 w-full">
                          <div className="w-full flex justify-between text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                            <p>Payment Method</p>
                            <button
                              className="text-[18px] font-arabicmed text-[#6FCF17]  hover:text-[#a6e66b] "
                              onClick={paymentMethodModal}
                            >
                              Add
                            </button>
                          </div>

                          <div className="flex pt-4 justify-end ">
                            <MultiSelect
                              placeholder="Payment method"
                              onChange={handleOnchange}
                              options={newOptions}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full flex  gap-4 mt-10 ">
                        <button
                          className="bg-[#252525] w-full rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:bg-[#363636]"
                          onClick={handlePrevious}
                        >
                          Previous
                        </button>

                        <button
                          className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:from-[#82e402] hover:to-[#146626]"
                          onClick={handleSubmit}
                        >
                          Post Now
                        </button>
                        {isPayMethod && (
                          <div className="modal w-full min-h-20">
                            <div className=" modal-content font-arabicmed rounded-lg overflow-hidden w-full">
                              <div className="w-full flex items-center border-b-[1px] border-[#3C403F] justify-between bg-[#2E2E2E] py-[12px] px-6">
                                <h1 className="text-[20px]">
                                  Add New Payment Method
                                </h1>
                                <div className="flex items-center justify-center rounded-lg bg-[#252525] border-[1px] border-[#818584] h-[26px] w-[26px] p-2">
                                  <span className="close" onClick={closeModal}>
                                    &times;
                                  </span>
                                </div>
                              </div>
                              <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6 ">
                                <div className="w-full flex-col items-center justify-between font-arabicreg py-4 my-2 text-[16px]">
                                  <div>
                                    {stepPayment === 2 && (
                                      <>
                                        <div id="divPaymentForm">
                                          {adsType == "for_sell" ? (
                                            <>
                                              <select
                                                id="slctPaymentForm"
                                                onChange={handleSelectForm}
                                                className="bg-[transparent] text-[18px] sm:text-[14px] text-[#AEB2B1] font-arabicmed w-full px-3 rounded-lg flex items-center h-[60px] sm:h-[40px] lg:min-h-[60px] border border-[#3C403F]"
                                              >
                                                <option
                                                  value=""
                                                  disabled
                                                  selected
                                                >
                                                  Select a payment method
                                                </option>
                                                {newDetailsOptions.map(
                                                  (option) => (
                                                    <option
                                                      value={option.value}
                                                    >
                                                      {option.label}
                                                    </option>
                                                  )
                                                )}
                                              </select>

                                              <div id="forms" ref={formsDivRef}>
                                                {renderedComponents}
                                              </div>

                                              <button
                                                className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C]  py-2 w-full mt-10 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:from-[#82e402] hover:to-[#146626] flex justify-center gap-2 items-center"
                                                // onClick={handlePaymentSubmit}
                                                onClick={
                                                  handleSubmitPaymentDetails
                                                }
                                              >
                                                Create
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              <div className="flex-col justify-between gap-4 my-4">
                                                <div className="w-full pb-4">
                                                  <h1 className="text-white font-arabicmed py-2">
                                                    Payment Method Name
                                                  </h1>
                                                  <input
                                                    type="text"
                                                    className="h-[48px] px-4 w-full rounded-lg bg-[#111010] border border-[#3C403F] text-[14px] text-white font-arabicmed"
                                                    placeholder="Name"
                                                    id="Title"
                                                  // onChange={handleInputChange}
                                                  // onChange={(event) =>
                                                  //   setPaymentMethodName((prevNames) => [...prevNames, event.target.value])
                                                  // }
                                                  // ref={nameInputRef}
                                                  />
                                                  {formErrors.length > 0 &&
                                                    formErrors[0] && (
                                                      <div
                                                        style={{ color: "red" }}
                                                      >
                                                        {formErrors[0]}
                                                      </div>
                                                    )}
                                                </div>
                                                <div
                                                  className="w-full pb-4"
                                                  id="paymentFields"
                                                >
                                                  <h1 className="text-white font-arabicmed ">
                                                    Required Information
                                                  </h1>
                                                  <div className="flex-col justify-between gap-4 my-2">
                                                    {formInputs.map(
                                                      (input, index) => (
                                                        <div className="w-full flex-col mb-4">
                                                          <div
                                                            className="w-full flex"
                                                            key={input.id}
                                                          >
                                                            <input
                                                              type="text"
                                                              className="h-[48px] px-4 my-2 w-full rounded-lg bg-[#111010] border border-[#3C403F] text-[14px] text-white font-arabicmed"
                                                              placeholder="Field name"
                                                              name="title"
                                                              value={
                                                                input.title
                                                              }
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                handleFormInputChange(
                                                                  index,
                                                                  event
                                                                )
                                                              }
                                                            />

                                                            <button
                                                              onClick={() =>
                                                                handleRemoveInput(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              {/* Remove */}
                                                              <img
                                                                src={remove}
                                                                alt=""
                                                                style={{
                                                                  width: "36px",
                                                                  height:
                                                                    "auto",
                                                                  margin:
                                                                    "0 10px ",
                                                                }}
                                                              />
                                                            </button>
                                                          </div>

                                                          {formErrors[
                                                            index
                                                          ] && (
                                                              <div
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              >
                                                                {
                                                                  formErrors[
                                                                  index
                                                                  ]
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      )
                                                    )}
                                                    <div className="w-full flex justify-center py-4">
                                                      <button
                                                        onClick={
                                                          handleAddFormInput
                                                        }
                                                        className="flex text-[18px] font-arabicbold gap-2"
                                                      >
                                                        <img
                                                          src={add}
                                                          alt=""
                                                          style={{
                                                            display: "block",
                                                            margin: "auto",
                                                          }}
                                                        />
                                                        Add
                                                      </button>
                                                    </div>

                                                    {/* <button
                                                    onClick={handleFormSubmit}
                                                  >
                                                    Submit
                                                  </button> */}
                                                  </div>
                                                </div>
                                              </div>
                                              <button
                                                className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C]  py-2 w-full mt-10 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:from-[#82e402] hover:to-[#146626] flex justify-center gap-2 items-center"
                                                // onClick={handlePaymentSubmit}
                                                onClick={handleFormSubmit}
                                              >
                                                Create
                                              </button>
                                            </>
                                          )}

                                          {openNotice && (
                                            <div className="modal w-full">
                                              <div className=" modal-content font-arabicmed rounded-lg overflow-hidden w-full">
                                                <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6">
                                                  <div className="w-full flex flex-col items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                                    <img
                                                      src={notice}
                                                      className="py-4"
                                                    />
                                                    <p className="text-white text-[24px] text-center sm:text-[18px] font-arabicbold">
                                                      Add Payment Method
                                                    </p>
                                                    <p className="text-[#AEB2B1] text-center py-2 font-arabicbold">
                                                      Are you sure you want to
                                                      add this payment method?
                                                    </p>
                                                  </div>

                                                  <div className="w-full flex sm:flex-col items-center py-4 pt-4 my-2 gap-4">
                                                    <button
                                                      className="bg-[#252525] w-full sm:order-2 border-2 border-[#3C403F] rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#363636]"
                                                      onClick={closeModal}
                                                    >
                                                      Cancel
                                                    </button>
                                                    <button
                                                      className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full sm:order-1 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:from-[#82e402] hover:to-[#146626] "
                                                      onClick={
                                                        postPaymentMethod
                                                      }
                                                    >
                                                      Confirm
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    )}

                                    {stepPayment === 6 && (
                                      <>
                                        <div className="bg-[#252525] border border-[#3C403F] p-8 rounded-lg text-center">
                                          <img
                                            src={confetti}
                                            className="mx-auto my-4 w-50 h-50"
                                          />
                                          <h1 className="text-white text-[20px] font-arabicbold">
                                            Payment Method Added Successfully
                                          </h1>
                                          <p className="text-[#AEB2B1] text-[16px] font-arabicreg mb-10">
                                            Thank you for adding your payment
                                            method.{" "}
                                          </p>
                                          <div className="flex justify-between gap-4 my-4">
                                            <button
                                              className="text-white text-[14px] bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-3 w-full"
                                              // onClick={() => navigate("/p2p/post-ad")}
                                              onClick={closeModal}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="text-white text-[14px] bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-lg py-3 w-full"
                                              onClick={handleGoBack}
                                            >
                                              Add New Payment Methods
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>

                                  {openNotice && (
                                    <div className="modal w-full">
                                      <div className=" modal-content font-arabicmed rounded-lg overflow-hidden w-full">
                                        <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6">
                                          <div className="w-full flex flex-col items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                            <img
                                              src={notice}
                                              className="py-4"
                                            />
                                            <p className="text-white text-[24px] text-center sm:text-[18px] font-arabicbold">
                                              Add Payment Method
                                            </p>
                                            <p className="text-[#AEB2B1] text-center py-2 font-arabicbold">
                                              Are you sure you want to add this
                                              payment method?
                                            </p>
                                          </div>

                                          <div className="w-full flex sm:flex-col items-center py-4 pt-4 my-2 gap-4">
                                            <button
                                              className="bg-[#252525] w-full sm:order-2 border-2 border-[#3C403F] rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#363636]"
                                              onClick={closeNotice}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full sm:order-1 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:from-[#82e402] hover:to-[#146626] "
                                              // onClick={postAdvertisement}
                                              // onClick={postPaymentMethod}
                                              onClick={postPaymentMethod}
                                            >
                                              Confirm
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {isOpen && (
                          <div className="modal w-full">
                            <div className=" modal-content font-arabicmed rounded-lg overflow-hidden w-full">
                              <div className="w-full flex items-center border-b-[1px] border-[#3C403F] justify-between bg-[#2E2E2E] py-[12px] px-6">
                                <h1 className="text-[20px]">Confirm to Post</h1>
                                <div className="flex items-center justify-center rounded-lg bg-[#252525] border-[1px] border-[#818584] h-[26px] w-[26px] p-2">
                                  <span className="close" onClick={closeModal}>
                                    &times;
                                  </span>
                                </div>
                              </div>
                              <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6">
                                <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                  <p className="text-[#AEB2B1]">Type</p>
                                  <p>Buy</p>
                                </div>
                                <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                  <p className="text-[#AEB2B1]">Asset</p>
                                  <p>{coin}</p>
                                </div>
                                <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                  <p className="text-[#AEB2B1]">Currency</p>
                                  <p>{selectedCurrency}</p>
                                </div>
                                <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                  <p className="text-[#AEB2B1]">Price Type</p>
                                  <p>{exchangeRateType.toUpperCase()}</p>
                                </div>
                                <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                  <p className="text-[#AEB2B1]">Fixed</p>
                                  <p>
                                    {totalAmount} {coin}
                                  </p>
                                </div>
                                <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                  <p className="text-[#AEB2B1]">Order limit</p>
                                  <p>
                                    {minAmount} - {maxAmount} {coin}
                                  </p>
                                </div>
                                <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                  <p className="text-[#AEB2B1]">
                                    Total Trading Amount
                                  </p>
                                  <p>
                                    {totalAmount} {coin}
                                  </p>
                                </div>
                                <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                  <p className="text-[#AEB2B1]">
                                    Estimated Fee
                                  </p>
                                  <p>2.80 USD</p>
                                </div>
                                <div className="py-3">
                                  <hr
                                    className="border-[#3C403F]"
                                    style={{ borderWidth: "1px" }}
                                  />
                                </div>

                                <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                  <div>
                                    <p className="text-[#AEB2B1]">
                                      Payment Method
                                    </p>
                                  </div>
                                  <div>
                                    {value.map((pm) => (
                                      <>
                                        <span>{pm.name}, </span>
                                      </>
                                    ))}
                                  </div>
                                </div>
                                <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                  <p className="text-[#AEB2B1]">
                                    Payment Time Limit
                                  </p>
                                  <p>{limit} min</p>
                                </div>
                                <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px] ">
                                  <p className="text-[#AEB2B1]">Type</p>
                                  <p>All Regions</p>
                                </div>
                                <div className="w-full flex sm:flex-col items-center py-2 my-2 gap-4">
                                  <button
                                    className="bg-[#252525] w-full sm:order-2 border-2 border-[#3C403F] rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#363636]"
                                    onClick={closeModal}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full sm:order-1 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:from-[#82e402] hover:to-[#146626] "
                                    onClick={postAdvertisement}
                                  >
                                    Confirm to Post
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {stepActive === 3 && (
                    <div className="">
                      <div className="pb-3">
                        <label
                          htmlFor="#"
                          className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                        >
                          Remarks (Optional)
                        </label>
                        <textarea
                          // If you want to handle the value and onChange events, uncomment the following lines
                          // value={comments}
                          // onChange={(e) => setComments(e.target.value)}
                          className="bg-[#111010] border-[1px] border-[#3C403F] h-[100px] w-full rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed p-4 sm:text-[14px]"
                        />
                      </div>
                      <div className="pb-3">
                        <label
                          htmlFor="#"
                          className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                        >
                          Auto Reply (Optional)
                        </label>
                        <textarea
                          // If you want to handle the value and onChange events, uncomment the following lines
                          // value={comments}
                          // onChange={(e) => setComments(e.target.value)}
                          className="bg-[#111010] border-[1px] border-[#3b3d3c] h-[100px] w-full rounded-lg outline-none text-white placeholder:text-[#8f9090] text-[18px] font-arabicmed p-4 sm:text-[14px]"
                          placeholder="Auto reply message will be sent to the counterparty once the order is created"
                        />
                      </div>
                      <div className="pb-3">
                        <label
                          htmlFor="#"
                          className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                        >
                          Select Region(s) Availability
                        </label>
                        <div className="bg-[#1A1A1B] px-4 sm:px-1 mb-2 rounded-lg flex items-center h-[60px] sm:h-[40px] lg:min-h-[60px] border border-[#3C403F]">
                          <select
                            name=""
                            id=""
                            value={flag}
                            onChange={(e) => setFlag(e.target.value)}
                            className="bg-[transparent] w-full text-[18px] sm:text-[14px] text-[#AEB2B1] font-arabicmed cursor-pointer outline-none h-full"
                          >
                            <option value="">All Regions&nbsp;</option>
                          </select>
                        </div>
                      </div>
                      <div className="py-5 flex lg:flex-col gap-5 ">
                        <div className="flex-col w-full">
                          <label
                            htmlFor="#"
                            className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                          >
                            Counterparty conditions
                          </label>
                          <div className="flex sm:flex-col gap-10 mt-4 lg:gap-5">
                            <div className="flex items-center text-white">
                              <input type="checkbox" id="registered" />
                              <label htmlFor="registered" className="ml-2">
                                Registered
                              </label>
                              <div className="bg-[#252525] h-[40px] lg:min-h-[40px] mx-2 flex items-center justify-between rounded-xl">
                                <input
                                  className="text-[18px] text-center w-20 font-arabicmed text-white bg-transparent  outline-none"
                                  placeholder="0"
                                />
                              </div>
                              <div></div>

                              <div>day (s) ago</div>
                            </div>
                            <div className="flex items-center text-white">
                              <input type="checkbox" id="registered" />
                              <label htmlFor="registered" className="ml-2">
                                Holdings more than
                              </label>
                              <div className="bg-[#252525] h-[40px] lg:min-h-[40px] mx-2 flex items-center justify-between rounded-xl">
                                <input
                                  className="text-[18px] text-center w-20 font-arabicmed text-white bg-transparent  outline-none"
                                  placeholder="0.01"
                                />
                              </div>
                              <div>BTC</div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-col w-full">
                          <label
                            htmlFor="#"
                            className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                          >
                            Status
                          </label>
                          <div className="flex gap-2 mt-4 xs:flex-col ">
                            <div className="w-full flex items-center gap-2">
                              <input
                                type="radio"
                                id="option1"
                                name="priceType"
                                value="fixed"
                                className="styled-radio"
                              />
                              <label
                                htmlFor="option1"
                                className="text-[18px] text-[#ACADB1] font-arabicmed"
                              >
                                <span className="text-white">
                                  Online right now
                                </span>
                              </label>
                            </div>

                            <div className="w-full flex items-center gap-2">
                              <input
                                type="radio"
                                id="option1"
                                name="priceType"
                                value="fixed"
                                className="styled-radio"
                              />
                              <label
                                htmlFor="option2"
                                className="text-[18px] text-[#ACADB1] font-arabicmed"
                              >
                                <span className="text-white">
                                  Offline, manually later
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/************ STEP 3 ************/}
                      {/* <div className="w-full flex  gap-4 mt-10 ">
                      <button
                        className="bg-[#252525] w-full rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:bg-[#363636]"
                        onClick={handlePrevious}
                      >
                        Previous
                      </button>
                      <button
                        className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:from-[#82e402] hover:to-[#146626] "
                        onClick={openModal}
                      >
                        Post Now
                      </button>
                      {isOpen && (
                        <div className="modal w-full">
                          <div className=" modal-content font-arabicmed rounded-lg overflow-hidden w-full">
                            <div className="w-full flex items-center border-b-[1px] border-[#3C403F] justify-between bg-[#2E2E2E] py-[12px] px-6">
                              <h1 className="text-[20px]">Confirm to Post</h1>
                              <div className="flex items-center justify-center rounded-lg bg-[#252525] border-[1px] border-[#818584] h-[26px] w-[26px] p-2">
                                <span className="close" onClick={closeModal}>
                                  &times;
                                </span>
                              </div>
                            </div>
                            <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6">
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Type</p>
                                <p>Buy</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Asset</p>
                                <p>USDT</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Currency</p>
                                <p>USD</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Price Type</p>
                                <p>Fixed</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Fixed</p>
                                <p>1.000USD</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Order limit</p>
                                <p>15.000 - 150USD USD</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">
                                  Total Trading Amount
                                </p>
                                <p>1.0000USD</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Estimated Fee</p>
                                <p>2.80 USD</p>
                              </div>
                              <div className="py-3">
                                <hr
                                  className="border-[#3C403F]"
                                  style={{ borderWidth: "1px" }}
                                />
                              </div>

                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Payment Method</p>
                                <p>Bank Transfer</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">
                                  Payment Time Limit
                                </p>
                                <p>15 min</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px] ">
                                <p className="text-[#AEB2B1]">Type</p>
                                <p>All Regions</p>
                              </div>
                              <div className="w-full flex sm:flex-col items-center py-2 my-2 gap-4">
                                <button
                                  className="bg-[#252525] w-full sm:order-2 border-2 border-[#3C403F] rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#363636]"
                                  onClick={closeModal}
                                >
                                  Cancel
                                </button>
                                <button className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full sm:order-1 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:from-[#82e402] hover:to-[#146626] "
                                  onClick={postAdvertisement}
                                >
                                  Confirm to Post
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div> */}
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
          <SidebarArea typeShowAside={typeShowAside} />
        </div>
      </div>
    </>
  );
};
