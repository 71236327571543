import React, { useMemo } from "react";
import grayStar from "../../assets/img/bonus-dashboard/gray-star.png";
import levelUpBorder from "../../assets/img/bonus-dashboard/level-up-border.png";
import star1 from "../../assets/img/bonus-dashboard/star1.png";
import star2 from "../../assets/img/bonus-dashboard/star2.png";
import star3 from "../../assets/img/bonus-dashboard/star3.png";
import star4 from "../../assets/img/bonus-dashboard/star4.png";
import star5 from "../../assets/img/bonus-dashboard/star5.png";
import { getStats } from "../../utils/modules/Stats";

export default function BonusStats({ vipLevelBonus }) {
  const bonusStats = vipLevelBonus?.totalAmount;
  // const bonusStats = 7500;
  const getStatsLevel = useMemo(() => {
    return getStats(Math.floor(bonusStats));
  }, [bonusStats]);
  const isStartingOut = bonusStats < 5000;
  console.log("isStartingOut", isStartingOut);
  console.log("bonusStats", getStatsLevel);
  return (
    <>
      <div className="flex flex-wrap w-full bg-[#1A1A1B] border-[1px] border-[#3C403F] rounded-xl pl-[50px] pr-[50px] pb-[50px] pt-[30px] mt-20">
        <div className="flex-1 w-full flex-wrap relative pb-[60px] pt-[90px] flex px-6 justify-center gap-x-14">
          <img
            src={levelUpBorder}
            alt="Border"
            className="absolute left-0 top-0 w-full h-full"
          />
          <div className="inline-flex flex-col w-[466px] p-[18px] absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-0 bg-[#201E1E] rounded-[10px] sm:w-full sm:mx-auto">
            <h4 className="text-white font-medium text-sm">
              Your VIP Progress
            </h4>
            <div className="flex w-full justify-center">
              {isStartingOut ? (
                <img
                  src={grayStar}
                  alt="Gray Star"
                  className="h-[20px] mb-2.5"
                />
              ) : (
                Array.from({ length: getStatsLevel.vipLevel }, (_, index) => (
                  <img
                    key={index}
                    src={star1}
                    alt={`Star ${index + 1}`}
                    className="h-[20px] mb-2.5"
                  />
                ))
              )}
            </div>

            <div className="flex w-full relative pb-8">
              <div className="flex relative w-full h-5 bg-[#3A3939] rounded-[30px]">
                <div
                  className={`absolute left-0 top-0 h-full bg-gradient-to-b from-[#F8D634] to-[#D08A1A] rounded-[30px]`}
                  style={{ width: `${getStatsLevel.progress}%` }}
                ></div>
              </div>
              <span className="absolute left-0 bottom-0.5 text-[10px] font-normal text-white leading-7">
                Current: {parseFloat(bonusStats ?? 0).toFixed(2)} GP
              </span>
              <div className="absolute right-0 bottom-0.5 inline-flex items-center gap-x-3">
                <span className="text-[10px] font-normal text-white leading-7">
                  Next Level
                </span>
                <div className="inline-flex -mb-1">
                  {isStartingOut ? (
                    <img src={grayStar} alt="1 Star" className="h-5" /> // Show 1 gray star for the first level
                  ) : (
                    Array.from(
                      { length: getStatsLevel.nextLevel },
                      (value) => value
                    ).map((_, index) => {
                      return (
                        <img
                          key={index}
                          src={grayStar}
                          alt="*"
                          className="h-5"
                        />
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
          <h2 className="text-white text-[24px] font-medium mb-8 w-full">
            VIP Level Up Bonus
          </h2>
          <div className="w-[275px] my-10">
            <div className="flex-col relative flex-1 z-20 rounded-[20px]">
              <div className="bg-transparent flex-col w-full rounded-[22px]">
                <div className="relative inline-flex flex-col w-full justify-center py-4 px-3 bg-[#252525] rounded-tl-[22px] rounded-tr-[22px] border border-[#9435E2]">
                  <img
                    src={star1}
                    alt="Star 1"
                    className="absolute left-1/2 -translate-x-1/2 bottom-full -mb-6"
                  />
                  <h4 className="font-bold text-[28px] text-white text-center">
                    5,000 - 10,000
                  </h4>
                  <p className="font-bold text-[12px] text-white text-center">
                    Required Game Points (GP)
                  </p>
                </div>
                <div className="relative inline-flex flex-col w-full justify-center pt-5 pb-10 px-3 bg-[rgba(40,100,151,0.27)] rounded-bl-[22px] rounded-br-[22px]">
                  <h4 className="font-bold text-[25px] text-white text-center">
                    Bonus
                  </h4>
                  <p className="font-bold text-[35px] text-white text-center">
                    up to $100
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[275px] my-10">
            <div className="flex-col relative flex-1 z-20 rounded-[20px]">
              <div className="bg-transparent flex-col w-full rounded-[22px]">
                <div className="relative inline-flex flex-col w-full justify-center py-4 px-3 bg-[#252525] rounded-tl-[22px] rounded-tr-[22px] border border-[#9435E2]">
                  <img
                    src={star2}
                    alt="Star 1"
                    className="absolute left-1/2 -translate-x-1/2 bottom-full -mb-6"
                  />
                  <h4 className="font-bold text-[28px] text-white text-center">
                    10,001 - 30,000
                  </h4>
                  <p className="font-bold text-[12px] text-white text-center">
                    Required Game Points (GP)
                  </p>
                </div>
                <div className="relative inline-flex flex-col w-full justify-center pt-5 pb-10 px-3 bg-[rgba(40,100,151,0.27)] rounded-bl-[22px] rounded-br-[22px]">
                  <h4 className="font-bold text-[25px] text-white text-center">
                    Bonus
                  </h4>
                  <p className="font-bold text-[35px] text-white text-center">
                    up to $900
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[275px] my-10">
            <div className="flex-col relative flex-1 z-20 rounded-[20px]">
              <div className="bg-transparent flex-col w-full rounded-[22px]">
                <div className="relative inline-flex flex-col w-full justify-center py-4 px-3 bg-[#252525] rounded-tl-[22px] rounded-tr-[22px] border border-[#9435E2]">
                  <img
                    src={star3}
                    alt="Star 1"
                    className="absolute left-1/2 -translate-x-1/2 bottom-full -mb-6"
                  />
                  <h4 className="font-bold text-[28px] text-white text-center">
                    30,001 - 50,000
                  </h4>
                  <p className="font-bold text-[12px] text-white text-center">
                    Required Game Points (GP)
                  </p>
                </div>
                <div className="relative inline-flex flex-col w-full justify-center pt-5 pb-10 px-3 bg-[rgba(40,100,151,0.27)] rounded-bl-[22px] rounded-br-[22px]">
                  <h4 className="font-bold text-[25px] text-white text-center">
                    Bonus
                  </h4>
                  <p className="font-bold text-[35px] text-white text-center">
                    up to $2,500
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[275px] my-10">
            <div className="flex-col relative flex-1 z-20 rounded-[20px]">
              <div className="bg-transparent flex-col w-full rounded-[22px]">
                <div className="relative inline-flex flex-col w-full justify-center py-4 px-3 bg-[#252525] rounded-tl-[22px] rounded-tr-[22px] border border-[#9435E2]">
                  <img
                    src={star4}
                    alt="Star 1"
                    className="absolute left-1/2 -translate-x-1/2 bottom-full -mb-6"
                  />
                  <h4 className="font-bold text-[28px] text-white text-center">
                    50,001 - 70,000
                  </h4>
                  <p className="font-bold text-[12px] text-white text-center">
                    Required Game Points (GP)
                  </p>
                </div>
                <div className="relative inline-flex flex-col w-full justify-center pt-5 pb-10 px-3 bg-[rgba(40,100,151,0.27)] rounded-bl-[22px] rounded-br-[22px]">
                  <h4 className="font-bold text-[25px] text-white text-center">
                    Bonus
                  </h4>
                  <p className="font-bold text-[35px] text-white text-center">
                    up to $4,900
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[275px] my-10">
            <div className="flex-col relative flex-1 z-20 rounded-[20px]">
              <div className="bg-transparent flex-col w-full rounded-[22px]">
                <div className="relative inline-flex flex-col w-full justify-center py-4 px-3 bg-[#252525] rounded-tl-[22px] rounded-tr-[22px] border border-[#9435E2]">
                  <img
                    src={star5}
                    alt="Star 1"
                    className="absolute left-1/2 -translate-x-1/2 bottom-full -mb-6"
                  />
                  <h4 className="font-bold text-[28px] text-white text-center">
                    70,001 - 100k up
                  </h4>
                  <p className="font-bold text-[12px] text-white text-center">
                    Required Game Points (GP)
                  </p>
                </div>
                <div className="relative inline-flex flex-col w-full justify-center pt-5 pb-10 px-3 bg-[rgba(40,100,151,0.27)] rounded-bl-[22px] rounded-br-[22px]">
                  <h4 className="font-bold text-[25px] text-white text-center">
                    Bonus
                  </h4>
                  <p className="font-bold text-[35px] text-white text-center">
                    up to $9,000
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full text-center text-white font-bold text-[25px]">
            Note: Wager 1GC = 1GP ; Sport Wager 1GC = 3GP
          </div>
        </div>
      </div>
    </>
  );
}
