import { useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

export const useGetPackages = () => useQuery("packages", async () => {
  try {
    const res = await privateRequest.get('user/stacking/getPackages')
    return res.data?.packages ?? []
  } catch (error) {
    console.log(error)
    throw new Error(error?.response?.data?.message ?? "Error getting packages")
  }
});
export const useGetDailyGrr = (status = '', txid = '') => useQuery(
  ["dailyGrrData", { status, txid }],
  () => privateRequest.get(`user/stacking/stakingReward?status=${status}&transactionReference=${txid}`),
  {
    keepPreviousData: true, 
  }
);
