import React from "react";
import "../../../src/assets/css/settings/index.css";
import { useGetPackages } from "../../queries/stake";
import ButtonLoader from "../ButtonLoader";
import { StakerPriceCard } from "./StakerPriceCard";

export const BecomeStaker = () => {
  const { data, isLoading } = useGetPackages();
  return (
    <div className="bg-[#1A1A1B] bg-[url('assets/img/staks-package-bg.jpg')] px-10 py-12 sm:px-5 rounded-xl border-[1px] border-[#3C403F]">
      <h1 className="text-center text-white text-[58px] font-arabiceg sm:text-[40px]">
        Become a   {" "}
        <span className="glow-text">
          <span
            //           className="text-#9BE402 bg-gradient-to-br from-[#9BE402] to-[#1FA83C]
            //  font-arabicbold sm:block"
            className="text-[#9BE402] font-medium"
          >

            Staker
          </span>
        </span>
      </h1>
      <p className="w-[693px] text-[#AEB2B1] text-[20px] font-arabicreg mx-auto text-center md:!w-[100%] sm:text-[15px]">
        BetWin Staking earns you rewards based on our operations like online
        gaming, casinos, and more!
      </p>

      {isLoading ? <>
        <div className="mt-5">
          <ButtonLoader />
        </div>
      </> : <>
        <div
          className="cards-wrapper mt-[60px] grid grid-cols-3 gap-[30px] md:!grid-cols-1
 xl:grid-cols-2 "
        >
          {data?.map((EachPrice) => (
            <StakerPriceCard data={EachPrice} />
          ))}
        </div>

      </>}

    </div>
  );
};
