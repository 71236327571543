import React, { useRef } from "react";
import "../assets/css/wallet/index.css";

// images
import nft_1 from "../assets/img/nft/nft_1.jpg";
import nft_2 from "../assets/img/nft/nft_2.jpg";
import close from "../assets/img/settings/svg/close.svg";
import plus from "../assets/img/wallet/plus.svg";

// components
import { useNavigate } from "react-router-dom";
import { WalletOptions } from "./common/WalletOptions";

export const NFTWalletBinanceLeft = () => {
  const ref = useRef(null);
  const navigate = useNavigate();

  // const ResizeWork = (e) => {
  //   let Element = window.innerWidth;
  //   ref.current.style.width = `${Element - 600}px`;
  // };
  // useEffect(() => {
  //   ResizeWork();
  //   window.addEventListener("resize", ResizeWork);
  // }, []);

  return (
    <div className="body-left-area  mr-6 w-full">
    {/* <div className="body-left-area  mr-6" ref={ref}> */}
      <div className="settings-area  rounded-lg">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Wallet</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>

        <div className="settings-content-area flex items-start relative">
          <div className="settings-options-area flex flex-col p-3">
            <WalletOptions active={7} />
          </div>

          <div className="wallet-content-body-area py-6 px-6 flex-1">
            <div className="popup DepositePopUp rounded-xl">
              <div className="buttons-wrapper pb-6 flex items-center  mb-6 ">
                <div className="box-total pr-6">
                  <h1>Total :</h1>
                  <p>$0.00</p>
                </div>
                <div className="box-total pl-6">
                  <h1>NFT :</h1>
                  <p>$0.00</p>
                </div>
              </div>

              <div className="option-three flex justify-center gap-6 lg:flex-col">
                <div className="upload-box rounded-lg py-1 px-1 flex flex-col items-center justify-between sm:mx-auto">
                  <img className="w-full h-full object-cover" src={nft_1} alt="" />
                </div>
                <div className="upload-box rounded-lg py-1 px-1 flex flex-col items-center justify-between sm:mx-auto">
                  <img className="w-full h-full object-cover" src={nft_2} alt="" />
                </div>
                <div className="upload-box rounded-lg py-6 px-3 flex flex-col items-center justify-between sm:mx-auto">
                  <img src={plus} alt="" />
                  <button className="w-full h-8 rounded-lg">
                    Deposit your first Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
