import React from 'react';
import Select from 'react-select';

const RegionSelect = ({ region, regions, setRegion }) => {
  const regionOptions = [
    { value: 'all', label: 'All Regions' },
    ...(regions?.data?.map(region => ({
      value: region._id,
      label: region.country
    })) || [])
  ];

  const handleRegionChange = (selectedOption) => {
    setRegion(selectedOption.value);
  };

  return (
    <Select
      value={regionOptions.find(option => option.value === region)}
      onChange={handleRegionChange}
      options={regionOptions}
      placeholder="Select Region"
      styles={customStyles}
    />
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#1A1A1B',
    borderColor: '#3c403f',
    color: 'white',
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: '8px',
    width: '160px'
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#111010',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#343536' : '#111010',
    color: state.isSelected ? 'white' : 'white',
    ':hover': {
      ...provided[':hover'],
      backgroundColor: '#2c2c2c',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  input: (provided) => ({
    ...provided,
    color: 'white',
  }),
};

export default RegionSelect;
