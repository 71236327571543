import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swap from "../assets/img/swap/swap.svg";
import swapTCx from "../assets/img/swap/swap_TC.pdf";
import loader from "../assets/img/swap/loader.svg";
import { HeaderStaker } from "./HeaderStaker";
import { SidebarArea } from "./SidebarArea";
import { PayDropdown } from "./popups/PayDropdown";
import { Coin } from "../utils/staking/Coin";

export const RightAreaSwapCrypto = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(true);
  const [typeShowAside, settypeShowAside] = useState(-1);

  return (
    <div className="flex-1">
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex items-start px-6 pt-6">
        <div className="body-left-area w-full">
          <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
            <div className="flex items-center  px-6 h-[91px] border-[#3C403F] border-b-[1px] lg:flex-col">
              <h1 className="text-white text-[24px] text-center sm:text-[18px]">
                Swap
              </h1>

              <div className="bg-[#252525] ml-6 border-[1px] border-[#3C403F] h-[48px]   flex items-center  justify-between rounded-xl w-[285px]  sm:!h-10  ">
                <button
                  className={`cursor-pointer h-full rounded-xl text-[14px] font-arabicmed bg-gradient-to-br from-[#9BE402] to-[#1FA83C] !text-[#FFF]
                flex-1 sm:!rounded-lg`}
                  onClick={(e) => {
                    navigate("/swap/crypto");
                  }}
                >
                  Crypto To Crypto
                </button>
                <button
                  className={`text-[#B7B8BC] cursor-pointer h-full rounded-xl text-[14px] font-arabicmed  flex-1 sm:!text-[16px] sm:!rounded-lg xs:!text-[14px]`}
                  onClick={(e) => {
                    navigate("/swap/fiat");
                  }}
                >
                  Crypto To Fiat
                </button>
              </div>

              <span className="flex-1"></span>

              <Link
                target="_blank"
                to={swapTCx}
                className="text-[18px] font-arabicmed text-[#6FCF17] sm:text-[12px] hover:text-[#a6e66b]"
              >
                Terms and Conditions
              </Link>
            </div>

            <div className="p-6">
              <>
                <div className="flex mb-3 items-center justify-between">
                  <h1 className="text-[18px] text-[#AEB2B1] font-arabicmed">
                    You get Approximately
                  </h1>
                  <p className="text-[18px] text-[#AEB2B1] font-arabicmed">
                    Min: $10
                  </p>
                </div>
                {active ? (
                  <div className="h-[80px] sm:h-[60px] rounded-lg  bg-[#252525] px-6 flex items-center sm:px-4">
                    <p className="text-[24px] sm:text-[18px] text-white font-arabicmed flex-1">
                      0
                    </p>
                    <p className="text-[#6FCF17] text-[16px] font-arabicmed mr-6">
                      MAX
                    </p>
                    <PayDropdown
                      stakeFix
                      dollar={Coin[0].img}
                      heading={Coin[0].heading}
                      list={Coin}
                    />
                  </div>
                ) : (
                  <div className="h-[80px]  rounded-lg bg-[#252525] px-6 flex items-center">
                    <p className="text-[24px] text-white font-arabicmed flex-1">
                      0.0000279
                    </p>

                    <PayDropdown
                      stakeFix
                      dollar={Coin[0].img}
                      heading={Coin[0].heading}
                      list={Coin}
                    />
                  </div>
                )}

                <img
                  src={swap}
                  alt=""
                  onClick={(e) => {
                    setActive(!active);
                  }}
                  className="my-3 mx-auto cursor-pointer"
                />

                {active ? (
                  <div className="h-[80px] mb-6 rounded-lg bg-[#252525] px-6 flex items-center">
                    <p className="text-[24px] text-white font-arabicmed flex-1">
                      0.0000279
                    </p>

                    <PayDropdown
                      stakeFix
                      dollar={Coin[0].img}
                      heading={Coin[0].heading}
                      list={Coin}
                    />
                  </div>
                ) : (
                  <div className="h-[80px] sm:h-[60px]   mb-6 sm:mb-2 rounded-lg  bg-[#252525] px-6 flex items-center sm:px-4">
                    <p className="text-[24px] sm:text-[18px] text-white font-arabicmed flex-1">
                      0
                    </p>
                    <p className="text-[#6FCF17] text-[16px] font-arabicmed mr-6">
                      MAX
                    </p>
                    <PayDropdown
                      stakeFix
                      dollar={Coin[0].img}
                      heading={Coin[0].heading}
                      list={Coin}
                    />
                  </div>
                )}

                <div className="flex mb-6 items-center justify-between">
                  <h1 className="text-[18px] text-[#AEB2B1] font-arabicmed">
                    Balance: <span className="text-white">54.03962 USDT</span>
                  </h1>
                  <p className="text-[18px] text-[#AEB2B1] font-arabicmed">
                    Conversion rate:{" "}
                    <span className="text-white">1.25462 USDT</span>
                  </p>
                </div>

                <div className="h-[80px] rounded-lg  bg-[#252525] px-6 flex items-center lg:flex-col sm:pt-2 sm:h-[100px]">
                  <img src={loader} alt="" />
                  <p className="text-[18px] ml-3 text-[#ACADB1] flex-1 font-arabicmed ">
                    1 USDT = 0.00002794 BTC
                  </p>
                  <p className="text-[18px] text-[#ACADB1] font-arabicmed ">
                    0.00006543 USDT
                  </p>
                </div>

                <div
                  className="h-[120px] mt-2 flex flex-col justify-center
              rounded-lg  bg-[#252525] px-6"
                >
                  <div className="flex items-center justify-between">
                    <p className="text-[18px] ml-3 text-[#ACADB1] flex-1 font-arabicmed ">
                      Estimated Time
                    </p>
                    <p className="text-[18px] text-[#ACADB1] font-arabicmed ">
                      Seconds
                    </p>
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    <p className="text-[18px] ml-3 text-[#ACADB1] flex-1 font-arabicmed ">
                      Swap fee
                    </p>
                    <p className="text-[18px] text-[#ACADB1] font-arabicmed ">
                      <span className="text-white">0.00006543</span> USDT
                    </p>
                  </div>
                </div>
              </>

              <button className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full mt-10 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:from-[#82e402] hover:to-[#146626]">
                Confirm
              </button>
            </div>
          </div>
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};
