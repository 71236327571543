import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetStakingStatistics } from "../queries/balance";
import { HeaderStaker } from "./HeaderStaker";
import { SidebarArea } from "./SidebarArea";
import { CancelPopup } from "./staker/CancelPopup";
import { OverviewTable } from "./staker/OverviewTable";
import { StakePopup } from "./staker/StakePopup";
import { TerminatePopup } from "./staker/TerminatePopup";


export const RightAreaStakeDashboard = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(1);
  const [StakePopupState, setStakePopupState] = useState(false);
  const [terminate, setTerminate] = useState(false);
  const [terminateId, setTerminateId] = useState();
  const [cancel, setCancel] = useState(false);
  const [cancelId, setCancelId] = useState();
  const { data: statistics } = useGetStakingStatistics();
  const [activeGRR, setactiveGRR] = useState('Daily');
  const [activeGGR, setactiveGGR] = useState('Daily');
  const [activeNGR, setactiveNGR] = useState('Daily');
  const [typeShowAside, settypeShowAside] = useState(-1);

  return (
    <div className="flex-1">
      {StakePopupState && (
        <StakePopup setStakePopupState={setStakePopupState} />
      )}
      {terminate && (
        <TerminatePopup
          closePopup={() => {
            setTerminate(false);
            setTerminateId(null);
          }}
          terminateId={terminateId}
        />
      )}

      {cancel && (
        <CancelPopup
          closePopup={() => {
            setCancel(false);
            setCancelId(null);
          }}
          cancelId={cancelId}
        />
      )}



      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
     
     <div className="body-area flex gap-2 staker-dashboard items-start px-6 pt-6">
        <div className="body-left-area w-full">
          {/* <img
            src={banner2}
            className="w-full rounded-lg mb-6"
            alt=""
          /> */}
          <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">

            <div className="flex items-center justify-between px-6 h-[91px] border-[#3C403F] border-b-[1px] sm:px-4 sm:py-4  sm:h-[unset]">
              <h1 className="text-white text-[24px] text-center sm:text-[18px]">
                Stake Portfolio
              </h1>
              <Link
                to="/"
                className="text-[14px] font-arabicmed text-white w-[185px] h-[43px]
                bg-[#2E2E2E] border-[1px] border-[#6FCF17] flex items-center justify-center rounded-lg sm:text-[10px] sm:w-[130px] sm:h-[30px] hover:bg-[#3C403F] "
              >
                Go to main Dashboard
              </Link>
            </div>

          
            <div className="px-6 py-8">
              <div className="border-[1px] pr-10 border-[#3C403F] grid rounded-xl grid-cols-[1fr_0.5fr] lg:grid-cols-1 lg:px-5">
                <div className="flex-1 flex items-center p-4 sm:flex-col">
                  {/* <img src={boxStake} alt="" /> */}
                  <div className="sm:flex sm:flex-col sm:items-center sm:pb-5 sm:min-w-full">
                    <h1 className="text-white text-[24px] md:text-[20px] sm:text-center font-arabicmed sm:text-[18px]">
                      BetWin Staking
                    </h1>
                    <p className="text-[#AEB2B1] text-[18px] sm:text-center md:text-[14px]  mt-4 mb-3 font-arabicreg sm:text-[14px]">
                      Your are currently staking at{" "}
                      <span className="text-[#6FCF17]">BetWin</span> – Your
                      gateway to the most secure and diversified staking in the
                      gaming industry.
                    </p>
                    <button
                      className="text-[#FFF] text-[18px] font-arabicmed bg-gradient-to-br from-[#9BE402] to-[#1FA83C] hover:from-[#82e402] hover:to-[#146626]
                      h-[50px] w-[223px] rounded-lg   md:text-[14px] md:w-[120px] md:h-[40px] sm:text-[12px] sm:min-w-[100%]"
                      onClick={(e) => {
                        navigate("/stake");
                      }}
                    >
                      Stake More
                    </button>
                  </div>
                </div>
                <div className="flex flex-col justify-between py-5 items-end">
                  <div className="flex items-center gap-3">
                    <h1 className="text-white text-[24px] md:text-[20px] sm:text-center font-arabicmed sm:!text-[18px]">
                      Daily GRR
                    </h1>
                    <p className="text-white text-[24px] md:text-[20px] sm:text-center font-arabicmed sm:!text-[18px]">
                      ${statistics?.totalStakingReward?.toFixed(2)}
                    </p>
                  </div>
                  <Link
                    to="/stake/daily-grr"
                    className="text-[#AEB2B1] text-[24px] md:text-[20px] sm:text-center font-arabicmed sm:!text-[18px] text-right hover:text-[#fff]"
                  >
                    View Daily GRR
                  </Link>
                </div>
                
              </div>

              <div className="overflow-auto">
                {active === 1 && (
                  <OverviewTable
                    setTerminate={setTerminate}
                    setTerminateId={setTerminateId}
                    setCancel={setCancel}
                    setCancelId={setCancelId}
                  />
                )}
                {/* {active === 2 && <ActivityTable />}
                {active === 3 && <StakedTable />} */}
              </div>
            </div>
          </div>
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};
