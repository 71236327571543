import React, { useState } from "react";

import arrow from "../../assets/img/popup/arrow-right.svg";
export const DepositeChooseNetwork = ({
  dollar,
  heading = "Select Network",
  list,
  cb,
  classname,
}) => {
  const [active, setActive] = useState(false);

  return (
    <div className="dropdown relative">
      <div
        className="input-notify-wrapper cursor-pointer rounded-lg mt-3 flex items-center px-4"
        onClick={(e) => {
          setActive(!active);
        }}
      >
        <p className="flex-1">{heading?.toUpperCase()}</p>
        <img src={arrow} alt="" className="ml-3" />
      </div>
      {active && (
        <ul className="DepositeCurrencyDropdown w-full absolute z-10">
          {list.map((EachText) => (
            <li
              className="h-10 flex items-center pl-4 cursor-pointer"
              onClick={(e) => {
                setActive(false);
                cb?.(EachText);
              }}
            >
              <p>{EachText?.toUpperCase()}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
