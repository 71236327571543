import React, { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import line from "../../assets/img/p2p/line.svg";
import notice from "../../assets/img/p2p/notice.svg";
import verified from "../../assets/img/p2p/verified.svg";
import { useAuth } from "../../contexts/AuthContext";
import { useGetAllBalance } from "../../queries/balance";
import {
    UseAdvertisementList,
    UseFetchMarketPrice,
    UseP2PCreateOrder,
    fetchCryptoExchangeRate,
    useUserPaymentMethods
} from "../../queries/p2p";
import PaymentDropdown from "./PaymentDropdown";
import { PaymentFormDetails } from "./PaymentFormDetails";
import PaymentMethodRenderer from './PaymentMethodRenderer';

export const AdvertiserBuyTable = ({ pathname, filters, p2pData }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const advertisementList = UseAdvertisementList(filters);
    const [adsType, setAdsType] = useState("to_buy");
    const [selectedCurrency, setSelectedCurrency] = useState("PHP");
    const [paycheck, setPaycheck] = useState(true);
    const [isBuying, setIsBuying] = useState(true);
    const market = UseFetchMarketPrice(filters);
    const [showBoxIndex, setShowBoxIndex] = useState(-1);
    const [payAmount, setPayAmount] = useState(0);
    const [receiveAmount, setReceiveAmount] = useState(0);
    const [adPrice, setAdprice] = useState(0);
    const [adCurrency, setAdCurrency] = useState("");
    const [adcrypto, setAdCrypto] = useState("");
    const [exchangeRate, setExchangeRate] = useState();
    const [validInput, setValidInput] = useState(false);
    const [notEnoughBalance, setNotEnoughBalance] = useState(false);
    const [minAmount, setMinAmount] = useState(0);
    const [maxAmount, setMaxAmount] = useState(0);
    const [exchangeRateType, setExchangeRateType] = useState();
    const [paymentMethod, setPaymentMethod] = useState();
    const [paymentMethodDetails, setPaymentMethodDetails] = useState();
    const [ads, setAds] = useState();
    const [receiver, setReceiver] = useState();
    const [sender, setSender] = useState();
    const [timeWindowInMinute, setTimeWindowInMinute] = useState();
    const [openNotice, setOpenNotice] = useState("");
    const [renderedComponents, setRenderedComponents] = useState([]);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const formsDivRef = useRef(null);
    const [inputs, setInputs] = useState({});
    const userPaymentMethods = useUserPaymentMethods();
    const userPatmentMethodMatch = userPaymentMethods?.data?.data;
    const balances = useGetAllBalance();

    const [market_lowest, setMarketLowest] = useState(
        market?.data?.data?.lowest_price
    );
    const [market_highest, setMarketHighest] = useState(
        market?.data?.data?.highest_price
    );

    const userPaymentMethodNames = [];
    if (Array.isArray(userPatmentMethodMatch)) {
        userPatmentMethodMatch.forEach(item => {
            if (item && item.paymentMethod && item.paymentMethod.name) {
                userPaymentMethodNames.push(item.paymentMethod.name);
            }
        });
    }
    // console.log(userPaymentMethodNames)

    const handleOptionChange = (value) => {
        console.log(userPaymentMethodNames)
        if (value.customFeild) {
            const components = <PaymentFormDetails details={value} />;
            setRenderedComponents(components);
            setShowPaymentForm(true)
        }
        setPaymentMethod(value);
    };

    const handleSetPaymentMethod = (value) => {
        setPaymentMethodDetails(value?.data);
        setPaymentMethod(value?.paymentMethod);
        console.log(value.data)
    };

    const handleMaxAmout = (price, maxLimit) => {
        setPayAmount(price * maxLimit);
    };

    const handleOrderPrice = async (margin, type) => {
        if (type === "float") {
            //coin gecko price
            const { exchangeRate: cryptoExchangeRate } =
                await fetchCryptoExchangeRate({
                    selectedCurrency: adCurrency,
                    selectedCrypto: adcrypto,
                });
            setExchangeRate(cryptoExchangeRate);
            setAdprice((cryptoExchangeRate * margin) / 100);
        }
    };

    const [rowMethodNames, setRowMethodNames] = useState([]);
    const handlePaymentMethodMatch = (rowMethods) => {
        const matchedMethods = [];
        rowMethods.forEach(rowItem => {
            userPatmentMethodMatch.forEach(item => {
                if (item && rowItem.name == item.paymentMethod.name) {
                    matchedMethods.push(item);
                }
            });
        });
        setRowMethodNames(matchedMethods);
    };

    const handleButtonClick = (index) => {
        setShowBoxIndex(index === showBoxIndex ? -1 : index);
    };

    const handleSenderReceiver = (rowUser) => {
        if (pathname === "/p2p/buy") {
            setReceiver(userInfo.userInfo.user);
            setSender(rowUser);
        } else if (pathname === "/p2p/sell") {
            setReceiver(rowUser);
            setSender(userInfo.userInfo.user);
        }
    };

    const handlePaymentDetails = async (modalValue) => {
        setOpenNotice(modalValue);
        const inputs = {};
        const inputElements = formsDivRef?.current?.querySelectorAll('input');
        var methodId = "";

        inputElements?.forEach(input => {
            const id = input.id;
            const value = input.value;
            methodId = input.getAttribute('methodid');
            inputs[id] = value;
        });
        setInputs(prevInputs => ({
            ...prevInputs,
            ...inputs
        }));
        // setPaymentMethodDetails(inputs);
    }
    const handleClick = async () => {
        const data = await CreateOrder();
        if (data?.status === "success") {
            let redirectUrl = "";
            redirectUrl = "/p2p/buy/details";
            if (redirectUrl) {
                const queryParams = new URLSearchParams({ details: data.data._id });
                window.location.href = `${redirectUrl}?${queryParams.toString()}`;
            }
        } else {
            toast.error(data)
        }
    };

    const userInfo = useAuth();
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleClearClick = () => {
        setShowBoxIndex(-1);
        setPayAmount(0);
        setReceiveAmount(0);
        setAdprice(0);
        setAdCurrency("");
        setExchangeRate(0);
        setValidInput(false);
        setMinAmount(0);
        setMaxAmount(0);
    }

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    useEffect(() => {
        setReceiveAmount(parseFloat(payAmount / exchangeRate).toFixed(8));
    }, [payAmount, exchangeRate]);

    useEffect(() => {
        if (receiveAmount < minAmount || receiveAmount > maxAmount) {
            setValidInput(true);
        } else {
            setValidInput(false);
        }
    }, [receiveAmount, minAmount, maxAmount]);

    //Create Order
    const CreateOrder = async () => {
        try {
            const data = await UseP2PCreateOrder({
                fiatAmount: payAmount,
                sender: sender,
                receiver: receiver,
                paymentMethod: paymentMethod,
                paymentDetails: paymentMethodDetails ? paymentMethodDetails : null,
                ads: ads,
                amount: receiveAmount,
                exchangeRate: payAmount,
                timeWindowInMinutes: timeWindowInMinute,
                remarks: "this is remarks",
            });
            return data;
        } catch (error) {
            return error.message;
        }
    };

    const closeModal = () => {
        setOpenNotice(false);
    }

    useEffect(() => {
        const cryptoBalance =
            balances?.data?.syntheticWalletBalance?.[adcrypto]?.available;
        if (receiveAmount < minAmount || receiveAmount > maxAmount) {
            if (cryptoBalance < minAmount) {
                setNotEnoughBalance(true);
            } else {
                setNotEnoughBalance(false);
            }
            setValidInput(true);
        } else {
            setValidInput(false);
        }
    }, [receiveAmount, minAmount, maxAmount]);


    return (
        <>
            <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />
            <div className="border border-[#3C403F] rounded-sm mt-4">
                <table className="w-full">
                    <thead className="border-b border-[#3C403F] bg-[#252525]">
                        <tr className="h-[44px] bg-[#2E2E2E] rounded-sm">
                            <th className="pl-2 text-[#AEB2B1] text-[14px] font-arabicreg text-left">
                                Advertiser
                            </th>
                            <th className="pl-2 text-[#AEB2B1] text-[14px] font-arabicreg text-left">
                                Price&nbsp;{" "}
                                {/* <span className="text-[#6FCF17]">lowest to highest</span> */}
                            </th>
                            <th className="pl-2 text-[#AEB2B1] text-[14px] font-arabicreg text-left">
                                Limit/Available
                            </th>
                            <th className="pl-2 text-[#AEB2B1] text-[14px] font-arabicreg text-left">
                                Payment
                            </th>
                            <th className="pl-2 text-[#AEB2B1] text-[14px] font-arabicreg text-left">
                                Trade
                            </th>
                        </tr>
                    </thead>

                    {p2pData &&
                        p2pData.length > 0 ? (
                        p2pData &&
                        p2pData?.map((row, index) => (
                            <tbody key={index}>
                                <tr>
                                    <td>
                                        <div className="flex items-center gap-3 pl-2 py-2">
                                            {Array.isArray(row?.avatar) && row?.avatar[0] ? (
                                                <img
                                                    src={row.avatar[0]}
                                                    className="mr-2 w-7 h-7 rounded-full"
                                                    alt="User Avatar"
                                                />
                                            ) : (
                                                <Avatar
                                                    round
                                                    color={Avatar.getRandomColor("sitebase", [
                                                        "#6FCF17",
                                                        "#B13E41",
                                                        "#0BAA95",
                                                        "#FFCC00",
                                                    ])}
                                                    size="30"
                                                    name={row?.user?.email}
                                                />
                                            )}
                                            <Link to={`/p2p/profile/${row?.user?._id}`}>
                                                <div className="items-start">
                                                    <h1 className="text-[14px] text-[#fff] font-arabicbold">
                                                        {row.user?.email}
                                                    </h1>
                                                    <h1 className="text-[12px] text-[#AEB2B1]">
                                                        {row?.user?.orderCount} Orders &nbsp;|&nbsp;
                                                        {row.user?.orderCompletionRate}% Completion
                                                    </h1>
                                                </div>
                                            </Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex items-center pl-2 py-2">
                                            <h1 className="text-[14px] text-[#fff] font-arabicbold">
                                                {row.price}&nbsp;
                                                {row?.exchangeRateType === "fixed" ? (
                                                    <span className="font-arabicreg text-[#AEB2B1]">
                                                        {row?.exchangeRate} {row?.fiatCurrency}
                                                    </span>
                                                ) : row?.adsType == "to_buy" ? (
                                                    <span className="font-arabicreg text-[#AEB2B1]">
                                                        {(market_highest *
                                                            row?.priceMarkupPercentage) /
                                                            100}{" "}
                                                        {row?.fiatCurrency}
                                                    </span>
                                                ) : (
                                                    <span className="font-arabicreg text-[#AEB2B1]">
                                                        {(market_lowest *
                                                            row?.priceMarkupPercentage) /
                                                            100}{" "}
                                                        {row?.fiatCurrency}
                                                    </span>
                                                )}
                                            </h1>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex items-center pl-2 py-2">
                                            <div className="items-start">
                                                <h1 className="text-[14px] text-[#fff] font-arabicbold">
                                                    {row.totalSupply} {row?.cryptoCurrency}
                                                </h1>
                                                <h1 className="text-[12px] text-[#AEB2B1]">
                                                    {parseFloat(row?.min).toFixed(5)} -{" "}
                                                    {parseFloat(row?.max).toFixed(5)}
                                                </h1>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex items-center pl-2 py-2">
                                            &nbsp;
                                            <h1 className="text-[13px] text-[#fff] font-arabicmed">
                                                {row.paymentMethods?.map((paymentMethod) => (
                                                    <div className="flex gap-2">
                                                        <img src={line} />
                                                        {paymentMethod.name}
                                                    </div>
                                                ))}
                                            </h1>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex items-center pl-2 py-2">
                                            <button
                                                className={`font-arabicbold text-[#FFF] py-2 px-6 text-[14px] rounded-lg bg-gradient-to-br from-[#9BE402] to-[#1FA83C]`}
                                                onClick={(e) => {
                                                    handleButtonClick(index);
                                                    setExchangeRateType(row?.exchangeRateType);
                                                    handlePaymentMethodMatch(row?.paymentMethods);
                                                    setAdCurrency(row?.fiatCurrency);
                                                    setAdCrypto(row?.cryptoCurrency);
                                                    setPayAmount(0);
                                                    setExchangeRate(row?.exchangeRate);
                                                    setMinAmount(parseFloat(row?.min));
                                                    setMaxAmount(parseFloat(row?.max));
                                                    setAds(row?._id);
                                                    handleSenderReceiver(row?.user);
                                                    setAdprice(row?.exchangeRate);
                                                    handleOrderPrice(
                                                        row?.priceMarkupPercentage,
                                                        row?.exchangeRateType
                                                    );
                                                    setTimeWindowInMinute(
                                                        row?.timeWindowInMinute
                                                    );
                                                }}
                                            >
                                                Buy {" "}
                                                {row?.cryptoCurrency}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                {showBoxIndex === index && (
                                    <tr>
                                        <td colSpan="5">
                                            <div className="flex w-full p-4 bg-[#252525] border border-[#3C403F]">
                                                <div>
                                                    <div className="flex items-center gap-2 mb-4">
                                                        {row?.userAvatar ? (
                                                            <>
                                                                <img
                                                                    src={row.userAvatar}
                                                                    className="mr-2 w-7 h-7 rounded-full"
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Avatar
                                                                    round
                                                                    color={Avatar.getRandomColor("sitebase", [
                                                                        "#6FCF17",
                                                                        "#B13E41",
                                                                        "#0BAA95",
                                                                        "#FFCC00",
                                                                    ])}
                                                                    size="35"
                                                                    name={row?.user?.email}
                                                                />
                                                            </>
                                                        )}
                                                        <div className="items-start">
                                                            <div className="flex gap-1">
                                                                <h1 className="text-[16px] text-[#fff] font-arabicbold">
                                                                    {row.user?.email}
                                                                </h1>
                                                                <img src={verified} />
                                                            </div>
                                                            <h1 className="text-[12px] text-[#AEB2B1]">
                                                                {row?.user?.orderCount} Orders
                                                                &nbsp;|&nbsp;
                                                                {row.user?.orderCompletionRate}%
                                                                Completion
                                                            </h1>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-wrap  gap-3 justify-between mb-4">
                                                        <div>
                                                            <h1 className="text-[16px] text-[#fff] font-arabicbold">
                                                                {row.timeWindowInMinute} mins
                                                            </h1>
                                                            <h1 className="text-[12px] text-[#AEB2B1]">
                                                                Payment Time Limit
                                                            </h1>
                                                        </div>
                                                        <div>
                                                            <h1 className="text-[16px] text-[#fff] font-arabicbold">
                                                                {row.releaseTime ?? 0}
                                                            </h1>
                                                            <h1 className="text-[12px] text-[#AEB2B1]">
                                                                Avg Release Time
                                                            </h1>
                                                        </div>
                                                    </div>

                                                    {row.adsType == "for_sell" && (
                                                        <PaymentDropdown
                                                            userPaymentMethods={
                                                                userPaymentMethods?.data?.data
                                                            }
                                                            options={row?.paymentMethods || []}
                                                            onOptionChange={handleOptionChange}
                                                        />
                                                    )}
                                                    <div className="mb-4 p-4 bg-[#2E2E2E] border border-[#3C403F] rounded-lg">
                                                        <h1 className="text-[16px] text-[#fff] font-arabicmed mb-2">
                                                            Advertiser's Terms (
                                                            <span className="text-[#6FCF17]">
                                                                Please read carefully
                                                            </span>
                                                            )
                                                        </h1>
                                                        <div className="whitespace-pre-line text-white">
                                                            {row?.advertiserTerms}
                                                        </div>
                                                    </div>
                                                    <Link
                                                        to="/p2p/payment-method"
                                                        className="flex justify-center gap-4 items-center text-white text-center  rounded-lg text-[16px] p-2 w-full border-[#403c3c] bg-[#6FCF17]"
                                                    >
                                                        Add new payment method
                                                    </Link>
                                                </div>

                                                <div className="border border-[#3C403F] mx-4"></div>
                                                <div className="w-full">
                                                    <h1 className="text-[16px] text-[#fff] font-arabicmed mb-2">
                                                        Price {adPrice ?? 0} {adCurrency}
                                                    </h1>

                                                    {row?.adsType == "for_sell" ? (
                                                        <>
                                                            <h1 className="text-[#AEB2B1] font-arabicmed text-[16px]">
                                                                Enter {row?.fiatCurrency} Amount
                                                            </h1>
                                                            <div className="h-[60px] sm:h-[60px] rounded-lg  bg-[#1A1A1B] border border-[#3C403F] pl-4 pr-2 flex items-center sm:px-4 mb-2">
                                                                <input
                                                                    type="text"
                                                                    className="text-[16px] sm:text-[16px] text-white font-arabicmed flex-1 bg-transparent focus:outline-none"
                                                                    value={payAmount}
                                                                    onChange={(e) => {
                                                                        setPayAmount(e.target.value);
                                                                    }}
                                                                    placeholder="0"
                                                                />

                                                                <p
                                                                    className="text-[#6FCF17] text-[16px] font-arabicmed mr-6 cursor-pointer"
                                                                    onClick={() =>
                                                                        handleMaxAmout(
                                                                            row?.exchangeRate,
                                                                            row?.max
                                                                        )
                                                                    }
                                                                >
                                                                    {" "}
                                                                    MAX{" "}
                                                                </p>
                                                            </div>

                                                            <h1 className="text-[#AEB2B1] font-arabicmed text-[16px] mt-3">
                                                                You will receive
                                                            </h1>
                                                            <div className="h-[60px] sm:h-[60px] rounded-lg  bg-[#1A1A1B] border border-[#3C403F] pl-4 pr-2 flex items-center sm:px-4 mb-4">
                                                                <div className="w-full">
                                                                    <input
                                                                        type="text"
                                                                        className="text-[16px] sm:text-[16px] text-white font-arabicmed flex-1 bg-transparent focus:outline-none"
                                                                        value={receiveAmount}
                                                                        placeholder="0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {validInput && (
                                                                <>
                                                                    <span className="text-red-400">
                                                                        Order Limits in {adcrypto} :{" "}
                                                                        {parseFloat(row?.min).toFixed(5)} -{" "}
                                                                        {parseFloat(row?.max).toFixed(5)}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h1 className="text-[#AEB2B1] font-arabicmed text-[16px]">
                                                                Enter {row?.cryptoCurrency} Amount
                                                            </h1>
                                                            <div className="h-[60px] sm:h-[60px] rounded-lg  bg-[#1A1A1B] border border-[#3C403F] pl-4 pr-2 flex items-center sm:px-4 mb-2">
                                                                <input
                                                                    type="text"
                                                                    className="text-[16px] sm:text-[16px] text-white font-arabicmed flex-1 bg-transparent focus:outline-none"
                                                                    value={receiveAmount}
                                                                    onChange={(e) => {
                                                                        setReceiveAmount(e.target.value);
                                                                    }}
                                                                    placeholder="0"
                                                                />

                                                                <p
                                                                    className="text-[#6FCF17] text-[16px] font-arabicmed mr-6 cursor-pointer"
                                                                    onClick={() => handleMaxAmout()}
                                                                >
                                                                    {" "}
                                                                    MAX{" "}
                                                                </p>
                                                            </div>
                                                            {notEnoughBalance && (
                                                                <>
                                                                    <span className="text-red-400">
                                                                        Available {adcrypto} balance:{" "}
                                                                        {
                                                                            balances?.data
                                                                                ?.syntheticWalletBalance?.[
                                                                                adcrypto
                                                                            ]?.available
                                                                        }
                                                                    </span>
                                                                </>
                                                            )}

                                                            <h1 className="text-[#AEB2B1] font-arabicmed text-[16px] mt-3">
                                                                You will receive
                                                            </h1>
                                                            <div className="h-[60px] sm:h-[60px] rounded-lg  bg-[#1A1A1B] border border-[#3C403F] pl-4 pr-2 flex items-center sm:px-4 mb-4">
                                                                <div className="w-full">
                                                                    <input
                                                                        type="text"
                                                                        className="text-[16px] sm:text-[16px] text-white font-arabicmed flex-1 bg-transparent focus:outline-none"
                                                                        value={payAmount}
                                                                        placeholder="0"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {validInput && (
                                                                <>
                                                                    <span className="text-red-400">
                                                                        Order Limits in {adcrypto} :{" "}
                                                                        {parseFloat(row?.min).toFixed(5)} -{" "}
                                                                        {parseFloat(row?.max).toFixed(5)}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </>
                                                    )}

                                                    {showPaymentForm && (
                                                        <div id="forms" ref={formsDivRef}>
                                                            {renderedComponents}
                                                        </div>
                                                    )}
                                                    {row?.adsType == "to_buy" && (
                                                        <PaymentMethodRenderer
                                                            rowMethodNames={rowMethodNames}
                                                            onOptionClick={handleSetPaymentMethod}
                                                        />
                                                    )}
                                                    <div className="flex gap-4 mt-4">
                                                        <button
                                                            onClick={handleClearClick}
                                                            className="border border-[#3C403F] bg-[#2E2E2] text-white p-2 rounded-lg w-1/2"
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            className={`text-[#FFF] p-2 text-[14px] rounded-lg w-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C]`}
                                                            // onClick={() => handleClick()}
                                                            onClick={() => handlePaymentDetails(true)}
                                                        >
                                                            Buy {" "}
                                                            {row?.cryptoCurrency}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        ))
                    ) : (
                        <tbody>
                            <tr>
                                <td
                                    colSpan="5"
                                    className="text-center py-4 text-[#AEB2B1] font-arabicreg"
                                >
                                    No data found
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>

                {openNotice && (
                    <div className="modal w-full">
                        <div className="modal-content font-arabicmed rounded-lg overflow-hidden w-full">
                            <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6">
                                <div className="w-full flex flex-col items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                    <img
                                        src={notice}
                                        className="py-4"
                                        style={{ color: "#F6465D" }}
                                    />
                                    <p className="text-white text-[24px] text-center sm:text-[18px] font-arabicbold">
                                        Buy {adcrypto}
                                    </p>
                                    <p className="text-[#AEB2B1] text-center py-2 font-arabicbold">
                                        Are you sure you want to buy this {adcrypto}?
                                    </p>
                                </div>

                                <div className="w-full flex sm:flex-col items-center py-4 pt-4 my-2 gap-4">
                                    <button
                                        className="bg-[#252525] w-full sm:order-2 border-2 border-[#3C403F] rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#363636]"
                                        onClick={closeModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full sm:order-1 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:from-[#82e402] hover:to-[#146626] "
                                        onClick={handleClick}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>


            {/* {p2pData?.length > 0 && (
                <div className="flex pagination-table-buttons items-center justify-center mt-8">
                    <button
                        className="flex items-center mr-2"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <img src={previous} alt="previous button" />

                        <p>Previous</p>
                    </button>
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button
                            key={index}
                            className={`rounded count ${currentPage === index + 1 ? "active" : ""
                                }`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button
                        className="flex active items-center ml-2"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <p>Next</p>
                        <img src={next} alt="next button" />
                    </button>
                </div>
            )} */}
        </>
    );
};
