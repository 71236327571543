import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import close from "../../assets/img/close.svg";
import tick from "../../assets/img/stake/successfull.svg";
import { privateRequest } from "../../config/axios.config";
import ButtonLoader from "../ButtonLoader";
export const CancelPopup = ({ closePopup, cancelId }) => {
  const queryClient = useQueryClient();
  console.log(cancelId);
  const { mutate, isLoading } = useMutation(
    () =>
      privateRequest.post("user/stacking/cancel", {
        subscriptionId: cancelId,
      }),
    {
      onSuccess: (res) => {
        closePopup();
        toast.success(res?.data?.message);
        queryClient.invalidateQueries("stakingStatistics");
      },
      onError: (error) => {
        console.log(error);
        toast.error(error?.response?.data?.message ?? "An error occured");
      },
    }
  );

  return (
    <div className="fixed flex items-center justify-center bg-[#111010B2] z-[99999999999] left-0 h-[100vh] w-full">
      <ToastContainer theme="dark" position="bottom-right" />
      <span
        className="fixed  -z-[10] left-0 h-[100vh] w-full block"
        onClick={closePopup}
      ></span>
      <div
        className="h-[90vh] max-h-[800px] w-[1000px] bg-[#252525] rounded-xl border-[1px]
         border-[#3C403F] md:w-[95%] md:h-[80vh] flex flex-col"
      >
        <div className="px-8 py-6 bg-[#2E2E2E] border-[1px] border-[#2E2E2E] rounded-tl-xl flex items-center justify-between  md:px-4">
          <h1 className="text-[24px] font-arabicmed text-white">
            Confirm Cancel
          </h1>
          <img
            src={close}
            alt=""
            onClick={closePopup}
            className="cursor-pointer"
          />
        </div>
        <div className="py-8 px-8 flex flex-col w-[629px] mx-auto flex-1 items-center justify-center text-center   md:px-4">
          <img src={tick} alt="" className="mb-10" />
          <h1 className="text-[24px] mb-6 font-arabicmed text-white">
            Confirm Cancel
          </h1>
          <h1 className="text-[23px] mb-10 font-arabicmed text-white">
            Are you sure you want to cancel your pending payment? If you cancel
            now
          </h1>
          <p className="text-[20px] mb-10 text-left font-arabicreg text-[#AEB2B1]">
            - Your subscription will not be activated.
            <br />
            - No charges will be applied.
            <br />- Please do not proceed with payment using the provided
            payment instructions for this subscription.
          </p>
          <div className="grid grid-cols-2 w-[100%] gap-6">
            <button
              className="h-[50px] rounded-lg bg-[#252525] text-[18px] font-arabicbold 
            text-[#fff] border-[1px] border-[#3C403F]"
              onClick={closePopup}
            >
              Cancel
            </button>
            <button
              className="h-[50px] rounded-lg bg-gradient-to-br from-[#9BE402] to-[#1FA83C] text-[18px] font-arabicbold 
            text-[#111010]"
              onClick={(e) => {
                mutate();
              }}
            >
              {isLoading ? <ButtonLoader /> : "Proceed"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
