class Shares {
  link;

  constructor() {
    this.link = encodeURI("https://www.betwin789.net/");
  }

  // facebook(e, referralLink) {
  //   e.target.href = `https://www.facebook.com/share.php?u=${
  //     this.link
  //   }&text=${encodeURIComponent(referralLink)}`;
  // }
  facebook(e, referralLink) {
    e.target.href = `https:///www.facebook.com/share.php?u=${encodeURIComponent(
      referralLink
    )}`;
  }
  twitter(e, referralLink) {
    e.target.href = `https://twitter.com/share?&text=${encodeURIComponent(
      referralLink
    )}`;
  }
  // telegram(e, referralLink) {
  //   e.target.href = `https://telegram.me/share/url?url=${
  //     this.link
  //   }&text=${encodeURIComponent(referralLink)}`;
  // }
  telegram(e, referralLink) {
    e.target.href = `https://telegram.me/share/url?url=${encodeURIComponent(
      referralLink
    )}`;
  }
}

const shares = new Shares();

export { shares };
