import React from "react";

import { convertToCurrency, dateFormatter } from "../../utils";
import copyIcon from "../../assets/img/wallet/copy.svg";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
export const TransactionTableStake = ({ transactionDataList }) => {
  return (
    <table className="my-8 w-full lgmed:min-w-[800px]">
      <thead>
        <tr className="h-[56px] bg-[#252525] border-[1px] border-[#3C403F]">
          <th className="pl-4 text-left font-arabicreg text-[18px] text-[#AEB2B1] border-r-[1px] border-r-[#3C403F]">
            TXID
          </th>
          <th className="pl-4 text-left font-arabicreg text-[18px] text-[#AEB2B1] border-r-[1px] border-r-[#3C403F]">
            Timestamp
          </th>

          <th className="text-left pl-4 font-arabicreg text-[18px] text-[#AEB2B1] border-r-[1px] border-r-[#3C403F]">
            Amount
          </th>
          <th className="pr-4 pl-4 text-left font-arabicreg text-[18px] text-[#AEB2B1] border-r-[1px] border-r-[#3C403F]">
            Royalty Bonus Reference
          </th>
          <th className="pr-4 pl-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        {transactionDataList.map((transaction, index) => (
          <tr
            key={index}
            className="h-[56px] bg-[#1A1A1B]
              border-b-[1px] border-b-[#3C403F]
              border-l-[1px] border-l-[#3C403F]
              border-r-[1px] border-r-[#3C403F]"
          >
            <td
              className={`pl-4 pt-3 flex items-center font-arabicreg text-[18px] text-[#fff] border-r-[1px] border-r-[#3C403F]`}
            >
              {transaction.transactionReference.slice(0, 15)}...
              <img
                className="brightness-0 ml-2 invert cursor-pointer"
                onClick={(e) => {
                  copy(transaction.transactionReference);
                  toast("Transaction Reference Copied");
                }}
                src={copyIcon}
                alt=""
              />
            </td>
            <td
              className={`font-arabicreg pl-4 text-[18px] text-[#fff] border-r-[1px] border-r-[#3C403F]`}
            >
              {dateFormatter(transaction.meta.paidDate)}
            </td>

            <td
              className={`font-arabicreg pl-4 capitalize text-[18px] text-[#fff] border-r-[1px] border-r-[#3C403F]`}
            >
              {convertToCurrency(transaction.amount, 2)}
            </td>
            <td
              className={`font-arabicreg pl-4 text-[18px] text-[#fff] border-r-[1px] border-r-[#3C403F]`}
            >
              {transaction?.meta?.stakingSubscriptionId}
            </td>
            <td
              className={`font-arabicreg pl-4 capitalize text-[18px] text-[#fff]`}
            >
              <div className="flex items-center gap-[5px]">
                <span
                  className={`w-2 h-2 rounded-full ${
                    transaction.status === "Completed"
                      ? "bg-[#0DC10D]"
                      : "bg-[#F0AD4E]"
                  } block`}
                ></span>
                {transaction.status}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
