import React from "react";

export const Input = ({ head, placeholder, value, onChange }) => {
  return (
    <div>
      <label htmlFor="" className="text-[18px] font-arabicreg text-[#AEB2B1]">
        {head}
      </label>
      <div className="block h-[50px] w-full px-4 bg-[#111010] rounded-lg mt-3">
        <input
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="h-full w-full border-none outline-none bg-[transparent] text-[18px] font-arabicreg text-[#fff] placeholder:text-white"
        />
      </div>
    </div>
  );
};
