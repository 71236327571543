import React from "react";
import close from "../../assets/img/close.svg";
export const StakePopup = ({ setStakePopupState }) => {
  return (
    <div className="fixed flex items-center justify-center bg-[#111010B2] z-[99999999999] left-0 h-[100vh] w-full">
      <span
        onClick={(e) => {
          setStakePopupState(false);
        }}
        className="fixed  -z-[10] left-0 h-[100vh] w-full block"
      ></span>
      <div
        className="h-[535px] w-[766px] bg-[#252525] rounded-xl border-[1px]
         border-[#3C403F] md:w-[95%] md:h-[80vh]"
      >
        <div className="px-8 py-6 bg-[#2E2E2E] border-[1px] border-[#2E2E2E] rounded-tl-xl flex items-center justify-between  md:px-4">
          <h1 className="text-[24px] font-arabicmed text-white">
            Daily GRR Report
          </h1>
          <img
            src={close}
            alt=""
            onClick={(e) => {
              setStakePopupState(false);
            }}
            className="cursor-pointer"
          />
        </div>
        <div className="py-8 px-8   md:px-4">
          <table className="w-full border-[1px] border-[#3C403F] ">
            <thead>
              <tr className="bg-[#2E2E2E] h-[40px] ">
                <td className="text-[#AEB2B1] border-r-[1px] border-r-[#3C403F] text-[12px] font-arabicbold pl-[10px]">
                  ID
                </td>
                <td className="text-[#AEB2B1] border-r-[1px] border-r-[#3C403F]  text-[12px] pl-[10px] font-arabicbold">
                  Timestamp
                </td>
                <td className="text-[#AEB2B1] border-r-[1px] border-r-[#3C403F]  text-[12px] pl-[10px] font-arabicbold">
                  Type
                </td>
                <td className="text-[#AEB2B1] border-r-[1px] border-r-[#3C403F]  text-[12px] pl-[10px] font-arabicbold">
                  Amount
                </td>
                <td className="text-[#AEB2B1] border-[1px] border-[#3C403F]  text-[12px] pl-[10px] font-arabicbold pr-[10px]">
                  Status
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="h-[40px]">
                <td className="text-[12px] border-[1px] border-[#3C403F] font-arabicbold pl-[10px] text-[#FFFFFF]">
                  112
                </td>
                <td className="text-[12px]   border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  2023-18-11
                </td>
                <td className="text-[12px]  border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  GRR
                </td>
                <td className="text-[12px]  border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  $5
                </td>
                <td className="text-[12px] font-arabicbold pl-[10px] text-[#FFFFFF]">
                  <div className="flex items-center gap-[5px]">
                    <span className="w-2 h-2 rounded-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C] block"></span>
                    Completed
                  </div>
                </td>
              </tr>
              <tr className="h-[40px]">
                <td className="text-[12px] border-[1px] border-[#3C403F] font-arabicbold pl-[10px] text-[#FFFFFF]">
                  112
                </td>
                <td className="text-[12px]   border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  2023-18-11
                </td>
                <td className="text-[12px]  border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  GRR
                </td>
                <td className="text-[12px]  border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  $5
                </td>
                <td className="text-[12px] border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  <div className="flex items-center gap-[5px]">
                    <span className="w-2 h-2 rounded-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C] block"></span>
                    Completed
                  </div>
                </td>
              </tr>
              <tr className="h-[40px]">
                <td className="text-[12px] border-[1px] border-[#3C403F] font-arabicbold pl-[10px] text-[#FFFFFF]">
                  112
                </td>
                <td className="text-[12px]   border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  2023-18-11
                </td>
                <td className="text-[12px]  border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  GRR
                </td>
                <td className="text-[12px]  border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  $5
                </td>
                <td className="text-[12px] border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  <div className="flex items-center gap-[5px]">
                    <span className="w-2 h-2 rounded-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C] block"></span>
                    Completed
                  </div>
                </td>
              </tr>
              <tr className="h-[40px]">
                <td className="text-[12px] border-[1px] border-[#3C403F] font-arabicbold pl-[10px] text-[#FFFFFF]">
                  112
                </td>
                <td className="text-[12px]   border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  2023-18-11
                </td>
                <td className="text-[12px]  border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  GRR
                </td>
                <td className="text-[12px]  border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  $5
                </td>
                <td className="text-[12px] border-[1px] border-[#3C403F]  font-arabicbold pl-[10px] text-[#FFFFFF]">
                  <div className="flex items-center gap-[5px]">
                    <span className="w-2 h-2 rounded-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C] block"></span>
                    Completed
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
