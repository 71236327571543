import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { privateRequest } from "../../config/axios.config";
import { useOrderDetails } from "../../queries/p2p";
import { HeaderStaker } from "../HeaderStaker";
import { TraderDetailBuy } from "./TraderDetailBuy";
import { TraderDetailSell } from "./TraderDetailSell";


export const RightAreaP2PTradeDetails = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [stepActive, setStepActive] = useState(1);
  const [previewImages, setPreviewImages] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [formattedExpireAt, setFormattedExpireAt] = useState("");
  const [formattedCreatedAt, setFormattedCreatedAt] = useState("");
  const [orderStatus, setOrderStatus] = useState(""); // State to track order status
  const [openNotice, setOpenNotice] = useState(false);
  let { orderId } = useParams();
  const result = useOrderDetails(orderId);
  const [socket, setSocket] = useState(null);
  const orderDetails = result?.data?.data;
  const [message, setMessage] = useState("");
  const [adsType, setAdsType] = useState(orderDetails?.advertisement?.adsType);

  const handleConfirmClick = () => {
    setShowModal(true);
  };

  const handleCancelOrderModal = () => {
    setShowCancelModal(false);
    setShowCancelOrderModal(true);
  };

  const handleAppeal = () => {
    setStepActive(2);
    setShowModal(false);
  };

  const handleGoBack = () => {
    setStepActive(1);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const previewImagesArray = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        previewImagesArray.push(reader.result);
        setPreviewImages([...previewImages, reader.result]);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleRemoveImage = (index) => {
    const newPreviewImages = [...previewImages];
    newPreviewImages.splice(index, 1);
    setPreviewImages(newPreviewImages);
  };

  useEffect(() => {
    document.body.style.overflow =
      showCancelModal || showCancelOrderModal || showModal ? "hidden" : "unset";
  }, [showCancelModal || showCancelOrderModal || showModal]);

  useEffect(() => {
    const initialTimeLeft = Math.floor(
      (new Date(orderDetails?.paymentWindowExpireAt) - new Date()) / 1000
    );
    setTimeLeft(initialTimeLeft);

    const formattedExpireDate = new Date(
      orderDetails?.paymentWindowExpireAt
    ).toLocaleString("en-US", { timeZone: "UTC" });
    setFormattedExpireAt(formattedExpireDate);

    const formattedCreateDate = new Date(
      orderDetails?.createdAt
    ).toLocaleString("en-US", { timeZone: "UTC" });
    setFormattedCreatedAt(formattedCreateDate);

    if (new Date(orderDetails?.paymentWindowExpireAt) < new Date()) {
      toast.error("Order time is expired");
      setOrderStatus("Expired");
    } else {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [orderDetails]);

  const formattedTimeLeft = `${Math.floor(timeLeft / 60)}:${(
    "0" +
    (timeLeft % 60)
  ).slice(-2)}`;

  const dropdownOptions = [
    { data: "Please Select", value: "" },
    { data: "Order Execution Errors", value: "appeal1" },
    { data: "Order Rejection", value: "appeal-2" },
    { data: "Market Manipulation", value: "appeal-3" },
    { data: "Technical Glitches", value: "appeal-4" },
    { data: "Fraudulent Activity", value: "appeal-5" },
    { data: "Dispute with Counterparty", value: "appeal-6" },
    { data: "Funds Settlement Issues", value: "appeal-7" },
    { data: "Margin Trading Issues", value: "appeal-8" },
    { data: "Customer Support Response", value: "appeal-9" },
  ];

  const buyerReasons = [
    "I do not want to trade anymore",
    "I do not meet the requirements of the advertiser's trading terms and condition",
    "There is a technical or network error with the payment platform",
    "I have not paid but clicked 'Transferred'",
    "Other reasons",
  ];

  const sellerReasons = [
    "Seller is asking for extra fee",
    "Problem with seller's payment method resulted in unsuccessful payments",
    "Seller cannot release the order due to a network issue. The seller has refunded the amount",
    "No response from the seller",
    "I have not paid the seller/have received the seller's refund",
    "Other reasons",
  ];

  const [appealReason, setAppealReason] = useState("");

  const handleTextareaChange = (event) => {
    const inputValue = event.target.value;
    // Update the state with the new value
    setAppealReason(inputValue);
  };

  const handleMarkAsFiatPaid = async () => {
    try {
      const res = await privateRequest.patch(
        `user/p2p/order/markAsPaid/${orderId}`
      );
      console.log(res.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };


  useEffect(() => {
    setAdsType(orderDetails?.advertisement?.adsType)
  }, [result]);

  return (
    <>
      <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />
      <div className="flex-1">
        <HeaderStaker
          headerType="Staker"
          login={login}
          settypeShowAside={settypeShowAside}
          setsigninState={setsigninState}
          setSignUpState={setSignUpState}
        />

        {adsType == "for_sell" ? (
          <>
            <TraderDetailSell />
          </>
        ) : (
          <>
            <TraderDetailBuy />
          </>
        )}
      </div>
    </>
  );
};
