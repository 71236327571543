import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import check from "../../assets/img/p2p/check.svg";
import deletebtn from "../../assets/img/p2p/delete.svg";
import line from "../../assets/img/p2p/line.svg";
import verified from "../../assets/img/p2p/verified.svg";
import {
  useDeleteAd,
  useMyAdvertisementList
} from "../../queries/p2p";
import { Coin } from "../../utils/staking/Coin";
import { CurrencyCoins } from "../../utils/staking/CurrencyCoins";
import { CurrencyDropdown } from "../popups/CurrencyDropdown";
import { PayDropdown } from "../popups/PayDropdown";

export const MyAdsBuyTable = ({ pathname, filters }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const advertisementList = useMyAdvertisementList();
  const deleteAd = useDeleteAd();
  const p2pData = advertisementList?.data?.data?.docs;
  const filteredP2pData = p2pData?.filter(item => item.adsType === "to_buy");
  const [adsType, setAdsType] = useState("to_buy");
  const [selectedCurrency, setSelectedCurrency] = useState("PHP");
  const [paycheck, setPaycheck] = useState(true);
  const [isBuying, setIsBuying] = useState(true);
  const [showBoxIndex, setShowBoxIndex] = useState(-1);

  useEffect(() => {
  }, [p2pData]);

  const handleButtonClick = (index) => {
    setShowBoxIndex(index === showBoxIndex ? -1 : index);
  };
  const handleClick = () => {
    window.location.href = '/p2p/buy/details';
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteClick = async (id) => {
    const data = await deleteAd.mutateAsync(id);
    toast.success("Ad has been deleted");
  };

  return (
    <>
      <ToastContainer theme="dark" autoClose={3000} />
      <div className="border border-[#3C403F] rounded-sm mt-4">
        <div className="relative sm:overflow-x-scroll">
          <table className="w-full">
            <thead className="border-b border-[#3C403F] bg-[#252525]">
              <tr className="h-[44px] bg-[#2E2E2E] rounded-sm">
                <th className="pl-2 text-[#AEB2B1] text-[14px] font-arabicreg text-left">Coin</th>
                <th className="pl-2 text-[#AEB2B1] text-[14px] font-arabicreg text-left">Price&nbsp; <span className="text-[#6FCF17]">lowest to highest</span></th>
                <th className="pl-2 text-[#AEB2B1] text-[14px] font-arabicreg text-left">Limit/Available</th>
                <th className="pl-2 text-[#AEB2B1] text-[14px] font-arabicreg text-left">Payment</th>
                {/* <th className="pl-2 text-[#AEB2B1] text-[14px] font-arabicreg text-left">Trade</th> */}
                <th className="pl-2 text-[#AEB2B1] text-[14px] font-arabicreg text-left">Action</th>
              </tr>
            </thead>
            {filteredP2pData && filteredP2pData.map((row, index) => (
              <tbody key={index}>
                <tr>
                  <td>
                    <div className="flex items-center pl-2 py-2">
                      <img src={`https://assets.coincap.io/assets/icons/${row.cryptoCurrency.toLowerCase()}@2x.png`} className="mr-2 w-7 h-7 rounded-sm" />
                      <h1 className="text-[14px] text-[#fff] font-arabicbold">{row.cryptoCurrency}</h1>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center pl-2 py-2">
                      <h1 className="text-[14px] text-[#fff] font-arabicbold">
                        {row.exchangeRate}&nbsp;<span className="font-arabicreg text-[#AEB2B1]">{row.fiatCurrency}</span>
                      </h1>
                      {/* {row.exchangeRateType == "fixed" ?
                          <h1 className="text-[14px] text-[#fff] font-arabicbold">
                            {row.exchangeRate}&nbsp;<span className="font-arabicreg text-[#AEB2B1]">{row.fiatCurrency}</span>
                          </h1>
                          :
                          <h1 className="text-[14px] text-[#fff] font-arabicbold">
                            {row.priceMarkupPercentage}&nbsp;<span className="font-arabicreg text-[#AEB2B1]">%</span>
                          </h1>
                        } */}

                    </div>
                  </td>
                  <td>
                    <div className="flex items-center pl-2 py-2">
                      <div className="items-start">
                        <h1 className="text-[14px] text-[#fff] font-arabicbold">{row.totalSupply}</h1>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center pl-2 py-2">
                      <img src={line} />&nbsp;<h1 className="text-[13px] text-[#fff] font-arabicmed">{row.paymentMethods?.map((paymentMethod) => (
                        <>
                          {paymentMethod.name}
                        </>
                      ))}</h1>
                    </div>
                  </td>
                  {/* <td>
                    <div className="flex items-center pl-2 py-2">
                      <button
                        className="font-arabicbold text-[#FFF] py-2 px-6 text-[14px] rounded-lg bg-gradient-to-br from-[#9BE402] to-[#1FA83C]"
                        onClick={() => handleButtonClick(index)}
                      >
                        Buy USDT
                      </button>
                    </div>
                  </td> */}
                  <td>
                    <button
                      onClick={() => handleDeleteClick(row._id)}
                    ><img src={deletebtn} className="w-[25px] h-[25px] ml-4" /></button>
                  </td>
                </tr>
                {showBoxIndex === index && (
                  <tr>
                    <td colSpan="5">
                      <div className="flex w-full p-4 bg-[#252525] border border-[#3C403F]">
                        <div>
                          <div className="flex items-start mb-4">
                            <img src={row?.advertiser} className="mr-2 w-7 h-7 rounded-sm" />
                            <div className="items-start">
                              <div className="flex gap-1">
                                <h1 className="text-[16px] text-[#fff] font-arabicbold">{row.name}</h1>
                                <img src={verified} />
                                {/* <div className="flex gap-1 rounded-lg border border-[#6FCF17] bg-[#3C502E] px-2 py-0.5">
                                  <img src={heart} />
                                  <h1 className="text-[14px] text-[#fff] font-arabicbold">{row.rate}</h1>
                                </div> */}
                              </div>
                              <h1 className="text-[12px] text-[#AEB2B1]">{row.order}&nbsp;|&nbsp;{row.completion}</h1>
                            </div>
                          </div>
                          <div className="flex justify-between mb-4">
                            <div>
                              <h1 className="text-[16px] text-[#fff] font-arabicbold">{row.paymentTime}</h1>
                              <h1 className="text-[12px] text-[#AEB2B1]">Payment Time Limit</h1>
                            </div>
                            <div>
                              <h1 className="text-[16px] text-[#fff] font-arabicbold">{row.releaseTime}</h1>
                              <h1 className="text-[12px] text-[#AEB2B1]">Avg Release Time</h1>
                            </div>
                            <div>
                              <h1 className="text-[16px] text-[#fff] font-arabicbold">{row.available}</h1>
                              <h1 className="text-[12px] text-[#AEB2B1]">Available</h1>
                            </div>
                          </div>
                          <div className="flex items-center mb-4 pl-2 py-2 bg-[#2E2E2E] border border-[#3C403F] rounded-lg">
                            <img src={line} />&nbsp;<h1 className="text-[13px] text-[#fff] font-arabicmed">Wise</h1>
                          </div>
                          <div className="mb-4 p-4 bg-[#2E2E2E] border border-[#3C403F] rounded-lg">
                            <h1 className="text-[16px] text-[#fff] font-arabicmed mb-2">Advertiser's Terms (<span className="text-[#6FCF17]">Please read carefully</span>)</h1>
                            <div className="flex items-start gap-2">
                              <img src={check} />
                              <h1 className="text-[14px] text-[#AEB2B1] font-arabicreg">Please provide bank card statement for 7 days. Funds deposited within 5 days will not be accepted (loans, financial management).</h1>
                            </div>
                            <div className="flex items-start gap-2">
                              <img src={check} />
                              <h1 className="text-[14px] text-[#AEB2B1] font-arabicreg">Screenshot of BetWin contract leverage position (must have assets on BetWin)</h1>
                            </div>
                            <div className="flex items-start gap-2">
                              <img src={check} />
                              <h1 className="text-[14px] text-[#AEB2B1] font-arabicreg">Pay under your real name without any remarks (otherwise it will be returned to the original route and the corresponding handling fee will be deducted)</h1>
                            </div>
                            <div className="flex items-start gap-2">
                              <img src={check} />
                              <h1 className="text-[14px] text-[#AEB2B1] font-arabicreg">This merchant has been registered with the public security. If you find illegal activities such as running scores, fake running water pictures, fund disks, money laundering, etc.</h1>
                            </div>
                          </div>
                        </div>
                        <div className="border border-[#3C403F] mx-4"></div>
                        <div className="w-full">
                          <h1 className="text-[16px] text-[#fff] font-arabicmed mb-2">Price 0.928 EUR</h1>
                          <h1 className="text-[#AEB2B1] font-arabicmed text-[16px]">I want to pay</h1>
                          <div className="h-[60px] sm:h-[60px] rounded-lg  bg-[#1A1A1B] border border-[#3C403F] pl-4 pr-2 flex items-center sm:px-4 mb-4">
                            <p className="text-[16px] sm:text-[16px] text-white font-arabicmed flex-1"> 0 </p>
                            <p className="text-[#6FCF17] text-[16px] font-arabicmed mr-6"> MAX </p>
                            <CurrencyDropdown
                              onCurrencyChange={(currency) => {
                                setSelectedCurrency(currency);
                                setPaycheck(currency === "PHP");
                              }}
                              list={CurrencyCoins}
                            />
                          </div>
                          <h1 className="text-[#AEB2B1] font-arabicmed text-[16px]">I will receive</h1>
                          <div className="h-[60px] sm:h-[60px] rounded-lg  bg-[#1A1A1B] border border-[#3C403F] pl-4 pr-2 flex items-center sm:px-4 mb-4">
                            <p className="text-[16px] sm:text-[16px] text-white font-arabicmed flex-1"> 0 </p>
                            <PayDropdown
                              stakeFix
                              dollar={Coin[0].img}
                              heading={Coin[0].heading}
                              list={Coin}
                            />
                          </div>
                          <div className="flex gap-4 mt-4">
                            <button className="border border-[#3C403F] bg-[#2E2E2] text-white p-2 rounded-lg w-1/2">Cancel</button>
                            <button
                              className="text-[#FFF] p-2 text-[14px] rounded-lg w-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C]"
                              onClick={() => handleClick()}
                            >
                              Buy USDT
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            ))}
          </table>
        </div>
      </div>

      {/* {filteredP2pData && filteredP2pData.length > 0 && (
      <div className="flex pagination-table-buttons items-center justify-center mt-8">
        <button className="flex items-center mr-2" onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}>

          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.77195 2.334C8.81435 2.29046 8.86504 2.25585 8.92103 2.23222C8.97702 2.20859 9.03718 2.19641 9.09795 2.19641C9.15872 2.19641 9.21888 2.20859 9.27487 2.23222C9.33087 2.25585 9.38155 2.29046 9.42395 2.334C9.51087 2.42325 9.55952 2.54292 9.55952 2.6675C9.55952 2.79209 9.51087 2.91175 9.42395 3.001L4.55095 7.999L9.42395 12.998C9.46782 13.0429 9.50241 13.096 9.52574 13.1543C9.54908 13.2126 9.56071 13.2749 9.55997 13.3377C9.55923 13.4004 9.54613 13.4624 9.52142 13.5202C9.4967 13.5779 9.46087 13.6301 9.41595 13.674C9.37317 13.7158 9.32256 13.7488 9.26702 13.771C9.21148 13.7932 9.15211 13.8043 9.09229 13.8035C9.03248 13.8028 8.97339 13.7903 8.91843 13.7667C8.86346 13.7431 8.81368 13.7089 8.77195 13.666L3.58995 8.348C3.49894 8.25463 3.448 8.12939 3.448 7.999C3.448 7.86861 3.49894 7.74337 3.58995 7.65L8.77195 2.334ZM12.772 2.334C12.8143 2.29046 12.865 2.25585 12.921 2.23222C12.977 2.20859 13.0372 2.19641 13.098 2.19641C13.1587 2.19641 13.2189 2.20859 13.2749 2.23222C13.3309 2.25585 13.3816 2.29046 13.424 2.334C13.5109 2.42325 13.5595 2.54292 13.5595 2.6675C13.5595 2.79209 13.5109 2.91175 13.424 3.001L8.55095 7.999L13.424 12.998C13.4678 13.0429 13.5024 13.096 13.5257 13.1543C13.5491 13.2126 13.5607 13.2749 13.56 13.3377C13.5592 13.4004 13.5461 13.4624 13.5214 13.5202C13.4967 13.5779 13.4609 13.6301 13.416 13.674C13.3732 13.7158 13.3226 13.7488 13.267 13.771C13.2115 13.7932 13.1521 13.8043 13.0923 13.8035C13.0325 13.8028 12.9734 13.7903 12.9184 13.7667C12.8635 13.7431 12.8137 13.7089 12.772 13.666L7.58995 8.348C7.49894 8.25463 7.448 8.12939 7.448 7.999C7.448 7.86861 7.49894 7.74337 7.58995 7.65L12.772 2.334Z"
              fill="#959699"
            />
          </svg>

          <p>Previous</p>
        </button>
        {Array.from({ length: totalPages }).map((_, index) => (
          <button
            key={index}
            className={`rounded count ${currentPage === index + 1 ? "active" : ""}`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button
          className="flex active items-center ml-2"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <p>Next</p>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.22805 2.334C8.18565 2.29046 8.13496 2.25585 8.07897 2.23222C8.02298 2.20859 7.96282 2.19641 7.90205 2.19641C7.84128 2.19641 7.78112 2.20859 7.72513 2.23222C7.66913 2.25585 7.61845 2.29046 7.57605 2.334C7.48913 2.42325 7.44048 2.54292 7.44048 2.6675C7.44048 2.79209 7.48913 2.91175 7.57605 3.001L12.4491 7.999L7.57605 12.998C7.53218 13.0429 7.49759 13.096 7.47426 13.1543C7.45092 13.2126 7.43929 13.2749 7.44003 13.3377C7.44077 13.4004 7.45387 13.4624 7.47858 13.5202C7.5033 13.5779 7.53913 13.6301 7.58405 13.674C7.62683 13.7158 7.67744 13.7488 7.73298 13.771C7.78852 13.7932 7.84789 13.8043 7.90771 13.8035C7.96752 13.8028 8.02661 13.7903 8.08157 13.7667C8.13654 13.7431 8.18632 13.7089 8.22805 13.666L13.4101 8.348C13.5011 8.25463 13.552 8.12939 13.552 7.999C13.552 7.86861 13.5011 7.74337 13.4101 7.65L8.22805 2.334ZM4.22805 2.334C4.18565 2.29046 4.13496 2.25585 4.07897 2.23222C4.02298 2.20859 3.96282 2.19641 3.90205 2.19641C3.84128 2.19641 3.78112 2.20859 3.72513 2.23222C3.66913 2.25585 3.61844 2.29046 3.57605 2.334C3.48913 2.42325 3.44048 2.54292 3.44048 2.6675C3.44048 2.79209 3.48913 2.91175 3.57605 3.001L8.44905 7.999L3.57605 12.998C3.53218 13.0429 3.49759 13.096 3.47425 13.1543C3.45092 13.2126 3.43929 13.2749 3.44003 13.3377C3.44077 13.4004 3.45387 13.4624 3.47858 13.5202C3.50329 13.5779 3.53913 13.6301 3.58405 13.674C3.62683 13.7158 3.67744 13.7488 3.73298 13.771C3.78852 13.7932 3.84789 13.8043 3.90771 13.8035C3.96752 13.8028 4.02661 13.7903 4.08157 13.7667C4.13654 13.7431 4.18632 13.7089 4.22805 13.666L9.41005 8.348C9.50106 8.25463 9.552 8.12939 9.552 7.999C9.552 7.86861 9.50106 7.74337 9.41005 7.65L4.22805 2.334Z"
              fill="#959699"
            />
          </svg>
        </button>
      </div>
      )} */}
    </>
  );
};
