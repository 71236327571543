import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HeaderStaker } from "./HeaderStaker";
import exportImg from "../assets/img/transaction/export.svg";
import backImg from "../assets/img/transaction/back.svg";
import { Dropdown } from "./transaction/Dropdown";
import { Input } from "./transaction/Input";
import forward from "../assets/img/affiliate/forward.svg";
import previous from "../assets/img/affiliate/previous.svg";
import { TransactionTable } from "./transaction/TransactionTable";
import { Transactions } from "../utils/transaction/transactions";
import { AffiliateHeader } from "./AffiliateHeader";
import { useGetTransactions } from "../queries/transactions";
import { TransactionTableStake } from "./transaction/TransactionTableStake";
import { useGetDailyGrr } from "../queries/stake";
import { ToastContainer } from "react-toastify";
export const TransactionHistoryStakeRightArea = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const [txid, setTxid] = useState("");
  const [status, setStatus] = useState("");

  const dailyGrrData = useGetDailyGrr(status,txid);
  const transactionDataList = dailyGrrData.data?.data?.transactions ?? [];
  console.log("transactionDataList",transactionDataList);
  return (

    <div className="flex-1">
          <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />

      <AffiliateHeader
        headerType="Affiliate"
        login={login}
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex-1  px-6 pt-6">
        <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
          <div className="flex items-center  px-6 h-[91px] border-[#3C403F] border-b-[1px]">
            <h1 className="text-white flex-1 text-[24px]  sm:text-[18px]">
              Daily GRR
            </h1>
            <Link
              to="/stake/dashboard"
              className="text-[14px] font-arabicmed text-white w-[162px] h-[43px]
               bg-[#2E2E2E] border-[1px] border-[#6FCF17] flex items-center justify-center rounded-lg sm:text-[12px] sm:w-[140px] gap-2 hover:bg-[#3C403F]"
            >
              Go to Stake Profile
            </Link>
          </div>

          <div className="px-6 py-8 ">
            <div className="flex items-end gap-10   mb-10 lg:flex-col lg:items-stretch lg:gap-3">
              <div className="max-w-[358px] flex-1 lg:max-w-[100%]">
                <Dropdown
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  head="Status"
                  list={[
                    {
                      data: "All",
                      value: "",
                    },
                    {
                      data: "Processing",
                      value: "processing",
                    },
                    {
                      data: "Paid",
                      value: "confirmed",
                    },
                  ]}
                />
              </div>
              <div className="flex-1">
                <Input
                  value={txid}
                  onChange={(e) => setTxid(e.target.value)}
                  head="TXID"
                  placeholder="Enter your TXID here"
                />
              </div>
              {/* <button
                to="#"
                className="text-[14px] font-arabicmed text-white w-[236px] h-[43px]
               bg-[#2E2E2E] border-[1px] border-[#52E3B0] flex items-center justify-center rounded-lg sm:text-[12px] sm:w-[140px] gap-2 lg:mt-2 lg:w-full"
              >
                <img src={exportImg} alt="" />
                Export Transaction History
              </button> */}
            </div>

            <div className="lg:overflow-auto">
              <TransactionTableStake
                transactionDataList={transactionDataList
                  ?.filter((el) => el.transactionReference?.includes(txid))
                  ?.filter((el) => el.status.includes(status))}
              />
            </div>

            {/* {Transactions.length > 0 && (
              <div className="flex items-center justify-center">
                <button
                  className="flex items-center text-[12px] font-arabicmed 
                  text-[#AEB2B1] gap-1"
                >
                  <img src={previous} alt="" />
                  Previous
                </button>

                <div className="flex items-center mx-2 gap-1">
                  <button className="px-2 py-1 text-[#FFF] text-[10px] font-arabicmed bg-[#2E2E2E] rounded">
                    1
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    2
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    3
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    ...
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    15
                  </button>
                </div>

                <button
                  className="flex items-center text-[12px] font-arabicmed 
                     text-[#fff] gap-1"
                >
                  Next
                  <img src={forward} alt="" />
                </button>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
    
  );
};
