import React, { useState } from "react";
import { useGetTransactions } from "../../queries/transactions";
import { dateFormatter } from "../../utils";
export const Table = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of transactions per page
  const { data: transactionData } = useGetTransactions({
    page: currentPage,
    interval: '',
    date: '',
    flag: '',
    filteredFlag: ['game', 'rollback'].join(','), // Convert array to string
  });

  const transactions = transactionData?.data?.transactions ?? [];
  const totalPages = transactionData?.data?.totalPages ?? 1; // Set default to 1 if totalPages is undefined
  console.log("TABLE LIST COUNT: ", totalPages);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatText = (text) => {
    if (typeof text !== 'string') {
      return '';
    }
    return text
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const [showEllipsis, setShowEllipsis] = useState(false);
  const renderPaginationButtons = () => {
    const buttons = [];
    const startPage = Math.max(1, currentPage - 4);
    const endPage = Math.min(totalPages, currentPage + 5);

    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button key={i} className={`rounded count ${currentPage === i ? 'active' : ''}`} onClick={() => handlePageChange(i)}>
            {i}
          </button>
        );
      }
    } else {
      for (let i = startPage; i <= endPage; i++) {
        buttons.push(
          <button key={i} className={`rounded count ${currentPage === i ? 'active' : ''}`} onClick={() => handlePageChange(i)}>
            {i}
          </button>
        );
      }
      if (currentPage > 5) {
        buttons.unshift(
          <button key="ellipsis-start" className="rounded count ellipsis" onClick={() => handlePageChange(currentPage - 5)}>
            ...
          </button>
        );
      }
      if (currentPage < totalPages - 4) {
        buttons.push(
          <button key="ellipsis-end" className="rounded count ellipsis" onClick={() => handlePageChange(currentPage + 5)}>
            ...
          </button>
        );
      }
    }

    return buttons;
  };


  return (
    <>
      <div className="md:overflow-x-scroll">
        {transactions.length === 0 ? <>
          <table className="my-8 w-full lgmed:min-w-[800px]">
            <thead>
              <tr className="h-[56px] bg-[#252525] border-[1px] border-[#3C403F]">
                <th className="pl-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                  Timestamp
                </th>
                <th className="text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                  Currency
                </th>
                <th className="text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                  Amount
                </th>
                <th className="pr-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                  Type
                </th>
                <th className="pr-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                  Note
                </th>
                <th className="pr-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={6} className="text-center text-white">
                  No data found
                </td>
              </tr>
            </tbody>
          </table>
        </> : <>
          <table className="my-8 w-full lgmed:min-w-[800px]">
            <thead>
              <tr className="h-[56px] bg-[#252525] border-[1px] border-[#3C403F]">
                <th className="pl-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                  Timestamp
                </th>
                <th className="text-left font-arabicreg text-[18px] text-[#AEB2B1]  ">
                  Currency
                </th>
                <th className="text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                  Amount
                </th>
                <th className="pr-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                  Type
                </th>
                <th className="pr-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                  Note
                </th>
                <th className="pr-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {transactions
                //.filter(row => row.flag !== "game") // Filter out rows with flag "GAME"
                .map((row, key) => (
                  <tr
                    key={key}
                    className="h-[56px] bg-[#1A1A1B]
            border-b-[1px] border-b-[#3C403F]
            border-l-[1px] border-l-[#3C403F]
            border-r-[1px] border-r-[#3C403F]
           "
                  >
                    <td className={`pl-4 font-arabicreg text-[18px] text-[#fff] px-3 capitalize`}>
                      {dateFormatter(row.createdAt)}
                    </td>

                    <td className={`font-arabicreg text-[18px] text-[#fff] px-3 uppercase`}>
                      {row.currency?.toLowerCase() === "transfer"
                        ? "Cash Wallet"
                        : row.currency}
                    </td>
                    <td
                      className={`font-arabicreg text-[18px] text-[#fff] px-3 ${row.transactionType === "debit"
                        ? "text-red-500"
                        : "text-green-500"
                        }`}
                    >
                      {(parseFloat(row.amount ?? "0")).toFixed(6)}
                    </td>
                    <td className={`pl-4 font-arabicreg text-[18px] text-[#fff] px-3 capitalize`}>
                      {formatText(row.flag)}
                    </td>
                    <td className={`font-arabicreg text-[18px] text-[#fff] px-3`}>
                      {row.note}
                    </td>
                    <td className={`font-arabicreg capitalize px-2 text-[18px] text-[#fff]`}>
                      {row.status}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>}
      </div>

      <div className="flex pagination-table-buttons items-center justify-center mt-8">
        <button className="flex items-center mr-2" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
          <p>Previous</p>
        </button>
        {renderPaginationButtons()}
        <button
          className={`flex active items-center ml-2 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}>
          <p>Next</p>
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
        </button>
      </div>
    </>
  );
};