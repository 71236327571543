import React, { useState } from "react";
import no_data from "../../assets/img/no_data.png";
import { useGetGCTransactions } from "../../queries/transactions";
import { convertToCurrency, dateFormatter } from "../../utils";

export const TablePlayer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of transactions per page

  const { data: transactionData } = useGetGCTransactions({
    page: currentPage,
    interval: '',
    date: '',
    flag: '',

  });


  const formatText = text => {
    return text
      ?.split('_')
      ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toUpperCase())
      ?.join(' ');
  };


  const transactions = transactionData?.data?.transactions ?? [];
  console.log(transactionData);
  const totalPages = transactionData?.data?.totalPages;

  console.log(totalPages);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [showEllipsis, setShowEllipsis] = useState(false);

  const renderPaginationButtons = () => {
    const buttons = [];
    const startPage = Math.max(1, currentPage - 4);
    const endPage = Math.min(totalPages, currentPage + 5);

    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button key={i} className={`rounded count ${currentPage === i ? 'active' : ''}`} onClick={() => handlePageChange(i)}>
            {i}
          </button>
        );
      }
    } else {
      for (let i = startPage; i <= endPage; i++) {
        buttons.push(
          <button key={i} className={`rounded count ${currentPage === i ? 'active' : ''}`} onClick={() => handlePageChange(i)}>
            {i}
          </button>
        );
      }
      if (currentPage > 5) {
        buttons.unshift(
          <button key="ellipsis-start" className="rounded count ellipsis" onClick={() => handlePageChange(currentPage - 5)}>
            ...
          </button>
        );
      }
      if (currentPage < totalPages - 4) {
        buttons.push(
          <button key="ellipsis-end" className="rounded count ellipsis" onClick={() => handlePageChange(currentPage + 5)}>
            ...
          </button>
        );
      }
    }

    return buttons;
  };


  return (
    <>
      {transactionData && transactionData.data && (
        <>
          {transactions.length === 0 ? (
            <div className="sm:overflow-x-visible">
              <table className="my-2 w-full lgmed:min-w-[800px]">
                <thead>
                  <tr className="h-[56px] bg-[#2E2E2E] rounded-lg">
                    <th className="pl-4 text-left font-arabicreg text-[14px] text-[#AEB2B1]">
                      TxID
                    </th>
                    <th className="pl-4 text-left font-arabicreg text-[14px] text-[#AEB2B1]">
                      Timestamp
                    </th>
                    <th className="pl-4 text-left font-arabicreg text-[14px] text-[#AEB2B1]">
                      Type
                    </th>
                    <th className="pl-4 text-left font-arabicreg text-[14px] text-[#AEB2B1]">
                      Amount
                    </th>

                    <th className="pl-4 text-left font-arabicreg text-[14px] text-[#AEB2B1]">
                      Note
                    </th>
                    <th className="pl-4 text-left font-arabicreg text-[14px] text-[#AEB2B1]">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={6} className="text-center text-white">
                      <div className="games-body  games-body-details py-8 flex flex-col items-center justify-center">
                        <img src={no_data} alt="" />
                        <h1>Oops! There is no data yet!</h1>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="sm:overflow-x-scroll">
              <table className="my-8 w-full lgmed:min-w-[800px]">
                <thead>
                  <tr className="h-[56px] bg-[#2E2E2E] rounded-lg">
                    <th className="pl-4 text-left font-arabicreg text-[14px] text-[#AEB2B1]">
                      TxID
                    </th>
                    <th className="pl-4 text-left font-arabicreg text-[14px] text-[#AEB2B1]">
                      Timestamp
                    </th>
                    <th className="pl-4 text-left font-arabicreg text-[14px] text-[#AEB2B1]">
                      Type
                    </th>
                    <th className="pl-4 text-left font-arabicreg text-[14px] text-[#AEB2B1]">
                      Amount
                    </th>

                    <th className="pl-4 text-left font-arabicreg text-[14px] text-[#AEB2B1]">
                      Note
                    </th>
                    <th className="pl-4 text-left font-arabicreg text-[14px] text-[#AEB2B1]">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {transactions?.map((row, key) => (
                    <tr
                      key={key}
                      className="h-[56px] bg-[#1A1A1B]
                    border-b-[1px] border-b-[#3C403F]
                    border-l-[1px] border-l-[#3C403F]
                    border-r-[1px] border-r-[#3C403F] "
                    >
                      <td className={`pl-4 font-arabicreg text-[18px] text-[#fff] capitalize`}>
                        {row._id}
                      </td>
                      <td className={`pl-4 font-arabicreg text-[18px] text-[#fff] capitalize`}>
                        {dateFormatter(row.createdAt)}
                      </td>
                      <td className={`pl-4 font-arabicreg text-[18px] text-[#fff] capitalize`}>
                        {formatText(row.flag)}
                        {/* {row.flag?.toLowerCase() === "transfer"
                        ? "Cash Wallet"
                        : row.flag} */}
                      </td>
                      <td
                        className={`pl-4 font-arabicreg text-[18px] ${row.transactionType === "debit" ? "text-red-500" : "text-green-500"
                          }`}
                      >
                        {row.transactionType === "debit" ? "-" : "+"}
                        {convertToCurrency(row.amount ?? "0", 2, row.currency)}
                      </td>

                      {/* <td className={`font-arabicreg capitalize text-[18px] text-[#fff]`}>
                      {!row.meta?.transactionType && row.currency === "transfer"
                        ? row.currency
                        : row.meta?.transactionType}
                    </td> */}

                      <td className={`pl-4 font-arabicreg text-[18px] text-[#fff]`}>
                        {row.note}
                      </td>
                      <td className={`pl-4 font-arabicreg capitalize text-[18px] text-[#fff]`}>
                        {row.status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}

      <div className="flex pagination-table-buttons items-center justify-center mt-8">
        <button
          className="flex items-center mr-2"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* Previous SVG path */}
          </svg>
          <p>Previous</p>
        </button>

        {Array.from({ length: totalPages }).map((_, index) => {
          if (
            index === totalPages - 1 ||
            (index >= currentPage - 2 && index <= currentPage + 5) ||
            (index === totalPages - 2 && currentPage <= totalPages - 7)
          ) {
            if (index === totalPages - 2 && currentPage <= totalPages - 7) {
              return <span color="white" key={index}>...</span>;
            } else {
              return (
                <button
                  key={index}
                  className={`rounded count ${currentPage === index + 1 ? "active" : ""}`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              );
            }
          }
          return null;
        })}

        <button
          className={`flex active items-center ml-2 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}>
          <p>Next</p>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* Next SVG path */}
          </svg>
        </button>
      </div>

    </>
  );
};