const vipLevels = [
  { min: 5000, max: 10000, nextLevelPoints: 10001 },
  { min: 10001, max: 30000, nextLevelPoints: 30001 },
  { min: 30001, max: 50000, nextLevelPoints: 50001 },
  { min: 50001, max: 70000, nextLevelPoints: 70001 },
  { min: 70001, max: 100000, nextLevelPoints: null } // no next level after the last one
];
export const getStats = (amount) => {
  // Find the current level based on the amount
  const levelIndex = vipLevels.findIndex(level => amount >= level.min && (amount <= level.max || level.max === undefined));

  // Handle the case where the amount is below the minimum of the first level
  if (levelIndex === -1) {
    return {
      vipLevel: 0,
      progress: (amount / vipLevels[0].min) * 100,
      nextLevel: 1,
      pointsToNextLevel: vipLevels[0].min - amount
    };
  }

  // Get the current level's info
  const levelInfo = vipLevels[levelIndex];
  const vipLevel = levelIndex + 1; // VIP level is index + 1 as it's not zero-based for users
  const nextLevelInfo = vipLevels[levelIndex + 1]; // Get the next level info if exists

  // Calculate the progress within the current level
  const progress = levelInfo.max ? ((amount - levelInfo.min) / (levelInfo.max - levelInfo.min)) * 100 : 100;

  // Calculate the points needed to reach the next level
  // If there's a next level, subtract the current amount from the min of the next level
  const pointsToNextLevel = nextLevelInfo ? nextLevelInfo.min - amount : 0;

  return {
    vipLevel,
    progress: Math.min(progress, 100), // Ensure progress doesn't exceed 100%
    nextLevel: nextLevelInfo ? vipLevel + 1 : null, // If there's a next level
    pointsToNextLevel: Math.max(pointsToNextLevel, 0) // Ensure pointsToNextLevel is not negative
  };
};

