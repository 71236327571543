import { useMutation, useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";


export const UseP2PSaveAds = async (p2prequest) => {
    try {
        const response = await privateRequest.post("/user/p2p/advertisement/post", p2prequest);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(
            error?.response?.data?.error ?? "Error saving advertisement."
        );
    }
};

export const UseP2GetAd = async (p2prequest) => {
    try {
        const url = `user/p2p/advertisement/info/${p2prequest}`;
        const response = await privateRequest.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(
            error?.response?.data?.error ?? "Error getting advertisement."
        );
    }
};

export const usePaymentMethods = () => {
    return useQuery("paymentMethodList", async () => {
        const res = await privateRequest.get(`user/p2p/paymentMethods`);
        return res.data;
    });
};

export const useUserPaymentMethods = () => {
    return useQuery("userPaymentMethodList", async () => {
        try {
            const res = await privateRequest.get(`user/p2p/user-payment-methods`);
            return res.data;
        } catch (error) {
            console.error(error);
            throw new Error(
                error?.response?.data?.error ?? "Error fetching user payment list"
            );
        }
    });
};
export const UseSaveUserPaymentMethodDetails = async (req) => {
    // console.log(req)
    try {
        const response = await privateRequest.post("/user/p2p/save-payment-method-data", req);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(
            error?.response?.data?.error ?? "Error saving payment details."
        );
    }
};
export const fetchCryptoExchangeRate = async (reqData) => {
    try {
        const url = `/user/balance/get-crypto-exchange-rate`;
        const response = await privateRequest.post(url,
            reqData
        );
        // console.log("Exchange Rate : ", response);
        return response.data;
    } catch (error) {
        console.error("Error fetching exchange rate:", error);
    }
};
export const useMyAdvertisementList = () => {
    return useQuery("advertisementList", async () => {
        const res = await privateRequest.get(`user/p2p/advertisement/list`);
        return res.data;
    });
};

export const useDeleteAd = () => {
    return useMutation(async (id) => {
        const url = `user/p2p/advertisement/${id}`;
        const response = await privateRequest.delete(url);
        return response.data;
    });
};

export const UseAdvertisementList = (filters, page) => {
    return useQuery(
        ['advertisementList', filters, page],
        async () => {
            const res = await privateRequest.get('user/p2p/advertisement/publicList', { params: filters, page });
            return res.data;
        },
        {
            enabled: !!filters,
        }
    );
};

export const useMyOrderList = (filters) => {
    return useQuery("orderList", async () => {
        const res = await privateRequest.get(`user/p2p/order/myOrderList`);

        // console.log("Order List data  ; ", filters);
        return res.data;
    });
};

export const useTraderOrderList = (filters, page) => {
    return useQuery(["traderOrderList", filters, page], async () => {
        const res = await privateRequest.get(`user/p2p/order/traderOrderList`, { params: filters, page });
        return res.data;
    });
};


export const UseP2PCreateOrder = async (p2pOrderRequest) => {
    try {
        const response = await privateRequest.post("user/p2p/order/create", p2pOrderRequest);
        return response.data;
    } catch (error) {
        throw new Error(
            error?.response?.data?.error ?? "Error creating order."
        );
    }
};

export const useOrderDetails = (id) => {
    return useQuery("OrderDetails", async () => {
        const res = await privateRequest.get(`user/p2p/order/info/${id}`);
        return res?.data;
    });
};

export const UseP2PSavePaymentMethod = async (paymentMethodRequest) => {
    console.log(paymentMethodRequest)
    try {
        const response = await privateRequest.post("/user/p2p/advertisement/postPaymentMethod", paymentMethodRequest);
        return response.data;
    } catch (error) {
        // console.error(error);
        throw new Error(
            error?.response?.data?.error ?? "Error saving Payment Method."
        );
    }
};

export const UseBLockUser = async (blockUserId) => {
    try {
        const response = await privateRequest.post(`/user/p2p/blocklist/block`, { blockUserId });
        return response.data;
    } catch (error) {
        // console.error(error);
        throw new Error(
            error?.response?.data?.error ?? "Error blocking user."
        );
    }
};

export const UseUnblockUser = async (unblockUserId) => {
    try {
        const response = await privateRequest.post(`/user/p2p/blocklist/unblock`, { unblockUserId });
        return response.data;
    } catch (error) {
        // console.error(error);
        throw new Error(
            error?.response?.data?.error ?? "Error unblocking user."
        );
    }
};
export const UseBlockList = async () => {
    try {
        const response = await privateRequest.get("user/p2p/blocklist/blockedUsersList");
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error(
            error?.response?.data?.error ?? "Error getting block list."
        );
    }
};

export const UseRegionsList = async () => {
    try {
        const response = await privateRequest.get("user/p2p/advertisement/regions-list");
        return response.data;
    } catch (error) {
        console.error("p2p errors", error);
        throw new Error(
            error?.response?.data?.error ?? "Error getting regions list."
        );
    }
};

export const UseFetchMarketPrice = (filters) => {
    return useQuery("marketPrice", async () => {
        const response = await privateRequest.get("user/p2p/advertisement/Marketprice", { params: filters });
        return response.data;
    });
};

export const UseGetRatings = () => {
    return useQuery("ratings", async () => {
        const response = await privateRequest.get("user/p2p/rating/get-reviews");
        return response.data;
    });
}

export const UseGetTraderRatings = (id) => {
    return useQuery("ratings", async () => {
        console.log("ID : ", id);
        const response = await privateRequest.get(`user/p2p/rating/get-trader-reviews/${id}`);
        return response.data;
    });
}