import { LucideAreaChart, LucideBarChart2, LucideLineChart } from 'lucide-react';
import React, { useState } from 'react';
import Chart from "react-apexcharts";
import { useQuery } from 'react-query';
import { privateRequest } from '../../config/axios.config';
import { charts } from "../../utils/modules/Charts";

export default function RewardChart() {
    const [chartType, setChartType] = useState('line');

    const handleChartTypeChange = (newChartType) => {
        setChartType(newChartType);
    };

    // chart api data
    const { data } = useQuery("chartData", async () => {
        const res = await privateRequest.get("user/total-reward-paid");
        return res?.data;
    });

    // Process data for ApexCharts
    const seriesData = [{
        name: 'Total Rewards',
        data: data?.map(item => parseFloat(item?.totalAmount).toFixed(0)),
    }];

    const categories = data?.map(item => `${item?._id?.year}-${item?._id?.month}`);
    const chartOptions = charts.affiliateChart()?.options;

    return (
        <>
            <div className="flex items-center justify-between px-8 sm:!px-5">
                <h1 className="text-[24px] sm:text-[20px]  font-arabicmed text-white">
                    Total Rewards Paid
                </h1>
                <div className="flex items-center gap-3">
                    <button onClick={() => handleChartTypeChange('bar')} className={`${chartType == 'bar' ? 'bg-[#6FCF17] text-white' : 'text-[#6FCF17]'} p-1 rounded-md`}><LucideBarChart2 className='w-6 h-6' /></button>
                    <button onClick={() => handleChartTypeChange('line')} className={`${chartType == 'line' ? 'bg-[#6FCF17] text-white' : 'text-[#6FCF17]'} p-1 rounded-md`}><LucideLineChart className='w-6 h-6' /></button>
                    <button onClick={() => handleChartTypeChange('area')} className={`${chartType == 'area' ? 'bg-[#6FCF17] text-white' : 'text-[#6FCF17]'} p-1 rounded-md`}><LucideAreaChart className='w-6 h-6' /></button>
                </div>
            </div>

            <div className="chart-wrapper pl-3 pr-6 mt-8">
                {data && (
                    <Chart
                        options={chartOptions}
                        series={seriesData}
                        type={chartType} // Ensure this prop is correctly set
                        width="100%"
                        height={190}
                        categories={categories}
                    />
                )}
            </div>
        </>
    );
}
