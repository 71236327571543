import React from 'react';
import Select from 'react-select';
import { getCoinName } from '../../utils';

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#111010',
    borderColor: '#3c403f', 
    color: 'white',
    paddingTop: '8px',
    paddingBottom: '8px',
    borderRadius: '8px'
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#111010',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#343536' : '#111010', 
    color: state.isSelected ? 'white' : '#D1D1D1', 
    ':hover': {
      ...provided[':hover'],
      backgroundColor: '#2c2c2c',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white', 
  }),
};


const formatOptionLabel = ({ value, label, customIcon }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img src={customIcon} alt={label} style={{ marginRight: 10, height: '20px' }} />
    {label}
  </div>
);

const DepositCryptoCurrencyDropdown = ({ onCurrencyChange, list }) => {
  // Prepare options with icons
  const options = list.map(item => ({
    value: item.value,
    label: item.label,
    customIcon: `https://cryptologos.cc/logos/thumbs/${getCoinName(item.label)}.png?v=030`
  }));

  return (
    <Select
      options={options}
      styles={customStyles}
      formatOptionLabel={formatOptionLabel}
      onChange={(selectedOption) => onCurrencyChange(selectedOption.value)}
      className='w-full'
    />
  );
};

export default DepositCryptoCurrencyDropdown;
