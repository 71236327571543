import { useMutation, useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";
export const useGenerateDepositAddress = () => {
  return useMutation(({ currency, network }) => {
    return privateRequest.post('/user/syntheticWallet/deposit-address', { currency, network });
  });
};
export const useGetAllWalletAddresses = () => useQuery("walletAddresses", async () => {
  const response = await privateRequest.get('user/syntheticWallet/get-all-wallet-address');
  return response?.data;
}, {
  // Other React Query configurations, if necessary
});
export const useGetAllWalletBalances = () => useQuery("walletBalances", async () => {
  const response = await privateRequest.get('user/syntheticWallet/balance');
  return response?.data;
}, {
  // Other React Query configurations, if necessary
});
export const useGetWallet = () => useQuery("wallet", async () => {
  try {
    const res = await privateRequest.get('user/wallet')
    return res?.data?.wallets ?? []
  } catch (error) {
    console.log(error)
    throw new Error(error?.response?.data?.message ?? "Error getting wallet")
  }
},
  {
    // Disable refetching on window focus
    refetchOnWindowFocus: false,

    // Disable refetching when the network status changes
    refetchOnReconnect: false,

    // Adjust the stale time as needed, for example, 5 minutes
    staleTime: 5 * 60 * 1000,

    // Disable automatic refetching on mount
    refetchOnMount: false
  });