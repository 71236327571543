import React, { useEffect, useRef, useState } from "react";
import "../assets/css/wallet/index.css";

// images
import close from "../assets/img/settings/svg/close.svg";
import dollar from "../assets/img/popup/dollar.svg";
import tusdt from "../assets/img/popup/t-usdt.svg";
import coin from "../assets/img/wallet/coin.svg";

// components
import { WalletOptions } from "./common/WalletOptions";
import { PayDropdown } from "./popups/PayDropdown";
import { useNavigate } from "react-router-dom";
import { DepositeCurrencyDropdown } from "./popups/DepositeCurrencyDropdown";
import { countries } from "../utils/Countries";

export const BetWinSwapBinanceLeft = () => {
  const ref = useRef(null);
  const [activeState, setActive] = useState(null);
  const navigate = useNavigate();
  const ResizeWork = (e) => {
    let Element = window.innerWidth;
    ref.current.style.width = `${Element - 600}px`;
  };
  useEffect(() => {
    ResizeWork();
    window.addEventListener("resize", ResizeWork);
  }, []);

  return (
    <div className="body-left-area  mr-6" ref={ref}>
      <div className="settings-area  rounded-lg">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Wallet</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>

        <div className="settings-content-area flex items-start relative">
          <div className="settings-options-area flex flex-col p-3">
            <WalletOptions active={5} />
          </div>

          <div className="wallet-content-body-area py-6 px-6 flex-1">
            <div className="popup DepositePopUp rounded-xl">
              <div className="option-three">
                <div className="input-container ">
                  <label htmlFor="">You get Approximately</label>
                  <div className="input-notify-wrapper rounded-lg mt-3 flex items-center px-4">
                    <p className="flex-1">0</p>
                    <div className="flex items-center mr-3 tags-wrapper">
                      <button
                        style={{ height: 34 }}
                        className={`${activeState === 1 && "active"}`}
                        onClick={(e) => {
                          setActive(1);
                        }}
                      >
                        Max
                      </button>
                    </div>
                    <PayDropdown
                      dollar={dollar}
                      heading="USD"
                      list={[
                        {
                          heading: "USD",
                          img: dollar,
                        },
                        {
                          heading: "USDT",
                          img: tusdt,
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="input-container mt-4">
                  <div className="input-notify-wrapper rounded-lg mt-3 flex items-center px-4">
                    <p className="flex-1">0</p>

                    <PayDropdown
                      dollar={tusdt}
                      heading="USDT"
                      list={[
                        {
                          heading: "USD",
                          img: dollar,
                        },
                        {
                          heading: "USDT",
                          img: tusdt,
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-3 mt-6 mb-6">
                  <div className="input-container ">
                    <label
                      htmlFor=""
                      className="!text-[#AEB2B1] !text-[12px] !font-arabicreg"
                    >
                      Full Name
                    </label>
                    <div className="input-notify-wrapper rounded-lg mt-2 flex items-center px-4">
                      <input
                        type="text"
                        className="flex-1 bg-transparent border-0 outline-none"
                        placeholder="John Smith"
                      />
                    </div>
                  </div>
                  <div className="input-container">
                    <label
                      htmlFor=""
                      className="!text-[#AEB2B1] !text-[12px] !font-arabicreg"
                    >
                      Account Number
                    </label>
                    <div className="input-notify-wrapper rounded-lg mt-2 flex items-center px-4">
                      <input
                        type="text"
                        className="flex-1 bg-transparent border-0 outline-none"
                        placeholder="96348480"
                      />
                    </div>
                  </div>
                  <div className="input-container margin_2">
                    <label
                      htmlFor=""
                      className="!text-[#AEB2B1] !text-[12px] !font-arabicreg"
                    >
                      Country
                    </label>

                    <DepositeCurrencyDropdown
                      heading="Select your Country"
                      list={countries}
                    />
                  </div>
                  <div className="input-container margin_2 ">
                    <label
                      htmlFor=""
                      className="!text-[#AEB2B1] !text-[12px] !font-arabicreg"
                    >
                      Payment Method
                    </label>

                    <DepositeCurrencyDropdown
                      heading="Cash"
                      list={[
                        {
                          heading: "Cash",
                        },
                        {
                          heading: "Card",
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="input-container">
                  <div className="details-wrapper mt-3 px-3 py-4 rounded-tl-lg rounded-tr-lg">
                    <div className="detail-row flex items-center ">
                      <img src={coin} alt="" />
                      <p className="ml-2">1 PHP ~ 0.018 USDT </p>
                    </div>
                  </div>
                </div>

                <div className="input-container">
                  <div className="details-wrapper mt-1 px-3 py-6 rounded-lg">
                    <div className="detail-row flex pb-6 items-center justify-between">
                      <h1>Estimated Time</h1>
                      <p>Seconds</p>
                    </div>
                    <div className="detail-row flex items-center justify-between">
                      <h1>Swap fee:</h1>
                      <p>0.0000000000 BTC</p>
                    </div>
                  </div>
                </div>

                <button className="flex outline-btn mt-6 moon-pay-btn items-center justify-center w-full ">
                  Swap Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
