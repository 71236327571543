import React, { useState } from "react";
import timer from "../../assets/img/p2p/clock-timer.svg";
import copy from "../../assets/img/p2p/copyID.svg";
import { useMyOrderList } from "../../queries/p2p";
import { useGetTransactions } from "../../queries/transactions";

export const TraderOrderAllOrders = () => {
  const [status, setStatus] = useState("");
  const [txid, setTxid] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const orderData = useMyOrderList();
  const [filters, setFilters] = useState({
    currency: "",
    date: "",
    type: "",
  });
  const orderList = orderData.data?.data?.order ?? [];
  const handleClick = () => {
    window.location.href = "/p2p/trader-order/details";
  };

  const [activeButton, setActiveButton] = useState(1);
  const handleButtonClick = (active) => {
    setActiveButton(active);
  };
  const { data: transactionData } = useGetTransactions({
    page: currentPage,
    interval: "",
    date: "",
    flag: "",
    filteredFlag: ["game", "rollback"].join(","), // Convert array to string
  });

  const transactions = transactionData?.data?.transactions ?? [];
  const totalPages = transactionData?.data?.totalPages ?? 1;

  const formatText = (text) => {
    return text
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      ?.join(" ");
  };

  const options = [
    { data: "All", value: "" },
    { data: "USDT", value: "USDT" },
    { data: "BTC", value: "BTC" },
    { data: "TUSD", value: "TUSD" },
    { data: "Completed", value: "completed" },
    { data: "BNB", value: "BNB" },
    { data: "ETH", value: "ETH" },
    { data: "DOGE", value: "DOGE" },
    { data: "BUSD", value: "BUSD" },
  ];

  const orderTypes = [
    { data: "All", value: "" },
    { data: "Buy", value: "buy" },
    { data: "Sell", value: "sell" },
  ];

  const dates = [
    { data: "All", value: "" },
    { data: "Daily", value: "daily" },
    { data: "Weekly", value: "weekly" },
    { data: "Monthly", value: "monthly" },
  ];

  return (
    <div>
      <div className="flex justify-start gap-4 mb-10 sm:overflow-x-auto">
        <button
          className={`border ${activeButton === 1
              ? "border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]"
              : "border-[#3C403F] bg-[##111010] text-[#AEB2B1]"
            } p-2 rounded-xl w-24`}
          onClick={() => {
            handleButtonClick(1);
          }}
        >
          All
        </button>
        <button
          className={`border ${activeButton === 2
              ? "border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]"
              : "border-[#3C403F] bg-[##111010] text-[#AEB2B1]"
            } p-2 rounded-xl w-24`}
          onClick={() => {
            handleButtonClick(2);
          }}
        >
          Unpaid
        </button>
        <button
          className={`border ${activeButton === 3
              ? "border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]"
              : "border-[#3C403F] bg-[##111010] text-[#AEB2B1]"
            } p-2 rounded-xl w-24`}
          onClick={() => {
            handleButtonClick(3);
          }}
        >
          Paid
        </button>
        <button
          className={`border ${activeButton === 4
              ? "border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]"
              : "border-[#3C403F] bg-[##111010] text-[#AEB2B1]"
            } p-2 rounded-xl w-24`}
          onClick={() => {
            handleButtonClick(4);
          }}
        >
          Appealed
        </button>
      </div>

      <div className="lgmed:overflow-auto">
        {/* <OrderTransactionTable
          orderList={orderList
            ?.filter((el) => el._id?.includes(txid))
            ?.filter((el) => el.status.includes(status))}
        /> */}
        <table className="my-8 w-full lgmed:min-w-[800px]">
          <thead>
            <tr className="h-[56px] bg-[#252525] border-[1px] border-[#3C403F]">
              <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                Type of Coin
              </th>
              <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                Fiat Amount
              </th>
              <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                Price
              </th>
              <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                Crypto Amount
              </th>
              <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                Counter Party
              </th>
              <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                Status
              </th>
              <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]"></th>
              <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]"></th>
            </tr>
          </thead>
          <tbody>

            {transactions.length === 0 ? (
              <tr>
                <td colSpan="7" className="text-center text-gray-500 py-4">
                  No data available
                </td>
              </tr>
            ) : (
              transactions.map((row, key) => (
                <tr
                  key={key}
                  className="h-[64px] bg-[#1A1A1B] border-b-[1px] border-b-[#3C403F] border-l-[1px] border-l-[#3C403F] border-r-[1px] border-r-[#3C403F] "
                >
                  <td
                    className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                  >
                    {/* {row.txid} */}
                    <div className="flex flex-col">
                      USDT
                      <div className="flex">
                        {" "}
                        <h1 className="flex items-center text-[14px] text-[#11E311] pr-2">
                          Buy
                        </h1>{" "}
                        |
                        <div className="flex text-[14px] text-[#AEB2B1] pl-2 items-center">
                          134123412341512351
                          <img src={copy} alt="copy-text" className="pl-2" />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                  >
                    {/* {row.txid} */}
                    20,000 PHP
                  </td>
                  <td
                    className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                  >
                    {/* {dateFormatter(row.createdAt)} */}
                    12,000 PHP
                  </td>
                  <td
                    className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                  >
                    {/* {formatText(row.flag)} */}
                    200 USDT
                  </td>
                  <td
                    className={
                      "pl-4 font-arabicreg text-[16px] text-[#4FB8FE] cursor-pointer"
                    }
                    onClick={() => handleClick()}
                  >
                    JOHN_TRADERS
                  </td>

                  <td
                    className={`pl-4 font-arabicreg capitalize text-[16px] text-[#fff] flex  mt-4`}
                  >
                    <div className="flex flex-col ">
                      <div className="flex items-center gap-1">
                        <div className="w-2 h-2 rounded-full bg-[#11E311]"></div>
                        {row.status}
                      </div>

                      <div className="flex justify-center items-center my-2">
                        <img src={timer} alt="timer" />
                        <h1 className="flex justify-center items-center text-[14px] text-[#11E311] pl-[4px]">
                          07:43
                        </h1>
                      </div>
                    </div>
                  </td>
                  <td
                    className={`pl-4 font-arabicreg capitalize text-[16px] text-[#fff] mt-8`}
                  >
                    <button onClick={() => handleClick()}>
                      <h1 className="font-arabicreg text-[16px] text-lg text-[#11E311]">
                        Contact
                      </h1>
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
