import React from "react";
import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";
import adslist from "../../assets/img/p2p/adslist.svg";
import adslistGreen from "../../assets/img/p2p/adslistGreen.svg";
import orderlist from "../../assets/img/p2p/orderlist.svg";
import orderlistGreen from "../../assets/img/p2p/orderlistGreen.svg";
import payment from "../../assets/img/p2p/payment.svg";
import paymentGreen from "../../assets/img/p2p/paymentGreen.svg";
import postad from "../../assets/img/p2p/postad.svg";
import postadGreen from "../../assets/img/p2p/postadGreen.svg";


export const P2PNav = ({ activeButton }) => {
  const { pathname } = useLocation();


  return (
    <div className="flex justify-between gap-4 sm:overflow-x-auto">
      <div className="flex gap-4 whitespace-nowrap mt-6 mb-2 mx-6 pt-4">
        <Link
          to="/p2p/buy"
          className={`flex gap-4 items-center text-white text-center font-arabicmed rounded-md text-[14px] py-3 p-6 w-full  border border-[#403c3c] ${activeButton === "buy" || pathname === "/p2p/buy"
            ? "bg-[#6FCF17]"
            : "bg-[#252525]"
            }`}
        >
         
          Buy
        </Link>
        <Link
          to="/p2p/sell"
          className={`flex gap-4 items-center text-white text-center font-arabicmed rounded-md text-[14px] py-3 p-6 w-full  border border-[#403c3c] ${activeButton === "sell" || pathname === "/p2p/sell"
            ? "bg-[#6FCF17]"
            : "bg-[#252525]"
            }`}
        >
          
          Sell
        </Link>
        <Link
          to="/p2p/post-ad"
          className={`flex gap-4 items-center text-white text-center font-arabicmed rounded-md text-[14px] py-3 p-6 w-full  border border-[#403c3c] ${activeButton === "post-ad" || pathname === "/p2p/post-ad"
            ? "bg-[#6FCF17]"
            : "bg-[#252525]"
            }`}
        >
          <img src={activeButton === "post-ad" ? postadGreen : postad} alt="postad" className="w-4 h-4" />
          Post Ad
        </Link>
        {/* <Link
          to="/p2p/my-ads"
          className={`flex gap-4 items-center text-white text-center font-arabicmed rounded-md text-[14px] py-3 p-6 w-full  border border-[#403c3c] ${activeButton === "my-ads" || pathname === "/p2p/my-ads"
            ? "bg-[#6FCF17]"
            : "bg-[#252525]"
            }`}
        >
          <img src={activeButton === "my-ads" ? adslistGreen : adslist} alt="adslist" className="w-4 h-4" />
          My ads
        </Link> */}
        <Link
          to="/p2p/order-history"
          className={`flex gap-4 items-center text-white text-center font-arabicmed rounded-md text-[14px] py-3 p-6 w-full  border border-[#403c3c] ${activeButton === "order-history" || pathname === "/p2p/order-history"
            ? "bg-[#6FCF17]"
            : "bg-[#252525]"
            }`}
        >
          <img src={activeButton === "order-history" ? orderlistGreen : orderlist} alt="orderlist" className="w-4 h-4" />
          Order List
        </Link>

        <Link
          to="/p2p/payment-method"
          className={`flex gap-4 items-center text-white text-center font-arabicmed rounded-md text-[14px] py-3 p-6 w-full border border-[#403c3c] ${activeButton === "payment-method" || pathname === "/p2p/payment-method"
            ? "bg-[#6FCF17]"
            : "bg-[#252525]"
            }`}
        >
          <img src={activeButton === "payment-method" ? paymentGreen : payment} alt="payment" className="w-4 h-4" />
          Payment Methods
        </Link>

        <Link
          to="/p2p/my-ads"
          className={`flex gap-4 items-center text-white text-center font-arabicmed rounded-md text-[14px] py-3 p-6 w-full  border border-[#403c3c] ${activeButton === "my-ads" || pathname === "/p2p/my-ads"
            ? "bg-[#6FCF17]"
            : "bg-[#252525]"
            }`}
        >
          <img src={activeButton === "my-ads" ? adslistGreen : adslist} alt="adslist" className="w-4 h-4" />
          My Profile
        </Link>
      </div>
    </div>
  );
};