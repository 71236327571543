import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { UseRegionsList, usePaymentMethods } from "../../queries/p2p";
import { Coin } from "../../utils/staking/Coin";
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";
import CurrencySelect from "../popups/CurrencySelect";
import { PayDropdown } from "../popups/PayDropdown";
import PaymentSelect from "../popups/PaymentSelect";
import RegionSelect from "../popups/RegionSelect";
import P2PBottom from "./P2PBottom";
import { P2PNav } from "./P2PNav";
import { P2PTable } from './P2PTable';


const FilterSection = ({ currency, currencies, handleCurrencyChange, payments, paymentFilter, setPaymentFilter, region, regions, setRegion, setAmount, amount }) => (
    <div className="flex justify-between sm:flex-col md:overflow-x-auto">
        <div className="bg-[#1A1A1B] pl-4 pr-2 h-[60px] lg:min-h-[60px] sm:w-full flex items-center justify-between rounded-xl border border-[#3C403F] mb-4">
            <input value={amount}
                onChange={(e) => {
                    setAmount(e.target.value)
                }}
                className="text-[18px] font-arabicmed text-[#AEB2B1] bg-transparent w-full flex-1 mr-2 outline-none"
                placeholder="Enter Amount"
            />
            <PayDropdown
                stakeFix
                dollar={Coin[0].img}
                heading={Coin[0].heading}
                list={Coin}
            />
        </div>
        <div className="flex justify-between gap-2 sm:mt-4 sm:mb-2 sm:order-first">
            <CurrencySelect
                currency={currency}
                currencies={currencies}
                onCurrencyChange={handleCurrencyChange}
            />
            <PaymentSelect
                paymentFilter={paymentFilter}
                payments={payments}
                setPaymentFilter={setPaymentFilter}
            />
            <RegionSelect
                region={region}
                regions={regions}
                setRegion={setRegion}
            />
            {/* <div className="bg-[#1A1A1B] px-4 sm:px-1 mb-2 rounded-lg flex items-center h-[60px] sm:h-[40px] lg:min-h-[60px] border border-[#3C403F]">
                <button
                    name=""
                    id=""
                    value={filterRef.current}
                    onClick={() => filterRef.current.click()}
                    className="bg-[transparent] w-[25px] text-[18px] sm:text-[14px] text-[#AEB2B1] font-arabicmed cursor-pointer outline-none h-full"
                >
                    <img src={filter} />
                </button>
            </div> */}
        </div>
    </div>
);

const ButtonGroup = ({ activeButton, handleButtonClick, setFilters }) => {
    const buttons = ["USDT", "USDC", "BTC", "TUSD", "BNB", "ETH", "DOGE", "BUSD"];
    return (
        <div className="flex justify-between gap-4 sm:overflow-x-auto">
            {buttons.map((btn) => (
                <button
                    key={btn}
                    className={`border ${activeButton === btn
                        ? "border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]"
                        : "border-[#3C403F] bg-[##111010] text-[#AEB2B1]"
                        } p-2 rounded-md w-20`}
                    onClick={() => {
                        handleButtonClick(btn);
                        setFilters((prev) => ({
                            ...prev,
                            cryptoCurrency: btn,
                        }));
                    }}
                >
                    {btn}
                </button>
            ))}
        </div>
    );
};

export const RightAreaP2P = ({ setsigninState, setSignUpState, login }) => {
    const location = useLocation();
    const { pathname } = location;

    const [active, setActive] = useState(true);
    const [typeShowAside, settypeShowAside] = useState(-1);
    const [currency, setCurrency] = useState('all');
    const [currencies, setCurrencies] = useState([]);
    const [amount, setAmount] = useState();
    const [region, setRegion] = useState("all");
    const [filters, setFilters] = useState({
        adsType: 'to_buy',
        cryptoCurrency: "USDT",
        amount: amount
    });
    const [activeButton, setActiveButton] = useState("USDT");
    const [paymentFilter, setPaymentFilter] = useState("all");
    const filterRef = useRef();
    const userInfo = useAuth();
    const payments = usePaymentMethods();
    const { data: regions, isLoading } = useQuery("get-regions", UseRegionsList);



    useEffect(() => {
        const fetchRegionsData = async () => {
            const regionsData = await UseRegionsList();
            const currencyCodes = [
                { value: 'all', label: 'All' },
                ...regionsData.data.map((region) => ({
                    value: region.currency_code,
                    label: region.currency_code,
                }))
            ];
            setCurrencies(currencyCodes);
        };
        fetchRegionsData();
        setCurrency({ value: userInfo?.userInfo?.user?.defaultCurrency, label: userInfo?.userInfo?.user?.defaultCurrency });
    }, [userInfo]);

    useEffect(() => {
        setFilters((prev) => ({
            ...prev,
            paymentMethod: paymentFilter,
            region: region,
            amount: amount,
            fiatCurrency: currency?.value,
        }));
    }, [paymentFilter, region, currency, amount]);

    // Update filters based on pathname
    useEffect(() => {
        if (pathname === '/p2p/buy') {
            setActive(true);
            setFilters((prev) => ({
                ...prev,
                adsType: "to_buy",
                fiatCurrency: currency?.value,
                region: region,
            }));
        } else if (pathname === '/p2p/sell') {
            setActive(false);
            setFilters((prev) => ({
                ...prev,
                adsType: "for_sell",
                fiatCurrency: currency?.value,
                region: region,
            }));
        }
    }, [pathname, currency, region]);

    const handleButtonClick = (currency) => {
        setActiveButton(currency);
    };

    const handleCurrencyChange = (selectedOption) => {
        setCurrency(selectedOption);
    };



    return (
        <div className="flex-1">
            <HeaderStaker
                headerType="Staker"
                login={login}
                settypeShowAside={settypeShowAside}
                setsigninState={setsigninState}
                setSignUpState={setSignUpState}
            />
            <P2PNav />
            <div className="body-area flex items-start px-6 pt-6">
                <div className="body-left-area w-full">
                    <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F]">
                        <div className="flex justify-between items-center p-6 border-[#3C403F] border-b-[1px]">
                            <h1 className="text-white text-[26px] text-center"> P2P </h1>
                            <Link
                                to="/p2p/terms-and-conditions"
                                className="text-[20px] font-arabicmed text-[#6FCF17] hover:text-[#a6e66b]"
                            >
                                Terms and Conditions
                            </Link>
                        </div>
                        <div className="p-6">
                            <div className="flex flex-col justify-center mb-4">
                                <h1 className="text-[36px] text-[#fff] text-center flex flex-col">
                                    Buy and Sell With Your Favorite{" "}
                                    <span className="text-[#6FCF17] font-arabicbold">
                                        Payment Methods
                                    </span>
                                </h1>
                                <p className="text-[18px] text-[#AEB2B1] text-center flex flex-col w-1/2 sm:w-full mx-auto mt-6 mb-10">
                                    Using your preferred payment method, you can buy or sell on
                                    P2P using your preferred method of payment!
                                </p>
                            </div>
                            <div className="border border-[#3C403F] rounded-lg p-4 mb-4">
                                {/* <div className="flex justify-between mb-4">
                                    <h1 className="text-[20px] text-white">P2P</h1>
                                </div> */}
                                <div className="flex justify-between sm:flex-col">
                                    <ButtonGroup
                                        activeButton={activeButton}
                                        handleButtonClick={handleButtonClick}
                                        setFilters={setFilters}
                                    />
                                </div>
                            </div>
                            <FilterSection
                                currency={currency}
                                currencies={currencies}
                                handleCurrencyChange={handleCurrencyChange}
                                payments={payments}
                                paymentFilter={paymentFilter}
                                setPaymentFilter={setPaymentFilter}
                                region={region}
                                regions={regions}
                                setRegion={setRegion}
                                setAmount={setAmount}
                                amount={amount}
                            />
                            <P2PTable
                                active={active}
                                filters={filters}
                                handleButtonClick={handleButtonClick}
                                pathname={pathname}
                            />
                        </div>
                        <div className="bg-[#1E1E1E] p-6 my-4">
                            <div className="flex justify-between sm:flex-col">
                                <h1 className="text-[#fff] text-[30px] font-arabicbold text-center sm:mb-4">
                                    HOW P2P WORKS
                                </h1>
                                <div className={`flex justify-between p-1 rounded-md w-full max-w-[200px] sm:max-w-full sm:mb-4 border ${pathname === '/p2p/sell' ? "border-[#F6465D]"
                                    : 'border-[#6FCF17]'}`}>
                                    <Link
                                        to="/p2p/buy"
                                        className={`text-white text-center font-arabicmed rounded-md text-[14px] py-2 w-full ${pathname === '/p2p/buy' && "bg-[#6FCF17]"
                                            }`}
                                    >
                                        Buy Crypto
                                    </Link>
                                    <Link
                                        to="/p2p/sell"
                                        className={`text-white text-center font-arabicmed rounded-md text-[14px] py-2 w-full ${pathname === '/p2p/sell' && "bg-[#F6465D]"}`}
                                    >
                                        Sell Crypto
                                    </Link>
                                </div>
                            </div>
                            <P2PBottom />
                        </div>
                    </div>
                </div>
                <SidebarArea typeShowAside={typeShowAside} />
            </div>
        </div>
    );
};