import React from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Header } from "../../components/admin/Header";
import { Footer } from "../../components/admin/Footer";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import game from "../../assets/img/game1.png";

// utils
import { CheckboxCustom } from "../../components/admin/CheckboxCustom";
import { useNavigate } from "react-router-dom";

export const EditBanner = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-start home admin-dashboard">
        {/* left area */}
        <Aside active="4/6" />

        <div className="flex-1">
          <Header heading="Banners" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Banners</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/admin-dashboard");
                  }}
                >
                  Home
                </span>{" "}
                /
                <span
                  className="mr-1"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/admin/admin/banner");
                  }}
                >
                  Banners
                </span>
                / Edit
              </button>
            </div>

            <div className="agent-wrapper create-agents mt-8  rounded-xl">
              <div className="top-agent  rounded-tl-xl  rounded-tr-xl py-7 px-6 top-agent-page flex items-center justify-between">
                <h1>Edit Banner</h1>
              </div>
              <div className="forms-wrapper px-6 py-6">
                <form action="#" className="grid grid-cols-2 gap-x-3 gap-y-6">
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Name <span>*</span>
                    </label>
                    <div className="input-container  mt-3">
                      <input type="text" placeholder="Enter your name" />
                    </div>
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="">
                      Redirect url <span>*</span>
                    </label>
                    <div className="input-container  mt-3">
                      <input type="text" placeholder="Enter your url" />
                    </div>
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="">
                      Logo <span>*</span>
                    </label>
                    <div className="input-container  mt-3">
                      <input type="text" placeholder="Enter your value" />
                      <input
                        type="file"
                        name=""
                        id="file-logo"
                        className="hidden"
                      />
                      <label
                        htmlFor="file-logo"
                        className="browser-file cursor-pointer flex items-center justify-center rounded"
                      >
                        Browse
                      </label>
                    </div>
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="">
                      Type <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <select name="" id="">
                        <option value="Select your currency">
                          Enter your key
                        </option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <div className="file-display">
                      <h1>View here</h1>
                      <img
                        src={game}
                        className="mt-3 rounded-lg object-cover"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="input-wrapper  flex items-center justify-between col-span-2">
                    <div className="flex items-center">
                      <div className="flex mr-9 items-center">
                        <CheckboxCustom type="checkbox" id="active-agree" />
                        <p className="ml-2 checkbox-para">Enable</p>
                      </div>
                    </div>
                    <div className="flex buttons-wrapper items-center">
                      <button
                        className="h-11 px-4 rounded mr-3"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/admin/admin/banner");
                        }}
                      >
                        Submit
                      </button>
                      <button className="h-11 px-4 rounded outline-button">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
