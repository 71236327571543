import React, { useState, useEffect } from "react";
import btc from "../../assets/img/sports/btc.svg";
import notFound from "../../assets/img/bonus-dashboard/not-found.png"
import {
  fetchSportsBetHighRollers
} from "../../queries/gaming";

export const HighRollersTable= ({ searchTerm, type }) => {
  const currency = "GC";
  const [highRollers, setHighRollers] = useState([]);
  const fetchData = async () => {
    try {
      const { data } = await fetchSportsBetHighRollers(type);
      if (data) {
        setHighRollers(data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [type]);

  const [filteredBets, setFilteredBets] = useState([]);
  
  useEffect(() => {
    const filtered = highRollers.filter((bet) =>
      bet.bet_team.toString().toLowerCase().includes(searchTerm) ||
      bet.user_id.toString().toLowerCase().includes(searchTerm) ||
      bet.bet_odds.toString().toLowerCase().includes(searchTerm) ||
      (`${bet.bet_amount} ${currency}`).toLowerCase().includes(searchTerm) ||
      formatDateString(bet.createdAt).toLowerCase().includes(searchTerm)
    );
    setFilteredBets(filtered);
  }, [highRollers, searchTerm]);
  
  function formatDateString(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    return formattedDate;
  }

  return (
    <>
    <div className="bg-[#1A1A1B] px-6 py-4 mt-4 rounded-lg w-full">
        {filteredBets && filteredBets.length === 0 ? (
          <div>
            <img
                src={notFound}
                alt="Not Found"
                className="mx-auto my-0"
              />
            <h1 className="no-data-available text-center text-white">Oops! There is no data yet!</h1>
          </div>
        ) : (
          <table className="w-full lgmed:min-w-[800px]">
            <thead>
              <tr className="h-[56px] border-b border-[#3A3A3A]">
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Game/Event
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  User
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Timestamp
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Odds
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Bet Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredBets?.map((row, key) => (
                <tr key={key} className="h-[56px]">
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.bet_team}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.user_id}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {formatDateString(row.createdAt)}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.bet_odds}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    <div className="flex">
                    {row.bet_amount} {currency}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
    </>
  );
};