import BAYD from "../../assets/img/e-wallets/BAYD.png";
import CC from "../../assets/img/e-wallets/CC.png";
import CEBL from "../../assets/img/e-wallets/CEBL.png";
import DPAY from "../../assets/img/e-wallets/DPAY.png";
import ECPY from "../../assets/img/e-wallets/ECPY.png";
import GCSH from "../../assets/img/e-wallets/GCSH.png";
import LBC from "../../assets/img/e-wallets/LBC.png";
import PYPL from "../../assets/img/e-wallets/PYPL.png";
import MLH from "../../assets/img/e-wallets/MLH.png";
import SMR from "../../assets/img/e-wallets/SMR.png";
import d711 from "../../assets/img/e-wallets/711.png";
import INPY from "../../assets/img/e-wallets/INPY.png";

export const wallets = [
  { img: BAYD, heading: "BAYD" },
  { img: CC, heading: "CC" },
  { img: CEBL, heading: "CEBL" },
  { img: DPAY, heading: "DPAY" },
  { img: ECPY, heading: "ECPY" },
  { img: GCSH, heading: "GCSH" },
  { img: LBC, heading: "LBC" },
  { img: PYPL, heading: "PYPL" },
  { img: MLH, heading: "MLH" },
  { img: SMR, heading: "SMR" },
  { img: d711, heading: "711" },
  { img: INPY, heading: "INPY" },
];
