// CurrencySelect.js
import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#1A1A1B",
    borderColor: "#3c403f",
    color: "white",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: "8px",
    width: "130px",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#111010",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#343536" : "#111010",
    color: state.isSelected ? "white" : "white",
    ":hover": {
      ...provided[":hover"],
      backgroundColor: "#2c2c2c",
      color: "white",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  input: (provided) => ({
    ...provided,
    color: "white",
  }),
};

const CurrencySelect = ({ currency, currencies, onCurrencyChange }) => {
  return (
    <Select
      value={currency}
      onChange={onCurrencyChange}
      options={currencies}
      placeholder="Currency"
      styles={customStyles}
    />
  );
};

export default CurrencySelect;
