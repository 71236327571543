import React from "react";
import { Link } from "react-router-dom";
import successfull from "../../assets/img/stake/successfull.svg";
import close from "../../assets/img/transfer/close.svg";
export const TransferDone = ({ setRefAllow }) => {
  return (
    <div className="welcome-ref-popup h-[100vh] w-full fixed top-0 left-0 flex items-center justify-center z-[99999] ">
      <span
        className="h-[100vh] w-full fixed top-0 left-0 -z-10"
        onClick={(e) => {
          setRefAllow(false);
        }}
      ></span>
      <div className="!bg-[#252525] border-[1px] border-[#3C403F]  w-[70vw] h-[90vh] rounded-xl lg:w-full lg:h-full relative flex flex-col">
        <div className="flex px-8 items-center justify-between h-[80px] rounded-tl-xl rounded-tr-xl bg-[#2E2E2E]">
          <h1 className="text-[#fff] text-[24px] font-arabicmed">Transfer</h1>
          <img
            src={close}
            alt=""
            className="cursor-pointer"
            onClick={(e) => {
              setRefAllow(false);
            }}
          />
        </div>

        <div className="content-area sm:p-6 flex w-[629px] sm:w-full text-center flex-col  items-center justify-center gap-[39px] mx-auto pb-[10vh] flex-1">
          <img src={successfull} alt="" />
          <h1 className="text-[#fff] font-arabicmed text-[24px]">
            Transfer Successful
          </h1>
          <p className="text-[20px] font-arabicmed text-[#AEB2B1]">
            Your transfer has been successful. You can track its progress on the
            Transaction History Page.
          </p>
          <div className="flex sm:flex-col flex-row items-center gap-6  w-full">
            <Link
              to="/"
              className="sm:w-2/3 w-full h-[50px] text-[18px] font-arabicbold text-white border-[1px] border-[#3C403F] bg-[#252525] rounded-lg flex items-center justify-center"
            >
              Back to Affiliate
            </Link>
            <Link
              to="/transaction-history"
              className="sm:w-2/3 w-full h-[50px] text-[18px] font-arabicbold text-[#111010] border-[1px] border-[#3C403F] bg-[#5DF9C2]  rounded-lg  flex items-center justify-center"
            >
              View History
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
