// src/hooks/useTradingData.js
import { useQuery } from 'react-query';
import { privateRequest } from '../config/axios.config';

const fetchTotalTrades = async () => {
  const response = await privateRequest.get('/trading/total');
  return response.data.data;
};

const fetchTotalProfit = async () => {
  const response = await privateRequest.get('/trading/profit');
  return response.data.data;
};
const getWinRate = async () => {
  const response = await privateRequest.get('/trading/winRate');
  return response.data.data;
};
const fetchRecentTrades = async () => {
  const response = await privateRequest.get('/trading');
  return response.data.data;
};

export const useTradingData = () => {
  const totalTradesQuery = useQuery('totalTrades', fetchTotalTrades);
  const totalProfitQuery = useQuery('totalProfit', fetchTotalProfit);
  const recentTradesQuery = useQuery('recentTrades', fetchRecentTrades);
  const winRate=useQuery('winRate', getWinRate);

  return {
    totalTrades: totalTradesQuery.data ?? 0,
    totalProfitPercent: totalProfitQuery.data ?? 0,
      winRate: winRate.data ?? 0,
    
    recentTrades: recentTradesQuery.data ?? [],
    isLoading: totalTradesQuery.isLoading || totalProfitQuery.isLoading || recentTradesQuery.isLoading,
    isError: totalTradesQuery.isError || totalProfitQuery.isError || recentTradesQuery.isError,
    error: totalTradesQuery.error || totalProfitQuery.error || recentTradesQuery.error,
  };
};
