import copy from 'copy-to-clipboard';
import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import copyIcon from "../../assets/img/wallet/copy.svg";
import { useGetAllWalletAddresses } from "../../queries/wallet";
import { getCoinName } from "../../utils";
import ButtonLoader from '../ButtonLoader';
export const DepositeCrypto = () => {
  const refText = useRef(null);
  const { data: cryptoWalletAddress, refetch, isLoading } = useGetAllWalletAddresses();
  const params = useParams();
  const coin = params?.coin;
  const [walletAddresses, setWalletAddress] = useState();

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
      if (cryptoWalletAddress && cryptoWalletAddress.data && cryptoWalletAddress.data[coin]) {
        setWalletAddress(cryptoWalletAddress.data[coin][0]?.address);
      }
    };
    fetchData();
  }, [coin, refetch, cryptoWalletAddress]);

  useEffect(() => {
    if (cryptoWalletAddress && cryptoWalletAddress.data && cryptoWalletAddress.data[coin]) {
      setWalletAddress(cryptoWalletAddress.data[coin][0]?.address);
    }
  }, [cryptoWalletAddress, coin]);

  return (
    <div>
      <h1 className="text-[24px] text-white font-arabicmed">Deposit Crypto</h1>
      {isLoading ? <>
        <ButtonLoader />
      </> : <>
        <div className="flex items-start mt-[51px] gap-12 ">
          {/* <img src={depositeSteps} alt="" className="h-[294px] md:hidden" /> */}
          <div className="flex-1">
            <div className="mb-[42px] flex items-center gap-2">
              <label
                htmlFor="#"
                className="text-[18px] font-arabicreg text-[#AEB2B1]  block"
              >
                Selected Coin
              </label>
              <p className="text-[#fff]">
                {coin}

              </p>
              <img
                src={`https://cryptologos.cc/logos/thumbs/${getCoinName(
                  coin
                )}.png?v=030`}
                alt=""
                className="h-7"
              />
            </div>
            <div>
              <h1 className="text-white text-[24px] font-arabicmed mb-3">
                Deposit Address
              </h1>
              <div className="bg-[#111010] border-[1px] border-[#3C403F] px-4 py-3 rounded-lg flex items-center gap-8 sm:flex-col sm:items-start">
                {walletAddresses && (
                  <QRCode
                    style={{ height: "auto", maxWidth: "100%", width: "140px" }} value={walletAddresses} />
                )}
                <div className="flex flex-col w-full flex-1 sm:flex-col sm:items-center sm:gap-2">
                  <div className="flex-1 w-full">
                    <h1 className="text-[#AEB2B1] text-[20px] font-arabicreg mb-[10px]">
                      Address
                    </h1>
                    <input
                      type="text"
                      ref={refText}
                      className="bg-[transparent] border-0 outline-none text-[22px] text-white font-arabicmed flex-1 w-full"
                      value={walletAddresses}
                    />
                  </div>
                  <button
                    className="text-[#111010] mt-5 text-[16px] font-arabicbold flex items-center gap-1 w-[180px] h-[50px] rounded-lg bg-gradient-to-br from-[#9BE402] to-[#1FA83C] justify-center sm:w-full"
                    onClick={(e) => {
                      copy(walletAddresses);
                      toast("Deposit Address Coppied");
                    }}
                  >
                    <img src={copyIcon} alt="" />
                    Copy Address
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
    </div>
  );
};
