import React from "react";
import { Link } from "react-router-dom";

export const SwitchPayment = ({ active, classStyle = null }) => {
  return (
    <div
      className={`bg-[#252525] border-[1px] border-[#3D3F40] h-[48px]  flex items-center  justify-between rounded-xl ml-3 sm:w-full sm:overflow-x-scroll md:w[60%] ${classStyle}`}
    >
      <Link
        to="/stake/payment/method/cash-wallet"
        className={` text-[#B7B8BC] flex items-center justify-center cursor-pointer h-full  w-[224px]  rounded-xl text-[14px] sm:text-[12px] font-arabicreg text-center ${active === 3 &&
          "bg-gradient-to-br from-[#9BE402] to-[#1FA83C] !text-[#FFF]"
          }`}
      >
        Cash Wallet
      </Link>
      <Link
        to="/stake/payment/method/crypto"
        className={` text-[#B7B8BC] flex items-center justify-center cursor-pointer h-full w-[138px] rounded-xl text-[14px] sm:text-[12px] font-arabicreg text-center  ${active === 1 &&
          "bg-gradient-to-br from-[#9BE402] to-[#1FA83C] !text-[#FFF]"
          }`}
      >
        Crypto
      </Link>
      <Link
        to="/stake/payment/method/fiat"
        className={` text-[#B7B8BC] flex items-center justify-center cursor-pointer h-full w-[150px] rounded-xl text-[14px] sm:text-[12px] font-arabicreg text-center ${active === 2 &&
          "bg-gradient-to-br from-[#9BE402] to-[#1FA83C] !text-[#FFF]"
          }`}
      >
        Fiat
      </Link>
      <Link
        to="/stake/payment/method/external-wallet"
        className={`text-[#B7B8BC] flex items-center justify-center cursor-pointer h-full !md:w-full lg:w-full rounded-xl px-5 text-[14px] sm:text-[12px] font-arabicreg text-center ${active === 4 &&
          "bg-gradient-to-br from-[#9BE402] to-[#1FA83C] !text-[#FFF]"
          }`}
      >
        External Crypto Wallet
      </Link>
    </div>
  );
};
