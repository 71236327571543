import React from "react";

export const StakeDuration = ({ data }) => {
  return (
    <ul className="bg-[#252525] mb-8 p-6 border-[1px] border-[#3C403F] rounded-xl ">
      <li className="flex items-center justify-between">
        {/* <p className="text-white font-arabicreg text-[21px] sm:text-[18px] xs:!text-[14px]">
          <span className="text-[#AEB2B1]">Duration:</span> {data?.lockedMonths} Month
        </p> */}
        <p className="text-white font-arabicreg text-[21px] sm:text-[18px] xs:!text-[14px]">
          <span className="text-[#AEB2B1]">Locked in</span> {data?.lockedMonths}{" "}
          Month
        </p>
      </li>
      <li className="flex items-center justify-between mt-6">
        <p className="text-[#AEB2B1] font-arabicreg text-[21px] sm:text-[18px] xs:!text-[14px]">
          Amount
        </p>
        <div className="text-white font-arabicreg text-[22px] h-11 w-[203px] xs:!w-[100px] bg-[#111010] border-[1px] border-[#3C403F] flex items-center justify-end px-4 rounded-lg">
          <span className="text-[#AEB2B1] sm:text-[18px]">$</span>
          <input
            type="number"
            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none sm:text-[18px] outline-none no-spinners"
            style={{
              background: "transparent",
              maxWidth: "160px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onKeyDown={(e) => {
              if (
                !(
                  e.key === "Backspace" ||
                  e.key === "Delete" ||
                  e.key === "ArrowLeft" ||
                  e.key === "ArrowRight" ||
                  e.key === "ArrowUp" ||
                  e.key === "ArrowDown" ||
                  e.key === "." ||
                  (e.key >= "0" && e.key <= "9")
                )
              ) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (!isNaN(value) && value >= 0) {
                window.sessionStorage.setItem("stake-amount", value);
              }
            }}
            // defaultValue="0"
            placeholder="0"
          />
        </div>
      </li>
    </ul>
  );
};
