import React, { useState } from "react";
import bankCard from "../../assets/img/wallet/bank.svg";
import bank from "../../assets/img/wallet/us-bank.svg";
import step1 from "../../assets/img/wallet/step1.svg";
import { PayDropdownFiat } from "../popups/PayDropdownFiat";
import { Currency } from "../../utils/staking/Currency";
import { RadioPayment } from "./RadioPayment";
import { TypeCard } from "./TypeCard";
import { TypeTransfer } from "./TypeTransfer";
export const DepositeFiatOne = ({ setStep }) => {
  const [PaymentState, setPayment] = useState(1);

  return (
    <div>
      <h1 className="text-[24px] text-white font-arabicmed">Deposit Fiat</h1>

      <div className="flex items-start mt-[51px] gap-12 ">
        <img src={step1} alt="" className="" />
        <div className="flex-1">
          <h1 className="text-[22px] text-white font-arabicmed mb-[52px]">
            Select Currency
          </h1>
          <div className="mb-[42px]">
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
            >
              Select Currency
            </label>

            <PayDropdownFiat
              stake
              classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
              heading="USD"
              list={Currency}
            />
          </div>

          <div className="mb-[52px] flex items-center justify-between">
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
            >
              Deposit With
            </label>
            <div className="flex items-center gap-3">
              <button className="text-[14px] font-arabicmed text-white bg-[#2E2E2E] w-[150px] h-[43px] rounded-lg border-[1px] border-[#6FCF17]">
                Recommended
              </button>
              <button className="text-[14px] font-arabicmed text-white bg-[#252525] w-[150px] h-[43px] rounded-lg  border-[1px] border-[transparent]">
                Other Methods
              </button>
            </div>
          </div>

          <div className="checkbox-wrapper flex gap-4 items-start">
            <RadioPayment
              id="bank-transfer"
              onchange={() => {
                setPayment(1);
              }}
            />
            <div>
              <div className="flex items-center">
                <img src={bank} alt="" />
                <h1 className="ml-4 text-white text-[20px] font-arabicmed">
                  Bank Transfer
                </h1>
              </div>
              <p className="text-[16px] pl-2 mt-4 text-[#AEB2B1] font-arabicmed">
                1 EUR Discounted Transaction Fee, 1-2 business days.
              </p>
            </div>
          </div>
          <div className="checkbox-wrapper gap-4  flex items-start mt-8">
            <RadioPayment
              id="bank-card"
              onchange={() => {
                setPayment(2);
              }}
            />
            <div>
              <div className="flex items-center">
                <img src={bankCard} alt="" />
                <h1 className="ml-4 text-white text-[20px] font-arabicmed">
                  Bank Card (Visa / MC)
                </h1>
              </div>
              <p className="text-[16px] pl-2 mt-4 text-[#AEB2B1] font-arabicmed">
                1.8% Transaction Fee
              </p>
            </div>
          </div>

          {PaymentState == 1 ? <TypeTransfer /> : <TypeCard />}

          <button
            className="text-[#111010] text-[16px] font-arabicbold flex items-center gap-1 w-full h-[50px] rounded-lg bg-gradient-to-br from-[#9BE402] to-[#1FA83C] justify-center"
            onClick={(e) => {
              setStep(2);
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
