import React, { useState } from 'react';
import { useQuery } from 'react-query';
import WorldMap from 'react-svg-worldmap';
import { privateRequest } from '../../config/axios.config';

export default function PlayersCommunity() {
    const [countriesData, setCountriesData] = useState([]);

    const colors = [
        {
            id: 0,
            color: "#36B5FF",
        },
        {
            id: 1,
            color: "#ff8036",
        },
        {
            id: 2,
            color: "#50eea4",
        },
        {
            id: 3,
            color: "#e070b2",
        }
    ];


    const { data: communityPlayers } = useQuery("communityPlayers", async () => {
        try {
            const res = await privateRequest.get("user/total-community-members");
            setCountriesData(res?.data?.playersByCountry);
            return res?.data;
        } catch (error) {
            console.log(error);

        }
    });

    const getStyle = ({ countryCode, color }) => {
        let finder = countriesData.find(
            (data) => data.country == countryCode.toLowerCase()
        );

        return {
            fill: finder != undefined ? "#6FCF17" : color,
            stroke: "#3C403F",
            strokeWidth: 1,
            strokeOpacity: 1,
            cursor: "pointer",
        };
    };


    return (
        <>
            <div className="bg-[#1A1A1B] h-[321px] border-[1px] border-[#3C403F] rounded-xl p-8 flex-1 sm:!p-5 relative sm:h-[unset]">
                <div className="flex items-center justify-between xs:flex-col xs:items-start sm:gap-5">
                    <h1 className="text-[24px] sm:text-[20px]  font-arabicmed text-white">
                        By Country
                    </h1>
                    <div className="grid grid-cols-3 gap-1    sm:grid-cols-2 sm:gap-2 ">
                        {communityPlayers?.playersByRank?.map((player, index) => <>
                            <div className="flex items-center col-span-2 sm:col-span-1 1xl:col-span-1">
                                <p className="text-[12px] xs:text-[9px] 1xl:text-[10px]    font-arabicreg text-white">
                                    {player?.rank} {player?.totalPlayers}
                                </p>
                                <span className={`w-2 h-2 block rounded-full ml-1`} style={{ backgroundColor: colors[index % colors.length].color }}></span>
                            </div>
                        </>)}
                    </div>
                </div>
                <div className="world-map">
                    <WorldMap
                        tooltipBgColor="#3FC999"
                        tooltipTextColor="#FFF"
                        color="#252525"
                        size="md"
                        data={countriesData}
                        styleFunction={getStyle}
                    />
                </div>
                {/* <img src={world} alt="" className="absolute bottom-0 left-0" /> */}
            </div>
        </>
    )
}
