import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

const TokenHandler = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    if (token) {
      localStorage.setItem('token', token);
      setIsAuthenticated(true);
      navigate('/'); 
    } else {
     //   window.location.href = `${process.env.REACT_APP_WEB_URL}`;

    }
  }, [navigate, setIsAuthenticated]);

  return <div>Loading...</div>; // Or any loading indicator
};

export default TokenHandler;
