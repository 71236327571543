import React, { useEffect, useState } from "react";
import "../assets/css/home.css";
import { Footer } from "../components/Footer";
import { SignIn } from "../components/SignIn";
import { RepeatPassword } from "../components/RepeatPassword";
import { SignUp } from "../components/SignUp";
import { SignUpPhone } from "../components/SignUpPhone";
import { RightAreaStakeDone } from "../components/RightAreaStakeDone";
import { Aside } from "../components/Aside";
import successFeature from "../assets/img/stake/successfull.svg";

export const DepositDone = () => {
  const [signInState, setsigninState] = useState(false);
  const [RepeatPasswordState, setRepeatPasswordState] = useState(false);
  const [SignUpState, setSignUpState] = useState(false);
  const [SignUpPhoneState, setSignUpPhoneState] = useState(false);
  const [provider, setProvider] = useState('');

  useEffect(() => {
    // Parse the URL query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const providerParam = queryParams.get('provider');
    setProvider(providerParam);
  }, []);

  // Function to render content based on the provider
  const renderContent = () => {
    if (provider === 'moneycollect') {
      return (
        <div>
          <p className="text-[#AEB2B1] w-[720px] mx-auto text-[18px] text-center mt-3 lgmed:w-full sm:text-[14px]">
            Your payment has been successfully processed through MoneyCollect. We are now working on crediting your cash wallet balance. This usually takes a short amount of time, so you can expect to see your balance updated very soon.
          </p>
          <p className="text-[#AEB2B1] w-[720px] mx-auto text-[18px] text-center mt-3 lgmed:w-full sm:text-[14px]">
            In the meantime, feel free to explore other services or contact our support team if you have any questions. Thank you for choosing our platform!
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p className="text-[#AEB2B1] w-[720px] mx-auto text-[18px] text-center mt-3 lgmed:w-full sm:text-[14px]">
            Congratulations! Your payment has been confirmed and your cash wallet balance has been credited. You can now utilize your funds for any transactions or services available on our platform.
          </p>
          <p className="text-[#AEB2B1] w-[720px] mx-auto text-[18px] text-center mt-3 lgmed:w-full sm:text-[14px]">
            If you need any assistance or have any queries, our customer support team is always here to help. Enjoy your experience with us!
          </p>
        </div>
      );
    }
  };
  
  return (
    <div>
      <div className="flex items-start home">
        {signInState && (
          <SignIn
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setRepeatPasswordState={setRepeatPasswordState}
          />
        )}
        {RepeatPasswordState && (
          <RepeatPassword
            setsigninState={setsigninState}
            setRepeatPasswordState={setRepeatPasswordState}
          />
        )}

        {SignUpState && (
          <SignUp
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setSignUpPhoneState={setSignUpPhoneState}
          />
        )}
        {SignUpPhoneState && (
          <SignUpPhone
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setSignUpPhoneState={setSignUpPhoneState}
          />
        )}

        {/* left area */}
        <Aside activeSwitch="staker-switch" />
        <div className="body-area flex-1 px-6 pt-6">
          <div className="bg-[#1A1A1B] px-10 rounded-xl border-[1px] border-[#3C403F] pt-[137px] pb-[200px]">
            <img src={successFeature} alt="" className="mx-auto" />
            <h1 className="text-white text-[24px] text-center mt-12 sm:text-[20px]">
              Your payment is being processed
            </h1>
            {renderContent()}
          



          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
