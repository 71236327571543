import React from "react";
import { dateFormatter } from "../../utils";

export const CommunityTable = ({data=[]}) => {
  return (
    <table className="mb-8 w-full lgmed:min-w-[800px]">
      <thead>
        <tr className="h-[56px] bg-[#252525] border-[1px] border-[#3C403F]">
          <th className="pl-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
            Level
          </th>
          <th className="text-left font-arabicreg text-[18px] text-[#AEB2B1]">
            Date joined
          </th>
          <th className="text-left font-arabicreg text-[18px] text-[#AEB2B1]">
            Email
          </th>
          {/* <th className="text-left font-arabicreg text-[18px] text-[#AEB2B1]">
            Type
          </th>
          <th className=" text-left font-arabicreg text-[18px] text-[#AEB2B1]">
            Country
          </th>
          <th className="pr-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
            Sponsor By
          </th> */}
          <th className="pr-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
            Rank
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((EachRow, key) => (
          <tr
            key={key}
            className="h-[56px] bg-[#1A1A1B]
            border-b-[1px] border-b-[#3C403F]
            border-l-[1px] border-l-[#3C403F]
            border-r-[1px] border-r-[#3C403F]
           "
          >
            <td className={`font-arabicreg text-[18px] text-[#fff] pl-4`}>
              {EachRow.level}
            </td>
            <td className={`font-arabicreg text-[18px] text-[#fff]`}>
              {dateFormatter(EachRow.createdAt)}
            </td>
            <td className={`font-arabicreg text-[18px] text-[#fff]`}>
              {EachRow.email}
            </td>
            <td className={`font-arabicreg text-[18px] text-[#fff]`}>
            {EachRow.rank}
            </td>
            {/* <td className={`font-arabicreg text-[18px] text-[#fff]`}>
              <div className="flex items-center gap-2">
                <span
                  className={`w-2 h-2 block rounded-full `}
                  style={{ background: EachRow.active.color }}
                ></span>
                <p>{EachRow.active.status}</p>
              </div>
            </td> */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
