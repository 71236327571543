import React from "react";

import profile from "../assets/img/profile.png";
import cup from "../assets/img/cup.svg";
import coin from "../assets/img/coin.svg";
import bets from "../assets/img/bets.svg";
import no_data from "../assets/img/no_data.png";

export const MedalsDetails = ({ setmedalState, setusername }) => {
  return (
    <div className="LayerUp">
      <form className="user-profile rounded-xl">
        <div className="head-edit flex items-center px-6 h-16">
          <svg
            onClick={(e) => {
              setmedalState(false);
              setusername(true);
            }}
            className="cursor-pointer"
            width="21"
            height="26"
            viewBox="0 0 21 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.1307 18.3225L9.23806 12.9928L15.1307 7.66311C15.2713 7.53594 15.3829 7.38496 15.459 7.2188C15.5351 7.05264 15.5742 6.87455 15.5742 6.6947C15.5742 6.51485 15.5351 6.33676 15.459 6.1706C15.3829 6.00444 15.2713 5.85346 15.1307 5.72628C14.9901 5.59911 14.8232 5.49823 14.6395 5.42941C14.4558 5.36058 14.2589 5.32516 14.06 5.32516C13.8612 5.32516 13.6643 5.36058 13.4806 5.42941C13.2968 5.49823 13.1299 5.59911 12.9893 5.72628L6.01836 12.0313C5.87757 12.1584 5.76587 12.3093 5.68966 12.4755C5.61345 12.6416 5.57422 12.8198 5.57422 12.9997C5.57422 13.1796 5.61345 13.3577 5.68966 13.5239C5.76587 13.6901 5.87757 13.841 6.01836 13.9681L12.9893 20.2731C13.1298 20.4004 13.2967 20.5015 13.4804 20.5704C13.6642 20.6393 13.8611 20.6748 14.06 20.6748C14.2589 20.6748 14.4559 20.6393 14.6396 20.5704C14.8233 20.5015 14.9902 20.4004 15.1307 20.2731C15.7078 19.7374 15.723 18.8582 15.1307 18.3225Z"
              fill="#959699"
            />
          </svg>

          <h1 className="flex-1 ml-3">Details</h1>

          <svg
            onClick={(e) => {
              setmedalState(false);
            }}
            className="cursor-pointer"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1.02539"
              y="0.507568"
              width="31"
              height="31"
              rx="7.5"
              fill="#2E2E2E"
            />
            <rect
              x="1.02539"
              y="0.507568"
              width="31"
              height="31"
              rx="7.5"
              stroke="#3C403F"
            />
            <path
              d="M12.3588 11.8408L20.6921 20.1742M12.3588 20.1742L20.6921 11.8408"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div className="px-6">
          <div className="top-px top-px-mbl flex items-center mt-6">
            <h1 className="mr-4">Statistics</h1>
            <div className="link-wrapper rounded-lg flex items-center justify-between">
              <p>Global</p>
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.75241 9.57442L6.69733 6.91084L3.75241 4.24726C3.68214 4.1837 3.62639 4.10825 3.58837 4.02521C3.55034 3.94217 3.53076 3.85317 3.53076 3.76329C3.53076 3.6734 3.55034 3.5844 3.58837 3.50136C3.62639 3.41832 3.68214 3.34287 3.75241 3.27931C3.82267 3.21575 3.9061 3.16534 3.99791 3.13094C4.08972 3.09654 4.18812 3.07884 4.2875 3.07884C4.38688 3.07884 4.48528 3.09654 4.57709 3.13094C4.6689 3.16534 4.75233 3.21575 4.8226 3.27931L8.30641 6.4303C8.37677 6.49381 8.43259 6.56925 8.47068 6.65229C8.50877 6.73534 8.52837 6.82437 8.52837 6.91427C8.52837 7.00418 8.50877 7.09321 8.47068 7.17626C8.43259 7.2593 8.37677 7.33474 8.30641 7.39825L4.8226 10.5492C4.75238 10.6129 4.66897 10.6634 4.57715 10.6978C4.48533 10.7323 4.38691 10.75 4.2875 10.75C4.1881 10.75 4.08967 10.7323 3.99785 10.6978C3.90603 10.6634 3.82262 10.6129 3.75241 10.5492C3.46399 10.2815 3.4564 9.84216 3.75241 9.57442Z"
                  fill="#959699"
                />
              </svg>
            </div>
            <div className="flex profile-px items-center flex-1 justify-end">
              <img src={profile} alt="" className="w-10 mr-3" />
              <h1>Kiyskvotnyb</h1>
            </div>
          </div>
          <div className="metal-box rounded-lg p-3 mt-5">
            <div className="boxes-wrapper  grid grid-cols-3 gap-5">
              <div className="box flex flex-col items-center justify-center rounded-lg">
                <h1 className="flex items-center">
                  <img src={cup} alt="cup" className="mr-1" />
                  Total Wins
                </h1>
                <p className="mt-2">0</p>
              </div>
              <div className="box  flex flex-col items-center justify-center rounded-lg">
                <h1 className="flex items-center">
                  <img src={bets} alt="cup" className="mr-1" />
                  Total Bets
                </h1>
                <p className="mt-2">0</p>
              </div>
              <div className="box  flex flex-col items-center justify-center rounded-lg">
                <h1 className="flex items-center">
                  <img src={coin} alt="cup" className="mr-1" />
                  Total Wagered
                </h1>
                <p className="mt-2">0</p>
              </div>
            </div>
          </div>

          <div className="metal-box rounded-lg p-3 mt-5">
            <div className="row flex items-center justify-between">
              <p>Currency</p>
              <p>Win</p>
              <p>Bet</p>
              <p>Wagered</p>
            </div>
            <div className="games-body  games-body-details py-8 flex flex-col items-center justify-center">
              <img src={no_data} alt="" />
              <h1>Oops! There is no data yet!</h1>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
