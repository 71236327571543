import React from "react";
import { useNavigate } from "react-router-dom";
import deposite from "../../assets/img/affiliate/deposite.svg";
import see from "../../assets/img/affiliate/see.svg";
import transaction from "../../assets/img/affiliate/transaction.svg";

export const ShowcaseBox = (boxData) => {
  const navigate = useNavigate();
  return (
    <div className="showcase-box  bg-[#1A1A1B] border-[1px] border-[#3C403F] rounded-xl p-5">
      <div className="flex items-center justify-between">
        <h1 className="text-[17px] sm:text-[16px] font-arabicreg text-white">
          {boxData.heading}
        </h1>
        {boxData.extra && (
          <p className="text-[14px] sm:text-[14px] font-arabicreg text-white">
            {boxData.extra}
          </p>
        )}
      </div>
      <div className="coin-wrapper my-4 flex items-center ">
        {boxData?.hideIcon ? null : <img src={boxData.coin} alt="" />}
        <p
          className={`text-[24px] ${
            boxData.coin && "ml-2"
          } sm:text-[20px]  font-arabicreg text-white`}
        >
          {boxData.dollar && "$"}
          {boxData.value}
        </p>
      </div>
      <div className="buttons-wrapper flex items-center gap-3 sm:flex-col sm:!items-stretch">
        {boxData.seeButton && (
          <button
            className={`flex w-[80px] h-[32px]  items-center border-[#3C403F] border-[1px] bg-[#2E2E2E] justify-center rounded-lg gap-1 ${
              !boxData.transactionButton && "!w-full"
            } sm:!w-full sm:!min-h-[32px]`}
          >
            <img src={see} alt="" />
            <p className="text-[12px] font-arabicmed text-white">See more</p>
          </button>
        )}

        {boxData.depositeButton && (
          <button
            className="flex w-[80px] h-[32px]  items-center border-[#3C403F] border-[1px] bg-[#2E2E2E] justify-center rounded-lg gap-1  sm:!w-full sm:!min-h-[32px] hover:bg-[#3C403F]"
            onClick={(e) => {
              navigate("/transfer");
            }}
          >
            <img src={deposite} alt="" />
            <p className="text-[12px] font-arabicmed text-white">Transfer</p>
          </button>
        )}
        {boxData.transactionButton && (
          <button
            className="flex  h-[32px]  items-center border-[#3C403F] border-[1px] bg-[#2E2E2E] justify-center rounded-lg gap-1 flex-1  sm:!w-full sm:!min-h-[32px] hover:bg-[#3C403F]"
            onClick={(e) => {
              navigate("/transaction-history");
            }}
          >
            <img src={transaction} alt="" />
            <p className="text-[12px] font-arabicmed text-white">
              Transaction History
            </p>
          </button>
        )}

        {boxData.dailyrb && (
          <button
            className="flex  h-[32px]  items-center border-[#3C403F] border-[1px] bg-[#2E2E2E] justify-center rounded-lg gap-1 flex-1  sm:!w-full sm:!min-h-[32px] hover:bg-[#3C403F]"
            onClick={(e) => {
              console.log("working");
            }}
          >
            <p className="text-[12px] font-arabicmed text-white">
              View Daily RB
            </p>
          </button>
        )}
      </div>
    </div>
  );
};
