import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaChartLine } from "react-icons/fa"; // Import the icon
import arrow from "../../assets/img/dashboard/arrow.svg";
import exchange from "../../assets/img/exchange.svg";
import p2p from "../../assets/img/p2p.svg";
import swap from "../../assets/img/swap.svg";

export const AsideOption = () => {
  const sidelist = [
    {
      heading: "Swap",
      img: swap,
      link: "/swap/crypto",
    },
    {
      heading: "P2P",
      img: p2p,
      link: "/p2p/buy",
    },
  ];
  const [active, setActive] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.startsWith("/p2p")) {
      setActive(!active);
    }
  }, [location.pathname]);

  return (
    <>
      <div className="dropdownaside relative">
        <div
          className={`bonus-area cursor-pointer px-5 border-[transparent] border-[1px] rounded-lg flex items-center h-11`}
          onClick={(e) => {
            setActive(!active);
          }}
        >
          <img src={exchange} alt="exchange" />
          <h1 className="flex-1 ml-2">Exchange</h1>
          <img src={arrow} alt="" className={`${active && "rotate-[-90deg]"}`} />
        </div>
        {active && (
          <ul className="w-full flex flex-col items-end">
            {sidelist.map((EachList, key) => (
              <Link
                key={key}
                to={EachList.link}
                className={`bonus-area px-5 w-[181px] flex items-center h-11 
                 rounded-lg !bg-[#252525] mt-2 ${
                   (EachList.link === '/p2p/buy' || EachList.link === '/p2p/sell') &&
                   location.pathname.startsWith("/p2p")
                     ? "active"
                     : ""
                 }`}
              >
                <img src={EachList.img} alt={EachList.heading} />
                <h1 className="flex-1 ml-2">{EachList.heading}</h1>
              </Link>
            ))}
          </ul>
        )}
      </div>
      
      <Link
        to="/trading-performance"
        className={`bonus-area px-5 w-full flex items-center h-11 
         rounded-lg mt-2 ${
           location.pathname === "/trading-performance" ? "active" : ""
         }`}
      >
        <FaChartLine className="text-lg text-white" /> 
        <h1 className="flex-1 ml-2">Trading Performance</h1>
      </Link>

    </>
  );
};