import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../assets/css/settings/index.css";
import add from "../../assets/img/p2p/add.svg";
import back from "../../assets/img/p2p/back.svg";
import bank from "../../assets/img/p2p/bank.svg";
import bdo from "../../assets/img/p2p/bdo.svg";
import boc from "../../assets/img/p2p/boc.svg";
import bpi from "../../assets/img/p2p/bpi.svg";
import card from "../../assets/img/p2p/card.svg";
import coins from "../../assets/img/p2p/coins.svg";
import confetti from "../../assets/img/p2p/confetti.svg";
import remove from "../../assets/img/p2p/delete.svg";
import gcash from "../../assets/img/p2p/gcash.svg";
import grab from "../../assets/img/p2p/grab.svg";
import leftarrow from "../../assets/img/p2p/leftarrow.svg";
import mastercard from "../../assets/img/p2p/mastercard.svg";
import maya from "../../assets/img/p2p/maya.svg";
import maybank from "../../assets/img/p2p/maybank.svg";
import metro from "../../assets/img/p2p/metro.svg";
import noresult from "../../assets/img/p2p/no-payment.png";
import notice from "../../assets/img/p2p/notice.svg";
import security from "../../assets/img/p2p/security.svg";
import ub from "../../assets/img/p2p/ub.svg";
import ucpb from "../../assets/img/p2p/ucpb.svg";
import visa from "../../assets/img/p2p/visa.svg";
import wallet from "../../assets/img/p2p/wallet.svg";
import {
  UseP2PSavePaymentMethod,
  UseSaveUserPaymentMethodDetails,
  usePaymentMethods,
  useUserPaymentMethods
} from "../../queries/p2p";
import ButtonLoader from "../ButtonLoader";
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";
import { P2PNav } from "./P2PNav";
import { PaymentFormDetails } from "./PaymentFormDetails";


const paymentData = [
  {
    method: "E-Wallet",
    img: wallet,
    type: [grab, maya, coins, gcash],
    name: ["Grab Pay", "Maya Pay", "Coins.ph", "G Cash"],
  },
  {
    method: "Bank Transfer",
    img: bank,
    type: [metro, boc, ucpb, maybank, bdo, security, bpi, ub],
    name: [
      "Metro Bank",
      "Bank of Commerce",
      "UCPB Online",
      "May Bank",
      "BDO Bank",
      "Security Online Bank",
      "BPI Bank",
      "Unionbank",
    ],
  },
  {
    method: "Card",
    img: card,
    type: [mastercard, visa],
    name: ["Mastercard", "Visa"],
  },
];

export const RightAreaP2PPaymentMethod = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [stepActive, setStepActive] = useState(1);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const userPaymentMethods = useUserPaymentMethods();
  const userPaymentMethodsData = userPaymentMethods?.data?.data;
  const [renderedComponents, setRenderedComponents] = useState([]);
  const formsDivRef = useRef(null);
  const payments = usePaymentMethods();
  const [newDetailsOptions, setNewDetailsOptions] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  // PAYMENT METHOD FORM
  const [formInputs, setFormInputs] = useState([{ id: 1, title: "" }]);
  const [formErrors, setFormErrors] = useState([]);
  const [openNotice, setOpenNotice] = useState("");
  const [isPayMethod, setIsPayMethod] = useState(false);

  // MODAL
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
    // setOpenNotice(false);
    // setIsPayMethod(false);
  };


  const handleSelectForm = (e, val) => {
    e.stopPropagation();
    const trimmedItem = (val).trim();
    const paymentMatch = payments.data.data.find(payment => payment.name === val);
    const arrayValue = [{
      _id: paymentMatch ? paymentMatch._id : null,
      name: trimmedItem,
      customFeild: paymentMatch ? paymentMatch.customFeild : null
    }]
    arrayValue.forEach(item => {
      const paymentMatch = payments.data.data.find(payment => payment._id === item._id);
      console.log(paymentMatch);
      const components = <PaymentFormDetails details={paymentMatch} />;
      setRenderedComponents(components);
    });
    handleProceed2();
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [id]: value,
    }));
  };

  const handleSubmitPaymentDetails = async () => {
    setIsOpen(true);
  };

  const postPaymentMethod = async () => {
    const inputs = {};

    const inputElements = formsDivRef.current.querySelectorAll('input');
    var methodId = "";

    inputElements.forEach(input => {
      const id = input.id;
      const value = input.value;
      methodId = input.getAttribute('methodid');
      inputs[id] = value;
    });
    setInputs(prevInputs => ({
      ...prevInputs,
      ...inputs
    }));
    try {
      const data = await UseSaveUserPaymentMethodDetails(
        {
          paymentMethodId: methodId,
          data: inputs,
        })
      toast.success(data?.message)
      setIsOpen(false);
      setIsSuccess(true);
    } catch (error) {
      console.error("Posting payment details error", error.message);
      toast.error(error.message);
    }
  };


  const postPaymentMethodTemplate = async () => {
    try {
      const fieldsValue = Array.from(document.querySelectorAll('#paymentFields input')).map(input => input.value);
      const name_ = document.querySelector('input#Title').value;
      const objectValue = {
        name: name_,
        customFeild: fieldsValue,
      };

      const data = await UseP2PSavePaymentMethod({
        paymentMethods: objectValue,
      });
      // console.log(data);
      toast.success(data?.message);
      setIsOpen(false);
      setIsPayMethod(false);
    } catch (error) {
      console.error(error.message);
      toast.error(error.message);
    }
    setOpenNotice(false);
  };

  const handleSubmit = () => {
    setIsOpen(true);
  };

  const handleProceed1 = () => {
    setStepActive(2);
  };
  const handleProceed2 = () => {
    setStepActive(3);
  };
  const handlePayment = (method) => {
    setSelectedPaymentMethod(method);
    setStepActive(2);
  };
  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
  };
  const handleGoBack1 = () => {
    setStepActive(1);
  };
  const handleGoBack = () => {
    setStepActive(2);
    setSelectedPaymentMethod(null);
  };
  const handleStepBack = () => {
    if (stepActive > 1) {
      setStepActive(stepActive - 1);
    }
  };

  // const handleGoToStep3 = () => {
  //   setStepActive(4);
  // };

  const handleGoToStep3 = () => {
    if (selectedPaymentMethod.method === "E-Wallet") {
      setStepActive(4);
    } else if (selectedPaymentMethod.method === "Bank Transfer") {
      setStepActive(5);
    } else if (selectedPaymentMethod.method === "Card") {
      setStepActive(6);
    }
  };

  const handleBack = () => {
    navigate(-1);
    setIsSuccess(false);
  };

  //PAYMENT METHOD MODAL
  const paymentMethodModal = () => {
    setIsPayMethod(true);
  };

  const handleFormSubmit = () => {
    // Basic validation - Check if any required fields are empty
    const newformErrors = formInputs.map((input) => {
      if (!input.title.trim()) {
        return "Field is required.";
      }
      return "";

    });


    setFormErrors(newformErrors);

    // If there are no formErrors, submit the form
    if (!newformErrors.some((error) => error !== "")) {
      // Implement your submit logic here
      console.log("Form submitted:", formInputs);
      setOpenNotice(true);
    }
  };

  const handleAddFormInput = () => {
    setFormInputs([...formInputs, { id: formInputs.length + 1, title: "" }]);
  };

  const handleRemoveInput = (index) => {
    const newformInputs = [...formInputs];
    newformInputs.splice(index, 1);
    setFormInputs(newformInputs);
    // Reset formErrors when input is removed
    setFormErrors([]);
  };

  const handleFormInputChange = (index, event) => {
    const { name, value } = event.target;
    const newformInputs = [...formInputs];
    newformInputs[index][name] = value;
    setFormInputs(newformInputs);
    // Reset formErrors when input changes
    setFormErrors([]);
  };


  useEffect(() => {
    setNewDetailsOptions(() => {
      return payments?.data?.data.map((payment) => ({
        label: payment.name,
        value: payment.name,
      }));
    });
  }, [payments?.data?.data]);

  return (
    <>
      <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />

      <div className="flex-1">
        <HeaderStaker
          headerType="Staker"
          login={login}
          settypeShowAside={settypeShowAside}
          setsigninState={setsigninState}
          setSignUpState={setSignUpState}
        />

        <P2PNav />

        <div className="body-area flex items-start px-6 pt-6">
          <div className="body-left-area w-full">
            <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
              <div className="flex items-center gap-4 p-6">
                <button onClick={stepActive === 1 ? handleBack : handleStepBack}>
                  <img src={back} />
                </button>
                <h1 className="text-white text-[24px] text-center sm:text-[18px]">
                  {" "}
                  {stepActive === 1
                    ? "Payment Method"
                    : stepActive === 2
                      ? "Save payment method"
                      : "Save payment method"}
                </h1>
              </div>

              <div className="p-6">
                <>
                  {stepActive !== 1 && (
                    <div className="flex justify-around buttons-wrapper-step gap-4 mb-6">
                      <div className="w-auto">
                        <button
                          className={`text-white font-arabicmed text-[18px] whitespace-nowrap `}
                          onClick={() => setStepActive(1)}
                        >
                          Step{" "}
                          {stepActive === 1 ? "" : stepActive === 3 ? "2" : "1"}{" "}
                          of 2
                        </button>
                      </div>

                      <hr
                        style={{ borderWidth: "1.5px" }}
                        className={`border-[#4b4b4b] w-full my-auto ${(stepActive === 2 ||
                          stepActive === 4 ||
                          stepActive === 5 ||
                          stepActive === 6 ||
                          stepActive === 3) &&
                          "border-[#6cb339]"
                          }`}
                      />

                      <hr
                        style={{ borderWidth: "1.5px" }}
                        className={`border-[#4b4b4b] w-full my-auto ${stepActive === 3 && "border-[#6cb339]"
                          }`}
                      />
                    </div>
                  )}

                  {stepActive === 1 && (
                    <div className="body-area flex-1 px-0 pt-6">
                      <div className="flex justify-end pb-6">
                        <button
                          className="flex justify-center items-center text-[#fff] gap-2 bg-gradient-to-br from-[#9BE402] to-[#1FA83C] p-3 text-[14px] rounded-lg"
                          onClick={handleProceed1}
                        >
                          <img src={add} />
                          Add Payment Method
                        </button>
                      </div>

                      <div>
                        {userPaymentMethods.isLoading ? <>
                          <ButtonLoader />
                        </> : <>
                          {userPaymentMethodsData && userPaymentMethodsData.length > 0 ? (
                            <div className="grid sm:grid-cols-1 grid-cols-2 gap-6">
                              {userPaymentMethodsData?.map((detail, index) => (
                                <div key={index} className="bg-[#2A2A2A] rounded-lg p-4 sm:w-full mb-4">
                                  <div className="flex gap-2 items-center mb-2">
                                    <h6 className="text-[#FFFFFF] font-arabicreg">{detail?.paymentMethod.name}</h6>
                                  </div>
                                  <div className="bg-[#111010] rounded-lg border border-[#3C403F]">
                                    <div className="p-2">
                                      {Object.entries(detail.data).map(([key, value]) => (
                                        <div key={key} className="flex justify-between items-center mb-2">
                                          <p className="text-[#AEB2B1] font-arabicreg">{key}</p>
                                          <p className="text-[#AEB2B1] font-arabicreg">{value}</p>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="flex flex-col justify-center items-center text-white p-4 m-6">
                              <img src={noresult} alt="no results" className="w-40 mb-4" />
                              <p className="text-[24px] font-arabicreg" >No Payment Method found</p>
                            </div>
                          )}
                        </>}


                      </div>
                    </div>
                  )}

                  {stepActive === 2 && (
                    <>
                      <div>
                        <div className="w-full flex justify-between text-[23px] text-[#ACADB1] flex-1 font-arabicmed mb-3">
                          <p>Payment Method</p>
                          <button
                            className="text-[23px] font-arabicmed text-[#6FCF17]  hover:text-[#a6e66b] "
                            onClick={paymentMethodModal}
                          >
                            Add +
                          </button>
                        </div>
                        {isPayMethod && (
                          <div className="modal w-full min-h-20">
                            <div className=" modal-content font-arabicmed rounded-lg overflow-hidden w-full">
                              <div className="w-full flex items-center border-b-[1px] border-[#3C403F] justify-between bg-[#2E2E2E] py-[12px] px-6">
                                <h1 className="text-[20px]">
                                  Add New Payment Method
                                </h1>
                                <div className="flex items-center justify-center rounded-lg bg-[#252525] border-[1px] border-[#818584] h-[26px] w-[26px] p-2">
                                  <span className="close" onClick={() => setIsPayMethod(false)}>
                                    &times;
                                  </span>
                                </div>
                              </div>
                              <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6 ">
                                <div className="w-full flex-col items-center justify-between font-arabicreg py-4 my-2 text-[16px]">
                                  <div className="flex-col justify-between gap-4 my-4">
                                    <div className="w-full pb-4">
                                      <h1 className="text-white font-arabicmed py-2">
                                        Payment Method Name
                                      </h1>
                                      <input
                                        type="text"
                                        className="h-[48px] px-4 w-full rounded-lg bg-[#111010] border border-[#3C403F] text-[14px] text-white font-arabicmed"
                                        placeholder="Name"
                                        id="Title"
                                      />
                                      {formErrors.length > 0 &&
                                        formErrors[0] && (
                                          <div style={{ color: "red" }}>
                                            {formErrors[0]}
                                          </div>
                                        )}
                                    </div>
                                    <div className="w-full pb-4" id="paymentFields">
                                      <h1 className="text-white font-arabicmed ">
                                        Required Information
                                      </h1>
                                      <div className="flex-col justify-between gap-4 my-2">
                                        {formInputs.map(
                                          (input, index) => (
                                            <div className="w-full flex-col mb-4">
                                              <div
                                                className="w-full flex"
                                                key={input.id}
                                              >
                                                <input
                                                  type="text"
                                                  className="h-[48px] px-4 my-2 w-full rounded-lg bg-[#111010] border border-[#3C403F] text-[14px] text-white font-arabicmed"
                                                  placeholder="Field name"
                                                  name="title"
                                                  value={input.title}
                                                  onChange={(event) =>
                                                    handleFormInputChange(
                                                      index,
                                                      event
                                                    )
                                                  }
                                                />

                                                <button
                                                  onClick={() =>
                                                    handleRemoveInput(
                                                      index
                                                    )
                                                  }
                                                >
                                                  {/* Remove */}
                                                  <img
                                                    src={remove}
                                                    alt=""
                                                    style={{
                                                      width: "36px",
                                                      height: "auto",
                                                      margin: "0 10px ",
                                                    }}
                                                  />
                                                </button>
                                              </div>

                                              {formErrors[index] && (
                                                <div
                                                  style={{ color: "red" }}
                                                >
                                                  {formErrors[index]}
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                        <div className="w-full flex justify-center py-4">
                                          <button
                                            onClick={handleAddFormInput}
                                            className="flex text-[18px] font-arabicbold gap-2"
                                          >
                                            <img
                                              src={add}
                                              alt=""
                                              style={{
                                                display: "block",
                                                margin: "auto",
                                              }}
                                            />
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C]  py-2 w-full mt-10 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:from-[#82e402] hover:to-[#146626] flex justify-center gap-2 items-center"
                                    // onClick={handlePaymentSubmit}
                                    onClick={handleFormSubmit}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {openNotice && (
                          <div className="modal w-full">
                            <div className=" modal-content font-arabicmed rounded-lg overflow-hidden w-full">
                              <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6">
                                <div className="w-full flex flex-col items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                  <img
                                    src={notice}
                                    className="py-4"
                                  />
                                  <p className="text-white text-[24px] text-center sm:text-[18px] font-arabicbold">
                                    Add Payment Method
                                  </p>
                                  <p className="text-[#AEB2B1] text-center py-2 font-arabicbold">
                                    Are you sure you want to add
                                    this payment method?
                                  </p>
                                </div>

                                <div className="w-full flex sm:flex-col items-center py-4 pt-4 my-2 gap-4">
                                  <button
                                    className="bg-[#252525] w-full sm:order-2 border-2 border-[#3C403F] rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#363636]"
                                    onClick={() => setOpenNotice(false)}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full sm:order-1 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:from-[#82e402] hover:to-[#146626] "
                                    onClick={postPaymentMethodTemplate}
                                  >
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {newDetailsOptions.map(option => (
                          <div
                            onClick={(e) => handleSelectForm(e, option.value)}
                            value={option.value}
                            className="flex justify-between gap-4 bg-[#252525] border border-[#3C403F] rounded-lg p-6 mb-4 cursor-pointer"
                          >
                            <div className="flex gap-4">
                              <div>
                                <h1 className="text-white font-arabicmed text-[18px]">
                                  {option.label}
                                </h1>
                                <p className="text-[#6FCF17] border border-[#6FCF17] rounded-lg bg-[#3C502E] text-center text-[12px] my-2">
                                  {option.value}
                                </p>
                                {/* <div className="flex gap-2 sm:flex-wrap">
                                  {option.label}
                                </div> */}
                              </div>
                            </div>
                            <button>
                              <img src={leftarrow} />
                            </button>
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  {stepActive === 3 && (
                    <>
                      {isSuccess ? (
                        <div className="bg-[#252525] border border-[#3C403F] p-8 rounded-lg text-center">
                          <img src={confetti} className="mx-auto my-4 w-50 h-50" />
                          <h1 className="text-white text-[20px] font-arabicbold">
                            Payment Method Added Successfully
                          </h1>
                          <p className="text-[#AEB2B1] text-[16px] font-arabicreg mb-10">
                            Thank you for adding your payment method.{" "}
                          </p>
                          <div className="flex justify-between gap-4 my-4">
                            <button
                              className="text-white text-[14px] bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-3 w-full"
                              onClick={() => navigate("/p2p/buy")}
                            >
                              Go to P2P
                            </button>
                            <button
                              className="text-white text-[14px] bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-lg py-3 w-full"
                              onClick={handleGoBack1}
                            >
                              See Payment Methods
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div id="forms" ref={formsDivRef}>
                            {renderedComponents}
                          </div>
                          <button
                            className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C]  py-2 w-full mt-10 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:from-[#82e402] hover:to-[#146626] flex justify-center gap-2 items-center"
                            // onClick={handlePaymentSubmit}
                            onClick={handleSubmitPaymentDetails}
                          >
                            Save
                          </button>
                        </>
                      )}
                    </>
                  )}

                  {isOpen && (
                    <div className="modal w-full">
                      <div className=" modal-content font-arabicmed rounded-lg overflow-hidden w-full">
                        <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6">
                          <div className="w-full flex flex-col items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                            <img src={notice} className="py-4" />
                            <p className="text-white text-[24px] text-center sm:text-[18px] font-arabicbold">
                              Add Payment Method
                            </p>
                            <p className="text-[#AEB2B1] text-center py-2 font-arabicbold">
                              Are you sure you want to add this payment method?
                            </p>
                          </div>

                          <div className="w-full flex sm:flex-col items-center py-4 pt-4 my-2 gap-4">
                            <button
                              className="bg-[#252525] w-full sm:order-2 border-2 border-[#3C403F] rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#363636]"
                              onClick={closeModal}
                            >
                              Cancel
                            </button>
                            <button
                              className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full sm:order-1 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:from-[#82e402] hover:to-[#146626] "
                              // onClick={postAdvertisement}
                              // onClick={postPaymentMethod}
                              onClick={postPaymentMethod}
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
          <SidebarArea typeShowAside={typeShowAside} />
        </div>
      </div>
    </>
  );
};
