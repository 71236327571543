import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import copy from "../../assets/img/p2p/copyID.svg";
import next from "../../assets/img/p2p/table-next.svg";
import previous from "../../assets/img/p2p/table-previous.svg";
import {
  useTraderOrderList
} from "../../queries/p2p";
import { dateFormatter } from "../../utils";
import ButtonLoader from '../ButtonLoader';
import { Dropdown } from "../transaction/Dropdown";

export const TraderOrderProcessing = () => {
  const [status, setStatus] = useState("");
  const [txid, setTxid] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderList, setOrderList] = useState([]);

  const [filters, setFilters] = useState({
    currency: "",
    date: "",
    type: "",
    status: "",
  });
  const { data: orderData, refetch, isLoading } = useTraderOrderList({ ...filters, page: currentPage });

  useEffect(() => {
    setOrderList(orderData?.data?.docs);
    setTotalPages(orderData?.data?.totalPages);
  }, [orderData]);

  const handleClick = () => {
    window.location.href = "/p2p/trader-order/details";
  };

  const [activeButton, setActiveButton] = useState(1);
  const handleButtonClick = (active) => {
    setActiveButton(active);
  };


  const formatText = (text) => {
    return text
      ?.split("_")
      ?.map(
        (word) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      ?.join(" ");
  };

  const options = [
    { data: "All", value: "" },
    { data: "USDT", value: "USDT" },
    { data: "BTC", value: "BTC" },
    { data: "TUSD", value: "TUSD" },
    { data: "Completed", value: "completed" },
    { data: "BNB", value: "BNB" },
    { data: "ETH", value: "ETH" },
    { data: "DOGE", value: "DOGE" },
    { data: "BUSD", value: "BUSD" },
  ];


  const orderTypes = [
    { data: "All", value: "" },
    { data: "Buy", value: "to_buy" },
    { data: "Sell", value: "for_sell" },
  ];

  const dates = [
    { data: "All", value: "" },
    { data: "Daily", value: "daily" },
    { data: "Weekly", value: "weekly" },
    { data: "Monthly", value: "monthly" },
  ];


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  return (
    <div>
      <div className="flex justify-start gap-4 mb-10 sm:overflow-x-auto">
        <button
          className={`border ${activeButton === 1
            ? "border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]"
            : "border-[#3C403F] bg-[##111010] text-[#AEB2B1]"
            } p-2 rounded-xl w-24`}
          onClick={() => {
            handleButtonClick(1);
          }}
        >
          All
        </button>
        <button
          className={`border ${activeButton === 2
            ? "border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]"
            : "border-[#3C403F] bg-[#111010] text-[#AEB2B1]"
            } p-2 rounded-xl w-24`}
          onClick={() => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              status: 'inEscrow'
            }));
            setActiveButton(2);
          }}
        >
          Pending
        </button>

        <button
          className={`border ${activeButton === 3
            ? "border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]"
            : "border-[#3C403F] bg-[##111010] text-[#AEB2B1]"
            } p-2 rounded-xl w-24`}
          onClick={() => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              status: 'released'
            }));
            setActiveButton(3);
          }}
        >
          Released
        </button>
        <button
          className={`border ${activeButton === 4
            ? "border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]"
            : "border-[#3C403F] bg-[##111010] text-[#AEB2B1]"
            } p-2 rounded-xl w-24`}
          onClick={() => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              status: 'cancelled'
            }));
            setActiveButton(4);
          }}
        >
          Cancelled
        </button>
      </div>
      <div className="grid grid-cols-4 md:grid-cols-1 gap-6 mb-10">
        <Dropdown
          value={filters.currency}
          onChange={(e) => setFilters((prevFilters) => ({
            ...prevFilters,
            currency: e.target.value
          }))}
          head="Coins"
          list={options}
        />

        <Dropdown
          value={filters.type}
          onChange={(e) => setFilters((prevFilters) => ({
            ...prevFilters,
            type: e.target.value
          }))}
          head="Order Types"
          list={orderTypes}
        />

        {/* <Dropdown
          value={filters.date}
          onChange={(e) => setFilters((prevFilters) => ({
            ...prevFilters,
            date: e.target.value
          }))}
          head="Dates"
          list={dates}
        /> */}

      </div>

      {isLoading ? <>
        <ButtonLoader />
      </> : <>
        <div className="lgmed:overflow-auto">
          <table className="my-8 w-full lgmed:min-w-[800px]">
            <thead>
              <tr className="h-[56px] bg-[#252525] border-[1px] border-[#3C403F]">
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Type of Coin
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Fiat Amount
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Price
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Crypto Amount
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Type
                </th>
                {/* <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                Buy/Seller Email
              </th> */}
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Status
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Fiat Paid
                </th>
                <th className="pl-4 text-left font-arabicreg text-[16px] text-[#AEB2B1]">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {orderList?.length === 0 ? (
                <tr>
                  <td colSpan="7" className="text-center text-gray-500 py-4">
                    No data available
                  </td>
                </tr>
              ) : (
                orderList?.map((row, key) => (
                  <tr
                    key={key}
                    className="h-[64px] bg-[#1A1A1B] border-b-[1px] border-b-[#3C403F] border-l-[1px] border-l-[#3C403F] border-r-[1px] border-r-[#3C403F] "
                  >
                    <td
                      className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                    >
                      {/* {row.txid} */}
                      <div className="flex flex-col">
                        {row?.advertisement?.cryptoCurrency}
                        <div className="flex">
                          <div className="flex text-[14px] text-[#AEB2B1] items-center">
                            {row?._id}

                            <img src={copy} alt="copy-text" className="pl-2" />
                          </div>
                        </div>
                        {dateFormatter(row?.createdAt)}
                      </div>
                    </td>
                    <td
                      className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                    >
                      {/* {row.txid} */}
                      {row?.fiatAmount} {row?.advertisement?.fiatCurrency}
                    </td>
                    <td
                      className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                    >
                      {row?.exchangeRate} {row?.advertisement?.fiatCurrency}
                    </td>
                    <td
                      className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                    >
                      {row?.amount} {row?.advertisement?.cryptoCurrency}
                    </td>
                    <td
                      className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                    >
                      {row?.advertisement?.adsType === 'for_sell' ? <>
                        Sell
                      </> : <>
                        Buy
                      </>}
                    </td>
                    {/* <td
                    className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                  >
                    {row?.sender?.email?.toLowerCase()} <br />
                    {row?.receiver?.email?.toLowerCase()}
                  </td> */}

                    <td
                      className={`pl-4 font-arabicreg capitalize text-[16px] text-[#fff] flex  mt-4`}
                    >
                      <div className="flex flex-col ">
                        <div className="flex items-center gap-1">
                          <div className="w-2 h-2 rounded-full bg-[#11E311]"></div>
                          {row.status}
                        </div>

                      </div>
                    </td>
                    <td
                      className={`pl-4 font-arabicreg text-[16px] text-[#fff] capitalize`}
                    >
                      <div className="flex items-center gap-1">
                        {row?.isFiatPaid ? (
                          <>
                            <div className="w-2 h-2 rounded-full bg-[#11E311]"></div>
                            True
                          </>
                        ) : (
                          <>
                            <div className="w-2 h-2 rounded-full bg-red-500"></div>
                            False
                          </>
                        )}
                      </div>
                    </td>
                    <td
                      className={`pl-4 font-arabicreg capitalize text-[16px] text-[#fff] mt-8`}
                    >
                      {row?.advertisement?.adsType === 'to_buy' && row?.myRole === 'receiver' ? <>
                        <Link to={`/p2p/buy/details?details=${row?._id}`}>
                          <h1 className="font-arabicreg text-[16px] text-lg text-[#F1A741]">
                            View Order
                          </h1>
                        </Link>
                      </> : <>
                        <Link to={`/p2p/sell/details?details=${row?._id}`}>
                          <h1 className="font-arabicreg text-[16px] text-lg text-[#F1A741]">
                            View Order
                          </h1>
                        </Link>
                      </>}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {orderList &&
          orderList?.filter(
            (row) => row?.cryptoCurrency === filters?.cryptoCurrency
          ).length > 0 && (
            <div className="flex pagination-table-buttons items-center justify-center mt-8">
              <button
                className="flex items-center mr-2"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <img src={previous} alt="previous button" />

                <p>Previous</p>
              </button>
              {Array.from({ length: totalPages }).map((_, index) => (
                <button

                  key={index}
                  className={`rounded count ${currentPage === index + 1 ? "active" : ""
                    }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
              <button
                className="flex active items-center ml-2"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <p>Next</p>
                <img src={next} alt="next button" />
              </button>
            </div>
          )}
      </>}
    </div>
  );
}

