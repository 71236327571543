import React, { useState } from "react";
import "../assets/css/sign-in.css";
import signInMainImg from "../assets/img/sign-in-girl.png";

import button from "../assets/img/Button.svg";
import button1 from "../assets/img/Button-1.svg";
import button2 from "../assets/img/Button-2.svg";
import button3 from "../assets/img/Button-3.svg";
import button4 from "../assets/img/Button-4.svg";
import button5 from "../assets/img/Button-5.svg";
import eye from "../assets/img/eye.svg";

import { CheckboxStyle } from "./CheckboxStyle";
export const SignIn = ({
  setsigninState,
  setSignUpState,
  setRepeatPasswordState,
  setloginType,
}) => {
  const [type, setType] = useState(true);
  return (
    <div className="SignIn LayerUp">
      <div className="inner-work-sign-in grid grid-cols-2">
        <div className="left-side px-[26px] flex items-center justify-center flex-col">
          <img src={signInMainImg} alt="sign in main presentation" />
          <div className="presentation">
            <h1>Welcome to BetWin</h1>
            <p className="mt-4">
              Welcome to BetWin, where you've just made the perfect choice by
              signing up! We're thrilled to have you on board, and we're here to
              set you up for success.
            </p>
          </div>
        </div>
        <div className="right-side  px-8 py-10">
          <form action="#" className="flex flex-col h-full">
            <h1 className="mb-12">Sign in</h1>
            <div className="input-wrapper">
              <label htmlFor="email">Email / Phone Number</label>
              <input
                type="text"
                placeholder="Jackrose11@gmail.com"
                id="email"
                className="rounded-lg px-6 mt-3"
              />
            </div>
            <div className="input-wrapper mt-5 mb-9">
              <label htmlFor="password">Login Password</label>
              <input
                type={type ? "password" : type}
                placeholder="Enter your passwoard"
                id="password"
                className="rounded-lg px-6 mt-3"
              />
              <img
                src={eye}
                className="eye-icon"
                onClick={(e) => {
                  setType(!type);
                }}
                alt="eye icon"
              />
            </div>

            <div className="flex items-center justify-between remember-me-portion">
              <div className="checkbox-wrapper flex items-center">
                <CheckboxStyle id="remember-me" type="checkbox" />
                <label htmlFor="" className="ml-1">
                  Remember me
                </label>
              </div>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setsigninState(false);
                  setRepeatPasswordState(true);
                }}
              >
                Forgot Password?
              </a>
            </div>

            <button
              className="w-full rounded-lg mt-4 mb-9"
              onClick={(e) => {
                e.preventDefault();
                setsigninState(false);
                setloginType(true);
              }}
            >
              Sign In
            </button>
            <p className="dont-have-p">
              Don’t have an account!{" "}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setsigninState(false);
                  setSignUpState(true);
                }}
              >
                Sign up
              </a>
            </p>

            <div className="ending-point  flex-1 flex flex-col  justify-end">
              <div className="line-breaker flex items-center mt-8">
                <span className="flex-1 mr-2"></span>
                <p>Or Login with</p>
                <span className="flex-1 ml-2"></span>
              </div>

              <div className="mt-7 social-icons flex items-center justify-between">
                <a href="/">
                  <img src={button} alt="social button" />
                </a>
                <a href="/">
                  <img src={button1} alt="social button" />
                </a>
                <a href="/">
                  <img src={button2} alt="social button" />
                </a>
                <a href="/">
                  <img src={button3} alt="social button" />
                </a>
                <a href="/">
                  <img src={button4} alt="social button" />
                </a>
                <a href="/">
                  <img src={button5} alt="social button" />
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
