import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import profile from "../../assets/img/p2p/profile.svg";
import verified from "../../assets/img/p2p/verified.svg";
import backImg from "../../assets/img/transaction/back.svg";
import { privateRequest } from "../../config/axios.config";
import { useAuth } from "../../contexts/AuthContext";
import { UseGetRatings } from "../../queries/p2p";
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";
import { Dropdown } from "../transaction/Dropdown";
import { Input } from "../transaction/Input";
import { BlockListTable } from "./BlockListTable";
import { MyAdsBuyTable } from "./MyAdsBuyTable";
import { MyAdsSellTable } from "./MyAdsSellTable";
import { P2PNav } from "./P2PNav";
import { RatingsTable } from "./RatingsTable";

export const RightAreaP2PMyAds = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const navigate = useNavigate();
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [active, setActive] = useState(true);
  const [activeOption, setActiveOption] = useState("OnlineAds");
  const { userInfo } = useAuth();
  const id = userInfo?.user?._id;
  const [searchInput, setSearchInput] = useState("");
  const [idx, setIdx] = useState("");
  const [status, setStatus] = useState("");
  const [selectedRank, setSelectedRank] = useState("");

  const handleSearchFetch = (e) => {
    setSearchInput(e.target.value);
    //   refetch();
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setSearchInput("");
    setSelectedRank("");
    // refetch();
  };

  const { data, isLoading } = useQuery("advertiserProfile", async () => {
    try {
      const res = await privateRequest.get(
        `user/p2p/advertisement/profile/${id}`
      );
      return res?.data?.profile;
    } catch (error) {
    }
  });
  const userRatings = UseGetRatings();




  return (
    <div className="flex-1">
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />

      <P2PNav />

      <div className="body-area flex items-start px-6 pt-6">
        <div className="body-left-area w-full">
          <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
            <div className="flex justify-between items-center p-6 border-[#3C403F] border-b-[1px]">
              <div className="flex items-center">
                <Link to="/p2p/buy">
                  <img src={backImg} alt="" />
                </Link>
                <h1 className="text-white text-[26px] text-center ml-4">P2P</h1>
              </div>

              <Link
                target="_blank"
                className="text-[20px] font-arabicmed text-[#6FCF17] hover:text-[#a6e66b]"
              >
                Terms and Conditions
              </Link>
            </div>

            <div className="p-6">
              <div className="flex justify-between mb-4 sm:flex-col">
                <div className="flex gap-2 sm:mb-4">
                  <img
                    src={data?.user?.avatar ? data?.user?.avatar : profile}
                    className="w-[80px] h-[80px] sm:w-[50px] sm:h-[50px]"
                  />
                  <div>
                    <div className="flex justify-between items-start gap-4 sm:grid sm:grid-cols-2">
                      <h1 className="text-white text-[20px] font-arabicbold flex gap-2 sm:text-[18px]">
                        {data?.length > 0 ? <>
                          {data?.user?.name
                            ? data?.user?.name
                            : data?.user?.email}
                        </> : <>
                          {userInfo?.user?.email}
                        </>}
                        <img src={verified} />
                      </h1>
                      {/* <h2 className="text-white bg-[#3C502E] border border-[#6FCF17] rounded-lg px-3 text-center cursor-pointer">
                        + Follow
                      </h2>
                      <p className="text-white">
                        <a className="cursor-pointer">Block</a>&nbsp; | &nbsp;
                        <a className="cursor-pointer">Report</a>
                      </p> */}
                    </div>
                    <p className="text-[#AEB2B1] text-[14px] mt-4">
                      Online | Joined on  {data?.length > 0 ? <>
                        {data?.user?.createdAt}
                      </> : <>
                        {userInfo?.user?.createdAt}
                      </>}
                    </p>
                  </div>
                </div>
                <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-4 ">
                  <div className="flex justify-between gap-8 sm:gap-2">
                    <div>
                      <p className="text-[#AEB2B1] text-[14px] mb-3 mt-2">
                        Positive Feedback
                      </p>
                      <h1 className="text-white text-[20px] font-arabicbold">
                        0% (0)
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#AEB2B1] text-[16px] flex gap-6 items-center justify-end mb-2">
                        Positive
                        <span className="text-center text-white bg-[#3C502E] border border-[#6FCF17] py-1 w-[72px] rounded-lg">
                          {data?.user?.like ?? 0}
                        </span>
                      </p>
                      <p className="text-[#AEB2B1] text-[16px] flex gap-4 items-center justify-end">
                        Negative
                        <span className="text-center text-white bg-[#ff707e3a] border border-[#FF707E] py-1 w-[72px] rounded-lg">
                          {data?.user?.dislike ?? 0}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mb-4 gap-4 sm:grid sm:grid-cols-2">
                <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-4 text-center w-full">
                  <p className="text-[#AEB2B1] text-[14px] mb-3">All Trades</p>
                  <h1 className="text-white text-[20px] font-arabicbold mb-1">
                    {data?.totalTrades ? `${data.totalTrades} Time(s)` : "0"}
                  </h1>
                  <p className="text-[#AEB2B1] text-[14px]">
                    <span className="text-[#6FCF17]">
                      Buy {data?.totalBuyAds ?? 0}
                    </span>
                    &nbsp; / &nbsp;{" "}
                    <span className="text-[#FF707E]">
                      Sell {data?.totalSellAds ?? 0}
                    </span>
                  </p>
                </div>
                <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-4 text-center w-full">
                  <p className="text-[#AEB2B1] text-[14px] mb-3">30d Trades</p>
                  <h1 className="text-white text-[20px] font-arabicbold">

                    {data?.last30DaysTrades ? `${data?.last30DaysTrades} Time(s)` : "0"}
                  </h1>
                </div>
                <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-4 text-center w-full">
                  <p className="text-[#AEB2B1] text-[14px] mb-3">
                    30d Completion Rate
                  </p>
                  <h1 className="text-white text-[20px] font-arabicbold">
                    {/* {data?.last30DayCompletionRate} % */}
                    {data?.last30DayCompletionRate ? `${data?.last30DayCompletionRate}Time(s)` : "0"}
                  </h1>
                </div>
                <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-4 text-center w-full">
                  <p className="text-[#AEB2B1] text-[14px] mb-3">
                    Avg. Release Time
                  </p>
                  <h1 className="text-white text-[20px] font-arabicbold">
                    {/* {data?.avgReleaseTime} Minute(s) */}
                    {data?.avgReleaseTime ? `${data?.avgReleaseTime} Minute(s)` : "0"}
                  </h1>
                </div>
                <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-4 text-center w-full">
                  <p className="text-[#AEB2B1] text-[14px] mb-3">
                    Avg. Pay Time
                  </p>
                  <h1 className="text-white text-[20px] font-arabicbold">
                    {/* {data?.avgPayTime} Minute(s) */}
                    {data?.avgPayTime ? `${data?.avgPayTime} Minute(s)` : "0"}
                  </h1>
                </div>
              </div>
              <div className="flex justify-between rounded-md w-full max-w-[360px] sm:max-w-full sm:mb-4 border border-[#3C403F] bg-[#252525]">
                <div
                  className={`flex-nowrap text-white text-center font-arabicmed rounded-md text-[14px] py-2 w-full  cursor-pointer ${activeOption === "OnlineAds"
                    ? "bg-[#6FCF17]"
                    : "bg-[#252525]"
                    }`}
                  onClick={() => setActiveOption("OnlineAds")}
                >
                  Online Ads
                </div>
                <div
                  className={`flex-nowrap text-white text-center font-arabicmed rounded-md text-[14px] py-2 w-full whitespace-nowrap px-2 cursor-pointer ${activeOption === "Feedback"
                    ? "bg-[#6FCF17]"
                    : "bg-[#252525]"
                    }`}
                  onClick={() => setActiveOption("Feedback")}
                >
                  Feedback ({userRatings?.data?.data?.length ?? 0})
                </div>
                <div
                  className={`flex-nowrap text-white text-center font-arabicmed rounded-md text-[14px] py-2 w-full whitespace-nowrap px-2 cursor-pointer ${activeOption === "Block-list"
                    ? "bg-[#6FCF17]"
                    : "bg-[#252525]"
                    }`}
                  onClick={() => setActiveOption("Block-list")}
                >
                  Block List
                </div>
              </div>

              {activeOption === "OnlineAds" && (
                <>
                  <h1 className="text-white text-[20px] font-arabicbold mt-4">
                    Online Buy Ads
                  </h1>
                  <MyAdsBuyTable />
                  <h1 className="text-white text-[20px] font-arabicbold mt-4">
                    Online Sell Ads
                  </h1>
                  <MyAdsSellTable />
                </>
              )}
              {activeOption === "Feedback" && <RatingsTable ratings={userRatings?.data} />}

              {activeOption === "Block-list" && (
                <div className="px-2 py-4 ">
                  <div className="bottom-agent bottom-agent-page p-2 flex items-end">
                    <div
                      className="grid grid-cols-5 sm:grid-cols-1 gap-4 mb-4"
                      style={{ width: "100%" }}
                    >

                      <div className="col-span-3">
                        <Input
                          value={idx}
                          onChange={(e) => setIdx(e.target.value)}
                          head="Search"
                          placeholder="Search email, agent name, first name, last name"
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                      <div className="col-span-1">
                        <Dropdown
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          head="Size"
                          list={[
                            {
                              data: "10",
                              value: "10",
                            },
                            {
                              data: "20",
                              value: "20",
                            },
                            {
                              data: "30",
                              value: "30",
                            },
                          ]}
                        />
                      </div>

                      <div className="flex flex-col justify-end items">
                        <button
                          onClick={resetFilter}
                          className="rounded-lg px-4  py-[13px] bg-[#2E2E2E] text-white "
                        >
                          Reset Filter
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="lgmed:overflow-auto">
                    <BlockListTable
                    // orderList={orderList
                    //   ?.filter((el) => el._id?.includes(txid))
                    //   ?.filter((el) => el.status.includes(status))}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};
