import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { privateRequest } from "../config/axios.config";
import { useGetAllBalance } from "../queries/balance";
import { useGetAllWalletAddresses } from "../queries/wallet";
import { CurrencyCoins } from "../utils/staking/CurrencyCoins";
import ButtonLoader from "./ButtonLoader";
import { HeaderStaker } from "./HeaderStaker";
import AcceptedCard from "./common/AcceptedCard";
import { PayDropdown } from "./popups/PayDropdown";
import { SwitchPayment } from "./staker/SwitchPayment";
import DepositCryptoCurrencyDropdown from "./wallet/DepositCryptoCurrencyDropdown";

export const RightAreaStakePayment = ({
  setsigninState,
  setSignUpState,
  login,
  active,
}) => {
  const navigate = useNavigate();
  const [fiatConditional, setfiatConditional] = useState(1);
  const [currencyCode, setCurrencyCode] = useState("PHP");
  const [coin, setCoin] = useState();
  const [Paycheck, setPaycheck] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState();
  const [provider, setProvider] = useState();
  const [loading, setLoading] = useState(false);
  const [fiatAmount, setFiatAmount] = useState(0);
  const [loadingGcash, setLoadingGcash] = useState(false);
  const [loadingEplayment, setLoadingEplayment] = useState(false);
  const { data: walletAddresses, isLoading: isLoadingWalletAddresses } = useGetAllWalletAddresses();
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const { data: allBalance } = useGetAllBalance();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [currencyAmount, setCurrencyAmount] = useState(0);
  const handleCurrencyChange = (selectedCurrency) => {
    setCurrencyCode(selectedCurrency);

    if (allBalance && allBalance.syntheticWalletBalance[selectedCurrency]) {
      const balance = allBalance.syntheticWalletBalance[selectedCurrency];
      // Do something with the balance object for the selected currency
      setCurrencyAmount(balance?.total);
    } else {
      // Handle the case where the selected currency is not found
      console.log(`Balance for ${selectedCurrency} not found`);
    }
  };


  // Use useEffect to set the default payment method based on the active prop
  const getConversion = () => {
    setLoading(true); // Start loading
    const amount = window.sessionStorage.getItem("stake-amount");

    privateRequest
      .post("/user/stacking/get-fiat-conversion", {
        amount,
        currency: currencyCode,
      })
      .then((res) => {
        const fiatAmount = res.data.fiatAmount;
        setFiatAmount(fiatAmount);
        localStorage.setItem("phpAmount", fiatAmount.toString());
        localStorage.setItem("fiatAmount", amount.toString());
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching conversion: ", err);
        toast.error("Error in currency conversion");
      });
  };

  useEffect(() => {
    getConversion();
  }, [currencyCode]);
  useEffect(() => {
    const storedFiatAmount = localStorage.getItem(
      currencyCode === "PHP" ? "phpAmount" : "fiatAmount"
    );

    if (storedFiatAmount) {
      setFiatAmount(storedFiatAmount);
    }
    switch (active) {
      case 1:
        setPaymentMethod("cryptoWallet");
        setProvider("cryptoWallet");
        setCoin({ currency: "BTC" });
        break;
      case 2:
        setPaymentMethod("fiat");
        setProvider("moneyCollect");
        setCoin({ currency: currencyCode });
        break;
      default:
        setPaymentMethod("cashwallet");
        setCoin({ currency: "USD" });
        setProvider("cashWallet");
    }
  }, [active, currencyCode]); // Dependency array with active ensures this runs when active prop changes


  const stakeNow = (eplaymentOption = null) => {
    setLoading(true);

    const amount = window.sessionStorage.getItem("stake-amount");
    const id = window.sessionStorage.getItem("stake-package-id");

    const payload = {
      packageId: id,
      amount: amount,
      paymentCurrency: currencyCode,
      paymentMethod: paymentMethod,
      provider: provider,
    };

    privateRequest
      .post("user/stacking/subscribe", payload)
      .then((res) => {
        console.log(res.data);
        // redirect to url
        if (provider === 'moneyCollect') {
          window.location.href = res?.data?.subscription?.paymentMeta?.paymentUrl
        } else {
          navigate("/stake/done", { state: { data: res.data.subscription } });
        }

        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };
  //console.log(CurrencyCoins);

  if (loading && provider === "eplayment") {
    return (
      <div className="flex justify-center items-center w-full h-screen bg-[#1A1A1B]">
        <div className="text-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-green-900 mx-auto"></div>
          <p className="text-white text-lg mt-3">
            Getting you to the payment processor page, please wait...
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />
      <div className="flex-1">
        <HeaderStaker
          headerType="Staker"
          login={login}
          settypeShowAside={1}
          setsigninState={setsigninState}
          setSignUpState={setSignUpState}
        />
        <div className="body-area flex-1 px-6 pt-6 DepositePopUp">
          <div className="px-10 py-6 bg-[#1A1A1B] border-[1px] rounded-xl border-[#3C403F]  sm:px-5">
            <div className="flex items-center gap-8 mb-8 sm:gap-2 sm:flex-col sm:items-start">
              <h1 className="text-white text-[22px] font-arabicmed">
                Payment method
              </h1>
              <SwitchPayment active={active} classStyle="sm:ml-0 sm:mt-0 " />
            </div>

            <div className="bg-[#252525] p-6 rounded-xl ">
              <div className="flex items-center justify-between xs:flex-col">
                {active === 1 ? (
                  <>
                    <h1 className="text-[18px] sm:text-[14px] font-arabicmed text-white">
                      Select your coin
                    </h1>
                    <p className="text-[#AEB2B1] text-[18px] sm:text-[14px] font-arabicreg">
                      Your Available balance {parseFloat(currencyAmount).toFixed(2)}
                    </p>
                  </>
                ) : (
                  <>
                    <h1 className="text-[18px] sm:text-[14px] font-arabicmed text-white">
                      Select your currency
                    </h1>
                  </>
                )}
              </div>
              <div className="mt-4 w-full pr-6 pl-[6px] h-[50px] rounded-[10px] flex items-center bg-[#2E2E2E]">
                {active === 1 ? (
                  <>
                    {isLoadingWalletAddresses ? <>
                      <ButtonLoader />
                    </> : <>
                      <DepositCryptoCurrencyDropdown
                        onCurrencyChange={handleCurrencyChange}
                        list={Object.keys(walletAddresses?.data).map(currency => ({ label: currency, value: currency }))}
                      />
                    </>}

                  </>
                ) : (
                  <>
                    <PayDropdown
                      classStyle="bg-[#3A3A3A] min-w-[196px] sm:min-w-[120px]"
                      callback={(e) => {
                        console.log(e);

                        setCurrencyCode(e.heading);
                        if (e.heading === "PHP") {
                          setPaycheck(true);
                        } else {
                          setPaycheck(false);
                        }
                      }}
                      dollar={CurrencyCoins[2].img}
                      stakeFix
                      heading={CurrencyCoins[2].heading}
                      list={CurrencyCoins}
                    />
                    <p className="text-2xl text-white pl-4">
                      {loading ? (
                        <span className="animate-pulse text-xs">Updating...</span>
                      ) : (
                        fiatAmount
                      )}
                    </p>
                  </>
                )}
              </div>
            </div>



            <div className="your-container-class">
              {active === 2 ? (
                <>
                  {/* {currencyCode === "PHP" ? (
                  <>
                    <BankTransfer Paycheck={true} />
                    <div className="flex space-x-4 !mt-12 mb-10 p-4 buttons-wrapper">
                      <button
                        className="active px-4 !text-lg hover:bg-blue-500 hover:text-white hover:scale-105 transition duration-200 ease-in-out"
                        onClick={() => stakeNow("gcash")}
                        disabled={loadingEplayment}
                      >
                        {loadingEplayment
                          ? "Processing..."
                          : "Pay via Gcash"}
                      </button>
                    </div>
                  </>
                ) : ( */}
                  <div className="mt-5 mb-16 buttons-wrapper">
                    <AcceptedCard />
                    <button
                      className="active px-20 mb-4 !text-lg transition duration-200 ease-in-out"
                      onClick={() => stakeNow("none")}
                      disabled={loading}
                    >
                      {loading ? "Processing..." : "Pay Now"}
                    </button>
                  </div>
                  {/* )} */}
                </>
              ) : (
                <div className="space-x-4 !mt-12 mb-10 p-4 buttons-wrapper">
                  <button
                    className="active px-20 !text-lg transition duration-200 ease-in-out"
                    onClick={() => stakeNow("none")}
                    disabled={loading}
                  >
                    {loading ? "Processing..." : "Confirm"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
