import { useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

export const useGetSubscription = (status) =>
  useQuery(["subscription", status], async () => {
    try {
      const res = await privateRequest.get("user/stacking/subscriptions", { params: { status } });
      return res.data.subscriptions;
    } catch (error) {
      console.log(error);
      throw new Error(
        error?.response?.data?.message ?? "Error getting subscription"
      );
    }
  });

