import React from "react";

export const Popup = ({ setactive }) => {
  return (
    <div className="delete-popup ">
      <span
        className="bg-popup"
        onClick={(e) => {
          setactive(false);
        }}
      ></span>
      <div className="flex flex-col rounded-lg py-10 px-20 items-center justify-center">
        <h1>Are you sure?</h1>
        <p className="my-3">You want to Deactivate it!</p>
        <div className="buttons-wrapper gap-5 flex items-center justify-center">
          <button
            className="px-5 py-2"
            onClick={(e) => {
              setactive(false);
            }}
          >
            {" "}
            Yes, Deactivate it!
          </button>
          <button
            className="px-5 py-2 del"
            onClick={(e) => {
              setactive(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
