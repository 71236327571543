import React, { useState } from "react";
import arrowDown from "../../assets/img/popup/arrow-right.svg";
export const DepositeYourMailDropdown = ({ dollar, heading, list }) => {
  const [active, setActive] = useState(false);
  const [data, setData] = useState({
    img: dollar,
    heading: heading,
  });
  return (
    <div className="dropdown relative">
      <div
        className="input-notify-wrapper rounded-lg  mt-3 flex items-center px-6"
        onClick={(e) => {
          setActive(!active);
        }}
      >
        <img src={data.img} alt="" className="mr-2 w-5" />
        <p className="flex-1">{data.heading}</p>
        <h1 className="flex mr-2 items-center">
          Blanca
          <p className="ml-2">0</p>
        </h1>
        <img src={arrowDown} alt="" />
      </div>
      {active && (
        <ul className="paydropdown w-full absolute z-10">
          {list.map((EachList) => (
            <li
              className="h-10 flex items-center pl-6 cursor-pointer"
              onClick={(e) => {
                setActive(false);
                setData({
                  img: EachList.img,
                  heading: EachList.heading,
                });
              }}
            >
              <img src={EachList.img} alt="" className="mr-2" />
              <p>{EachList.heading}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
