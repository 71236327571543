import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useGetAllBalance, useGetGCBalance } from "../queries/balance";
import { AsideOption } from "./common/AsideOption";

// Images & Icons
import communityTeam from "../assets/img/affiliate/communityTeam.svg";
import bonusDashboard from "../assets/img/bonus-dashboard/bonus-dashboard-icon.svg";
import dashboard from "../assets/img/dashboard-icon.svg";
import arrow from "../assets/img/dashboard/arrow.svg";
import { default as stake } from "../assets/img/dashboard/stake.svg";
import logo from "../assets/img/logo.svg";
import marketing from "../assets/img/marking-tool.svg";
import menuExpander from "../assets/img/menu.svg";
import news from "../assets/img/news.svg";
import refer from "../assets/img/refer.svg";
import bonusGreen from "../assets/img/sidebar/bonusGreen.png";
import communityGreen from "../assets/img/sidebar/communityGreen.png";
import dashboardGreen from "../assets/img/sidebar/dashboardGreen.png";
import gamehub from "../assets/img/sidebar/gamehub.png";
import gamehubGreen from "../assets/img/sidebar/gamehubGreen.png";
import marketingGreen from "../assets/img/sidebar/marketingGreen.png";
import newsGreen from "../assets/img/sidebar/newsGreen.png";
import stakeGreen from "../assets/img/sidebar/stakeGreen.png";
import walletGreen from "../assets/img/sidebar/walletGreen.png";
import versusGreen from "../assets/img/sports/versus-green.svg";
import versus from "../assets/img/sports/versus.svg";
import walletActive from "../assets/img/wallet-option.svg";
import withdraw from "../assets/img/withdraw.svg";

// betwin789.net/mlrich
export const Aside = ({ activeSwitch }) => {
  const navigate = useNavigate();
  const { setUserInfo, userInfo } = useAuth();
  const { data: balance } = useGetAllBalance();
  const { data: GCBalanceData } = useGetGCBalance();
  const numberOfNotifications = "0";
  const referralLink = userInfo
    ? `https://betwin789.net/signup?ref=${userInfo?.user?.userCode}`
    : "https://betwin789.net/mlrich";
  const cashWalletBalance = parseFloat(balance?.balances?.cashWallet) ?? 0.0;
  const winningBalance =  parseFloat(
    balance?.balances?.gbcBalance + balance?.balances?.winningBalance
  ) ?? 0.0;
  const [active, setActive] = useState(false);

  console.log("Balance : ", balance);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/all-games")
      || location.pathname.startsWith("/sports-bet")
      || location.pathname.startsWith("/live-casino")
      || location.pathname.startsWith("/casino")
      || location.pathname.startsWith("/virtual-sports")
      || location.pathname.startsWith("/live-games")
      || location.pathname.startsWith("/crash-fast")
      || location.pathname.startsWith("/sports")) {
      setActive(!active);
    }
  }, [location.pathname]);


  return (
    <aside className="px-5 py-4 flex flex-col justify-between">
      <div>
        <div className="flex items-center justify-between">
          <img
            src={logo}
            alt="logo"
            className="cursor-pointer"
            onClick={(e) => {
              navigate("/");
            }}
          />
          <img src={menuExpander} alt="menuExpander" />
        </div>

        {activeSwitch === "affiliate-switch" ||
          activeSwitch === "staker-switch" ? (
          <div className="total-balance mt-12 min-h-[144px] flex flex-col justify-center items-center w-full rounded-xl  px-5 text-center">
            <h1 className="text-[#FFF] text-[36px]">
              $
              {cashWalletBalance
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </h1>
            <p className="text-[#FFF] text-[14px] mt-2 flex items-center">
              {/* <span className="mr-2"><img src={cashwalletBlack} width={20} alt="card" /></span>  */}
              Cash Wallet
            </p>
            <button
              className="flex items-center mt-2 h-8 rounded-lg w-full justify-center gap-2 bg-gradient-to-b from-[#9435E2] to-[#3733B9] hover:from-[#804ba8] hover:to-[#262297]"
              onClick={(e) => {
                navigate("/home/wallet/withDraw");
              }}
            >
              <img src={withdraw} alt="withdraw" />
              <p className="text-[#FFF] text-[12px]">Withdraw</p>
            </button>
          </div>
        ) : activeSwitch === "player-switch" ? (
          <div className="total-balance mt-12 min-h-[144px] flex flex-col justify-center items-center w-full rounded-xl  px-5 text-center">
            <h1 className="text-[#FFF] text-[36px]">
              {winningBalance?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </h1>
            <p className="text-[#FFF] text-[14px] mt-2 flex items-center">
              Available In-play Balance
            </p>
            <button
              className="flex items-center mt-2 h-8 rounded-lg w-full justify-center gap-2 bg-gradient-to-b from-[#9435E2] to-[#3733B9] hover:from-[#804ba8] hover:to-[#262297]"
              onClick={(e) => {
                navigate("/transfer/gbc");
              }}
            >
              <img src={withdraw} alt="withdraw" />
              <p className="text-[#fff] text-[12px]">Transfer</p>
            </button>
          </div>
        ) : null}

        <div className="flex w-100 mt-4 bg-[#111010] rounded-md">
          <Link
            to="/"
            onClick={(e) => {
              window.sessionStorage.setItem("currentMenu", "affiliate-switch");
            }}
            className={`flex-1 flex py-3 justify-center rounded-md items-center ${activeSwitch === "affiliate-switch" && "bg-[#AEB2B1] group active"
              }`}
          >
            <h1 className="text-[12px] text-white group-[.active]:!text-black font-bold">
              Affiliate
            </h1>
          </Link>

          <Link
            to="/stake/dashboard"
            onClick={(e) => {
              window.sessionStorage.setItem("currentMenu", "staker-switch");
            }}
            className={`flex-1 flex py-3 justify-center rounded-md items-center ${activeSwitch === "staker-switch" && "bg-[#AEB2B1] group active"
              }`}
          >
            <h1 className="text-[12px] text-white group-[.active]:!text-black font-bold">
              Staker
            </h1>
          </Link>

          <Link
            to="/player/dashboard"
            onClick={(e) => {
              window.sessionStorage.setItem("currentMenu", "player-switch");
            }}
            className={`relative group flex-1 flex py-3 justify-center rounded-md items-center ${activeSwitch === "player-switch" && "bg-[#AEB2B1] group active"
              }`}
          >
            <h1 className="text-[12px] text-white group-[.active]:!text-black font-bold">
              Player
            </h1>
          </Link>
        </div>

        {/* <div class="relative  animate-claim-bonus">
          <Link 
            // to="" 
            className="px-5 py-2 mt-6 mb-2 rounded-lg flex items-center h-11 bg-gradient-to-b from-[#9BE402] to-[#1FA83C]"
          >
            <img src={claim} alt="claim" />
            <h1 className="flex-1 ml-2 font-black text-white text-shadow-lg">Claim Bonus</h1>
          </Link>
          <div class="absolute top-0 right-0 -mt-1 -mr-1 h-5 w-5 bg-red-500 rounded-full flex items-center justify-center text-white text-xs">
            {numberOfNotifications}
          </div>
        </div> */}

        <Link
          to={
            activeSwitch === "affiliate-switch"
              ? "/"
              : activeSwitch === "staker-switch"
                ? "/stake/dashboard"
                : "/player/dashboard"
          }
          className={`bonus-area px-5 mt-6 mb-2 rounded-lg flex items-center h-11 ${window.location.pathname ===
            (activeSwitch === "affiliate-switch"
              ? "/"
              : activeSwitch === "staker-switch"
                ? "/stake/dashboard"
                : "/player/dashboard")
            ? "active"
            : ""
            }`}
        >
          {window.location.pathname ===
            (activeSwitch === "affiliate-switch"
              ? "/"
              : activeSwitch === "staker-switch"
                ? "/stake/dashboard"
                : "/player/dashboard") ? (
            <img src={dashboardGreen} alt="card" />
          ) : (
            <img src={dashboard} alt="card" />
          )}
          <h1 className="flex-1 ml-2">Dashboard</h1>
        </Link>

        <Link
          to="/home/wallet/balance"
          className={`bonus-area px-5 mb-2 rounded-lg flex items-center h-11 ${window.location.pathname === "/home/wallet/balance" ||
            window.location.pathname === "/home/wallet/deposite" ||
            window.location.pathname === "/home/wallet/withDraw" ||
            window.location.pathname === "/home/wallet/buy-crypto" ||
            window.location.pathname === "/home/wallet/vault-pro" ||
            window.location.pathname === "/home/wallet/nft" ||
            window.location.pathname === "/home/wallet/trasaction"
            ? "active"
            : ""
            }`}
        >
          {window.location.pathname === "/home/wallet/balance" ||
            window.location.pathname === "/home/wallet/deposite" ||
            window.location.pathname === "/home/wallet/withDraw" ||
            window.location.pathname === "/home/wallet/buy-crypto" ||
            window.location.pathname === "/home/wallet/vault-pro" ||
            window.location.pathname === "/home/wallet/nft" ||
            window.location.pathname === "/home/wallet/trasaction" ? (
            <img src={walletGreen} alt="card" />
          ) : (
            <img src={walletActive} alt="card" />
          )}
          <h1 className="flex-1 ml-2">Wallet</h1>
        </Link>

        <a
          href={referralLink}
          target="_blank"
          className="flex mb-2  px-5  w-full rounded-lg refer-button  items-center justify-start h-11"
          rel="noreferrer"
        >
          <img src={refer} alt="refer" className="mr-2" />
          <p>Refer and Earn</p>
        </a>



        {activeSwitch === "player-switch" && (
          <div className="dropdownaside relative mb-2">
            <div
              className={`bonus-area cursor-pointer px-5 border-[transparent] border-[1px] rounded-lg flex items-center h-11`}
              onClick={(e) => {
                setActive(!active);
              }}
            >
              <img src={gamehub} alt="card" />

              <h1 className="flex-1 ml-2">Gaming Hub</h1>

              <img src={arrow} alt="" className={`${active && "rotate-[-90deg]"}`} />
            </div>
            {active && (
              <ul className=" w-full flex flex-col items-end">
                <Link
                  to="/all-games"
                  className={`bonus-area px-5 w-[181px]  flex items-center h-11 
                  rounded-lg !bg-[#252525] mt-2 ${window.location.pathname.startsWith("/all-games") ||
                      window.location.pathname.startsWith("/live-casino") ||
                      window.location.pathname.startsWith("/casino") ||
                      window.location.pathname.startsWith("/virtual-sports") ||
                      window.location.pathname.startsWith("/live-games") ||
                      window.location.pathname.startsWith("/crash-fast") ||
                      (window.location.pathname.startsWith("/sports") && !window.location.pathname.startsWith("/sports-bet")) ||
                      window.location.pathname.startsWith("/game/6")
                      ? "active"
                      : ""
                    }`}
                >
                  {window.location.pathname.startsWith("/all-games") ||
                    window.location.pathname.startsWith("/live-casino") ||
                    window.location.pathname.startsWith("/casino") ||
                    window.location.pathname.startsWith("/virtual-sports") ||
                    window.location.pathname.startsWith("/live-games") ||
                    window.location.pathname.startsWith("/crash-fast") ||
                    (window.location.pathname.startsWith("/sports") && !window.location.pathname.startsWith("/sports-bet")) ||
                    window.location.pathname.startsWith("/game/6")
                    ? (
                      <img src={gamehubGreen} alt="card" />
                    ) : (
                      <img src={gamehub} alt="card" />
                    )}
                  <h1 className="flex-1 ml-2">All Games</h1>
                </Link>
                <Link
                  to="/sports-bet"
                  className={`bonus-area px-5 w-[181px]  flex items-center h-11 
                  rounded-lg !bg-[#252525] mt-2 ${window.location.pathname.startsWith("/sports-bet")
                      ? "active"
                      : ""
                    }`}
                >
                  {window.location.pathname.startsWith("/sports-bet")
                    ? (
                      <img src={versusGreen} alt="card" />
                    ) : (
                      <img src={versus} alt="card" /> 
                    )}
                  <h1 className="flex-1 ml-2">Sports</h1>
                </Link>
              </ul>
            )}
          </div>
          // <Link
          //   to="/all-games"
          //   className={`bonus-area mb-2  px-5 rounded-lg flex items-center h-11 ${
          //     window.location.pathname.startsWith("/all-games") ||
          //     window.location.pathname.startsWith("/live-casino") ||
          //     window.location.pathname.startsWith("/casino") ||
          //     window.location.pathname.startsWith("/virtual-sports") ||
          //     window.location.pathname.startsWith("/live-games") ||
          //     window.location.pathname.startsWith("/crash-fast") ||
          //     window.location.pathname.startsWith("/sports") ||
          //     window.location.pathname.startsWith("/game/6")
          //     ? "active"
          //     : ""
          //   }`}
          // >
          //   { window.location.pathname.startsWith("/all-games") ||
          //     window.location.pathname.startsWith("/live-casino") ||
          //     window.location.pathname.startsWith("/casino") ||
          //     window.location.pathname.startsWith("/virtual-sports") ||
          //     window.location.pathname.startsWith("/live-games") ||
          //     window.location.pathname.startsWith("/crash-fast") ||
          //     window.location.pathname.startsWith("/sports") ||
          //     window.location.pathname.startsWith("/game/6")
          //     ? (
          //     <img src={gamehubGreen} alt="card" />
          //   ) : (
          //     <img src={gamehub} alt="card" />
          //   )}
          //   <h1 className="flex-1 ml-2">Gaming Hub</h1>
          // </Link>
        )}

        {/* {activeSwitch === "affiliate-switch" && (
          <Link
            to="/affiliate"
            className={`bonus-area mb-2  px-5 rounded-lg flex items-center h-11 ${window.location.pathname === "/affiliate" && "active"
              }`}
          >
            {window.location.pathname === "/affiliate" ? (
              <img src={affiliateGreen} alt="card" />
            ) : (
              <img src={affiliateAsideActive} alt="card" />
            )}
            <h1 className="flex-1 ml-2">Affiliate</h1>
          </Link>
        )} */}

        {activeSwitch === "affiliate-switch" && (
          <Link
            to="/community-team"
            className={`bonus-area mb-2  px-5 rounded-lg flex items-center h-11 ${window.location.pathname === "/community-team" && "active"
              }`}
          >
            {window.location.pathname === "/community-team" ? (
              <img src={communityGreen} alt="card" />
            ) : (
              <img src={communityTeam} alt="card" />
            )}
            <h1 className="flex-1 ml-2">Community Team</h1>
          </Link>
        )}

        {activeSwitch === "staker-switch" && (
          <Link
            to="/stake"
            className={`bonus-area mb-2 px-5 rounded-lg flex items-center h-11 ${window.location.pathname === "/stake" || // Exact match for /stake
              window.location.pathname === "/stake/payment/method/cash-wallet" ||
              window.location.pathname === "/stake/payment/method/crypto" ||
              window.location.pathname === "/stake/payment/method/fiat" ||
              window.location.pathname === "/stake/payment/method/external-wallet" ||
              window.location.pathname.startsWith("/stake/6") // Starts with /stake/6
              ? "active" // Apply "active" class if the condition is true
              : "" // Otherwise, no class should be applied
              }`}
          >
            {window.location.pathname === "/stake" || // Exact match for /stake
              window.location.pathname.startsWith("/stake/6") ? ( // Starts with /stake/6
              <img src={stakeGreen} alt="card" />
            ) : (
              <img src={stake} alt="card" />
            )}
            <h1 className="flex-1 ml-2">Stake Now</h1>
          </Link>
        )}

        <AsideOption />

        {activeSwitch === "player-switch" && (
          <Link
            to="/bonus-dashboard"
            className={`bonus-area mb-2 mt-2  px-5 rounded-lg flex items-center h-11 ${window.location.pathname === "/bonus-dashboard" ||
              window.location.pathname === "/bonus-dashboard/details"
              ? "active"
              : "" // Otherwise, no class should be applied
              }`}
          >
            {window.location.pathname === "/bonus-dashboard" ||
              window.location.pathname === "/bonus-dashboard/details"
              ? (
                <img src={bonusGreen} alt="Bonus" />
              ) : (
                <img src={bonusDashboard} alt="Bonus" />
              )}

            <h1 className="flex-1 ml-2">Bonus Dashboard</h1>
          </Link>
        )}
      </div>

      <div className="mt-10">
        <a
          target="_blank"
          rel="noreferrer"
          // href="https://drive.google.com/file/d/1Ax0eaMb3xt_uMC02ej4qLF4y8mHbT_go/view"
          href="https://drive.google.com/file/d/1M8J-qRxBWWANaztInjidls7VtlymRPzM/view?usp=drivesdk"
          className="bonus-area mb-2  px-5 rounded-lg flex items-center h-11"
        >
          {window.location.pathname ===
            // "https://drive.google.com/file/d/1Ax0eaMb3xt_uMC02ej4qLF4y8mHbT_go/view"
            "https://drive.google.com/file/d/1M8J-qRxBWWANaztInjidls7VtlymRPzM/view?usp=drivesdk" ? (
            <img src={marketingGreen} alt="card" />
          ) : (
            <img src={marketing} alt="card" />
          )}
          <h1 className="flex-1 ml-2">Marketing Tools</h1>
        </a>

        <Link
          target="_blank"
          to="https://betwin789.net/"
          className="bonus-area mb-2  px-5 rounded-lg flex items-center h-11"
        >
          {window.location.pathname === "https://betwin789.net/" ? (
            <img src={newsGreen} alt="card" />
          ) : (
            <img src={news} alt="card" />
          )}
          <h1 className="flex-1 ml-2">News and Event</h1>
        </Link>
      </div>
    </aside>
  );
};
