import React, { useState } from "react";
import "../assets/css/wallet/index.css";

// images
import close from "../assets/img/settings/svg/close.svg";
import coin from "../assets/img/wallet/coin.svg";
import search from "../assets/img/wallet/search.svg";
// components
import { useNavigate } from "react-router-dom";
import { useGetBalance, useGetCoinMarketPrices } from "../queries/balance";
import { useGetAllWalletBalances } from "../queries/wallet";
import { convertToCurrency } from "../utils";
import { walletBinance } from "../utils/walletBinance";
import ButtonLoader from "./ButtonLoader";
import { WalletOptions } from "./common/WalletOptions";
import { Switch } from "./settings/Switch";

export const WalletBinanceLeft = () => {

  const navigate = useNavigate();
  const [optionDeposite, setoptionDeposite] = useState(1);
  const [activeState, setActive] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [isHideBalance, setHideBalance] = useState(
    localStorage.getItem("hide_balance") === "true"
  );

  const { data: walletData, isLoading } = useGetAllWalletBalances();
  const walletBalances = Object.entries(walletData?.data || {}).map(([currency, details]) => ({
    currency,
    ...details
  }));

  const { data: balance } = useGetBalance();
  console.log("balance is : ", balance);
  const { data: coinPrices } = useGetCoinMarketPrices();

  // const totalBalance = data?.reduce((acc, el) => {
  //   const price = coinPrices?.find(
  //     (coin) => coin.symbol?.toLowerCase() === el.currency?.toLowerCase()
  //   )?.current_price;
  //   return acc + Number(price) * Number(el.balance);
  // }, 0);
  const totalBalance = 0;

  console.log("balance issss : ", balance);
  return (
    <div className="body-left-area w-full">
      <div className="settings-area  rounded-lg">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Wallet</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>

        <div className="settings-content-area flex items-start relative">
          <div className="settings-options-area flex flex-col p-3">
            <WalletOptions active={1} />
          </div>

          <div className="wallet-content-body-area py-6 px-6 flex-1">
            <div className="popup DepositePopUp rounded-xl">
              <div className="buttons-wrapper pb-6  mb-6 grid grid-cols-2 gap-3">
                <button
                  className={`${optionDeposite === 1 && "active"}`}
                  onClick={(e) => {
                    setoptionDeposite(1);
                  }}
                >
                  Crypto
                </button>
                <button
                  className={`${optionDeposite === 2 && "active"}`}
                  onClick={(e) => {
                    setoptionDeposite(2);
                  }}
                >
                  Cash Wallet
                </button>
              </div>
            </div>
            {optionDeposite === 1 && <>
              <div className="balance pb-4 flex items-center">
                {/* <div class=" text-white p-10 rounded-lg shadow-md">
                <h2 class="text-xl font-semibold mb-4">🛠 Exciting Updates on the Way!</h2>

                <p class="mb-2 text-lg">
                    <span class="font-bold text-[#6FCF17]">🔒 What's Happening? </span> 
                    We're currently syncing and enhancing the security of our crypto wallets. Plus, we're upgrading our blockchain protocols for better and faster performance.
                </p>

                <p class="mb-2 text-md">
                    <span class="font-bold text-[#6FCF17] pr-2">⌛️  When Will We Be Back?  :</span> 
                   Crypto wallet will resume shortly. We appreciate your patience and understanding during this brief pause.
                </p>

               

               
            </div> */}

                {/* <img src={coin} alt="" className="mr-2" /> */}

                {/* <h1 className="flex-1">Total Balance</h1>
                {totalBalance ? <>
                  <p>{totalBalance?.toFixed(2)} USD</p>
                </> : <>
                  <p>{convertToCurrency(balance?.balance ?? 0, 2)} USD</p>
                </>} */}

              </div>
              <div className="hide-balance mt-4 h-9 flex items-center">
                <h1 className="mr-3">Hide 0 balance</h1>
                <Switch
                  id="hide_balance"
                  activeSwitch={isHideBalance}
                  onChange={(e) => {
                    setHideBalance(!isHideBalance);
                    localStorage.setItem("hide_balance", !isHideBalance);
                  }}
                />
                <div className="flex-1"></div>
                <div className="flex search-wrapper min-w-[300px] items-center px-4 rounded-lg py-3">
                  <img src={search} alt="" />
                  <input
                    type="text"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    placeholder="Search"
                    className="flex-1 bg-transparent outline-none border-0 w-full ml-1"
                  />
                </div>
              </div>
              <div className="currency-balance mt-4 ">
                {isLoading ? <>
                  <ButtonLoader />
                </> : <>
                  <div className="mt-4 border-[1px] rounded-lg  border-[#3C403F]">
                    {walletBalances
                      .filter(el => !isHideBalance || Number(el.available) > 0)
                      .filter(el => el.currency.toLowerCase().includes(searchValue.toLowerCase()))
                      .map((row, key) => (
                        <div
                          key={key}
                          className={`px-5 2 row flex !bg-[transparent] border-b-[1px] border-b-[#3C403F] items-center !rounded-none h-14 ${key === walletBalances.length - 1 && "border-b-[0px]"}`}
                        >
                          <img
                            className="h-8 w-8"
                            src={
                              walletBinance?.find(
                                (el) => el.name === row?.currency?.split("_")[0]
                              )?.img
                            }
                            alt=""
                          />
                          <h1 className="ml-3 w-48 mr-2 whitespace-nowrap">
                            {row?.currency.includes("_")
                              ? `${row?.currency?.split("_")[0]} (${row?.currency?.split("_")[1]
                              })`
                              : row.currency}
                          </h1>

                          <h1 className=" flex-1  text-[#AEB2B1] mr-5 whitespace-nowrap sm:mr-2">
                            {Number(row.total).toFixed(8)}
                          </h1>
                          {/* <p className="mr-3">0.000</p> */}
                          <button
                            className="mr-3 w-60 px-3 text-[14px] whitespace-nowrap sm:px-1 sm:mr-1 sm:!text-[10px]"
                            onClick={(e) => {
                              window.sessionStorage.setItem(
                                "deposit",
                                JSON.stringify(row)
                              );
                              navigate(`/wallet/deposit/${row.currency}`);
                            }}
                          >
                            Deposit
                          </button>
                          <button
                            className="px-3 whitespace-nowrap !w-[unset] sm:px-1 sm:!text-[10px]"
                            onClick={(e) => {
                              window.sessionStorage.setItem(
                                "withdraw",
                                JSON.stringify(row)
                              );
                              localStorage.setItem("activeCashWallet", false);
                              navigate(`/home/wallet/withDraw/${row.currency}`);
                            }}
                          >
                            Withdraw
                          </button>
                        </div>
                      ))}
                  </div>
                </>}
              </div>
            </>}
            {optionDeposite === 2 && <>
              <div className="currency-balance mt-4">
                {isLoading ? (
                  <>
                    <ButtonLoader />
                  </>
                ) : (
                  <>
                    <div className="mt-4 border-[1px] rounded-lg border-[#3C403F]">
                      {Object.keys(balance?.balances ?? {}).map((key, index) => (
                        <div
                          key={index}
                          className={`px-5 row flex !bg-[transparent] border-b-[1px] border-b-[#3C403F] items-center !rounded-none h-14 ${index === Object.keys(balance.balances).length - 1 && "border-b-[0px]"}`}
                        >
                          <img className="h-8 w-8" src={coin} alt={`${key} icon`} />
                          <h1 className="ml-3 w-48 mr-2 whitespace-nowrap">
                            {key === 'cashWallet' ? 'Cash Wallet' : key === 'gbcBalance' ? 'GC Balance' : 'Winning Balance'}
                          </h1>
                          <h1 className="flex-1 text-[#AEB2B1] mr-5 whitespace-nowrap sm:mr-2">
                            {convertToCurrency(balance?.balances[key] ?? 0, 2)} {key === 'cashWallet' ? 'USD' : key === 'gbcBalance' ? 'GC' : 'WB'}
                          </h1>
                          {key === 'cashWallet' && (
                            <>


                              <button
                                className="px-3 whitespace-nowrap !w-[unset] sm:px-1 sm:!text-[10px]"
                                onClick={() => {
                                  window.sessionStorage.setItem("withdraw", JSON.stringify({ currency: key, balance: balance.balances[key] }));
                                  localStorage.setItem("activeCashWallet", key === 'cashWallet');
                                  navigate(`/home/wallet/withDraw/${key}`);
                                }}
                              >
                                Withdraw
                              </button>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>


            </>}

            {/* <div className="currency-balance mt-4">
              <div className="flex items-center mt-2 ">
                <h1 className="mr-3 w-[10vw] text-[12px] font-arabicmed text-white">
                  Asset Name
                </h1>
                <p className="mr-3 text-[12px] ml-[36px] font-arabicmed text-white">
                  Price
                </p>
              </div>
              <div className="row flex  items-center  h-11 mt-2">
                <img src={usdt} alt="" />
                <h1 className="ml-3 w-[10vw]">USDT</h1>
                <p className="text-[10px] flex-1 font-arabicreg text-[#AEB2B1]">
                  {convertToCurrency(balance?.balance ?? 0)}
                </p>
                <p className="mr-3">Balance</p>
                <button
                  className="mr-3"
                  onClick={(e) => {
                    window.sessionStorage.setItem(
                      "deposit",
                      JSON.stringify({
                        img: usdt,
                        name: "USDT",
                      })
                    );
                    navigate("/wallet/deposit");
                  }}
                >
                  Deposit
                </button>
                <button
                  onClick={(e) => {
                    window.sessionStorage.setItem(
                      "withdraw",
                      JSON.stringify({
                        img: usdt,
                        name: "USDT",
                      })
                    );

                    navigate("/home/wallet/withDraw");
                  }}
                >
                  Withdraw
                </button>
              </div>
            </div> */}


          </div>
        </div>
      </div>
    </div>
  );
};
