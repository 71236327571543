// imoonGameDetails.js

const imoonGameDetails = {
  imoonDetails: [
    {
      id: "1001",
      RTP: "99%",
      defaultBet: "$10",
      minBet: "$1",
      maxBet: "$300",
      maxMultiplier: "999.99x",
      maxExposure: "$500,000",
      dailyRounds: "~5000",
      licence: "Curacao",
    },
    {
      id: "1002",
      RTP: "99%",
      defaultBet: "$10",
      minBet: "$1",
      maxBet: "$300",
      maxMultiplier: "999.99x",
      maxExposure: "$500,000",
      dailyRounds: "~5000",
      licence: "Curacao",
    },
    {
      id: "1003",
      RTP: "99%",
      defaultBet: "$10",
      minBet: "$1",
      maxBet: "$300",
      maxMultiplier: "999.99x",
      maxExposure: "$500,000",
      dailyRounds: "~5000",
      licence: "Curacao",
    },
    {
      id: "1004",
      RTP: "99%",
      defaultBet: "$10",
      minBet: "$1",
      maxBet: "$300",
      maxMultiplier: "999.99x",
      maxExposure: "$500,000",
      dailyRounds: "~5000",
      licence: "Curacao",
    },
    {
      id: "1005",
      RTP: "99%",
      defaultBet: "$10",
      minBet: "$1",
      maxBet: "$300",
      maxMultiplier: "999.99x",
      maxExposure: "$500,000",
      dailyRounds: "~5000",
      licence: "Curacao",
    },
    {
      id: "1006",
      RTP: "99%",
      defaultBet: "$10",
      minBet: "$1",
      maxBet: "$300",
      maxMultiplier: "999.99x",
      maxExposure: "$500,000",
      dailyRounds: "~5000",
      licence: "Curacao",
    },
    {
      id: "1007",
      RTP: "99%",
      defaultBet: "$10",
      minBet: "$1",
      maxBet: "$300",
      maxMultiplier: "999.99x",
      maxExposure: "$500,000",
      dailyRounds: "~5000",
      licence: "Curacao",
    },
    {
      id: "2001",
      RTP: "99%",
      defaultBet: "$10",
      minBet: "$1",
      maxBet: "$300",
      maxMultiplier: "999.99x",
      maxExposure: "$500,000",
      dailyRounds: "~5000",
      licence: "Curacao",
    },
  ],
};

export default imoonGameDetails;
