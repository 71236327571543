import React, { useEffect, useRef, useState } from "react";
import { Header } from "./Header";

import { BetWinSwapBinanceLeft } from "./BetWinSwapBinanceLeft";
import { BuyCryptoBinanceLeft } from "./BuyCryptoBinanceLeft";
import { CasinoLeft } from "./CasinoLeft";
import { DepositBinanceLeft } from "./DepositBinanceLeft";
import { GeneralActiveSessionLeft } from "./GeneralActiveSessionLeft";
import { GeneralPrivacyLeft } from "./GeneralPrivacyLeft";
import { GeneralSecurityLeft } from "./GeneralSecurityLeft";
import { GeneralSettingsLeft } from "./GeneralSettingsLeft";
import { GeneralVerifyLeft } from "./GeneralVerifyLeft";
import { HomeLeft } from "./HomeLeft";
import { NFTWalletBinanceLeft } from "./NFTWalletBinanceLeft";
import { SidebarArea } from "./SidebarArea";
import { SportsLeft } from "./SportsLeft";
import { TransactionWalletBinanceLeft } from "./TransactionWalletBinanceLeft";
import { WalletBinanceLeft } from "./WalletBinanceLeft";
import { WithdrawBinanceLeft } from "./WithdrawBinanceLeft";
export const RightAreaHome = ({
  setsigninState,
  setSignUpState,
  login,
  condition = null,
}) => {
  const [notifyType, setnotifyType] = useState(1);
  const [typeShowAside, settypeShowAside] = useState(-1);

  const refRight = useRef(null);

  const ResizeWork = (e) => {
    let ElementHeight = window.innerHeight;
    if (!refRight?.current) return;
    refRight.current.style.height = `${ElementHeight - 140}px`;
  };
  useEffect(() => {
    ResizeWork();
    window.addEventListener("resize", ResizeWork);
  }, []);
  return (
    <div className="flex-1">
      <Header
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex items-start px-6 pt-6 gap-3">
        {condition === "general-settings" && <GeneralSettingsLeft />}
        {condition === "general-privacy" && <GeneralPrivacyLeft />}
        {/* {condition === "general-mailphone" && <GeneralMailPhoneLeft />} */}
        {condition === "general-security" && <GeneralSecurityLeft />}
        {condition === "general-active-session" && <GeneralActiveSessionLeft />}
        {condition === "general-verify" && <GeneralVerifyLeft />}
        {condition === "binance-wallet" && <WalletBinanceLeft />}
        {condition === "deposite-wallet" && <DepositBinanceLeft />}
        {condition === "withdraw-wallet" && <WithdrawBinanceLeft />}
        {condition === "buy-crypto-wallet" && <BuyCryptoBinanceLeft />}
        {condition === "betwin-swap-wallet" && <BetWinSwapBinanceLeft />}
        {/* {condition === "vault-pro-wallet" && <VaultProBinanceLeft />} */}
        {condition === "nft-wallet" && <NFTWalletBinanceLeft />}
        {condition === "transaction-wallet" && <TransactionWalletBinanceLeft />}
        {condition === "casino" && <CasinoLeft />}
        {condition === "sports" && <SportsLeft />}
        {!condition && <HomeLeft />}

        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};
