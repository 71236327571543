import React, { useEffect, useRef, useState } from "react";
import "../assets/css/wallet/index.css";

// images
import close from "../assets/img/settings/svg/close.svg";
import eplay from "../assets/img/wallet/eplay.svg";

// components
import { WalletOptions } from "./common/WalletOptions";
import { PayDropdown } from "./popups/PayDropdown";
import { useNavigate } from "react-router-dom";
import { Coin } from "../utils/staking/Coin";
import { CurrencyCoins } from "../utils/staking/CurrencyCoins";
import pdfFile from "../assets/pdf/disclaimer.pdf";

export const BuyCryptoBinanceLeft = () => {

  const navigate = useNavigate();
  const [payCheck, setPaycheck] = useState(false);

  return (
    <div className="body-left-area w-full">
      <div className="settings-area  rounded-lg">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Wallet</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>

        <div className="settings-content-area flex items-start relative">
          <div className="settings-options-area flex flex-col p-3">
            <WalletOptions active={4} />
          </div>

          <div className="wallet-content-body-area py-6 px-6 flex-1">
            <div className="popup DepositePopUp rounded-xl">
              <div className="option-three">
                <div className="input-container ">
                  <label htmlFor="">You pay with</label>
                  <div className="input-notify-wrapper rounded-lg mt-3 flex items-center pl-4">
                    <p className="flex-1">0</p>

                    <PayDropdown
                      callback={(e) => {
                        if (e.heading === "PHP") {
                          setPaycheck(true);
                        } else {
                          setPaycheck(false);
                        }
                      }}
                      dollar={CurrencyCoins[0].img}
                      stakeFix
                      heading={CurrencyCoins[0].heading}
                      list={CurrencyCoins}
                    />
                  </div>
                </div>
                <div className="input-container mt-4">
                  <label htmlFor="">You get</label>
                  <div className="input-notify-wrapper rounded-lg mt-3 flex items-center pl-4">
                    <p className="flex-1">0</p>

                    <PayDropdown
                      stakeFix
                      dollar={Coin[0].img}
                      heading={Coin[0].heading}
                      list={Coin}
                    />
                  </div>
                </div>

                <div className="input-container mt-4">
                  <label htmlFor="">Payment Details</label>
                  <div className="details-wrapper mt-3 px-3 py-6 rounded-lg">
                    <div className="detail-row flex pb-6 items-center justify-between">
                      <h1>Provider</h1>
                      <p>{payCheck ? "Eplayment" : "Moonpay"}</p>
                    </div>
                    <div className="detail-row flex pb-6 items-center justify-between">
                      <h1>Method</h1>
                      <p>Credit Card</p>
                    </div>
                    <div className="detail-row flex items-center justify-between">
                      <h1>Deposit to account</h1>
                      <p>Kiyskvotnyb</p>
                    </div>
                  </div>
                  <div className="details-wrapper mt-3 px-3 py-6 rounded-lg">
                    <div className="detail-row flex pb-6 items-center justify-between">
                      <h1>Total (including fee)</h1>
                      <p>0 USD</p>
                    </div>
                    <div className="detail-row flex  items-center justify-between">
                      <h1>You will get</h1>
                      <p className="yellow">0 USDT</p>
                    </div>
                  </div>
                </div>

                <div className="warning mt-5  py-3 flex items-center px-6 rounded-xl">
                  <p className="!w-full">
                    <span>Notice</span> : Buying crypto with a credit card
                    depends on payment confirmation and blockchain processing,
                    which can take 10 minutes to an hour. Thank you for your
                    understanding.
                  </p>
                </div>

                <div className="agreement flex items-center my-5">
                  <input type="checkbox" id="agreement-deposit" />
                  <label
                    onClick={(e) => {
                      window.open(pdfFile);
                    }}
                    htmlFor="agreement-deposit"
                    className="w-3 h-3 rounded flex items-center justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="#fff"
                      class="w-6 h-6"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </label>
                  <p className="ml-3">
                    I have read and agree to the disclaimer.
                  </p>
                </div>

                <div className="grid gap-6 grid-cols-2">
                  {payCheck && (
                    <button
                      className="flex moon-pay-btn items-center justify-center w-full "
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <img src={eplay} alt="" className="mr-2" />
                      Buy Via Eplayment
                      <svg
                        className="ml-6"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.7152 4.47667C1.85584 4.33607 2.04657 4.25708 2.24545 4.25708C2.44432 4.25708 2.63505 4.33607 2.7757 4.47667L6.4882 8.18917L10.2007 4.47667C10.3421 4.34005 10.5316 4.26446 10.7282 4.26617C10.9249 4.26787 11.113 4.34675 11.2521 4.48581C11.3911 4.62486 11.47 4.81297 11.4717 5.00962C11.4734 5.20627 11.3978 5.39572 11.2612 5.53717L7.01845 9.77992C6.8778 9.92053 6.68707 9.99951 6.4882 9.99951C6.28932 9.99951 6.09859 9.92053 5.95795 9.77992L1.7152 5.53717C1.57459 5.39653 1.49561 5.20579 1.49561 5.00692C1.49561 4.80805 1.57459 4.61732 1.7152 4.47667Z"
                          fill="url(#paint0_linear_264_5208)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_264_5208"
                            x1="1.49561"
                            y1="8.95725"
                            x2="5.19184"
                            y2="2.16774"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#5DF9C2" />
                            <stop offset="1" stop-color="#3AC192" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </button>
                  )}

                  {!payCheck && (
                    <button
                      className="flex moon-pay-btn items-center justify-center w-full "
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      Moonpay
                    </button>
                  )}

                  <button
                    className="flex moon-pay-btn moon-pay-btn-pure items-center justify-center w-full "
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Confirm Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
