import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import copy from "../../assets/img/p2p/copy.svg";
import backImg from "../../assets/img/transaction/back.svg";
import { useOrderDetails } from "../../queries/p2p";
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";

export const RightAreaP2POrderDetails = ({
    setsigninState,
    setSignUpState,
    login,
}) => {
    const [typeShowAside, settypeShowAside] = useState(-1);
    const [stepActive, setStepActive] = useState(1);
    // get orderId from params
    let { orderId } = useParams();
    const result = useOrderDetails(orderId);

    const orderDetails = result?.data?.data;
    return (
        <>
            <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />
            <div className="flex-1">
                <HeaderStaker
                    headerType="Staker"
                    login={login}
                    settypeShowAside={settypeShowAside}
                    setsigninState={setsigninState}
                    setSignUpState={setSignUpState}
                />
                <div className="body-area flex items-start px-6 pt-6">
                    <div className="body-left-area w-full">
                        <div className="flex justify-between gap-4 sm:flex-col">
                            {stepActive === 1 && (
                                <>
                                    {/*RELEASE DIV */}
                                    <div className="bg-[#1A1A1B] border border-[#3C403F] rounded-lg p-4 w-full">
                                        
                                        <div className="flex items-center">
                                            <Link to="/p2p/buy">
                                                <img src={backImg} alt="" />
                                            </Link>
                                            <h1 className="text-white text-[20px] font-arabicbold ml-4">
                                                Order Created
                                            </h1>
                                        </div>

                                        <div className="flex justify-between pb-6 border-b border-[#3C403F]  sm:flex-col">
                                            <p className="text-[#AEB2B1] text-[16px] font-arabicreg">
                                                Created time:{" "}
                                                <span className="text-white">{orderDetails?.createdAt}</span>
                                            </p>
                                            <p className="text-[#AEB2B1] text-[16px] font-arabicreg flex gap-2">
                                                Order Number:{" "}
                                                <span className="text-white">{orderDetails?._id}</span>
                                                <img src={copy} />
                                            </p>
                                        </div>
                                        <div className="flex justify-between py-6 border-b border-[#3C403F]">
                                            <div>
                                                <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                                                    Amount
                                                </p>
                                                <h1 className="text-white text-[16px] font-arabicbold">
                                                    {parseFloat(orderDetails?.fiatAmount).toFixed(2)}
                                                </h1>
                                            </div>
                                            <div>
                                                <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                                                    Price
                                                </p>
                                                <h1 className="text-white text-[16px] font-arabicbold">
                                                    {orderDetails?.exchangeRate} {orderDetails?.advertisement?.fiatCurrency}
                                                </h1>
                                            </div>
                                            <div>
                                                <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                                                    Quantity
                                                </p>
                                                <h1 className="text-white text-[16px] font-arabicbold">
                                                    {orderDetails?.amount}  {orderDetails?.advertisement?.cryptoCurrency}
                                                </h1>
                                            </div>
                                        </div>
                                        <div className="w-full mt-5">
                                            {orderDetails?.paymentDetails.map((detail, index) => (
                                                <div key={index}>
                                                    {Object?.entries(detail).map(([key, value]) => (
                                                        <div key={key} className="flex justify-between items-center mb-2">
                                                            <p className="text-[#AEB2B1] text-[16px] font-arabicbold">{key}</p>
                                                            <h1 className="text-white text-[16px] font-arabicbold flex items-center">{value}<img src={copy} className="ml-2" alt="copy icon" /></h1>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <SidebarArea typeShowAside={typeShowAside} />
                </div>
            </div>

        </>
    );
};
