import img6 from "../assets/img/wallet/ada.svg";
import img3 from "../assets/img/wallet/bnb.svg";
import img1 from "../assets/img/wallet/btc.svg";
import img16 from "../assets/img/wallet/busd.png";
import img11 from "../assets/img/wallet/dai.svg";
import img7 from "../assets/img/wallet/doge.svg";
import img12 from "../assets/img/wallet/dot.svg";
import img2 from "../assets/img/wallet/eth.svg";
import img13 from "../assets/img/wallet/matic.svg";
import img14 from "../assets/img/wallet/shib.svg";
import img8 from "../assets/img/wallet/sol.svg";
import img10 from "../assets/img/wallet/ton.svg";
import img9 from "../assets/img/wallet/trx.svg";
import img4 from "../assets/img/wallet/usdc.svg";
import img15 from "../assets/img/wallet/usdt.svg";
import img5 from "../assets/img/wallet/xrp.svg";

export const walletBinance = [
  {
    img: img1,
    name: "BTC",
  },
  {
    img: img2,
    name: "ETH",
  },
  {
    img: img3,
    name: "BNB",
  },
  {
    img: img4,
    name: "USDC",
  },
  {
    img: img5,
    name: "XRP",
  },
  {
    img: img6,
    name: "ADA",
  },
  {
    img: img7,
    name: "DOGE",
  },
  {
    img: img8,
    name: "SOL",
  },
  {
    img: img9,
    name: "TRX",
  },
  {
    img: img10,
    name: "TON",
  },
  {
    img: img11,
    name: "DAI",
  },
  {
    img: img12,
    name: "DOT",
  },
  {
    img: img13,
    name: "MATIC",
  },
  {
    img: img14,
    name: "SHIB",
  }, {
    img: img15,
    name: "USDT",
  },
  {
    img: img16,
    name: "BUSD",
  },
];
