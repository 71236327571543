import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircleTimer = ({ totalTimeInSeconds }) => {
  const [timeLeft, setTimeLeft] = useState(totalTimeInSeconds);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [timeLeft]);

  const calculateProgress = () => {
    return (timeLeft / totalTimeInSeconds) * 100;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <div style={{ width: "100px", margin: "auto" }}>
      <CircularProgressbar
        value={calculateProgress()}
        text={formatTime(timeLeft)}
        styles={buildStyles({
          pathColor: `rgba(103,202,26, ${calculateProgress() / 100})`,
          textColor: "#fff",
          trailColor: "#1A1A1B",
        })}
      />
    </div>
  );
};

export default CircleTimer;
