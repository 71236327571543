import React from "react";
import { wallets } from "../../utils/staking/Wallets";

export const EWallet = ({ classnames = null, Paycheck = null }) => {
  return (
    <div className="grid grid-cols-2 mt-6 gap-6">
      {Paycheck && (
        <div className="input-wrapper col-span-2">
          <label
            htmlFor=""
            className="text-[14px] font-arabicreg text-[#AEB2B1]"
          >
            Available wallet
          </label>

          <div className={`flex items-center gap-2 mt-3  ${classnames}`}>
            <>
              {wallets.map((EacWallet) => (
                <div
                  className={`flex items-center justify-center rounded-lg h-[50px] bg-[#2E2E2E] border-[1px] border-[#3C403F] flex-1 gap-1`}
                >
                  <img src={EacWallet.img} alt="" />
                  <p className="text-[#fff] font-arabicreg text-[18px]">
                    {EacWallet.heading}
                  </p>
                </div>
              ))}
            </>
          </div>
        </div>
      )}
      {/* <div className="input-wrapper col-span-2">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          Your E-wallet account
        </label>
        <input
          type="text"
          placeholder="87543268954"
          className="bg-[#111010] placeholder:text-white border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white text-[16px] font-arabicmed px-6"
        />
      </div> */}

      <div className="input-wrapper col-span-2">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          Your email address
        </label>
        <input
          type="email"
          placeholder="support@betwin789.net"
          className="bg-[#111010] placeholder:text-white border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white text-[16px] font-arabicmed px-6"
        />
      </div>
    </div>
  );
};
