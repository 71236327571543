import React, { useEffect, useState } from "react";
import arrowDown from "../../assets/img/admin/svg/arrow-down.svg";

const PaymentDropdown = ({ options, onOptionChange, userPaymentMethods }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!selectedOption && Array.isArray(userPaymentMethods) && Array.isArray(options)) {
      const defaultOption = options.find(option =>
        userPaymentMethods.some(userMethod => userMethod?.paymentMethod._id === option._id)
      );

      if (defaultOption) {
        setSelectedOption(defaultOption.name);
        onOptionChange(defaultOption);
      }
    }
  }, [userPaymentMethods, options, onOptionChange, selectedOption]);

  // Function to handle option selection
  const handleOptionChangeInternal = (option) => {
    setSelectedOption(option.name);
    setIsOpen(false);
    onOptionChange(option);
  };

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      <div
        className="flex items-center mb-4 p-2 bg-[#2E2E2E] border border-[#3C403F] rounded-lg cursor-pointer"
        onClick={toggleDropdown}
      >
        {/* Display the selected option */}
        <h1 className="flex-1 text-[13px] text-[#fff] font-arabicmed">
          {selectedOption || "Select Payment Method"}
        </h1>
        <img
          src={arrowDown}
          alt=""
          className={isOpen ? "transform rotate-180" : ""}
        />
      </div>
      {/* Render dropdown options if it's open */}
      {isOpen && (
        <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-lg shadow-md z-10">
          {options.map((option, index) => (
            <div
              key={index}
              className="py-2 px-4 cursor-pointer hover:bg-gray-200"
              onClick={() => handleOptionChangeInternal(option)}
            >
              {option.name} {/* Display the name */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PaymentDropdown;
