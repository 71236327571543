import React from "react";
import refresh from "../../assets/img/stake/refresh.svg";
import { Switch } from "../../components/settings/Switch";

export const AutoSubscribe = () => {
  return (
    <div className="bg-[#252525] mb-8 p-6 border-[1px] border-[#3C403F] rounded-xl">
      <div className="top-area flex items-center">
        <img src={refresh} alt="" />
        <h1 className="text-[22px] ml-2 font-arabicmed text-white sm:text-[18px] xs:!text-[14px]">
          Auto Subscribe
        </h1>
        <span
          className="ml-2 text-[12px] h-[22px] w-[67px] bg-gradient-to-br from-[#9BE402] to-[#1FA83C]
 flex items-center justify-center rounded font-arabicreg text-[#FFF] "
        >
          Popular
        </span>
        <div className="flex-1 flex items-center justify-end">
          <Switch id="auto-subscribe" />
        </div>
      </div>
      <p className="text-[16px] mt-6 font-arabicreg text-[#AEB2B1] sm:text-[14px]">
        Enable auto stake to press stake to the same Locked Product upon expiry
        If disabled your assets will be automatically transferred to the
        designated wallet.
      </p>
    </div>
  );
};
