import React, { useState } from "react";
import { Link } from "react-router-dom";
import backImg from "../../assets/img/transaction/back.svg";
import { useMyOrderList } from "../../queries/p2p";
import { AffiliateHeader } from "../AffiliateHeader";
import { P2PNav } from "./P2PNav";
import { TraderOrderProcessing } from "./TraderOrderProcessing";

export const RightAreaP2POrderHistory = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const [txid, setTxid] = useState("");
  const [status, setStatus] = useState("");

  const orderData = useMyOrderList();
  const orderList = orderData.data?.data?.order ?? [];
  const [activeButton, setActiveButton] = useState(2);
  const [activeP2PButton, setActiveP2PButton] = useState(1);
  const [activeTradeBtn, setActiveTradeBtn] = useState(1);

  const handleButtonClick = (active) => {
    setActiveButton(active);
  };

  const handleP2PButtonClick = (active) => {
    setActiveP2PButton(active);
  };

  const handleTradeBtnClick = (active) => {
    setActiveTradeBtn(active);
  }




  return (
    <div className="flex-1">
      <AffiliateHeader
        headerType="Affiliate"
        login={login}
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />

      <P2PNav />

      <div className="body-area flex-1  px-6 pt-6">
        <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
          <div className="flex items-center  px-6 h-[91px] border-[#3C403F] border-b-[1px]">
            <Link to="/p2p/buy">
              <img src={backImg} alt="" />
            </Link>

            <h1 className="text-white flex-1 text-[24px]  sm:text-[18px] ml-4">
              P2P Order History
            </h1>

          </div>

          <div className="px-6 pb-8 mt-5 pt-2 ">
            <TraderOrderProcessing />
          </div>


        </div>
      </div>
    </div>
  );
};
