import React from "react";
import { Accept } from "../../utils/staking/Accept";
import AcceptedCard from "../common/AcceptedCard";

export const BankTransfer = ({ classnames = null, Paycheck = null ,currencyCode =null}) => {
  return (
    <>
      {Paycheck && (
           <div className="mt-6 gap-6 rounded-xl bg-[#252525] min-h-[308px] border-[1px] border-[#3C403F] p-8">
           <h1 className="text-[24px] font-arabicmed text-white mb-8">
             We Accept
           </h1>
 
           <div className={`flex flex-wrap items-center gap-2 ${classnames}`}>
             {Accept.map((EacWallet, index) => (
               <div key={index} className="flex items-center justify-center rounded-lg h-[80px] bg-[#2E2E2E] border-[1px] border-[#3C403F] flex-none gap-1 px-4 ">
                 <img src={EacWallet.img} alt={EacWallet.heading} className="h-14 object-contain" />
                 {/* <p className="text-[#fff] font-arabicreg text-[18px] truncate">
                   {EacWallet.heading}
                 </p> */}
               </div>
             ))}
        
             <p className="text-white text-md"> and many more..</p>
           </div>
         </div>
      )}
       {!Paycheck && (
           <div className="mt-6 gap-6 rounded-xl bg-[#252525] min-h-[308px] border-[1px] border-[#3C403F] p-8">
           <h1 className="text-[24px] font-arabicmed text-white mb-8">
             We Accept
           </h1>
 
           <div className={`flex flex-wrap items-center gap-2 ${classnames}`}>
           {currencyCode !== "PHP" && <AcceptedCard />}

             <p className="text-white text-md"> and many more..</p>
           </div>
         </div>
      )}
         
    </>
  );
};
