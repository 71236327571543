import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "../../assets/css/home.css";
import { Aside } from "../Aside";
import { Footer } from "../Footer";
import { RepeatPassword } from "../RepeatPassword";
import { SignIn } from "../SignIn";
import { SignUp } from "../SignUp";
import { SignUpPhone } from "../SignUpPhone";
import GameInfo from "./GameInfo";

export const CasinoInfo = () => {
  const [loginType, setloginType] = useState(true);
  const [signInState, setsigninState] = useState(false);
  const [RepeatPasswordState, setRepeatPasswordState] = useState(false);
  const [SignUpState, setSignUpState] = useState(false);
  const [SignUpPhoneState, setSignUpPhoneState] = useState(false);
  const { id } = useParams();
  const [gameId, gameType] = id?.split('&game=');
  return (
    <div>
      <div className="flex items-start home">
        {signInState && (
          <SignIn
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setRepeatPasswordState={setRepeatPasswordState}
            setloginType={setloginType}
          />
        )}
        {RepeatPasswordState && (
          <RepeatPassword
            setsigninState={setsigninState}
            setRepeatPasswordState={setRepeatPasswordState}
          />
        )}

        {SignUpState && (
          <SignUp
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setSignUpPhoneState={setSignUpPhoneState}
            setloginType={setloginType}
          />
        )}
        {SignUpPhoneState && (
          <SignUpPhone
            setsigninState={setsigninState}
            setSignUpPhoneState={setSignUpPhoneState}
            setloginType={setloginType}
          />
        )}

        {/* left area */}
        <Aside
          activeSwitch={
            window.sessionStorage.getItem("currentMenu") == null
              ? "affiliate-switch"
              : window.sessionStorage.getItem("currentMenu")
          }
        />
        {/* right area */}
        {gameId && (
          <GameInfo
            gameId={gameId}
            gameType={gameType}
            login={true}
            setsigninState={setsigninState}
            setRepeatPasswordState={setRepeatPasswordState}
            setSignUpState={setSignUpState}
            setSignUpPhoneState={setSignUpPhoneState}
          />
        )}
      </div>

      <Footer />
    </div>
  );
};
