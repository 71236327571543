import React, { useState } from "react";
import "../assets/css/sign-in.css";
import signInMainImg from "../assets/img/sign-in-girl.png";

import eye from "../assets/img/eye.svg";

import { CheckboxStyleSignUp } from "./CheckboxStyleSignUp";
export const SignUp = ({
  setsigninState,
  setSignUpPhoneState,
  setSignUpState,
  setloginType,
}) => {
  const [type, setType] = useState(true);
  return (
    <div className="SignIn LayerUp">
      <div className="inner-work-sign-in grid grid-cols-2">
        <div className="left-side px-[26px] flex items-center justify-center flex-col">
          <img src={signInMainImg} alt="sign in main presentation" />
          <div className="presentation">
            <h1>Welcome to BetWin</h1>
            <p className="mt-4">
              Welcome to BetWin, where you've just made the perfect choice by
              signing up! We're thrilled to have you on board, and we're here to
              set you up for success.
            </p>
          </div>
        </div>
        <div className="right-side  px-8 py-10">
          <form action="#" className="flex flex-col h-full">
            <h1 className="mb-8">Join BETWIN</h1>

            <div className="input-wrapper">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                placeholder="Jackrose11@gmail.com"
                id="email"
                className="rounded-lg px-6 mt-3"
              />
            </div>
            <div className="input-wrapper mt-4 mb-5">
              <label htmlFor="password">Login Password</label>
              <input
                type={type ? "password" : type}
                placeholder="Enter your passwoard"
                id="password"
                className="rounded-lg px-6 mt-3"
              />
              <img
                src={eye}
                className="eye-icon"
                onClick={(e) => {
                  setType(!type);
                }}
                alt="eye icon"
              />
            </div>

            <div class="input-wrapper">
              <label htmlFor="password">
                Enter Referral / Promo Code (Optional)
              </label>
              <input
                type="text"
                name="sponsor"
                id="sponsor"
                placeholder="Enter Referral / Promo Code"
                class="rounded-lg px-6 mt-3"
                value=""
              />
            </div>

            <div className="agreement-list mt-4">
              <div className="list-inner flex items-start">
                <CheckboxStyleSignUp type="checkbox" id="user-agree" />
                <p className="ml-3">
                  I agree to the User <a href="/">Agreement & Confirm</a> i am
                  at I east 18 years old
                </p>
              </div>

              <div className="list-inner mt-2 flex items-center">
                <CheckboxStyleSignUp type="checkbox" id="marketing" />
                <p className="ml-3" style={{ fontSize: 12 }}>
                  I agree to receive marketing promotions from BetWin
                </p>
              </div>
            </div>

            <button
              className="w-full rounded-lg mt-4 mb-4"
              onClick={(e) => {
                e.preventDefault();
                setloginType(true);
                setSignUpState(false);
              }}
            >
              Sign Up
            </button>
            <p className="dont-have-p">
              Already have an account!{" "}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setSignUpState(false);
                  setsigninState(true);
                }}
              >
                Sign In
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
