import React, { useState, useEffect } from "react";
import close from "../../assets/img/sports/close.svg";
import notFound from "../../assets/img/bonus-dashboard/not-found.png"
import {
  fetchMySportsBet
} from "../../queries/gaming";
import search from "../../assets/img/search-header.svg";

export const BetsTable = ({type}) => {
  const currency = "GC";
  const [myBets, setMyBets] = useState([]);
  const fetchData = async () => {
    try {
      const { data } = await fetchMySportsBet(type);
      if (data) {
        setMyBets(data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const [filteredBets, setFilteredBets] = useState([]);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filtered = myBets.filter((bet) =>
      bet.bet_team.toString().toLowerCase().includes(searchTerm) ||
      bet._id.toString().toLowerCase().includes(searchTerm) ||
      bet.bet_points.toString().toLowerCase().includes(searchTerm) ||
      (`${bet.bet_amount} ${currency}`).toString().toLowerCase().includes(searchTerm) ||
      formatDateString(bet.createdAt).toLowerCase().includes(searchTerm)
    );
    setFilteredBets(filtered);
  };
  

  useEffect(() => {
    setFilteredBets(myBets);
  }, [myBets]);

  const [selectedRow, setSelectedRow] = useState(null);
  const openModal = (row) => {
    setSelectedRow(row);
  };

  const closeModal = () => {
    setSelectedRow(null);
  };

  function formatDateString(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    return formattedDate;
  }

  return (
    <>
      
    <div className="flex justify-between items-center">
      <h1 className="text-[#6FCF17]">My Bet</h1>
      <div className="flex w-fit items-center my-2 md:w-full">
        <p className="text-[#fff] mr-2 md:hidden">Search Event</p>
        <div className="search-box-container w-[201px] h-8 px-4 bg-[#2E2E2E] border-[1px] border-[#3C403F] rounded-lg  flex items-center gap-1 md:w-full">
          <img src={search} alt="" />
          <input
            type="text"
            placeholder="Search here"
            className="w-full bg-transparent border-0 outline-none text-[14px] text-[#AEB2B1]"
            onChange={handleSearchChange}
          />
        </div>
      </div>
    </div>
    <div className="bg-[#1A1A1B] px-6 py-4 mt-4 rounded-lg w-full">
        {filteredBets && filteredBets.length === 0 ? (
          <div>
            <img
                src={notFound}
                alt="Not Found"
                className="mx-auto my-0"
              />
            <h1 className="no-data-available text-center text-white mb-3">It looks like you haven't placed any bets on sports events yet. Place your first bet now!</h1>
          </div>
        ) : (
          <table className="w-full lgmed:min-w-[800px]">
            <thead>
              <tr className="h-[56px] border-b border-[#3A3A3A]">
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Game/Event
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Bet ID
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Timestamp
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Odds
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Bet Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredBets?.map((row, key) => (
                <tr key={key} className="h-[56px]" onClick={() => openModal(row)}>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.bet_team}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row._id}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {formatDateString(row.createdAt)}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.bet_odds}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    <div className="flex">
                    {row.bet_amount} {currency}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>

    {selectedRow && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-[#1A1A1B] p-8 rounded-lg w-1/4">
            <div className="flex justify-between items-center border-b border-[#fff] pb-4">
              <h2 className="text-[16px] text-white font-arabicbold">My Bet</h2>
              <img src={close} className="cursor-pointer" alt="close" onClick={closeModal}/>
            </div>
            <div className="text-center">
              <h1 className="text-[#fff] font-arabicbold text-[12px] mt-4">Sports</h1>
              <h1 className="text-[#fff] font-arabicbold text-[12px]">{selectedRow._id}</h1>
              <p className="text-[#3C403F] font-arabicreg text-[12px]">{formatDateString(selectedRow.createdAt)}</p>
            </div>
            <div className="bg-[#2E2E2E] p-4 rounded-lg my-4">
              <div className="flex justify-start">
                <h1 className="text-[#fff] font-arabicbold text-[12px]">{selectedRow.bet_team}</h1>
                <h1 className="text-[#fff] font-arabicbold text-[12px] px-1"> - </h1>
                <h1 className="text-[#fff] font-arabicbold text-[12px]">{selectedRow.opponent_team}</h1>
              </div>
              <h1 className="text-[#fff] font-arabicreg text-[12px] mt-4">Winner</h1>
              <div className="flex justify-between">
                <h1 className="text-[#fff] font-arabicbold text-[12px]">{selectedRow.bet_team}</h1>
                <h1 className="text-[#6FCF17] font-arabicbold text-[12px]">{selectedRow.bet_odds}</h1>
              </div>
              <div className="flex justify-between mt-8">
                <h1 className="text-[#fff] font-arabicreg text-[12px]">Stake</h1>
                <h1 className="text-[#fff] font-arabicreg text-[12px] flex">{selectedRow.bet_amount.toFixed(2)} {currency}</h1>
              </div>
              <div className="flex justify-between">
                <h1 className="text-[#fff] font-arabicreg text-[12px]">Est. Payout</h1>
                <h1 className="text-[#fff] font-arabicreg text-[12px] flex">{selectedRow.payout.toFixed(2)} {currency}</h1>
              </div>
            </div>
            
          </div>
        </div>
      )}
    </>
  );
};