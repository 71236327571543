import React, { useState, useEffect } from 'react';

function Carousel({ imageUrls }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000); // Change image every 3 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [imageUrls.length]);

  return (
    <div className="carousel">
      <img
        src={imageUrls[currentImageIndex]}
        className="w-full rounded-lg cursor-pointer"
        alt=""
      />
    </div>
  );
}

export default Carousel;
