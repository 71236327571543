import React, { useState } from "react";
import "../assets/css/home.css";
import { Footer } from "../components/Footer";
import { SignIn } from "../components/SignIn";
import { RepeatPassword } from "../components/RepeatPassword";
import { SignUp } from "../components/SignUp";
import { SignUpPhone } from "../components/SignUpPhone";
import { RightAreaStakeTerms } from "../components/RightAreaStakeTerms";
import { Aside } from "../components/Aside";
export const StakeTerms = () => {
  const [signInState, setsigninState] = useState(false);
  const [RepeatPasswordState, setRepeatPasswordState] = useState(false);
  const [SignUpState, setSignUpState] = useState(false);
  const [SignUpPhoneState, setSignUpPhoneState] = useState(false);
  return (
    <div>
      <div className="flex items-start home">
        {signInState && (
          <SignIn
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setRepeatPasswordState={setRepeatPasswordState}
          />
        )}
        {RepeatPasswordState && (
          <RepeatPassword
            setsigninState={setsigninState}
            setRepeatPasswordState={setRepeatPasswordState}
          />
        )}

        {SignUpState && (
          <SignUp
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setSignUpPhoneState={setSignUpPhoneState}
          />
        )}
        {SignUpPhoneState && (
          <SignUpPhone
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setSignUpPhoneState={setSignUpPhoneState}
          />
        )}

        {/* left area */}
        <Aside activeSwitch="staker-switch" />
        {/* right area */}
        <RightAreaStakeTerms
          login={true}
          setsigninState={setsigninState}
          setRepeatPasswordState={setRepeatPasswordState}
          setSignUpState={setSignUpState}
          setSignUpPhoneState={setSignUpPhoneState}
        />
      </div>

      <Footer />
    </div>
  );
};
