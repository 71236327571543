import React, { useState } from "react";

export const SwitchWithDraw = ({ setdepositType }) => {
  const [active, setActive] = useState(1);
  return (
    <div className="bg-[#252525] border-[1px] border-[#3C403F] h-[48px] w-[277px]  flex items-center  justify-between rounded-xl ml-3  sm:!h-10  ">
      <button
        className={`text-[#B7B8BC] cursor-pointer h-full rounded-xl text-[14px] font-arabicmed  ${
          active === 1 &&
          "bg-gradient-to-br from-[#9BE402] to-[#1FA83C] !text-[#111010]"
        }
flex-1

     sm:!rounded-lg
  
        `}
        onClick={(e) => {
          setActive(1);
          setdepositType(1);
        }}
      >
        Withdraw Crypto
      </button>
      <button
        className={`text-[#B7B8BC] cursor-pointer h-full rounded-xl text-[14px] font-arabicmed ${
          active === 2 &&
          "bg-gradient-to-br from-[#9BE402] to-[#1FA83C] !text-[#111010]"
        }
        flex-1
 
        sm:!text-[16px] sm:!rounded-lg
        xs:!text-[14px]
        `}
        onClick={(e) => {
          setActive(2);
          setdepositType(2);
        }}
      >
        Withdraw Fiat
      </button>
    </div>
  );
};
