import React, { useState } from "react";
import "../assets/css/home.css";
import { Aside } from "../components/Aside";
import { CreateNewPassword } from "../components/CreateNewPassword";
import { Footer } from "../components/Footer";
import { Otp } from "../components/Otp";
import { PasswordChangeDone } from "../components/PasswordChangeDone";
import { RepeatPassword } from "../components/RepeatPassword";
import { RightAreaStake } from "../components/RightAreaStake";
import { SignIn } from "../components/SignIn";
import { SignUp } from "../components/SignUp";
import { SignUpPhone } from "../components/SignUpPhone";
export const Stake = () => {
  const [signInState, setsigninState] = useState(false);
  const [RepeatPasswordState, setRepeatPasswordState] = useState(false);
  const [SignUpState, setSignUpState] = useState(false);
  const [SignUpPhoneState, setSignUpPhoneState] = useState(false);
  const [createPassword, setcreatePassword] = useState(false);
  const [OtpState, setOtpState] = useState(false);
  const [passwordChangeState, setpasswordChangeState] = useState(false);
  return (
    <div>
      <div className="flex items-start home">
        {signInState && (
          <SignIn
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setRepeatPasswordState={setRepeatPasswordState}
          />
        )}
        {RepeatPasswordState && (
          <RepeatPassword
            setsigninState={setsigninState}
            setRepeatPasswordState={setRepeatPasswordState}
            setOtpState={setOtpState}
          />
        )}

        {SignUpState && (
          <SignUp
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setSignUpPhoneState={setSignUpPhoneState}
          />
        )}
        {SignUpPhoneState && (
          <SignUpPhone
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setSignUpPhoneState={setSignUpPhoneState}
          />
        )}
        {OtpState && (
          <Otp
            setOtpState={setOtpState}
            setcreatePassword={setcreatePassword}
          />
        )}
        {createPassword && (
          <CreateNewPassword
            setcreatePassword={setcreatePassword}
            setpasswordChangeState={setpasswordChangeState}
          />
        )}
        {passwordChangeState && (
          <PasswordChangeDone
            setpasswordChangeState={setpasswordChangeState}
            setsigninState={setsigninState}
          />
        )}

        {/* left area */}
        <Aside activeSwitch="staker-switch" />
        {/* right area */}
        <RightAreaStake
          login={true}
          setsigninState={setsigninState}
          setRepeatPasswordState={setRepeatPasswordState}
          setSignUpState={setSignUpState}
          setSignUpPhoneState={setSignUpPhoneState}
        />
      </div>

      <Footer />
    </div>
  );
};
