import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import emoji from "../../assets/img/emoji.svg";
import confetti from "../../assets/img/p2p/confetti.svg";
import copy from "../../assets/img/p2p/copy.svg";
import noticeRed from "../../assets/img/p2p/noticeRed.svg";
import profile from "../../assets/img/p2p/profile.svg";
import send from "../../assets/img/send.svg";
import backImg from "../../assets/img/transaction/back.svg";
import notificationSound from '../../assets/sound/message_sound.mp3';
import { privateRequest } from "../../config/axios.config";
import { useAuth } from "../../contexts/AuthContext";
import { useOrderDetails } from "../../queries/p2p";
import { dateFormatter } from "../../utils";
import { SidebarArea } from "../SidebarArea";
import Stars from "../popups/Stars";
import { P2PNav } from "./P2PNav";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL || "http://localhost:3000";
const SOCKET_PATH = "/user/socket/chat";

export const TraderDetailSell = ({ }) => {
  const { userInfo } = useAuth();
  const audio = new Audio(notificationSound);
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [stepActive, setStepActive] = useState(1);
  const [appealName, setAppealName] = useState("");
  const [appealNumer, setAppealNumber] = useState("");
  const [status, setStatus] = useState("");
  const [previewImages, setPreviewImages] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [formattedExpireAt, setFormattedExpireAt] = useState("");
  const [formattedCreatedAt, setFormattedCreatedAt] = useState("");
  const [orderStatus, setOrderStatus] = useState(""); // State to track order status
  const [openNotice, setOpenNotice] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  let { orderId } = useParams();
  const result = useOrderDetails(orderId);
  const [socket, setSocket] = useState(null);
  const orderDetails = result?.data?.data;
  const [showRateModal, setShowRateModal] = useState(false);
  const defaultRating = localStorage.getItem("starRating");

  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      alert('Copied to clipboard');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  const handleConfirmClick = () => {
    setShowModal(true);
  };

  const handleCancelOrderModal = () => {
    setShowCancelModal(false);
    setShowCancelOrderModal(true);
  };

  const handleAppeal = () => {
    setStepActive(2);
    setShowModal(false);
  };

  const handleGoBack = () => {
    setStepActive(1);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const previewImagesArray = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        previewImagesArray.push(reader.result);
        setPreviewImages([...previewImages, reader.result]);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleRemoveImage = (index) => {
    const newPreviewImages = [...previewImages];
    newPreviewImages.splice(index, 1);
    setPreviewImages(newPreviewImages);
  };

  useEffect(() => {
    document.body.style.overflow =
      showCancelModal || showCancelOrderModal || showModal ? "hidden" : "unset";
  }, [showCancelModal || showCancelOrderModal || showModal]);

  // useEffect(() => {
  //   const initialTimeLeft = Math.floor(
  //     (new Date(orderDetails?.paymentWindowExpireAt) - new Date()) / 1000
  //   );
  //   setTimeLeft(initialTimeLeft);

  //   const formattedExpireDate = new Date(
  //     orderDetails?.paymentWindowExpireAt
  //   ).toLocaleString("en-US", { timeZone: "UTC" });
  //   setFormattedExpireAt(formattedExpireDate);

  //   const formattedCreateDate = new Date(
  //     orderDetails?.createdAt
  //   ).toLocaleString("en-US", { timeZone: "UTC" });
  //   setFormattedCreatedAt(formattedCreateDate);

  //   if (new Date(orderDetails?.paymentWindowExpireAt) < new Date()) {
  //     if (
  //       orderDetails.status == "inEscrow" &&
  //       orderDetails.isFiatPaid == false
  //     ) {
  //       toast.error("Order time is expired");  
  //       orderDetails.status = "Expired";
  //     }
  //     // setOrderStatus('Expired');
  //   } else {
  //     const intervalId = setInterval(() => {
  //       setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
  //     }, 1000);
  //     return () => clearInterval(intervalId);
  //   }
  // }, [orderDetails]);

  useEffect(() => {
    setOrderStatus(orderDetails?.status)
  }, [result]);

  const formattedTimeLeft = `${Math.floor(timeLeft / 60)}:${(
    "0" +
    (timeLeft % 60)
  ).slice(-2)}`;

  const [appealReason, setAppealReason] = useState("");

  const handleTextareaChange = (event) => {
    const inputValue = event.target.value;
    // Update the state with the new value
    setAppealReason(inputValue);
  };

  // handle ratings
  const handleRatings = async () => {
    try {
      const res = await privateRequest.post(`user/p2p/rating/submitRating`, {
        orderId: orderId,
        point: defaultRating,
        remarks: appealReason
      });
      toast.success("Ratings given successfully!");
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("Error : ", error);
    }
    setShowRateModal(false);
  };


  const handleReleaseOrder = async () => {
    try {
      const res = await privateRequest.patch(
        `user/p2p/order/releaseOrder/${orderId}`
      );
      toast.success("Order Released!");
      result.refetch();

    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setShowModal(false);
  };
  const handleDisputeOrder = async () => {
    try {
      const res = await privateRequest.patch(
        `user/p2p/order/disputeOrder/${orderId}`
      );
      toast.success("Order Disputed!");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setOpenNotice(false)
  };

  // send message
  const sendMessage = async () => {
    if (!message) return; // Prevent sending empty messages
    try {
      const sentMessage = {
        senderType: "sender",
        content: message,
        conversation: orderId,
        createdAt: new Date().toISOString(), // Mocking the timestamp
      };
      await privateRequest.post(`user/chat/sendMessage`, {
        refId: orderId,
        message: message,
      });
      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      setMessage("");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const newSocket = io(SOCKET_SERVER_URL, { path: SOCKET_PATH });
    const currentUserId = userInfo?.user?._id;
    newSocket.on("connect", () => {
      console.log("Socket connected, joining room:", orderId);
      newSocket.emit("joinRoom", { chatroomId: orderId });
    });

    newSocket.on("newMessage", (message) => {
      console.log("Messages", message);
      if (message.content.sender._id !== currentUserId) {
        setMessages((prevMessages) => [...prevMessages, message.content]);
        audio.play().catch(error => console.error('Error playing the sound:', error));
      }
    });

    newSocket.on("connect_error", (err) => {
      console.error("Connection error:", err);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [orderId]);

  return (
    <>
      <div>
        <P2PNav />
      </div>
      <div className="body-area flex items-start px-6 pt-6">
        <div className="body-left-area w-full">
          <div className="flex justify-between gap-4 sm:flex-col">
            {stepActive === 1 && (
              <>
                {/*RELEASE DIV */}
                <div className="bg-[#1A1A1B] border border-[#3C403F] rounded-lg p-4 w-full">
                  <div className="flex items-center">
                    <Link to="/p2p/trader-order">
                      <img src={backImg} alt="" />
                    </Link>
                    <h1 className="flex justify-between text-white text-[20px] font-arabicbold ml-4">
                      Sell  {orderDetails?.advertisement.cryptoCurrency}
                    </h1>

                  </div>
                  <div className="flex justify-between pb-6 border-b border-[#3C403F]  sm:flex-col">
                    {/* <p className="text-[#AEB2B1] text-[16px] font-arabicreg">
                        Created time:{" "}
                        <span className="text-white">{orderDetails?.createdAt}</span>
                      </p> */}
                    <p className="text-[#AEB2B1] text-[16px] font-arabicreg flex gap-2">
                      Order Number:{" "}
                      <span className="text-white">{orderDetails?._id}</span>
                      <img
                        src={copy}
                        className="cursor-pointer"
                        onClick={() => handleCopyClick(orderDetails?._id)}
                      />
                    </p>
                  </div>
                  <div className="flex justify-between py-6 border-b border-[#3C403F]">
                    <div>
                      <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                        Amount
                      </p>
                      <h1 className="text-white text-[16px] font-arabicbold">
                        {parseFloat(orderDetails?.fiatAmount)}{" "}
                        {orderDetails?.advertisement?.fiatCurrency}
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                        Price per {orderDetails?.advertisement?.cryptoCurrency}
                      </p>
                      <h1 className="text-white text-[16px] font-arabicbold">
                        {orderDetails?.exchangeRate}{" "}
                        {orderDetails?.advertisement?.fiatCurrency}
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">
                        Quantity
                      </p>
                      <h1 className="text-white text-[16px] font-arabicbold">
                        {orderDetails?.amount}{" "}
                        {orderDetails?.advertisement?.cryptoCurrency}
                      </h1>
                    </div>
                  </div>
                  <div className="w-full mt-5 border-b border-[#3C403F]">
                    {orderDetails?.isFiatPaid === false ? <>
                      <h1 className="text-white text-[20px] font-arabicbold mb-4">
                        Buyer will transfer funds to this account:
                      </h1>
                    </> : <>
                      <h1 className="text-white text-[20px] font-arabicbold mb-4">
                        Buyer has Transfered the payment
                      </h1>
                    </>}
                    {orderDetails?.paymentMethod?.map((detail, index) => (
                      <div key={index}>
                        <div className="text-center">
                          <h1 className="text-white text-[18px] font-arabicbold mx-auto">
                            {detail?.name}
                          </h1>
                        </div>
                        {Object?.entries(detail?.data?.data)?.map(
                          ([key, value]) => (
                            <div
                              key={key}
                              className="flex justify-between items-center mb-2"
                            >
                              <p className="text-[#AEB2B1] text-[16px] font-arabicbold">
                                {key}
                              </p>
                              <h1 className="text-white text-[16px] font-arabicbold flex items-center">
                                {value}
                                <img
                                  src={copy}
                                  className="ml-2 cursor-pointer"
                                  alt="copy icon"
                                  onClick={() => handleCopyClick(value)}
                                />
                              </h1>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="mt-5">
                    {orderDetails?.status === 'released' ? <>
                      <h1 className="text-[#6FCF17] text-[18px] font-arabicbold pt-4 w-full text-center mb-3">
                        Order released, trade has completed
                      </h1>
                      <button className="text-[#282828] bg-[#6FCF17] rounded-lg py-2 w-full font-arabicbold" onClick={(e) => {
                        setShowRateModal(!showRateModal);
                      }}>Give ratings</button>
                    </> : <>
                      {orderDetails?.isFiatPaid === true ? <>
                        <div className="flex justify-between items-center gap-3">
                          <button
                            onClick={() => setOpenNotice(true)}
                            className="text-white bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-2 w-full"
                          >
                            Dispute
                          </button>
                          <button
                            className="text-[#2E2E2E] bg-[#6FCF17] rounded-lg py-2 w-full font-arabicbold"
                            onClick={handleConfirmClick}
                          >
                            Release Order
                          </button>
                        </div>
                      </> : <>
                        <h1 className="text-white text-[20px] font-arabicbold mb-4 text-center">
                          Wait for the buyer to paid
                        </h1>
                      </>}
                    </>}
                  </div>
                </div>
              </>
            )}



            {/* CHAT BOX */}
            <div className="bg-[#1A1A1B] border border-[#3C403F] rounded-lg p-4 w-full relative">
              <div className="flex items-start gap-2 pb-6 border-b border-[#3C403F]">
                <img src={profile} />
                <div>
                  <h1 className="text-white text-[18px] font-arabicbold">
                    {orderDetails?.receiver?.email}
                  </h1>
                  <p className="text-[#AEB2B1] text-[16px] font-arabicreg">
                    ID - {orderDetails?.sender?._id}
                  </p>
                </div>
              </div>


              <div className="flex flex-col gap-2 mt-3 h-[335px] overflow-y-scroll pr-2 mb-10">
                {messages?.map((msg, index) => (
                  <div
                    key={index}
                    className={`flex ${msg?.senderType === "sender"
                      ? "justify-end mb-4"
                      : "justify-start"
                      } w-full`}
                  >
                    <div className="flex flex-col w-2/3">
                      <div className="flex items-center">
                        {msg?.senderType === "sender" ? (
                          <>
                            <span class="text-xs text-gray-700">
                              You
                            </span>
                          </>
                        ) : <>
                          <img src={profile} className="w-12 h-12 pr-4" />

                        </>}
                        <h4 className="text-sm text-white">
                          {msg?.sender?.email}
                        </h4>
                      </div>
                      <div className="p-4 mt-1 bg-[#252525] rounded-t-lg rounded-br-xl">
                        <p className="text-[#AEB2B1] text-[14px] font-arabicreg">
                          {msg?.content}
                        </p>
                      </div>
                      {msg?.senderType === "user" && (
                        <>
                          <span class="text-xs text-gray-700">
                            {dateFormatter(msg?.createdAt)}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="message-area flex items-center px-4 h-14 rounded-lg border border-[#3C403F]">
                <input
                  type="text"
                  placeholder="Send a message..."
                  className="flex-1 w-full"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
                <div className="flex">
                  <img src={emoji} alt="emoji" className="mr-3" />
                  <img
                    src={send}
                    alt="send"
                    className="cursor-pointer"
                    onClick={sendMessage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>

      {showRateModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#111010] bg-opacity-70 z-999 ">
          <div className="bg-[#252525] border border-[#3C403F] p-8 rounded-lg text-center w-[520px]">
            {/* <img src={confetti} className="mx-auto my-4 w-50 h-50" /> */}
            <h1 className="text-white text-[20px] font-arabicbold">
              Rate your experience
            </h1>
            <p className="text-[#AEB2B1] text-[16px] font-arabicreg mb-4">
              We highly value your feedback! Kindly take a moment to rate your
              experience and provide us with your valuable feedback.{" "}
            </p>

            <Stars iconSize={74} defaultRating={defaultRating} />
            <div className="flex flex-col">
              <textarea
                className="mt-4 p-2 border text-white font-arabicbold bg-[#2E2E2E] border-gray-300 rounded-md focus:outline-none focus:ring focus:white"
                rows="5"
                maxLength="500"
                placeholder="Tell us about your experience!"
                value={appealReason}
                onChange={handleTextareaChange}
              ></textarea>
              <p className="text-right text-[#6FCF17] text-[14px] py-4 font-arabicbold">
                {appealReason.length}{" "}
                <span className="text-white font-arabicbold text-[14px]">
                  /500
                </span>
              </p>
            </div>
            <div className="flex justify-between gap-4 my-4">
              <button
                className="text-white text-[14px] bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-3 w-full"
                onClick={() => setShowRateModal(false)}
              >
                No, Thanks
              </button>
              <button
                className="text-white text-[14px] bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-lg py-3 w-full"
                // onClick={handleConfirmClick}
                onClick={handleRatings}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )}

      {openNotice && (
        <div className="modal w-full">
          <div className="modal-content font-arabicmed rounded-lg overflow-hidden w-full">
            <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6">
              <div className="w-full flex flex-col items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                <img
                  src={noticeRed}
                  className="py-4"
                  style={{ color: "#F6465D" }}
                />
                <p className="text-white text-[24px] text-center sm:text-[18px] font-arabicbold">
                  Dispute Order
                </p>
                <p className="text-[#AEB2B1] text-center py-2 font-arabicbold">
                  Are you sure you want to dispute order?
                </p>
              </div>

              <div className="w-full flex sm:flex-col items-center py-4 pt-4 my-2 gap-4">
                <button
                  className="bg-[#252525] w-full sm:order-2 border-2 border-[#3C403F] rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#363636]"
                  // onClick={closeModal}
                  onClick={() => setOpenNotice(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#F6465D] w-full sm:order-1 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#ad4343]"
                  // onClick={handleSellClick}
                  onClick={handleDisputeOrder}
                >
                  Dispute
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#111010] bg-opacity-70 z-999">
          <div className="bg-[#252525] border border-[#3C403F] p-8 rounded-lg text-center">
            <img src={confetti} className="mx-auto my-4 w-50 h-50" />
            <h1 className="text-white text-[20px] font-arabicbold">
              Release Order
            </h1>
            <p className="text-[#AEB2B1] text-[16px] font-arabicreg mb-10">
              Please confirm theat payment has been made to the seller. <br />
              Malicious clicks will lead to account frozen.
            </p>
            <div className="flex justify-between gap-4 my-4">
              <button
                className="text-white text-[14px] bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-3 w-full"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="text-[#2E2E2E] text-[14px] bg-[#F6465D] rounded-lg py-3 w-full font-arabicbold"
                onClick={handleReleaseOrder}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

    </>
  );
};
